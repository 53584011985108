import React, { type FunctionComponent, useCallback } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Box, Center, Heading, Button, Text } from '@chakra-ui/react';

import { useKeycloak } from '@react-keycloak/web';

export const Login: FunctionComponent = () => {
    const { keycloak } = useKeycloak();

    const location = useLocation();
    const currentLocationState: Record<string, unknown> = location.state || {
        from: { pathname: '/' },
    };

    const login = useCallback(async () => {
        await keycloak?.login();
    }, [keycloak]);

    if (keycloak?.authenticated) return <Navigate to={currentLocationState?.from as string} />;

    return (
        <Box py="12" px="8">
            <Heading mt="20" mb="10" textAlign="center" size="xl" fontWeight="extrabold">
                Outbank ServicePortal
            </Heading>
            <Text textAlign="center">Sign in to the next generation Outbank tools powered by Wolfsrudel.</Text>
            <Center mt="10">
                <Button
                    colorScheme="blue"
                    size="lg"
                    fontSize="md"
                    onClick={async () => {
                        await login();
                    }}
                >
                    Sign in
                </Button>
            </Center>
        </Box>
    );
};
