import React, { type FunctionComponent } from 'react';
import { Box, HStack, Icon, Square, Stack, Heading, Button, useColorModeValue as mode } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { type IconType } from 'react-icons';

interface Props {
    count?: number;
    title: string;
    link: string;
    icon: IconType;
}

export const StatBox: FunctionComponent<Props> = (props) => {
    const { count, title, link, icon } = props;

    const navigate = useNavigate();
    const clickLink = (): void => {
        navigate(link);
    };

    return (
        <Box
            px={{ base: '4', md: '6' }}
            py={{ base: '5', md: '6' }}
            bg="bg-surface"
            borderRadius="lg"
            boxShadow={{ base: 'none', sm: 'md' }}
        >
            <Stack spacing={{ base: '5', md: '6' }}>
                <Stack direction="row" justify="space-between">
                    <HStack spacing="4">
                        <Square
                            size="12"
                            bg={mode('gray.200', 'gray.700')}
                            borderRadius="md"
                            onClick={clickLink}
                            cursor="pointer"
                        >
                            <Icon as={icon} boxSize="6" />
                        </Square>
                        <Heading as="h4" size="md">
                            {title}
                        </Heading>
                    </HStack>
                </Stack>
                <Stack spacing="4">
                    {count?.toString && <Heading textAlign="center">{count}</Heading>}
                    <Button onClick={clickLink}>more</Button>
                </Stack>
            </Stack>
        </Box>
    );
};
