import React, { type FunctionComponent, type ReactNode } from 'react';
import { Box, Heading, Stack, Text, useColorModeValue as mode } from '@chakra-ui/react';
import LoadingIndicator from './LoadingIndicator';
import { type CombinedError } from 'urql';
import ErrorBox from './ErrorBox';

interface Props {
    children?: ReactNode;
    headline: string;
    description?: string;
    showSpinner?: boolean;
    error?: CombinedError;
}

export const ContentContainer: FunctionComponent<Props> = (props: Props) => {
    const { showSpinner = false, error } = props;

    let content = props.children;
    if (showSpinner) content = <LoadingIndicator />;
    if (error) content = <ErrorBox title={error.name} message={error.message} />;

    return (
        <Box pb="4">
            <Stack>
                <Heading size="lg">{props.headline}</Heading>
                {props.description && (
                    <Text color={mode('gray.600', 'gray.400')} pb="3">
                        {props.description}
                    </Text>
                )}
            </Stack>
            {content}
        </Box>
    );
};
