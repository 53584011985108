/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { type FunctionComponent, type ReactNode, useEffect } from 'react';
import { Text, Box, useDisclosure, Divider, Flex, Collapse } from '@chakra-ui/react';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';

interface Props {
    headline?: string;
    children?: ReactNode | ReactNode[];
    collapseAble?: boolean;
    collapseInitial?: boolean;
    [x: string]: unknown;
}

export const RoundedBox: FunctionComponent<Props> = (props: Props) => {
    const { headline, children, collapseAble = false, collapseInitial, ...rest } = props;
    const { isOpen, onToggle } = useDisclosure();

    useEffect(() => {
        if (collapseInitial) onToggle();
    }, []);

    let chevron = null;
    if (collapseAble && isOpen) chevron = <IoChevronUp />;
    else if (collapseAble && !isOpen) chevron = <IoChevronDown />;

    const _rest = {
        ...{ rounded: { lg: 'lg' }, shadow: 'base', my: 4, p: 3 },
        ...rest,
    };

    let styleText = {};
    if (collapseAble) styleText = { as: 'h3', fontWeight: 'bold', fontSize: 'lg', size: 'max' };

    return (
        <Box {..._rest}>
            {headline && !collapseAble && <Text {...styleText}>{headline}</Text>}
            {headline && collapseAble && (
                <Flex align="center" justify="space-between" cursor="pointer" onClick={onToggle}>
                    <Text as="h3" fontWeight="bold" fontSize="lg" size="max">
                        {headline}
                    </Text>
                    {isOpen ? <IoChevronUp /> : <IoChevronDown />}
                </Flex>
            )}
            {collapseAble && isOpen && <Divider mt={2} />}
            {collapseAble && (
                <>
                    <Collapse in={isOpen} animateOpacity>
                        {children}
                    </Collapse>
                </>
            )}
            {!headline && !collapseAble && children}
        </Box>
    );
};
