import React, { type FunctionComponent, useState, useMemo } from 'react';
import { type IContractsData } from '../../interface/SampleData';
import {
    Card,
    CardHeader,
    Heading,
    CardBody,
    Editable,
    EditablePreview,
    EditableTextarea,
    Divider,
    Button,
} from '@chakra-ui/react';
import { type Column } from 'react-table';
import { StateOptions } from '../../components/table/StateOptions';
import { colorForState } from '../../helper';
import { TableContainer } from '../../components/table/TableContainer';
import { cloneDeep } from 'lodash';

interface SampleContractDataProps {
    selectedContract: IContractsData;
    saveContract: (contract: IContractsData) => void;
}

export const SampleContractData: FunctionComponent<SampleContractDataProps> = ({
    selectedContract,
    saveContract,
}: SampleContractDataProps) => {
    const [currentlySelectedContract, setCurrentlySelectedContract] = useState<IContractsData>(selectedContract);
    const tempContract: IContractsData = cloneDeep(selectedContract);

    const handleChange = (
        key: keyof IContractsData,
        value: string,
        arrayIndex: number = -1,
        arrayName: keyof IContractsData | undefined = undefined,
    ): boolean => {
        if (arrayIndex >= 0 && arrayName && Array.isArray(tempContract[arrayName])) {
            const array = tempContract[arrayName] as any[];
            if (arrayIndex < array.length) {
                const arrayItem = array[arrayIndex];
                if (typeof arrayItem === 'object' && arrayItem !== null) {
                    arrayItem[key] = value;
                }
            }
        } else {
            (tempContract[key] as string) = value;
        }
        setCurrentlySelectedContract(tempContract);
        return true;
    };

    const saveChanges = (): boolean => {
        saveContract(currentlySelectedContract);
        return true;
    };

    const amountsTableData = useMemo(() => currentlySelectedContract.amounts, [currentlySelectedContract]);
    const amountsColumns: Array<Column<any>> = useMemo(
        () => [
            {
                Header: 'key',
                accessor: 'key',
                Cell: (cell) => {
                    return (
                        <>
                            <Editable
                                defaultValue={cell.row.original.key}
                                onChange={(value) =>
                                    handleChange(
                                        'key' as keyof IContractsData,
                                        value,
                                        cell.row.index,
                                        'amounts' as keyof IContractsData,
                                    )
                                }
                            >
                                <EditablePreview />
                                <EditableTextarea />
                            </Editable>
                        </>
                    );
                },
            },
            {
                Header: 'value',
                accessor: 'value',
                Cell: (cell) => {
                    return (
                        <>
                            <Editable
                                defaultValue={cell.row.original.value.toString()}
                                onChange={(value) =>
                                    handleChange(
                                        'value' as keyof IContractsData,
                                        value,
                                        cell.row.index,
                                        'amounts' as keyof IContractsData,
                                    )
                                }
                            >
                                <EditablePreview />
                                <EditableTextarea />
                            </Editable>
                        </>
                    );
                },
            },
            {
                Header: 'currency',
                accessor: 'currency',
                Cell: (cell) => {
                    return (
                        <>
                            <Editable
                                defaultValue={cell.row.original.currency}
                                onChange={(value) =>
                                    handleChange(
                                        'currency' as keyof IContractsData,
                                        value,
                                        cell.row.index,
                                        'amounts' as keyof IContractsData,
                                    )
                                }
                            >
                                <EditablePreview />
                                <EditableTextarea />
                            </Editable>
                        </>
                    );
                },
            },
        ],
        [],
    );

    const fieldsTableData = useMemo(() => currentlySelectedContract.fields, [currentlySelectedContract]);
    const fieldsColumns: Array<Column<any>> = useMemo(
        () => [
            {
                Header: 'key',
                accessor: 'key',
                Cell: (cell) => {
                    return (
                        <>
                            <Editable
                                defaultValue={cell.row.original.key}
                                onChange={(value) =>
                                    handleChange(
                                        'key' as keyof IContractsData,
                                        value,
                                        cell.row.index,
                                        'fields' as keyof IContractsData,
                                    )
                                }
                            >
                                <EditablePreview />
                                <EditableTextarea />
                            </Editable>
                        </>
                    );
                },
            },
            {
                Header: 'value',
                accessor: 'value',
                Cell: (cell) => {
                    return (
                        <>
                            <Editable
                                defaultValue={cell.row.original.value.toString()}
                                onChange={(value) =>
                                    handleChange(
                                        'value' as keyof IContractsData,
                                        value,
                                        cell.row.index,
                                        'fields' as keyof IContractsData,
                                    )
                                }
                            >
                                <EditablePreview />
                                <EditableTextarea />
                            </Editable>
                        </>
                    );
                },
            },
        ],
        [],
    );

    return (
        <>
            <Card>
                <CardHeader>
                    <Heading size="md">Contract Details</Heading>
                </CardHeader>

                <CardBody>
                    <Heading size="xs" textTransform="uppercase">
                        ID
                    </Heading>
                    <Editable
                        defaultValue={currentlySelectedContract.id}
                        onChange={(value) => handleChange('id' as keyof IContractsData, value)}
                    >
                        <EditablePreview />
                        <EditableTextarea />
                    </Editable>
                </CardBody>
            </Card>
            <Divider mt="8" />
            <Heading as="h3" size="lg">
                Amounts
            </Heading>
            <TableContainer
                columns={amountsColumns}
                data={amountsTableData}
                search={{
                    placeholder: 'Search for Amounts',
                    selectConfig: {
                        columAccessor: 'state',
                        selectOptions: <StateOptions />,
                    },
                }}
                hiddenColumns={[]}
                showColoredState={true}
                coloredStateMethod={colorForState}
                initialState={{
                    sortBy: [{ id: 'order', desc: true }],
                }}
                defaultPageSize={20}
            />
            <Divider mt="8" />
            <TableContainer
                columns={fieldsColumns}
                data={fieldsTableData}
                search={{
                    placeholder: 'Search for Fields',
                    selectConfig: {
                        columAccessor: 'state',
                        selectOptions: <StateOptions />,
                    },
                }}
                hiddenColumns={[]}
                showColoredState={true}
                coloredStateMethod={colorForState}
                initialState={{
                    sortBy: [{ id: 'order', desc: true }],
                }}
                defaultPageSize={20}
            />
            <Button onClick={saveChanges} colorScheme="blue" mr="10">
                Save Changes
            </Button>
        </>
    );
};
