/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { type FunctionComponent, useMemo } from 'react';
import { type Request } from '../../interface/Crash';
import { type Column } from 'react-table';
import { TableContainer } from '../table/TableContainer';
import { Code, Divider } from '@chakra-ui/react';
import { convertObjectToHeaderArray } from './RequestHeaderView';
import { Buffer } from 'buffer';

interface Props {
    request: Request;
}

export const RequestBodyView: FunctionComponent<Props> = (props: Props) => {
    const { request } = props;

    let requestBody: string | null = null;
    if (request.url.startsWith('starfish://graphql') && request.data && typeof request.data === 'string') {
        requestBody = JSON.parse(request.data);
    } else if (request.data && 'bytes' in request.data) {
        requestBody = Buffer.from(request.data.bytes, 'base64').toString();
    }

    let tableData;
    let columns;
    if (!request.url.startsWith('starfish://graphql') && requestBody) {
        const requestContentTypeElement = convertObjectToHeaderArray(request.headers).find(
            (item: string[]) => item[0].toLowerCase() === 'content-type',
        );
        const requestContentType = requestContentTypeElement ? requestContentTypeElement[1] : null;
        let interpretedRequestBody: string[][] | null = null;
        if (requestContentType?.includes('urlencoded')) {
            interpretedRequestBody = decodeURIComponent(encodeURIComponent(requestBody))
                .split('&')
                .map((item) => item.split('='));
        } else if (requestContentType?.includes('application/json')) {
            try {
                const _parsedRequestBody = JSON.parse(requestBody);
                interpretedRequestBody = Object.keys(_parsedRequestBody).map((key) => {
                    return [key, _parsedRequestBody[key]];
                });
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log('Error during application/json parsing');
                // eslint-disable-next-line no-console
                console.log('error: ', error);
                // eslint-disable-next-line no-console
                console.log('requestBody: ', requestBody);
            }
        }

        tableData = useMemo(() => interpretedRequestBody, [interpretedRequestBody]);
        columns = useMemo(
            () => [
                {
                    Header: 'name',
                    accessor: (row) => row[0],
                    disableSortBy: true,
                },
                {
                    Header: 'value',
                    accessor: (row) => JSON.stringify(row[1], null, 2),
                    disableSortBy: true,
                },
            ],
            [interpretedRequestBody],
            // any is in real Array<Column<Array<string>>>, but TabelContainer can't work with it
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ) as Array<Column<any>>;
    }

    return (
        <>
            {request.url.startsWith('starfish://graphql') && requestBody && (
                <Code p={1}>{JSON.stringify(requestBody)}</Code>
            )}
            {!request.url.startsWith('starfish://graphql') && requestBody && <Code p={1}>{requestBody}</Code>}
            {tableData && columns && (
                <>
                    <Divider mt={5} />
                    <TableContainer
                        columns={columns}
                        data={tableData}
                        search={{
                            placeholder: 'Search for values',
                        }}
                    />
                </>
            )}
        </>
    );
};
