import React, { type FunctionComponent } from 'react';
import { Stack, Text, HStack } from '@chakra-ui/react';
import { DetailsDrawer } from '../../components/detailsDrawer/DetailsDrawer';
import { DetailsElement } from '../../components/detailsDrawer/DetailsElement';
import { type OutbankFeatureData } from '../../interface/Issue';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    featureDetails: OutbankFeatureData;
}

export const Details: FunctionComponent<Props> = (props: Props) => {
    const { featureDetails, isOpen, onClose } = props;
    const { name, description, notes } = featureDetails;

    return (
        <DetailsDrawer
            isOpen={isOpen}
            onClose={onClose}
            title={
                <HStack>
                    <Text>User request details</Text>
                </HStack>
            }
            size="lg"
        >
            <Stack>
                <DetailsElement label="Name" value={name} />
                <DetailsElement label="Description" value={description.split('<details>')[0]} />
                {notes.length > 0 && (
                    <>
                        <DetailsElement label="Notes">
                            {notes.map(function (item, idx) {
                                return (
                                    <Text key={idx}>
                                        {item.split('[[DP')[0]}
                                        <hr />
                                    </Text>
                                );
                            })}
                        </DetailsElement>
                    </>
                )}
            </Stack>
        </DetailsDrawer>
    );
};
