/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { type FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Text, HStack, Button, Link, Spacer } from '@chakra-ui/react';
import { DetailsDrawer } from '../../components/detailsDrawer/DetailsDrawer';
import { type Size } from '../../interface/Chakra';
import { useFilteredCrashesGroupedByVersionQuery } from '../../generated/types';
import { TableContainer } from '../../components/table/TableContainer';
import moment from 'moment';
import { type Column } from 'react-table';
import { Link as RouterLink } from 'react-router-dom';
import { RoundedBox } from '../../components/RoundedBox';
import { FileVersion, requestVersionDetails } from '../../components/FileVersion';
import { type Commit } from '../../interface/Commit';
import { type CrashGroup } from '../crash/CrashTable';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    crashGroup: CrashGroup;
    size?: Size;
    actionMenu: (item: CrashGroup) => JSX.Element;
}

interface CrashGroupData {
    id: string;
    fileString: string;
    dateRange: string;
    total: number;
    line: string;
    detailPath: string;
    dateMaxInt: number;
}

const regexVersion = /(.*)@(\w+):\d+/;

export const Details: FunctionComponent<Props> = (props: Props) => {
    const { crashGroup, actionMenu } = props;
    const [resultFilteredCrashesGroupedByVersionQuery] = useFilteredCrashesGroupedByVersionQuery({
        variables: { id: crashGroup.id },
    });
    const [fileVersionDetails, setFileVersionDetails] = useState<Record<string, Commit[]> | undefined>(undefined);
    const { data, fetching, error } = resultFilteredCrashesGroupedByVersionQuery;

    const crashGroupData = data?.crashes?.groupedAggregates?.map((item) => {
        const fileString = `${crashGroup.file}@${item.keys![0].split('_')[0]}:${item?.average?.fileLineNumber}`;
        return {
            id: fileString,
            fileString,
            line: parseInt(item.distinctCount?.fileLineNumber) > 1 ? 'multiple lines' : fileString.split(':')[1],
            dateRange: `${moment.unix(item.min!.createdAtToInt!).format('YY-MM-DD')} - ${moment
                .unix(item.max!.createdAtToInt!)
                .format('YY-MM-DD')}`,
            total: item.distinctCount?.id,
            dateMaxInt: item.max,
            detailPath: `${crashGroup.file}@${item.keys![0].split('_')[0]}`,
        };
    });

    useEffect(() => {
        async function loadFileVersions(): Promise<void> {
            const detailsObject: Record<string, Commit[]> = {};

            if (crashGroupData) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const array: any = crashGroupData.map((item) => {
                    const result = item.fileString.match(regexVersion);
                    if (result) return result[1];
                    return undefined;
                });

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                const arrayUniqueByKey = [...new Set(array)];
                if (arrayUniqueByKey) {
                    for (const item of arrayUniqueByKey) {
                        detailsObject[item] = await requestVersionDetails(item);
                    }
                    setFileVersionDetails(JSON.parse(JSON.stringify(detailsObject)));
                }
            }
        }

        loadFileVersions();
    }, [data]);

    const tableData = useMemo(() => crashGroupData, [crashGroupData]);
    const columns: Array<Column<CrashGroupData>> = useMemo(
        () => [
            {
                Header: 'Version',
                accessor: (row) => {
                    const result = row.fileString.match(regexVersion);
                    if (result && fileVersionDetails)
                        return (
                            <FileVersion file={result[1]} version={result[2]} details={fileVersionDetails[result[1]]} />
                        );
                    return null;
                },
            },
            {
                Header: 'Line',
                accessor: 'line',
            },
            {
                Header: 'Date Range (YY-MM-DD)',
                accessor: 'dateRange',
            },
            {
                Header: 'Total',
                accessor: 'total',
                textAlign: 'right',
            },
            {
                Header: 'DateMaxInt',
                accessor: 'dateMaxInt',
            },
            {
                Header: '',
                accessor: 'id',
                disableSortBy: true,
                // eslint-disable-next-line react/display-name
                Cell: (_cell) => (
                    <Button mt="2">
                        <Link
                            as={RouterLink}
                            to={`../crashlist/crashgroups/${_cell.row.original.detailPath}:${crashGroup.id}`}
                        >
                            Open
                        </Link>
                    </Button>
                ),
                textAlign: 'right',
                hasCellClick: false,
            },
        ],
        [crashGroupData, fileVersionDetails],
    );

    return (
        <DetailsDrawer
            {...props}
            title={
                <HStack mr={10}>
                    <Text style={{ flex: 1 }}>{crashGroup.crashes.nodes[0].file}</Text>
                    <Spacer />
                    {actionMenu(crashGroup)}
                </HStack>
            }
            showSpinner={fetching}
            error={error}
        >
            <RoundedBox mb={8} bg="yellow.200" w="100%" color="black">
                {crashGroup.codeLine}
            </RoundedBox>
            <RoundedBox mb={8} bg="blue.200" w="100%" color="black">
                {crashGroup.message}
            </RoundedBox>
            {tableData && (
                <TableContainer
                    columns={columns}
                    data={tableData}
                    initialState={{
                        sortBy: [{ id: 'dateMaxInt', desc: false }],
                    }}
                    hiddenColumns={['dateMaxInt']}
                />
            )}
        </DetailsDrawer>
    );
};
