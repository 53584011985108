import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { Client, Provider as UrqlProvider, fetchExchange, cacheExchange } from 'urql';
import { authExchange } from '@urql/exchange-auth';
import { Request } from './helper';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { keycloakObject } from './keycloak';

const KEYCLOAK_KEY_TOKEN = 'token';
const KEYCLOAK_KEY_REFRESH_TOKEN = 'refreshToken';

const eventLogger = (event: unknown, error: unknown): void => {
    if (error) {
        // eslint-disable-next-line no-console
        console.log('onKeycloakEvent', event, error);
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const tokenLogger = (tokens: any): void => {
    if (tokens?.token) {
        Request.accessToken = tokens.token;
        localStorage.setItem(KEYCLOAK_KEY_TOKEN, tokens.token);
        localStorage.setItem(KEYCLOAK_KEY_REFRESH_TOKEN, tokens.refreshToken);
    }
};

interface InitializeAuthState {
    token: string | null;
    refreshToken: string | null;
}

const initializeAuthState = (): InitializeAuthState => {
    const token = localStorage.getItem(KEYCLOAK_KEY_TOKEN);
    const refreshToken = localStorage.getItem(KEYCLOAK_KEY_REFRESH_TOKEN);

    return { token, refreshToken };
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
const auth = authExchange(async (utils) => {
    return {
        addAuthToOperation(operation: any) {
            const { token } = initializeAuthState();
            if (!token) {
                return operation;
            }

            return utils.appendHeaders(operation, {
                Authorization: `Bearer ${token}`,
            });
        },
        didAuthError(error: any) {
            return error.graphQLErrors.some((e: any) => e.message === 'jwt expired');
        },
        willAuthError() {
            const { token } = initializeAuthState();
            if (!token) return true;
            return false;
        },
        async refreshAuth() {
            const { token, refreshToken } = initializeAuthState();
            if (!token || !refreshToken) {
                try {
                    const tokenUpdated = await keycloakObject.updateToken(300);
                    if (tokenUpdated) {
                        if (keycloakObject.token && keycloakObject.refreshToken) {
                            localStorage.setItem(KEYCLOAK_KEY_TOKEN, keycloakObject.token);
                            localStorage.setItem(KEYCLOAK_KEY_REFRESH_TOKEN, keycloakObject.refreshToken);
                        }
                    }
                    return { token: keycloakObject.token, refreshToken: keycloakObject.refreshToken };
                } catch (error) {
                    keycloakObject.logout();
                    return null;
                }
            }
        },
    };
});

export const client = new Client({
    url: `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_GRAPHQL_PATH}`,
    exchanges: [cacheExchange, auth, fetchExchange],
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <ReactKeycloakProvider authClient={keycloakObject} onEvent={eventLogger} onTokens={tokenLogger}>
        <ChakraProvider>
            <UrqlProvider value={client}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </UrqlProvider>
        </ChakraProvider>
    </ReactKeycloakProvider>,
);
