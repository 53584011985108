import React, { type FunctionComponent, useRef, useState, useEffect } from 'react';
import {
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    Button,
    Input,
} from '@chakra-ui/react';

interface Props {
    isOpen: boolean;
    onClose: (inputValue?: string) => void;
    title: string;
    content?: string;
    isDestructive?: boolean;
    okButtonTitle?: string;
    input?: {
        placeholder: string;
        initialValue: string;
    };
}

export const Dialog: FunctionComponent<Props> = (props: Props) => {
    const { isDestructive = false, okButtonTitle = 'OK' } = props;
    const [inputValue, setInputValue] = useState('');
    const cancelRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        if (props.input) {
            setInputValue(props.input.initialValue);
        }
    }, [props.input]);

    return (
        <AlertDialog
            isOpen={props.isOpen}
            leastDestructiveRef={props.input ? inputRef : cancelRef}
            onClose={(): void => {
                props.onClose();
            }}
            isCentered
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        {props.title}
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        {props.content}
                        {props.input && (
                            <Input
                                placeholder={props.input.placeholder}
                                value={inputValue}
                                mt={props.content ? '6' : '0'}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                    setInputValue(event.target.value);
                                }}
                                ref={inputRef}
                            />
                        )}
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button
                            ref={cancelRef}
                            onClick={(): void => {
                                props.onClose();
                            }}
                            variant="outline"
                        >
                            Cancel
                        </Button>
                        <Button
                            colorScheme={isDestructive ? 'red' : 'green'}
                            onClick={(): void => {
                                props.onClose(inputValue);
                            }}
                            ml={3}
                            variant="outline"
                        >
                            {okButtonTitle}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};
