/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { type FunctionComponent, useMemo, useState } from 'react';
import { type Frame } from '../../interface/Crash';
import { type Column } from 'react-table';
import { TableContainer } from '../table/TableContainer';
import { Box, Code, Divider, HStack, Stack, Text } from '@chakra-ui/react';
import { IoChevronDown, IoChevronForward } from 'react-icons/io5';

interface Props {
    frame: Frame;
    codeColor?: string;
}

export const BindingsItem: FunctionComponent<Props> = (props: Props) => {
    const { frame, codeColor } = props;
    const [showDetails, setShowDetails] = useState(false);

    const bindingsItems = Object.entries(frame.bindings);
    const tableData = useMemo(() => bindingsItems, [bindingsItems]);
    const columns = useMemo(
        () => [
            {
                Header: 'name',
                accessor: (row) => row[0],
                disableSortBy: true,
            },
            {
                Header: 'value',
                accessor: (row) => <pre>{JSON.stringify(row[1], null, 2)}</pre>,
                disableSortBy: true,
            },
        ],
        [bindingsItems],
        // any is in real Array<Column<Array<string>>>, but TabelContainer can't work with it
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) as Array<Column<any>>;

    return (
        <>
            <HStack spacing="1">
                <Box
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        setShowDetails(!showDetails);
                    }}
                >
                    {showDetails ? <IoChevronDown size="25" /> : <IoChevronForward size="25" />}
                </Box>
                <Box overflow="hidden">
                    <Code backgroundColor="transparent">{`${frame.linedefinedCode} (${frame.source}@${frame.version}:${frame.linedefined})`}</Code>
                    <Stack direction="row" h="34">
                        <Text overflow="hidden" color="blue.300">
                            {frame.currentline}
                        </Text>
                        <Divider orientation="vertical" />
                        <Code style={{ marginTop: '2px' }} colorScheme={codeColor}>
                            {frame.currentlineCode}
                        </Code>
                    </Stack>
                </Box>
            </HStack>
            {showDetails && tableData && columns && <TableContainer columns={columns} data={tableData} />}
        </>
    );
};
