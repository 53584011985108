import React, { type FunctionComponent } from 'react';
import { useColorMode, useColorModeValue, IconButton, type IconButtonProps } from '@chakra-ui/react';
import { IoMoon, IoSunny } from 'react-icons/io5';

type ColorModeSwitcherProps = Omit<IconButtonProps, 'aria-label'>;

export const ColorModeSwitcher: FunctionComponent<ColorModeSwitcherProps> = (props) => {
    const { toggleColorMode } = useColorMode();
    const text = useColorModeValue('dark', 'light');
    const SwitchIcon = useColorModeValue(IoMoon, IoSunny);

    return (
        <IconButton
            size="xs"
            fontSize="lg"
            variant="unstyled"
            onClick={toggleColorMode}
            color="gray.400"
            icon={<SwitchIcon />}
            aria-label={`Switch to ${text} mode`}
            _focus={{
                outline: 'none',
            }}
            {...props}
        />
    );
};
