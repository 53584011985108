import React, { type FunctionComponent, type ChangeEvent, useState, useEffect } from 'react';
import { type AppData, type AppStoreVersion, type Releasenotes } from '../../interface/AppData';
import { VStack, useBoolean, useToast } from '@chakra-ui/react';
import { labelForPlatform } from './Helper';
import { InputArea } from './InputArea';
import { InputForm } from './InputForm';
import { Request as apiRequest, getErrorMessage } from '../../helper';
import LoadingIndicator from '../../components/LoadingIndicator';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    appData: AppData;
    version: AppStoreVersion;
}

export const ReleasenotesForm: FunctionComponent<Props> = (props) => {
    const { isOpen, onClose, appData, version } = props;
    const versionName = `${appData.name} ${labelForPlatform(version.platform)} ${version.versionString}`;
    const path = `/apps/${appData.id}/releasenotes/${version.id}`;

    const [isSaving, setSaving] = useBoolean();
    const [isReloading, setReloading] = useBoolean();
    const toast = useToast({ position: 'top' });
    const maxLength = 4000;

    const [releasenotes, setReleasenotes] = useState<Releasenotes>();
    const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>): any => {
        const element = e.target;
        setReleasenotes((releasenotes: Releasenotes | undefined): Releasenotes | undefined => {
            if (releasenotes) {
                const newReleasenotes = { ...releasenotes };
                for (const entry of Object.entries(newReleasenotes)) {
                    if (entry[0] === element.name && entry[1]) {
                        entry[1].text = element.value;
                    }
                }
                return newReleasenotes;
            }
            return releasenotes;
        });
    };

    const getReleasenotes = async (reload: boolean): Promise<void> => {
        try {
            const response = await apiRequest.get<Releasenotes>({ path });
            setReleasenotes(response.data);
        } catch (e) {
            if (reload) {
                toast({
                    title: 'Failure!',
                    description: `Could not load release notes for ${versionName}: ${getErrorMessage(e)}.`,
                    status: 'error',
                    isClosable: true,
                });
            }
        }
    };

    useEffect(() => {
        setSaving.off();
        setReleasenotes(undefined);
        getReleasenotes(false);
    }, []);

    /* eslint-disable @typescript-eslint/no-explicit-any */
    let isSaveDisabled = true;
    if (releasenotes) {
        isSaveDisabled = Object.entries(releasenotes)
            .filter((e: [string, any]) => e[1] !== undefined)
            .some((e: [string, any]) => e[1].text.length > maxLength);
    }
    /* eslint-enable @typescript-eslint/no-explicit-any */

    const onReload = async (): Promise<void> => {
        setReloading.on();
        await getReleasenotes(true);
        setReloading.off();
    };

    const onSave = async (): Promise<void> => {
        try {
            setSaving.on();
            const response = await apiRequest.post<Releasenotes>({
                path,
                data: releasenotes,
            });
            setReleasenotes(response.data);
            onClose();

            toast({
                title: 'Success!',
                description: `Release notes for ${versionName} saved.`,
                status: 'success',
                isClosable: true,
            });
        } catch (e) {
            toast({
                title: 'Failure!',
                description: `Could not save release notes for ${versionName}: ${getErrorMessage(e)}.`,
                status: 'error',
                isClosable: true,
            });
        }

        setSaving.off();
    };

    const inputContainer = (title: string, value: string): JSX.Element => (
        <InputArea
            key={title}
            title={title.charAt(0).toUpperCase() + title.slice(1)}
            name={title}
            value={value}
            maxLength={maxLength}
            isDisabled={isSaving || isReloading}
            onChange={handleInputChange}
            height="150"
        />
    );

    const loadedContent = (releasenotes: Releasenotes): JSX.Element => (
        <VStack align="justify" spacing="4">
            {Object.entries(releasenotes).map((entry) => {
                return inputContainer(entry[0], entry[1].text);
            })}
        </VStack>
    );

    let content;
    if (releasenotes) content = loadedContent(releasenotes);
    else content = <LoadingIndicator />;

    return (
        <InputForm
            title={versionName}
            subtitle="What's new?"
            isOpen={isOpen}
            onClose={onClose}
            onReload={onReload}
            isSaveDisabled={isSaveDisabled}
            onSave={onSave}
        >
            {content}
        </InputForm>
    );
};
