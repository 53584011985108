import { Flex, type FlexProps, Spacer, Stack, useColorModeValue as mode, Divider, Link } from '@chakra-ui/react';
import React, { type FunctionComponent } from 'react';
import { Logo } from '../Logo';
import { NavLink } from './NavLink';
import { NavGroup } from './NavGroup';
import { IoLogOut } from 'react-icons/io5';
import { ColorModeSwitcher } from '../ColorModeSwitcher';
import { Link as RouterLink } from 'react-router-dom';
import { baseElements, groupedNavElements, type NavigationElement } from '../../Navigation';
import { v4 as uuidv4 } from 'uuid';
import { UserProfile } from '../UserProfile';
import { useKeycloak } from '@react-keycloak/web';
import { getUser, checkScope } from '../../keycloak';

const generateNavElement: FunctionComponent<NavigationElement> = (navElement: NavigationElement) => {
    const linkProps = navElement.external
        ? { isExternal: true, href: navElement.link }
        : { to: navElement.link, as: RouterLink };

    return <NavLink label={navElement.label} icon={navElement.icon} key={uuidv4()} {...linkProps} />;
};

const generateNavLinks = (elements: NavigationElement[], userScopes: string[]): any => {
    return (
        elements
            // only show allowed elements for user
            .filter((element) => element.scopes === undefined || checkScope(userScopes, element.scopes))
            .map((element) => generateNavElement(element))
    );
};

const generateNavigation = (userScopes: string[]): any => {
    const navigation = [];
    navigation.push(
        <Stack key={uuidv4()} spacing="1">
            {generateNavLinks(baseElements, userScopes)}
        </Stack>,
    );
    for (const key of Object.keys(groupedNavElements)) {
        const navLinks = generateNavLinks(groupedNavElements[key], userScopes);
        if (navLinks.length > 0) {
            navigation.push(
                <NavGroup key={uuidv4()} label={key}>
                    {navLinks}
                </NavGroup>,
            );
        }
    }
    return navigation;
};

export const Sidebar: FunctionComponent<FlexProps> = (props: FlexProps) => {
    const { keycloak } = useKeycloak();
    const user = getUser(keycloak);
    console.log('user: ', user);
    return (
        <Flex bg={mode('gray.50', 'gray.800')} direction="column" borderRightWidth="1px" width="64" {...props}>
            <Flex direction="column" flex="1" pt="5" pb="4" overflowY="auto" px="4">
                <Flex align="center" justify="space-between" mb="8">
                    <Link as={RouterLink} to="/">
                        <Logo />
                    </Link>
                    <ColorModeSwitcher />
                </Flex>
                {keycloak.authenticated && (
                    <Stack spacing="6" as="nav" aria-label="Sidebar Navigation">
                        {user && generateNavigation(user?.roles)}
                        <Divider />
                        <NavLink
                            label="Logout"
                            icon={IoLogOut}
                            onClick={async () => {
                                await keycloak.logout({ redirectUri: window.location.origin });
                            }}
                        />
                    </Stack>
                )}
                <Spacer />
            </Flex>
            {keycloak.authenticated && <UserProfile name={user?.name} email={user?.email} />}
        </Flex>
    );
};
