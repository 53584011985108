import React, { type FunctionComponent } from 'react';
import { Box, Button, Code as ChakraCode, type CodeProps, useToast } from '@chakra-ui/react';
import { IoCopyOutline } from 'react-icons/io5';
import { copyWithFeedback } from '../helper';

interface Props extends CodeProps {
    textToCopy?: string;
}

export const Code: FunctionComponent<Props> = (props: Props) => {
    const { textToCopy, children, ...rest } = props;
    const toast = useToast();

    return (
        <ChakraCode {...rest}>
            {textToCopy ? (
                <>
                    <Button
                        mt={-3}
                        left={'95%'}
                        textAlign="right"
                        onClick={() => {
                            copyWithFeedback(textToCopy, toast);
                        }}
                    >
                        <IoCopyOutline />
                    </Button>
                    <Box mt={-5}>{children}</Box>
                </>
            ) : (
                <Box>{children}</Box>
            )}
        </ChakraCode>
    );
};
