import React, { type FunctionComponent, useState, useMemo, useEffect } from 'react';
import { ContentContainer } from '../../components/ContentContainer';
import { type Column } from 'react-table';
import { TableContainer } from '../../components/table/TableContainer';
import { Request as apiRequest } from '../../helper';
import {
    type ISampleDataArray,
    type ISampleData,
    type IBalancesData,
    type ITransactionsData,
    type IContractsData,
    type ISharesData,
} from '../../interface/SampleData';
import { SampleDataDetails } from './SampleDataDetails';
import { Button, useToast } from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';

export const SampleData: FunctionComponent = () => {
    const [data, setData] = useState<ISampleData[]>();
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [selectedAccount, setSelectedAccount] = useState<ISampleData | undefined>(undefined);
    const toast = useToast();

    useEffect(() => {
        setData(undefined);
        async function getData(): Promise<void> {
            const response = await apiRequest.get<ISampleDataArray>({ path: '/webconnect/sampledata' });
            setData(response.data.result);
        }
        getData();
    }, [selectedAccount]);

    const openDetails = (selectedAccount: ISampleData): void => {
        if (selectedAccount.id) {
            setSelectedAccount(data?.find((account) => account.id === selectedAccount.id));
            setShowDetails(true);
        }
    };

    const closeDetails = (): void => {
        setSelectedAccount(undefined);
        setShowDetails(false);
    };

    const addBalance = (newBalance: IBalancesData): void => {
        if (selectedAccount) {
            const newAccount = { ...selectedAccount };
            newAccount.balances.push(newBalance);
            setSelectedAccount(newAccount);
        }
    };

    const addTransaction = (newTransaction: ITransactionsData): void => {
        if (selectedAccount) {
            const newAccount = { ...selectedAccount };
            newAccount.transactions.push(newTransaction);
            setSelectedAccount(newAccount);
        }
    };

    const addShare = (newShare: ISharesData): void => {
        if (selectedAccount) {
            const newAccount = { ...selectedAccount };
            newAccount.shares.push(newShare);
            setSelectedAccount(newAccount);
        }
    };

    const addContract = (newContract: IContractsData): void => {
        if (selectedAccount) {
            const newAccount = { ...selectedAccount };
            newAccount.contracts.push(newContract);
            setSelectedAccount(newAccount);
        }
    };

    async function saveNewAccount(): Promise<ISampleData> {
        const response = await apiRequest.post<ISampleDataArray>({
            path: '/webconnect/sampledata',
            data: selectedAccount,
        });
        return response.data.result[0];
    }

    const saveAccount = (): void => {
        saveNewAccount().then((newAccount) => {
            setSelectedAccount(newAccount);
            toast({
                title: 'Account saved.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        });
    };

    const saveToClipboard = (): void => {
        navigator.clipboard
            .writeText(JSON.stringify(data))
            .then(() => {
                toast({
                    title: 'Copied to clipboard.',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
            })
            .catch((error) => {
                toast({
                    title: 'Error',
                    description: error,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            });
    };

    const tableData = useMemo(() => data, [data]);
    const columns: Array<Column<ISampleData>> = useMemo(
        () => [
            {
                Header: 'state',
                accessor: 'state',
            },
            {
                Header: 'id',
                accessor: 'id',
            },
            {
                Header: 'name',
                accessor: 'name',
            },
            {
                Header: 'currency',
                accessor: 'currency',
            },
            {
                Header: 'ownerName',
                accessor: 'ownerName',
            },
            {
                Header: 'accountNumber',
                accessor: 'accountNumber',
            },
            {
                Header: 'iban',
                accessor: 'iban',
            },
            {
                Header: 'timezone',
                accessor: 'timezone',
            },
            {
                Header: 'hasBalance',
                accessor: 'hasBalance',
            },
            {
                Header: 'hasTransactions',
                accessor: 'hasTransactions',
            },
            {
                Header: 'hasContracts',
                accessor: 'hasContracts',
            },
            {
                Header: 'isDepot',
                accessor: 'isDepot',
            },
            {
                Header: 'balances',
                accessor: 'balances',
            },

            {
                Header: 'transactions',
                accessor: 'transactions',
            },
            {
                Header: 'contracts',
                accessor: 'contracts',
            },
            {
                Header: 'shares',
                accessor: 'shares',
            },
        ],
        [data],
    );

    return (
        <ContentContainer headline="Sample Data" description="List of all accounts in sample data" showSpinner={!data}>
            {tableData && !showDetails && (
                <TableContainer
                    columns={columns}
                    data={tableData}
                    search={{
                        placeholder: 'Search for new account',
                        selectConfig: {
                            columAccessor: 'state',
                            selectOptions: <StateOptions />,
                        },
                    }}
                    hiddenColumns={[
                        'state',
                        'currency',
                        'timezone',
                        'hasBalance',
                        'hasTransactions',
                        'hasContracts',
                        'isDepot',
                        'balances',
                        'transactions',
                        'contracts',
                        'shares',
                    ]}
                    showColoredState={true}
                    coloredStateMethod={colorForState}
                    initialState={{
                        sortBy: [{ id: 'order', desc: true }],
                    }}
                    rowClick={openDetails}
                    defaultPageSize={20}
                />
            )}
            {/* Render SampleDataDetails component if showDetails is true */}
            {showDetails && selectedAccount && (
                <SampleDataDetails
                    selectedAccount={selectedAccount}
                    setSelectedAccount={setSelectedAccount}
                    addBalance={addBalance}
                    addTransaction={addTransaction}
                    addContract={addContract}
                    addShare={addShare}
                    saveAccount={saveAccount}
                    saveNewAccount={saveNewAccount}
                />
            )}

            {/* Add a button or link to close the details */}
            {showDetails && (
                <>
                    <Button onClick={closeDetails} mr="10">
                        Close Details
                    </Button>
                </>
            )}

            {!showDetails && (
                <Button onClick={saveToClipboard}>
                    <CopyIcon />
                </Button>
            )}
        </ContentContainer>
    );
};

enum StateType {
    InProgress = 3,
    Todo = 2,
    Blocked = 1,
}

const allStates = [StateType.InProgress, StateType.Todo, StateType.Blocked];

const colorForState = (state: StateType): string => {
    switch (state) {
        case StateType.Todo:
            return 'blue.500';
        case StateType.InProgress:
            return 'yellow.500';
        case StateType.Blocked:
            return 'red.300';
    }
};

const labelForState = (state: StateType): string => {
    switch (state) {
        case StateType.InProgress:
            return 'In Progress';
        case StateType.Todo:
            return 'Todo';
        case StateType.Blocked:
            return 'Blocked';
    }
};

const StateOptions: FunctionComponent = () => {
    return (
        <>
            <option value="">All</option>
            {allStates.map((state: StateType) => (
                <option value={state} key={state}>
                    {labelForState(state)}
                </option>
            ))}
        </>
    );
};
