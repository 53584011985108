import React, { type FunctionComponent, useState, useMemo, useEffect } from 'react';
import {
    Badge,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Tooltip,
    useDisclosure,
    Box,
    MenuItem,
} from '@chakra-ui/react';
import { type Column } from 'react-table';
import { TableContainer } from '../../components/table/TableContainer';
import { CrashDetail } from './Details';
import {
    type CrashGroupFragment,
    type CrashListElementFragment,
    type Maybe,
    StateType,
    useUpdateCrashStateMutation,
    type GetCrashesByIdQuery,
    type GetCrashesByUserQuery,
} from '../../generated/types';
import moment from 'moment';
import { storedSessionUser } from '../../keycloak';
import { ActionMenu } from '../../components/ActionMenu';
import { StateMenuItem } from '../../components/StateMenuItem';
import { IoClipboardOutline, IoFolder, IoGitCommit } from 'react-icons/io5';
import { openInNewTab } from '../../helper';
import { FileVersion, requestVersionDetails } from '../../components/FileVersion';
import { type Commit } from '../../interface/Commit';

export type CrashGroup = CrashGroupFragment & { state: string };

interface GroupedCrashListItem {
    state: StateType;
    groupCount: number;
    crashId: string;
    message: string;
    emails: string[];
    dates: string[];
    osVersions: string[];
    appVersions: string[];
    crashGroupId: string;
    id: string;
    file: string;
    fileVersion: string;
    fileLineNumber: number;
}

export interface SerachValue {
    crashId?: string;
    crashGroupId?: string;
    fileVersion?: string;
    userId?: string;
}

interface Props {
    data: GetCrashesByIdQuery | GetCrashesByUserQuery;
    initialSearchValue?: SerachValue;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    initialState?: any;
}

const regExCrashId = /(.*)@(.*):(.*)/;

export const CrashTable: FunctionComponent<Props> = (props) => {
    const { data, initialSearchValue, initialState } = props;

    const [, updateCrashState] = useUpdateCrashStateMutation();
    const [selectedItemId, setSelectedItemId] = useState<Maybe<string> | undefined>();
    const [fileVersionDetails, setFileVersionDetails] = useState<Record<string, Commit[]> | undefined>(undefined);
    const { isOpen: isOpenDetails, onOpen: onOpenDetails, onClose: onCloseDetails } = useDisclosure();

    const selectedItem = data?.crashes?.nodes.find((item) => item?.id === selectedItemId);
    const openDetails = (selectedCrashGroup: CrashGroup): void => {
        if (selectedCrashGroup.id) {
            setSelectedItemId(selectedCrashGroup.id);
            onOpenDetails();
        }
    };

    useEffect(() => {
        async function loadFileVersions(): Promise<void> {
            const detailsObject: Record<string, Commit[]> = {};

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const array: any = data?.crashes?.nodes.map((item) => item.fileName);
            if (array) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                const arrayUniqueByKey = [...new Set(array)];
                for (const item of arrayUniqueByKey) {
                    detailsObject[item] = await requestVersionDetails(item);
                }
                setFileVersionDetails(JSON.parse(JSON.stringify(detailsObject)));
            }
        }

        loadFileVersions();
    }, [data]);

    const changeCrashesState = async (id: string, value: string): Promise<void> => {
        await updateCrashState({ id, state: value });
    };

    const recordsGrouped = useMemo(() => groupData(data?.crashes?.nodes), [data?.crashes?.nodes]);
    const tableDataAll = useMemo(() => data?.crashes?.nodes, [data?.crashes?.nodes]);
    const columnsAll: Array<Column<CrashListElementFragment>> = useMemo(
        () => [
            {
                Header: 'State',
                accessor: 'state',
            },
            {
                Header: 'file',
                accessor: (row) => row.file,
            },
            {
                Header: 'hash',
                accessor: (row) => {
                    if (fileVersionDetails) {
                        return (
                            <FileVersion
                                file={row.file}
                                version={row.fileVersion}
                                details={fileVersionDetails[row.file]}
                            />
                        );
                    }
                    return null;
                },
            },
            {
                Header: 'line',
                accessor: (row) => row.fileLineNumber,
            },
            {
                Header: 'Message',
                accessor: 'message',
            },
            {
                Header: 'Email',
                accessor: (row) => row.outbankUser?.email ?? row.outbankUser?.id,
            },
            // {
            //     Header: 'App Version',
            //     accessor: (row) => appVersionString(row.appVersion),
            //     textAlign: 'right',
            // },
            {
                Header: 'Date',
                accessor: (row) => createTableDate(row),
                textAlign: 'right',
            },
            {
                Header: 'UserId',
                accessor: (row) => row.outbankUser?.id,
            },
            {
                Header: '',
                accessor: 'id',
                disableSortBy: true,
                // eslint-disable-next-line react/display-name
                Cell: (cell) => rowActionMenu(cell.row.original),
                textAlign: 'right',
                hasCellClick: false,
            },
        ],
        [data?.crashes?.nodes, fileVersionDetails],
    );

    const columnsAllSupport: Array<Column<CrashListElementFragment>> = useMemo(
        () => [
            {
                Header: 'State',
                accessor: 'state',
            },
            {
                Header: 'file',
                accessor: (row) => row.file,
            },
            {
                Header: 'hash',
                accessor: (row) => {
                    if (fileVersionDetails) {
                        return (
                            <FileVersion
                                file={row.file}
                                version={row.fileVersion}
                                details={fileVersionDetails[row.file]}
                            />
                        );
                    }
                    return null;
                },
            },
            {
                Header: 'line',
                accessor: (row) => row.fileLineNumber,
            },
            {
                Header: 'Message',
                accessor: 'message',
            },
            {
                Header: 'Email',
                accessor: (row) => row.outbankUser?.email ?? row.outbankUser?.id,
            },
            {
                Header: 'Source',
                accessor: (row) => row.outbankUser?.appSource?.name,
                textAlign: 'right',
            },
            // {
            //     Header: 'App Version',
            //     accessor: (row) => appVersionString(row.appVersion),
            //     textAlign: 'right',
            // },
            {
                Header: 'Date',
                accessor: (row) => createTableDate(row),
                textAlign: 'right',
            },
            {
                Header: 'UserId',
                accessor: (row) => row.outbankUser?.id,
            },
            {
                Header: '',
                accessor: 'id',
                disableSortBy: true,
                // eslint-disable-next-line react/display-name
                Cell: (cell) => rowActionMenu(cell.row.original),
                textAlign: 'right',
                hasCellClick: false,
            },
        ],
        [data?.crashes?.nodes, fileVersionDetails],
    );

    const copyLinkClipboard = async (recordId: string): Promise<void> => {
        const url = window.location.protocol + '//' + window.location.host;
        navigator.clipboard.writeText(`${url}/crashlist/${recordId}`);
    };

    const generateCommitMessage = async (item: GroupedCrashListItem | CrashListElementFragment): Promise<void> => {
        const url = window.location.protocol + '//' + window.location.host;
        const message = `[${item.file.replace('+', '/').replace('.py', '')}] 

<a href='${url}/crashlist/${item.id}' target="_blank">${item.file}@${item.fileVersion}:${item.fileLineNumber}</a>
<a href='${url}/crashgroup/${item.crashGroupId}' target="_blank">CrashGroup</a>`;
        navigator.clipboard.writeText(message);
    };

    const rowActionMenu = (item: GroupedCrashListItem | CrashListElementFragment): JSX.Element => (
        <ActionMenu>
            <MenuItem
                icon={<IoClipboardOutline />}
                onClick={async () => {
                    await copyLinkClipboard(item.id);
                }}
            >
                Copy link to clipboard
            </MenuItem>
            <MenuItem
                icon={<IoFolder />}
                onClick={() => {
                    openInNewTab(`/crashgroup/${item.crashGroupId}`);
                }}
            >
                Go to crash group
            </MenuItem>
            <MenuItem
                icon={<IoGitCommit />}
                onClick={async () => {
                    await generateCommitMessage(item);
                }}
            >
                Generate commit message
            </MenuItem>
        </ActionMenu>
    );

    const groupedRowActionMenu = (item: GroupedCrashListItem | CrashListElementFragment): JSX.Element => (
        <ActionMenu>
            <MenuItem
                icon={<IoFolder />}
                onClick={() => {
                    openInNewTab(`/crashgroup/${item.crashGroupId}`);
                }}
            >
                Go to crash group
            </MenuItem>
            <MenuItem
                icon={<IoGitCommit />}
                onClick={async () => {
                    await generateCommitMessage(item);
                }}
            >
                Generate commit message
            </MenuItem>
        </ActionMenu>
    );

    const tableDataGrouped = useMemo(() => recordsGrouped, [recordsGrouped]);
    const columnsGrouped: Array<Column<GroupedCrashListItem>> = useMemo(
        () => [
            {
                Header: 'State',
                accessor: 'state',
            },
            {
                Header: 'Count',
                accessor: 'groupCount',
                Cell: (cell: any) => {
                    return (
                        <Badge variant="outline" colorScheme="blue">
                            {cell.row.original.groupCount}
                        </Badge>
                    );
                },
                textAlign: 'right',
            },
            {
                Header: 'file',
                accessor: (row) => row.crashId.match(regExCrashId)?.[1],
            },
            {
                Header: 'hash',
                accessor: (row) => {
                    const stringMatch = row.crashId.match(regExCrashId);
                    if (stringMatch && fileVersionDetails) {
                        return (
                            <FileVersion
                                file={stringMatch[1]}
                                version={stringMatch[2]}
                                details={fileVersionDetails[stringMatch[1]]}
                            />
                        );
                    }
                    return null;
                },
            },
            {
                Header: 'line',
                accessor: (row) => row.crashId.match(regExCrashId)?.[3],
            },
            {
                Header: 'Message',
                accessor: 'message',
            },
            {
                Header: 'Emails',
                accessor: (row) => generateToolTipp(row.emails),
            },
            // {
            //     Header: 'App Versions',
            //     accessor: (row) => generateToolTipp(row.appVersions),
            //     textAlign: 'right',
            // },
            {
                Header: 'Date',
                accessor: (row) => generateToolTipp(row.dates),
                textAlign: 'right',
            },
            {
                Header: '',
                accessor: 'id',
                disableSortBy: true,
                // eslint-disable-next-line react/display-name
                Cell: (cell) => groupedRowActionMenu(cell.row.original),
                textAlign: 'right',
                hasCellClick: false,
            },
        ],
        [recordsGrouped, fileVersionDetails],
    );

    let actionMenu: JSX.Element | null = null;
    if (initialSearchValue?.crashGroupId) {
        actionMenu = (
            <ActionMenu label="CrashGroup Actions">
                {[StateType.Open, StateType.Inprogress, StateType.Closed].map((state: StateType) => {
                    return (
                        <StateMenuItem
                            state={state}
                            currentState={StateType.Open}
                            recordId={initialSearchValue.crashGroupId ?? ''}
                            click={changeCrashesState}
                            key={state}
                            showAll={true}
                        />
                    );
                })}
            </ActionMenu>
        );
    }

    return (
        <>
            <Tabs defaultIndex={storedSessionUser?.role.helpdesk ? 2 : 0}>
                <TabList>
                    <Tab>Grouped</Tab>
                    <Tab>All</Tab>
                    <Tab>Support</Tab>
                </TabList>
                {actionMenu && (
                    <Box mt={-9} textAlign="right">
                        {actionMenu}
                    </Box>
                )}

                <TabPanels>
                    <TabPanel>
                        <TableContent
                            columns={columnsGrouped}
                            table={tableDataGrouped}
                            rowClick={openDetails}
                            hiddenColumns={['state']}
                            showColoredState={true}
                            initialState={initialState}
                        />
                    </TabPanel>
                    <TabPanel>
                        <TableContent
                            columns={columnsAll}
                            table={tableDataAll}
                            hiddenColumns={['state', 'UserId']}
                            rowClick={openDetails}
                            showColoredState={true}
                        />
                    </TabPanel>
                    <TabPanel>
                        <TableContent
                            columns={columnsAllSupport}
                            table={tableDataAll}
                            hiddenColumns={['state', 'UserId']}
                            rowClick={openDetails}
                            showColoredState={true}
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>
            {selectedItem && (
                <CrashDetail isOpen={isOpenDetails} onClose={onCloseDetails} id={selectedItem?.id} size="full" />
            )}
        </>
    );
};

interface TableProps {
    // use React.useMemo here to ensure that our data isn't recreated on every render
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    columns: Array<Column<any>>;
    // use React.useMemo here to ensure that our data isn't recreated on every render
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    table: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rowClick?: (original: any) => void;
    hiddenColumns?: string[];
    showColoredState?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    initialState?: any;
}
const TableContent: FunctionComponent<TableProps> = (props) => {
    const {
        table,
        columns,
        rowClick,
        hiddenColumns,
        initialState = {
            sortBy: [{ id: 'Date', desc: true }],
        },
        showColoredState = false,
    } = props;
    return (
        table && (
            <TableContainer
                columns={columns}
                data={table}
                search={{
                    placeholder: 'Search for Crash',
                }}
                rowClick={rowClick}
                hiddenColumns={hiddenColumns}
                initialState={initialState}
                showColoredState={showColoredState}
            />
        )
    );
};

const generateToolTipp = (values: string[]): JSX.Element => {
    return <Tooltip label={values.join(', ')}>{`${values[0]}${values.length > 1 ? ', ...' : ''}`}</Tooltip>;
};

const createCrashId = (data: CrashListElementFragment): string => {
    return `${data.file}@${data.fileVersion}:${data.fileLineNumber}`;
};

const createTableDate = (data: CrashListElementFragment): string => {
    return moment(data.createdAt).format('YY-MM-DD HH:mm:ss');
};

const appVersionString = (appVersion: string): string => {
    if (appVersion === 'UNKNOWN') return '';
    const matchString = appVersion.match(/\d+\.\d+\.\d+/);
    if (matchString) return matchString[0];
    return '';
};

const groupData = (data: CrashListElementFragment[] | undefined): GroupedCrashListItem[] => {
    const result: GroupedCrashListItem[] = [];
    if (!data) return result;

    for (const item of data) {
        const found = result.find((_item) => _item.message === item.message && _item.crashId === createCrashId(item));
        if (!found) {
            result.push({
                state: item.state,
                id: item.id,
                groupCount: 1,
                message: item.message ?? '',
                crashId: createCrashId(item),
                dates: [createTableDate(item)],
                emails: item.outbankUser?.email ? [item.outbankUser.email] : [],
                osVersions: [item.osVersion] || [],
                appVersions: [appVersionString(item.appVersion)] || [],
                crashGroupId: item.crashGroupId,
                file: item.file,
                fileLineNumber: item.fileLineNumber,
                fileVersion: item.fileVersion,
            });
        } else {
            if (found.state !== item.state && item.state === StateType.Closed) found.state = item.state;
            found.groupCount++;
            if (item.outbankUser?.email) found.emails.push(item.outbankUser.email);
            found.osVersions.push(item.osVersion);
            found.dates.push(createTableDate(item));
        }
    }

    return result;
};
