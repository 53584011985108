import React, { type FunctionComponent, useState, useEffect } from 'react';
import {
    type ITransactionsData,
    type ISampleData,
    type IBalancesData,
    type ISharesData,
    type IContractsData,
} from '../../interface/SampleData';
import {
    Card,
    CardHeader,
    Heading,
    CardBody,
    Box,
    Flex,
    Editable,
    EditablePreview,
    EditableTextarea,
    FormControl,
    Switch,
    Button,
    useToast,
} from '@chakra-ui/react';
import { SampleTransactionData } from './SampleTransactionData';
import { TransactionsTable } from './SampleDataComponents/TransactionsTable';
import { SharesTable } from './SampleDataComponents/SharesTable';
import { BalanceTable } from './SampleDataComponents/BalanceTable';
import { ContractTable } from './SampleDataComponents/ContractsTable';
import { SampleContractData } from './SampleContractData';
import { cloneDeep, isEqual } from 'lodash';

interface SampleDataDetailsProps {
    selectedAccount: ISampleData;
    setSelectedAccount: React.Dispatch<React.SetStateAction<ISampleData | undefined>>;
    addBalance: (newBalance: IBalancesData) => void;
    addTransaction: (newTransaction: ITransactionsData) => void;
    addContract: (newContract: IContractsData) => void;
    addShare: (newShare: ISharesData) => void;
    saveAccount: () => void;
    saveNewAccount: () => Promise<ISampleData>;
}

export const SampleDataDetails: FunctionComponent<SampleDataDetailsProps> = ({
    selectedAccount,
    setSelectedAccount,
    addBalance,
    addTransaction,
    addContract,
    addShare,
    saveAccount,
    saveNewAccount,
}: SampleDataDetailsProps) => {
    const [transactionsData, setTransactionsData] = useState<ITransactionsData[]>(selectedAccount.transactions);
    const [balancesData, setBalancesData] = useState<IBalancesData[]>(selectedAccount.balances);
    const [sharesData, setSharesData] = useState<ISharesData[]>(selectedAccount.shares);
    const [contractsData, setContractsData] = useState<IContractsData[]>(selectedAccount.contracts);
    const [editTransactions, setEditTransactions] = useState<boolean>(false);
    const [selectedTransaction, setSelectedTransaction] = useState<ITransactionsData | undefined>(undefined);
    const [editContracts, setEditContracts] = useState<boolean>(false);
    const [selectedContract, setSelectedContract] = useState<IContractsData | undefined>(undefined);
    const toast = useToast();

    const saveTransaction = (transaction: ITransactionsData): void => {
        const index = selectedAccount.transactions.findIndex((trans) => isEqual(trans, selectedTransaction));
        if (index !== -1) {
            selectedAccount.transactions[index] = transaction;
            saveNewAccount().then((newAccount: ISampleData) => {
                selectedAccount = newAccount;
            });
            closeEditTransactions();
            toast({
                title: 'Transaction saved.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        } else {
            toast({
                title: 'Operation failed!',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const saveContract = (contract: IContractsData): void => {
        const index = selectedAccount.contracts.findIndex((entry) => isEqual(entry, selectedContract));
        if (index !== -1) {
            selectedAccount.contracts[index] = contract;
            saveNewAccount().then((newAccount: ISampleData) => {
                setSelectedAccount(newAccount);
            });
            closeEditContracts();
            toast({
                title: 'Contract saved.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        } else {
            toast({
                title: 'Operation failed!',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const saveInput = (
        key: keyof ISampleData,
        value: any = '',
        arrayIndex: number = -1,
        arrayName: keyof ISampleData = '' as keyof ISampleData,
    ): boolean => {
        const newAccount: ISampleData = cloneDeep(selectedAccount);
        if (arrayIndex >= 0 && arrayName.toString() !== '' && Array.isArray(newAccount[arrayName])) {
            const array = newAccount[arrayName] as any[];
            if (arrayIndex < array.length) {
                const arrayItem = array[arrayIndex];
                if (typeof arrayItem === 'object' && arrayItem !== null) {
                    arrayItem[key] = value;
                }
            }
        } else {
            (newAccount[key] as any) = value;
        }
        setSelectedAccount(newAccount);
        return true;
    };

    const openEditTransactions = (transaction: ITransactionsData): void => {
        setSelectedTransaction(transaction);
        setEditTransactions(true);
    };

    const closeEditTransactions = (): void => {
        setSelectedTransaction(undefined);
        setEditTransactions(false);
    };

    const openEditContracts = (contract: IContractsData): void => {
        setSelectedContract(contract);
        setEditContracts(true);
    };

    const closeEditContracts = (): void => {
        setSelectedContract(undefined);
        setEditContracts(false);
    };

    useEffect(() => {
        setTransactionsData(selectedAccount.transactions);
        setBalancesData(selectedAccount.balances);
        setSharesData(selectedAccount.shares);
        setContractsData(selectedAccount.contracts);
    }, [selectedAccount]);

    return (
        <>
            {!editTransactions && !editContracts && (
                <Card>
                    <CardHeader>
                        <Heading size="md">Account Details</Heading>
                    </CardHeader>

                    <CardBody>
                        <Flex justifyContent="space-between">
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    ID
                                </Heading>
                                <Editable
                                    defaultValue={selectedAccount.id}
                                    fontSize="sm"
                                    onSubmit={(value) => saveInput('id', value)}
                                >
                                    <EditablePreview />
                                    <EditableTextarea />
                                </Editable>
                            </Box>
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    Name
                                </Heading>
                                <Editable
                                    defaultValue={selectedAccount.name}
                                    fontSize="sm"
                                    onSubmit={(value) => saveInput('name', value)}
                                >
                                    <EditablePreview />
                                    <EditableTextarea />
                                </Editable>
                            </Box>
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    Currency
                                </Heading>
                                <Editable
                                    defaultValue={selectedAccount.currency}
                                    fontSize="sm"
                                    onSubmit={(value) => saveInput('currency', value)}
                                >
                                    <EditablePreview />
                                    <EditableTextarea />
                                </Editable>
                            </Box>
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    Owner Name
                                </Heading>
                                <Editable
                                    defaultValue={selectedAccount.ownerName}
                                    fontSize="sm"
                                    onSubmit={(value) => saveInput('ownerName', value)}
                                >
                                    <EditablePreview />
                                    <EditableTextarea />
                                </Editable>
                            </Box>
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    Account Number
                                </Heading>
                                <Editable
                                    defaultValue={selectedAccount.accountNumber}
                                    fontSize="sm"
                                    onSubmit={(value) => saveInput('accountNumber', value)}
                                >
                                    <EditablePreview />
                                    <EditableTextarea />
                                </Editable>
                            </Box>
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    IBAN
                                </Heading>
                                <Editable
                                    defaultValue={selectedAccount.iban}
                                    fontSize="sm"
                                    onSubmit={(value) => saveInput('iban', value)}
                                >
                                    <EditablePreview />
                                    <EditableTextarea />
                                </Editable>
                            </Box>
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    timezone
                                </Heading>
                                <Editable
                                    defaultValue={selectedAccount.timezone}
                                    fontSize="sm"
                                    onSubmit={(value) => saveInput('timezone', value)}
                                >
                                    <EditablePreview />
                                    <EditableTextarea />
                                </Editable>
                            </Box>
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    Has Balance?
                                </Heading>
                                <FormControl pt="2">
                                    <Switch
                                        id="isChecked"
                                        isChecked={selectedAccount.hasBalance}
                                        onChange={() =>
                                            saveInput('hasBalance' as keyof ISampleData, !selectedAccount.hasBalance)
                                        }
                                    />
                                </FormControl>
                            </Box>
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    Has Transactions?
                                </Heading>
                                <FormControl pt="2">
                                    <Switch
                                        id="isChecked"
                                        isChecked={selectedAccount.hasTransactions}
                                        onChange={() =>
                                            saveInput(
                                                'hasTransactions' as keyof ISampleData,
                                                !selectedAccount.hasTransactions,
                                            )
                                        }
                                    />
                                </FormControl>
                            </Box>
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    Has Contracts?
                                </Heading>
                                <FormControl pt="2">
                                    <Switch
                                        id="isChecked"
                                        isChecked={selectedAccount.hasContracts}
                                        onChange={() =>
                                            saveInput(
                                                'hasContracts' as keyof ISampleData,
                                                !selectedAccount.hasContracts,
                                            )
                                        }
                                    />
                                </FormControl>
                            </Box>
                            <Box>
                                <Heading size="xs" textTransform="uppercase">
                                    Is Depot?
                                </Heading>
                                <FormControl pt="2">
                                    <Switch
                                        id="isChecked"
                                        isChecked={selectedAccount.isDepot}
                                        onChange={() =>
                                            saveInput('isDepot' as keyof ISampleData, !selectedAccount.isDepot)
                                        }
                                    />
                                </FormControl>
                            </Box>
                        </Flex>
                    </CardBody>
                </Card>
            )}
            {balancesData.length > 0 && !editTransactions && !editContracts && (
                <BalanceTable balancesData={balancesData} saveInput={saveInput} addBalance={addBalance} />
            )}
            {transactionsData.length > 0 && !editTransactions && !editContracts && (
                <TransactionsTable
                    transactionsData={transactionsData}
                    openEditTransactions={openEditTransactions}
                    addTransaction={addTransaction}
                />
            )}
            {sharesData.length > 0 && !editTransactions && !editContracts && (
                <SharesTable sharesData={sharesData} saveInput={saveInput} addShare={addShare} />
            )}
            {contractsData.length > 0 && !editTransactions && !editContracts && (
                <ContractTable
                    contractsData={contractsData}
                    openEditContracts={openEditContracts}
                    addContract={addContract}
                />
            )}
            {editTransactions && selectedTransaction && (
                <>
                    <SampleTransactionData
                        selectedTransaction={selectedTransaction}
                        saveTransaction={saveTransaction}
                    />
                    <Button onClick={closeEditTransactions} mr="10">
                        Close Transaction
                    </Button>
                </>
            )}
            {editContracts && selectedContract && (
                <>
                    <SampleContractData selectedContract={selectedContract} saveContract={saveContract} />
                    <Button onClick={closeEditContracts} mr="10">
                        Close Contract
                    </Button>
                </>
            )}
            {!editContracts && !editTransactions && (
                <Button onClick={saveAccount} colorScheme="blue" mr="10">
                    Save Changes
                </Button>
            )}
        </>
    );
};
