import React, { type FunctionComponent } from 'react';
import { useColorModeValue } from '@chakra-ui/react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import { ReactComponent as OutbankLogo } from '../images/Outbank-Logo.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import { ReactComponent as OutbankLogoDark } from '../images/Outbank-Logo-Dark.svg';

export const Logo: FunctionComponent = () => {
    return useColorModeValue(<OutbankLogo />, <OutbankLogoDark />);
};
