import React, { type FunctionComponent, useState, type ChangeEvent, type ReactNode, useEffect } from 'react';
import { Stack, HStack, FormControl, InputGroup, Select, Input, InputRightElement } from '@chakra-ui/react';
import { IoSearchOutline } from 'react-icons/io5';

interface Props {
    placeholder: string;
    setGlobalFilter: (filterValue: string) => void;
    setFilter: (columnId: string, updater: string) => void;
    selectConfig?: {
        columAccessor: string;
        selectOptions: ReactNode;
    };
    startSearchValue?: string;
}

export const Search: FunctionComponent<Props> = (props: Props) => {
    const [searchValue, setSearchValue] = useState('');
    const [selectValue, setSelectValue] = useState('');

    useEffect(() => {
        if (props.startSearchValue) {
            setNewFilter(props.startSearchValue);
        }
    }, [props.startSearchValue]);

    const setNewFilter = (value: string): void => {
        setSearchValue(value);
        props.setGlobalFilter(value);
    };

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value || '';
        setNewFilter(value);
    };

    const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>): void => {
        const value = e.target.value;
        setSelectValue(value);
        props.setFilter(props.selectConfig?.columAccessor ?? '', value);
    };

    return (
        <Stack spacing="4" direction={{ base: 'column', md: 'row' }} justify="space-between" py="6">
            <HStack>
                <FormControl minW={{ sm: '280px', md: '520px' }} id="search">
                    <InputGroup>
                        <InputRightElement color="gray.400" pointerEvents="none">
                            <IoSearchOutline />
                        </InputRightElement>
                        <Input placeholder={props.placeholder} onChange={handleSearchChange} value={searchValue} />
                    </InputGroup>
                </FormControl>
                {props.selectConfig && (
                    <Select rounded="base" value={selectValue} onChange={handleSelectChange}>
                        {props.selectConfig.selectOptions}
                    </Select>
                )}
            </HStack>
        </Stack>
    );
};
