/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { type FunctionComponent, useState } from 'react';
import { Box, Code, Divider, HStack, List, ListItem, Spacer, VStack } from '@chakra-ui/react';
import { type Protocol } from '../../interface/Protocol';
import { DetailsElement } from '../detailsDrawer/DetailsElement';
import { RoundedBox } from '../RoundedBox';
import { BKProtocolMethods } from './BKProtocolMethods';
import { type ParsedBkLog } from '../../screen/protocol/Details';

interface Props {
    protocolId: string;
    details: Protocol;
    bkLogFilteredMessages: ParsedBkLog;
}

export const BKQuickView: FunctionComponent<Props> = (props: Props) => {
    const { details, bkLogFilteredMessages } = props;

    const [isLogRestartet, setIsLogRestatet] = useState(false);
    const wcCalledScripts: string[] = [];
    let wcLastMessage: string | undefined;

    for (const trace of details.trace) {
        if (trace.body && !wcCalledScripts.includes(trace.body?.fileName)) wcCalledScripts.push(trace.body.fileName);
        if (trace.message) wcLastMessage = trace.message;
    }

    let startFetch = false;
    for (const [key, methods] of Object.entries(bkLogFilteredMessages)) {
        methods?.methods?.forEach((mIndex: any) => {
            const method = methods.methods[mIndex];
            if (
                details ||
                ['fetch', 'synchronizeLogin', 'createLogin', 'setLoggingEnabled', 'APP STARTED'].includes(method.method)
            ) {
                if (['fetch', 'synchronizeLogin'].includes(method?.method)) startFetch = true;
                if (!isLogRestartet && startFetch && ['setLoggingEnabled', 'APP STARTED'].includes(method.method)) {
                    setIsLogRestatet(true);
                }
            }
        });
    }

    return (
        <>
            {isLogRestartet && (
                <RoundedBox mb={8} bg="yellow.200" w="100%" color="black">
                    App was restarted or logging has been enabled after fetching accounts. WC Trace could be empty
                </RoundedBox>
            )}
            {wcCalledScripts.length > 0 ? (
                <RoundedBox headline="Called webconnect scripts" collapseAble={true} collapseInitial={true}>
                    <List p={2} spacing={1}>
                        {wcCalledScripts.map((item) => (
                            <ListItem key={item}>{item}</ListItem>
                        ))}
                    </List>
                </RoundedBox>
            ) : null}
            {wcLastMessage ? (
                <RoundedBox headline="Last webconnect message" collapseAble={true} collapseInitial={true}>
                    <Box p={2}>{wcLastMessage}</Box>
                </RoundedBox>
            ) : null}
            <BKProtocolMethods bkLogFilteredMessages={bkLogFilteredMessages} details={false} collapseInitial={true} />
        </>
    );
};
