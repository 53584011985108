import React, { type FunctionComponent, useEffect, useState } from 'react';
import { Text, HStack, Button, Icon, Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import { DetailsDrawer } from '../../components/detailsDrawer/DetailsDrawer';
import { RoundedBox } from '../../components/RoundedBox';
import { DetailsElement } from '../../components/detailsDrawer/DetailsElement';
import { IoCopyOutline, IoShareOutline } from 'react-icons/io5';
import { copy, Request as apiRequest } from '../../helper';
import { type Protocol as ProtocolDetails } from '../../interface/Protocol';
import { type Protocol } from '../../generated/types';
import { BKHeader } from '../../components/protocol/BKHeader';
import { BKProtocol } from '../../components/protocol/BKProtocol';
import { BKTrace } from '../../components/protocol/BKTrace';
import { storedSessionUser } from '../../keycloak';
import { BKQuickView } from '../../components/protocol/BKQuickView';
import { v4 as uuidv4 } from 'uuid';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    data: Protocol;
    actionMenu: (item: Protocol) => JSX.Element;
}

export type ParsedBkLog = Record<string, { time: string; methods: Methods[] }>;

export interface Methods {
    method: string;
    params: string[] | unknown[];
}

type MethodType = 'BK' | 'API' | 'CRASH' | 'FINTS' | 'WEB' | 'FINLYTICS' | 'WATCH' | 'GC' | 'BIOMETRICS';

export interface BkLogRaw {
    time: string;
    level: 'INFO' | 'DEBUG' | 'WARN' | 'ERROR';
    type: MethodType;
    message: string;
}

export const allBkLogTypes = ['BK', 'API', 'CRASH', 'FINTS', 'WEB', 'FINLYTICS', 'WATCH', 'GC', 'BIOMETRICS'];

export const Details: FunctionComponent<Props> = (props: Props) => {
    const { data } = props;
    const [details, setDetails] = useState<ProtocolDetails>();

    useEffect(() => {
        setDetails(undefined);
        async function getDetails(): Promise<void> {
            if (data.id) {
                const response = await apiRequest.get<ProtocolDetails>({ path: `/report/protocol/${data.id}` });
                setDetails(response.data);
            }
        }
        getDetails();
    }, [data]);

    const modifyMessage = (type: MethodType, message: string): string => {
        if (type === 'FINTS') {
            // eslint-disable-next-line @typescript-eslint/quotes, quotes
            return message.split("'").join("'\n");
        }
        return message;
    };

    const bkLogSplittedEntries: BkLogRaw[] = [];
    const bkLogFilteredMessages: ParsedBkLog = {};

    if (details) {
        for (const logMessage of details.protocol.split('\n')) {
            const found = logMessage.match(
                /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}) ([A-Za-z]+) ([A-Za-z]+)(?:\[| )(.*)/,
            );

            if (found) {
                const _type = found[2] as MethodType;
                bkLogSplittedEntries.push({
                    time: found[1],
                    type: _type,
                    level: found[3] as 'INFO' | 'DEBUG' | 'ERROR',
                    message: modifyMessage(_type, found[4]),
                });
            } else {
                const lastItem = bkLogSplittedEntries[bkLogSplittedEntries.length - 1];
                if (lastItem) {
                    lastItem.message = `${lastItem.message}\n${modifyMessage(lastItem.type, logMessage)}`;
                }
            }

            if (
                // !logMessage.trim().startsWith('<') &&
                logMessage.trim().startsWith('{') &&
                logMessage.trim().endsWith('}') &&
                logMessage.includes('"method":') &&
                logMessage.includes('"params":')
            ) {
                const lastItem = bkLogSplittedEntries[bkLogSplittedEntries.length - 1];
                const parsedLog = JSON.parse(logMessage);
                if (parsedLog.params && logMessage.includes('0x')) {
                    const address = parsedLog.params[parsedLog.params.length - 1];
                    if (address in bkLogFilteredMessages) bkLogFilteredMessages[address].methods.push(parsedLog);
                    else
                        bkLogFilteredMessages[address] = {
                            time: lastItem.time,
                            methods: [parsedLog],
                        };
                }
            }

            if (logMessage.includes('setLoggingEnabled') || logMessage.includes('APP STARTED')) {
                const lastItem = bkLogSplittedEntries[bkLogSplittedEntries.length - 1];

                const address = uuidv4();
                const method = logMessage.includes('setLoggingEnabled') ? 'setLoggingEnabled' : 'APP STARTED';
                bkLogFilteredMessages[address] = {
                    time: lastItem.time,
                    methods: [{ method, params: [lastItem.message] }],
                };
            }
        }
    }

    const createRawProtocolForCopy = (): any => {
        let modifiedRawProtocol = '';
        for (const entry of bkLogSplittedEntries) {
            modifiedRawProtocol = `${modifiedRawProtocol ? `${modifiedRawProtocol}\n\n` : ''}${entry.time} ${
                entry.type
            } ${entry.level} ${entry.message}`;
        }
        return modifiedRawProtocol;
    };

    let startTabIndex = 0;
    if (storedSessionUser?.role.helpdesk) startTabIndex = 3;
    else if (storedSessionUser?.role.webconnect) startTabIndex = 2;

    console.log(details);

    return (
        <DetailsDrawer
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={
                <HStack>
                    <Text>Protocol details</Text>
                </HStack>
            }
            size="full"
            showSpinner={!details}
        >
            {details && (
                <>
                    <HStack spacing={4}>
                        <Button
                            rightIcon={<Icon as={IoCopyOutline} />}
                            borderColor="black"
                            variant="outline"
                            onClick={() => {
                                copy(JSON.stringify(details.header));
                            }}
                        >
                            Copy Header
                        </Button>
                        <Button
                            rightIcon={<Icon as={IoCopyOutline} />}
                            borderColor="black"
                            variant="outline"
                            onClick={() => {
                                copy(createRawProtocolForCopy());
                            }}
                        >
                            Copy Log
                        </Button>
                        <Button
                            rightIcon={<Icon as={IoCopyOutline} />}
                            borderColor="black"
                            variant="outline"
                            onClick={() => {
                                copy(JSON.stringify(details.trace));
                            }}
                        >
                            Copy Trace
                        </Button>
                        <Button
                            rightIcon={<Icon as={IoCopyOutline} />}
                            borderColor="black"
                            variant="outline"
                            onClick={() => {
                                copy(JSON.stringify(details.sync));
                            }}
                        >
                            Copy Sync
                        </Button>
                    </HStack>
                    <RoundedBox>
                        <HStack>
                            <DetailsElement
                                pb={0}
                                boxProps={{ flex: '1' }}
                                label="Email"
                                value={data.outbankUser?.email}
                            />
                            {data.model && (
                                <DetailsElement pb={0} boxProps={{ flex: '1' }} label="Model" value={data.model} />
                            )}
                            {data.osVersion && (
                                <DetailsElement pb={0} boxProps={{ flex: '1' }} label="Model" value={data.osVersion} />
                            )}
                            {data.appVersion && (
                                <DetailsElement
                                    pb={0}
                                    boxProps={{ flex: '1' }}
                                    label="App Version"
                                    value={data.appVersion}
                                />
                            )}
                            <Button
                                rightIcon={<Icon as={IoShareOutline} />}
                                borderColor="black"
                                variant="outline"
                                onClick={() => {
                                    copy(
                                        `${
                                            window.location.protocol + '//' + window.location.host
                                        }/protocols/${data?.id}`,
                                    );
                                }}
                            >
                                Protocol path
                            </Button>
                        </HStack>
                    </RoundedBox>
                    <Tabs defaultIndex={startTabIndex} pt={35} isFitted>
                        <TabList>
                            <Tab>BK Header</Tab>
                            <Tab>BK Log</Tab>
                            <Tab>WC Trace</Tab>
                            <Tab>Quick View</Tab>
                        </TabList>

                        <TabPanels>
                            <TabPanel px={0}>
                                <BKHeader details={details} />
                            </TabPanel>
                            <TabPanel px={0}>
                                <BKProtocol
                                    bkLogFilteredMessages={bkLogFilteredMessages}
                                    bkLogSplittedEntries={bkLogSplittedEntries}
                                />
                            </TabPanel>
                            <TabPanel px={0}>
                                {details.trace.length > 0 && <BKTrace protocolId={data.id} details={details} />}
                            </TabPanel>
                            <TabPanel px={0}>
                                <BKQuickView
                                    protocolId={data.id}
                                    details={details}
                                    bkLogFilteredMessages={bkLogFilteredMessages}
                                />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </>
            )}
        </DetailsDrawer>
    );
};
