import React, { type FunctionComponent, useEffect, useState } from 'react';
import { Box, Divider, HStack, Tooltip } from '@chakra-ui/react';
import { WarningTwoIcon } from '@chakra-ui/icons';
import { Request } from '../helper';
import { type Commit, type CommitServerResponse } from '../interface/Commit';
import { FileCommitMessage } from './FileCommitMessage';

interface Props {
    file?: string;
    version?: string;
    details?: Commit[];
    selfLoad?: boolean;
}

export const requestVersionDetails = async (file: string): Promise<Commit[]> => {
    if (file) {
        const response = await Request.get<CommitServerResponse>({ path: `/gitlab/file/versions/${file}` });
        return response.data.versions;
    }

    return [];
};

export const FileVersion: FunctionComponent<Props> = (props: Props) => {
    const { file = '', version = '', details, selfLoad = false } = props;
    const [_details, setDetails] = useState<Commit[]>();

    useEffect(() => {
        async function getDetails(): Promise<void> {
            const result = await requestVersionDetails(file);
            if (result) setDetails(result);
        }

        if (selfLoad && version !== 'dirty') getDetails();
    }, []);

    const commitsBehind: Commit[] = [];
    let counter = 0;
    if ((details ?? _details) && version !== 'dirty') {
        const data = details ?? _details ?? [];
        for (const item of data) {
            if (item.short_id === version) break;
            counter++;
        }

        if (version && counter > 0) {
            for (const commit of data) {
                commitsBehind.push(commit);
                if (commit.short_id.startsWith(version)) break;
            }
        }
    }

    return (
        <Box>
            <HStack>
                <Box>{version}</Box>
                {commitsBehind.length > 1 ? (
                    <Tooltip
                        label={
                            <Box>
                                <Box>
                                    Behind: {commitsBehind.length - 1}
                                    {commitsBehind.length - 1 >= 9 ? '+' : null}
                                </Box>
                                <Divider />
                                {commitsBehind.map((item, index) => (
                                    <FileCommitMessage
                                        key={item.id}
                                        commit={item}
                                        last={index === commitsBehind.length - 1}
                                        first={index === 0}
                                    />
                                ))}
                            </Box>
                        }
                    >
                        <WarningTwoIcon color={'#DD6B20'} />
                    </Tooltip>
                ) : null}
            </HStack>
        </Box>
    );
};
