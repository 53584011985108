import React, { type FunctionComponent } from 'react';
import { Box, Divider, VStack } from '@chakra-ui/react';
import { type Commit } from '../interface/Commit';

interface Props {
    commit: Commit;
    last: boolean;
    first: boolean;
}

export const FileCommitMessage: FunctionComponent<Props> = (props: Props) => {
    const { commit, last, first } = props;

    return (
        <Box>
            <VStack spacing={0} align="left">
                <Box>{commit.title}</Box>
                <Box>{commit.author_name}</Box>
                <Box>
                    {commit.short_id.slice(0, -1)}
                    {first ? ' (master)' : null}
                </Box>
            </VStack>

            {last ? null : <Divider />}
        </Box>
    );
};
