import React, { type FunctionComponent } from 'react';
import { ContentContainer } from '../../components/ContentContainer';
import { useGetImporterErrorsQuery } from '../../generated/types';
import { CrashTable } from './CrashTable';

export const List: FunctionComponent = () => {
    const [resultQuery] = useGetImporterErrorsQuery();
    const { data, fetching, error } = resultQuery;

    return (
        <ContentContainer
            headline="Importer Errors"
            description="A list of all sent crashes to importer"
            showSpinner={fetching}
            error={error}
        >
            {data && (
                <CrashTable
                    data={data}
                    initialState={{
                        sortBy: [{ id: 'groupCount', desc: true }],
                    }}
                />
            )}
        </ContentContainer>
    );
};
