import { Request } from '.';

export const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
};

export const openInNewTabWithBearerToken = (path: string): void => {
    let url = `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_API_PATH}`;
    url = `${url}${path.startsWith('/') ? '' : '/'}${path}`;

    const bearer = Request.accessToken;
    const character = bearer.includes('?') ? '&' : '?';
    openInNewTab(`${url}${character}bearer=${bearer}`);
};
