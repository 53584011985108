import { Flex, HStack, Text, useColorModeValue as mode } from '@chakra-ui/react';
import React, { type FunctionComponent } from 'react';

interface UserProfileProps {
    name?: string;
    email?: string;
}

export const UserProfile: FunctionComponent<UserProfileProps> = (props: UserProfileProps) => {
    const { name, email } = props;
    return (
        <HStack spacing="4" px="2" flexShrink={0} borderTopWidth="1px" p="4">
            <Flex direction="column" fontWeight="medium">
                {name && <Text fontSize="sm">{name}</Text>}
                {email && (
                    <Text fontSize="xs" lineHeight="shorter" color={mode('gray.600', 'gray.400')}>
                        {email}
                    </Text>
                )}
            </Flex>
        </HStack>
    );
};
