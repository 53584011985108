/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { type FunctionComponent, useMemo } from 'react';
import { type Request } from '../../interface/Crash';
import { type Column } from 'react-table';
import { TableContainer } from '../table/TableContainer';

interface Props {
    request: Request;
}

export const ResponseCookieView: FunctionComponent<Props> = (props: Props) => {
    const { request } = props;

    if (!request.response?.headers) return null;

    const _responseHeaders = Object.entries(request.response.headers);
    const responseCookieElement = _responseHeaders.find((item) => item[0].toLowerCase() === 'set-cookie');
    let tableData;
    let columns;
    if (responseCookieElement) {
        const responseCookieItems = responseCookieElement[1].split(',').map((item) => item.trim());
        tableData = useMemo(() => responseCookieItems, [responseCookieItems]);
        columns = useMemo(
            () => [
                {
                    Header: 'value',
                    accessor: (row) => row,
                    disableSortBy: true,
                },
            ],
            [responseCookieItems],
            // any is in real Array<Column<Array<string>>>, but TabelContainer can't work with it
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ) as Array<Column<any>>;
    }

    return (
        <>
            {tableData && columns && (
                <TableContainer
                    columns={columns}
                    data={tableData}
                    search={{
                        placeholder: 'Search for values',
                    }}
                />
            )}
        </>
    );
};
