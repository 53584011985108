/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { type AxiosResponse, type AxiosError } from 'axios';
// import { setupCache } from 'axios-cache-adapter';

// Cache for 2 minutes
// const cache = setupCache({
//     maxAge: 2 * 60 * 1000,
// });

const _axios = axios.create({
    // adapter: cache.adapter,
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export interface AxiosResponseErrorInterface<T> extends AxiosResponse<T>, AxiosError<T> {}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class Request {
    static accessToken = '';

    /** Only prepared not finished */
    static async get<T>(params: {
        url?: string;
        headers?: any;
        path?: string;
        raw?: boolean | undefined;
    }): Promise<AxiosResponseErrorInterface<T> | never> {
        const headers: any = params.headers ? params.headers : {};
        const url: string = params.url
            ? params.url
            : `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_API_PATH}`;
        const path: string = params.path ? params.path : '/';
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const raw: boolean = params.raw ? params.raw : false;

        try {
            const response: AxiosResponseErrorInterface<T> = await _axios.get(url + path, {
                headers: {
                    'Content-Type': 'application/json',
                    ...headers,
                    ...{ Authorization: `Bearer ${Request.accessToken}` },
                },
            });
            return response;
        } catch (e) {
            // @ts-expect-error: Let's ignore a compile error like this unreachable code
            const error: AxiosResponseErrorInterface<T> = e;
            throw error;
        }
    }

    static async post<T>(params: {
        url?: string;
        headers?: any;
        path?: string;
        data?: any;
    }): Promise<AxiosResponseErrorInterface<T> | never> {
        const headers: any = params.headers ? params.headers : {};
        const url: string = params.url
            ? params.url
            : `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_API_PATH}`;
        const path: string = params.path ? params.path : '/';

        try {
            const response: AxiosResponseErrorInterface<T> = await _axios.post(url + path, params.data, {
                headers: {
                    'Content-Type': 'application/json',
                    ...headers,
                    ...{ Authorization: `Bearer ${Request.accessToken}` },
                },
            });
            return response;
        } catch (e) {
            // @ts-expect-error: Let's ignore a compile error like this unreachable code
            const error: AxiosResponseErrorInterface<T> = e;
            throw error;
        }
    }

    static async download<T>(
        params: { url?: string; headers?: any; path?: string },
        fileName: string,
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        toast?: any,
    ): Promise<void> {
        const headers: any = params.headers ? params.headers : {};
        const url: string = params.url
            ? params.url
            : `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_API_PATH}`;
        const path: string = params.path ? params.path : '/';

        if (toast) {
            toast({
                title: 'Download started!',
                status: 'info',
                isClosable: true,
                duration: 4000,
            });
        }

        try {
            const response = await axios({
                url: url + path,
                method: 'GET',
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                    ...headers,
                    ...{ Authorization: `Bearer ${Request.accessToken}` },
                },
            });
            const tagUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = tagUrl;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (e) {
            if (toast) {
                toast({
                    title: 'Download error!',
                    status: 'error',
                    isClosable: true,
                    duration: 2000,
                });
            }
            // @ts-expect-error: Let's ignore a compile error like this unreachable code
            const error: AxiosError<T> = e;
            throw error;
        }

        if (toast) {
            toast({
                title: 'Download done!',
                status: 'success',
                isClosable: true,
                duration: 2000,
            });
        }
    }

    static async delete<T>(params: {
        url?: string;
        headers?: any;
        path?: string;
        raw?: boolean | undefined;
    }): Promise<AxiosResponseErrorInterface<T> | never> {
        const headers: any = params.headers ? params.headers : {};
        const url: string = params.url
            ? params.url
            : `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_API_PATH}`;
        const path: string = params.path ? params.path : '/';
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const raw: boolean = params.raw ? params.raw : false;

        try {
            const response: AxiosResponseErrorInterface<T> = await axios.delete(url + path, {
                headers: {
                    'Content-Type': 'application/json',
                    ...headers,
                    ...{ Authorization: `Bearer ${Request.accessToken}` },
                },
            });
            return response;
        } catch (e) {
            // @ts-expect-error: Let's ignore a compile error like this unreachable code
            const error: AxiosResponseErrorInterface<T> = e;
            throw error;
        }
    }

    /**
     *
     * @param requestQuery post data
     * @param params { headers?: object; url?: string; path?: string; raw?: boolean | undefined }
     * @param url Base server url
     * @param headers custom headers
     * @param path path
     * @param raw boolean for raw data or complete request data
     */
    static async graphql<T>(
        requestQuery = '',
        params: { headers?: any; url?: string; path?: string; raw?: boolean | undefined },
    ): Promise<AxiosResponseErrorInterface<T> | never> {
        const headers: any = params.headers ? params.headers : {};
        const url: string = params.url
            ? params.url
            : `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_GRAPHQL_PATH}`;
        const path: string = params.path ? params.path : '';
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const raw: boolean = params.raw ? params.raw : false;

        try {
            const response: AxiosResponseErrorInterface<T> = await axios.post(
                url + path,
                { query: requestQuery },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        ...headers,
                        ...{ Authorization: `Bearer ${Request.accessToken}` },
                    },
                },
            );

            return response;
        } catch (e) {
            // @ts-expect-error: Let's ignore a compile error like this unreachable code
            const error: AxiosResponseErrorInterface<T> = e;
            throw error;
        }
    }
}
