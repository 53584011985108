import React, { type FunctionComponent, useState, useMemo } from 'react';
import { type Column } from 'react-table';
import { TableContainer } from '../../components/table/TableContainer';
import { type IntegrationData } from '../../interface/Integration';
import { type Maybe } from '../../generated/types';
import { useDisclosure } from '@chakra-ui/react';
import { Details } from './Details';
import moment from 'moment';

interface Props {
    integrations: IntegrationData[];
    extended?: boolean;
}

export const ListComponent: FunctionComponent<Props> = (props) => {
    const { integrations, extended = false } = props;
    const [selectedItemKey, setSelectedItemKey] = useState<Maybe<string> | undefined>();
    const { isOpen: isOpenDetails, onOpen: onOpenDetails, onClose: onCloseDetails } = useDisclosure();

    const selectedItem = integrations?.find((item) => item?.key === selectedItemKey);
    const openDetails = (selectedIntegration: IntegrationData): void => {
        setSelectedItemKey(selectedIntegration.key);
        onOpenDetails();
    };

    const tableData = useMemo(() => integrations, [integrations]);
    let columns: Array<Column<IntegrationData>> = useMemo(
        () => [
            {
                Header: 'Key',
                accessor: 'key',
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Hybrid',
                accessor: (row) => (row.hybrid ? 'True' : ''),
            },
            {
                Header: 'Account Types',
                accessor: (row) => row.processAccountTypes?.join(', '),
            },
            {
                Header: 'JS-Injection',
                accessor: (row) => (row.jsInjectionTypes.length > 0 ? 'True' : ''),
            },
        ],
        [integrations],
    );

    if (extended) {
        columns = useMemo(
            () => [
                {
                    Header: 'Key',
                    accessor: 'key',
                },
                {
                    Header: 'Name',
                    accessor: 'name',
                },
                {
                    Header: 'Hybrid',
                    accessor: (row) => (row.hybrid ? 'True' : ''),
                },
                {
                    Header: 'Last Modified',
                    accessor: (row) => moment(row.commits[0].created_at).format('YYYY-MM-DD'),
                },
                {
                    Header: 'Created',
                    accessor: (row) => moment(row.commits[row.commits.length - 1].created_at).format('YYYY-MM-DD'),
                },
            ],
            [integrations],
        );
    }

    return (
        <>
            {tableData && (
                <TableContainer
                    columns={columns}
                    data={tableData}
                    search={{
                        placeholder: 'Search for integration',
                    }}
                    defaultPageSize={20}
                    rowClick={openDetails}
                />
            )}
            {selectedItem && selectedItem && (
                <Details isOpen={isOpenDetails} onClose={onCloseDetails} integrationDetails={selectedItem} />
            )}
        </>
    );
};
