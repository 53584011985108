import React, { type FunctionComponent } from 'react';
import { ContentContainer } from '../../components/ContentContainer';
import { useGetCrashGroupQuery } from '../../generated/types';
import { useParams } from 'react-router';
import { CrashGroupList } from './CrashGroupList';

export const List: FunctionComponent = () => {
    const { id } = useParams<Record<string, string | undefined>>();
    const [resultCrashGroupsQuery] = useGetCrashGroupQuery({ variables: { id } });
    const { data, fetching, error } = resultCrashGroupsQuery;

    return (
        <ContentContainer
            headline="Crashgroup"
            description="Crashes are grouped by filename and code line."
            showSpinner={fetching}
            error={error}
        >
            {data && <CrashGroupList data={data} showSearch={false} />}
        </ContentContainer>
    );
};
