import React, { type FunctionComponent, useState, useEffect } from 'react';
import { type Translation, type IProject } from '../../interface/Localization';
import { Request as apiRequest } from '../../helper';
import { useParams } from 'react-router-dom';
import { LocalizationsTableComponent } from './LocalizationsTableComponent';
import { ContentContainer } from '../../components/ContentContainer';

export const LocalizationsDetailsList: FunctionComponent = () => {
    const { projectId } = useParams();
    const [data, setData] = useState<Record<string, Translation[]>>();

    useEffect(() => {
        async function getData(): Promise<void> {
            const response = await apiRequest.get<IProject>({ path: '/localization/project/' + projectId });
            const languages = response.data.result.language;
            const translations: Translation[] = [];
            languages?.forEach((language) => {
                language.translation?.forEach((trans) => {
                    trans.placeholder = trans.value;
                    trans.state = trans.value?.trim() ? 2 : 1;
                    trans.language = language.id;
                    translations.push(trans);
                });
            });
            const groupedItems = translations.reduce<Record<string, Translation[]>>((acc, translation) => {
                if (!acc[translation.id]) {
                    acc[translation.id] = [];
                }
                acc[translation.id].push(translation);
                return acc;
            }, {});
            setData(groupedItems);
        }

        getData();
    }, []);

    return (
        <ContentContainer headline="Localizations" description="List of all localizations" showSpinner={!data}>
            {data && <LocalizationsTableComponent translationsData={data} searchQuery={false} />}
        </ContentContainer>
    );
};
