import { type Request } from '../interface/Crash';
import { type HAR, type HARItem, type HARRequest, type NameValue } from '../interface/Har';

export const convertCrashToHar = (requests: Request[]): HAR => {
    const result: HAR = {
        log: {
            version: '1.2',
            creator: {
                name: 'Outbank ServicePortal',
                version: '1.0.1',
            },
            browser: {
                name: 'Webconnect',
                version: '1',
            },
            pages: [
                {
                    startedDateTime: '',
                    id: '',
                    title: '',
                },
            ],
            entries: processRequests(requests ?? []),
        },
    };

    return result;
};

const processRequests = (requests: Request[]): HARItem[] => {
    const result: HARItem[] = [];
    for (const request of requests) {
        // Check for Websocket
        const isWSS = request.url.startsWith('wss');
        const response = request.response;
        let cookies = [];
        if (isWSS) {
            cookies = ['Cookie', request.headers.cookie.trim()];
        } else {
            if (request.headers) {
                cookies = request.headers?.find((item: string[]) => item[0].toLowerCase() === 'cookie');
            }
        }

        const responseHeaders = response?.headers ?? {};
        let responseCookies: string[] | undefined = [];
        if (isWSS) {
            responseCookies = [];
        } else {
            if (response?.headers) {
                responseCookies =
                    response && 'set-cookie' in response.headers
                        ? response.headers['set-cookie'].split(',')
                        : undefined;
            }
        }
        const responseCookiesHar: NameValue[] = [];
        responseCookies?.forEach((item: any) => {
            const splitted = item.split(';');
            if (splitted) {
                const someValue = splitted[0].split('=');
                if (someValue.length === 2) {
                    responseCookiesHar.push({
                        name: someValue[0].trim(),
                        value: someValue[1].trim(),
                    });
                }
            }
        });
        const responseHeadersHar: NameValue[] = [];
        Object.entries(responseHeaders).forEach(([name, value]) => {
            if (!['__id__', '__class__'].includes(name)) {
                responseHeadersHar.push({
                    name,
                    value,
                });
            }
        });

        let requestContentTypeHar = '';
        let requestHeadersHar = [];
        if (isWSS) {
            requestHeadersHar = Object.keys(request.headers)
                .map((key) => {
                    if (['__id__', '__class__'].includes(key)) {
                        return undefined;
                    }
                    return {
                        name: key,
                        value: request.headers[key],
                    };
                })
                .filter((item) => item !== undefined);
        } else {
            requestHeadersHar = request.headers?.map((item: any) => {
                if (item[0].toLowerCase() === 'content-type') {
                    requestContentTypeHar = item[1];
                }
                return {
                    name: item[0],
                    value: `${item[1]}`,
                };
            });
        }

        const createHARObject = (item: any): any => {
            return {
                name: item[0],
                value: `${item[1]}`,
            };
        };

        const responseBody = response?.body;
        const _request: HARRequest = {
            headers: requestHeadersHar,
            method: request.method,
            url: request.url,
            cookies: !cookies ? [] : cookies.map((item: any) => createHARObject(item)),
        };

        if (request.data) {
            let _text: string;
            try {
                _text = decodeURI(window.atob(request.data.bytes));
            } catch (error) {
                _text = window.atob(request.data.bytes);
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const _tmpPostData: any = {
                mimeType: requestContentTypeHar,
                text: _text,
            };
            if (requestContentTypeHar.includes('form-urlencoded')) {
                const params = _text.split('&').map((item) => {
                    return {
                        name: item[0],
                        value: item[1],
                    };
                });

                if (params) _tmpPostData.params = params;
            }
            _request.postData = _tmpPostData;
        }

        const _response = {
            status: response?.code ?? 0,
            statusText: 'OK',
            headers: responseHeadersHar,
            cookies: responseCookiesHar,
            content: {
                mimeType: responseBody ? responseBody.content_type : '',
                text: responseBody ? window.atob(responseBody.bytes) : '',
            },
        };

        result.push({
            startedDateTime: request.response?.headers?.date ?? '',
            request: _request,
            response: _response,
            // Firefox need this timings object, but we need no content
            cache: {},
            timings: {},
        });
    }
    return result;
};
