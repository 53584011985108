import React, { type FunctionComponent } from 'react';
import { ContentContainer } from '../../components/ContentContainer';
import { SimpleGrid } from '@chakra-ui/react';
import { Keyword } from './Keyword';
import { Campaign } from './Campain';

export const AppleAds: FunctionComponent = (props) => {
    return (
        <ContentContainer headline="Apple Ads" description="Get prepared Apple Ads Report as CSV">
            <SimpleGrid columns={{ base: 1, md: 3 }} gap={{ base: '5', md: '6' }} mt={5}>
                <Campaign />
                <Keyword />
            </SimpleGrid>
        </ContentContainer>
    );
};

export const download = (value: string, fileName: string): void => {
    const file = new Blob([value], { type: 'text/plain' });
    const element = document.createElement('a');
    element.href = URL.createObjectURL(file);
    element.download = fileName;

    // simulate link click
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    // remove link element
    element?.parentNode?.removeChild(element);
};
