import Keycloak from 'keycloak-js';

export const keycloakObject = new Keycloak({
    realm: `${process.env.REACT_APP_KEYCLOAK_REALM}`,
    url: `${process.env.REACT_APP_KEYCLOAK_URL}`,
    clientId: `${process.env.REACT_APP_KEYCLOAK_CLIENTID}`,
});

interface User {
    name: string;
    email: string;
    roles: string[];
    role: {
        license: boolean;
        helpdesk: boolean;
        webconnect: boolean;
        admin: boolean;
        app: boolean;
        marketing: boolean;
        groundcontrol: boolean;
    };
}

export const getUser = (keycloak: Keycloak.KeycloakInstance): User => {
    const parsedUser = keycloak.tokenParsed as {
        name: string;
        email: string;
        resource_access: {
            serviceportal_webconnect: { roles: string[] };
        };
        group: string[];
    };

    const roles = [...(parsedUser?.group || []), 'serviceportal_default'];

    storedSessionUser = {
        name: parsedUser?.name,
        email: parsedUser?.email,
        // serviceportal_all is a default role for restrictions
        roles: roles,
        role: {
            // roles: parsedUser?.group.filter((item) => item !== 'serviceportal_admin'),
            license: roles.filter((item) => item === 'serviceportal_license').length > 0,
            helpdesk: roles.filter((item) => item === 'serviceportal_helpdesk').length > 0,
            webconnect: roles.filter((item) => item === 'serviceportal_webconnect').length > 0,
            admin: roles.filter((item) => item === 'serviceportal_admin').length > 0,
            app: roles.filter((item) => item === 'serviceportal_app').length > 0,
            marketing: roles.filter((item) => item === 'serviceportal_marketing').length > 0,
            groundcontrol: roles.filter((item) => item === 'serviceportal_groundcontrol').length > 0,
        },
    };

    console.log('storedSessionUser: ', storedSessionUser);

    return storedSessionUser;
};

export const checkScope = (userScopes: string[], neededScopes: string[]): boolean => {
    return userScopes.filter((value: string) => neededScopes.includes(value)).length > 0;
};

export let storedSessionUser: User | null = null;
