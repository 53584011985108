import React, { type FunctionComponent } from 'react';
import { Stack, Text, HStack, Tabs, Tab, TabList, TabPanel, TabPanels, Link, Button } from '@chakra-ui/react';
import { DetailsDrawer } from '../../components/detailsDrawer/DetailsDrawer';
import { DetailsElement } from '../../components/detailsDrawer/DetailsElement';
import { type OutbankMilestoneData } from '../../interface/Milestone';
import { MarkDownBox } from '../../components/MarkDownBox';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    milestoneData: OutbankMilestoneData;
}

export const Details: FunctionComponent<Props> = (props: Props) => {
    const { milestoneData, isOpen, onClose } = props;
    const { fullTitle, description, wikiEntries } = milestoneData;

    return (
        <DetailsDrawer
            isOpen={isOpen}
            onClose={onClose}
            title={
                <HStack>
                    <Text>Milestone details</Text>
                </HStack>
            }
            size="lg"
        >
            <Stack>
                <DetailsElement label="Title" value={fullTitle} />
                <DetailsElement label="Description" markdown={description} />
                {wikiEntries && (
                    <DetailsElement label="Release Notes">
                        <Tabs>
                            <TabList>
                                {wikiEntries.map((entry, index) => (
                                    <Tab key={index}>{entry.title}</Tab>
                                ))}
                            </TabList>

                            <TabPanels>
                                {wikiEntries.map((entry, index) => (
                                    <TabPanel key={index}>
                                        <Button mt={3} mb={5}>
                                            <Link
                                                href={`https://gitlab.outbankapp.com/outbank/OBK/-/wikis/${entry.link}`}
                                                isExternal
                                            >
                                                Edit {entry.link}
                                            </Link>
                                        </Button>
                                        <MarkDownBox markdown={entry.content.replaceAll('```', '')} />
                                    </TabPanel>
                                ))}
                            </TabPanels>
                        </Tabs>
                    </DetailsElement>
                )}
            </Stack>
        </DetailsDrawer>
    );
};
