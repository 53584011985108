import React, { type FunctionComponent, useRef } from 'react';
import { ContentContainer } from '../../components/ContentContainer';
import { Button, Textarea, useToast } from '@chakra-ui/react';
import { copyWithFeedback } from '../../helper';

export const MailConverter: FunctionComponent = () => {
    const outputRef = useRef<HTMLTextAreaElement>(null);
    const toast = useToast();

    const convertMailString = (inputString: string): string => {
        const mailList = inputString
            .split('; ')
            .map((item) => item.split(' ').pop()?.replace('(', '').replace(')', ''));
        return mailList.join('\n');
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        const convertedString = convertMailString(event.target.value);
        if (outputRef?.current) {
            outputRef.current.value = convertedString;
        }
    };

    return (
        <ContentContainer
            headline="Mail Adress Converter"
            description="Prepare a string with mail addresses for Outlook"
        >
            <Textarea mb={6} h="400px" placeholder="Input mail string here..." onChange={handleInputChange} />
            <Textarea mb={6} h="400px" placeholder="Converted string" ref={outputRef} />
            <Button
                colorScheme="blue"
                onClick={() => {
                    copyWithFeedback(outputRef?.current?.value ?? '', toast);
                }}
            >
                Copy
            </Button>
        </ContentContainer>
    );
};
