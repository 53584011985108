/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { type FunctionComponent, useMemo } from 'react';
import { Request } from '../../interface/Crash';
import { type Column } from 'react-table';
import { TableContainer } from '../table/TableContainer';

interface Props {
    data: string[][];
    headers: string[];
}

export const RequestTableView: FunctionComponent<Props> = (props: Props) => {
    const { data, headers } = props;

    const tableData = useMemo(() => data, [data]);
    const columns = useMemo(
        () => [
            {
                Header: headers[0],
                accessor: (row) => row[0],
                disableSortBy: true,
            },
            {
                Header: headers[1],
                accessor: (row) => row[1],
                disableSortBy: true,
            },
        ],
        [data],
        // any is in real Array<Column<Array<string>>>, but TabelContainer can't work with it
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) as Array<Column<any>>;

    return (
        <>
            {tableData && (
                <TableContainer
                    columns={columns}
                    data={tableData}
                    search={{
                        placeholder: 'Search for values',
                    }}
                />
            )}
        </>
    );
};
