import React, { type FunctionComponent, useMemo, useEffect, useState } from 'react';
import { type IBalancesData, type ISampleData } from '../../../interface/SampleData';
import { Button, Divider, Editable, EditablePreview, EditableTextarea, Heading } from '@chakra-ui/react';
import { type Column } from 'react-table';
import { StateOptions } from '../../../components/table/StateOptions';
import { colorForState } from '../../../helper';
import { TableContainer } from '../../../components/table/TableContainer';

interface BalanceTableProps {
    balancesData: IBalancesData[];
    saveInput: (key: keyof ISampleData, value: any, arrayIndex?: number, arrayName?: keyof ISampleData) => boolean;
    addBalance: (newBalance: IBalancesData) => void;
}

export const BalanceTable: FunctionComponent<BalanceTableProps> = ({
    balancesData,
    saveInput,
    addBalance,
}: BalanceTableProps) => {
    const [balancesTableData, setBalancesTableData] = useState<IBalancesData[]>(balancesData);
    const createBalance = (): void => {
        const newBalance: IBalancesData = {
            key: 'newKey',
            value: 0,
            currency: 'newCurrency',
        };
        addBalance(newBalance);
    };

    useEffect(() => {
        setBalancesTableData(balancesData);
    }, [balancesData]);

    const balancesColumns: Array<Column<IBalancesData>> = useMemo(
        () => [
            {
                Header: 'key',
                accessor: 'key',
                Cell: (cell) => {
                    return (
                        <>
                            <Editable
                                defaultValue={cell.row.original.key}
                                onSubmit={(value) =>
                                    saveInput(
                                        cell.column.Header
                                            ? (cell.column.Header as keyof ISampleData)
                                            : ('' as keyof ISampleData),
                                        value,
                                        cell.row.index,
                                        'balances' as keyof ISampleData,
                                    )
                                }
                            >
                                <EditablePreview />
                                <EditableTextarea />
                            </Editable>
                        </>
                    );
                },
            },
            {
                Header: 'value',
                accessor: 'value',
                Cell: (cell) => {
                    return (
                        <>
                            <Editable
                                defaultValue={cell.row.original.value.toString()}
                                onSubmit={(value) =>
                                    saveInput(
                                        cell.column.Header
                                            ? (cell.column.Header as keyof ISampleData)
                                            : ('' as keyof ISampleData),
                                        value,
                                        cell.row.index,
                                        'balances' as keyof ISampleData,
                                    )
                                }
                            >
                                <EditablePreview />
                                <EditableTextarea />
                            </Editable>
                        </>
                    );
                },
            },
            {
                Header: 'currency',
                accessor: 'currency',
                Cell: (cell) => {
                    return (
                        <>
                            <Editable
                                defaultValue={cell.row.original.currency}
                                onSubmit={(value) =>
                                    saveInput(
                                        cell.column.Header
                                            ? (cell.column.Header as keyof ISampleData)
                                            : ('' as keyof ISampleData),
                                        value,
                                        cell.row.index,
                                        'balances' as keyof ISampleData,
                                    )
                                }
                            >
                                <EditablePreview />
                                <EditableTextarea />
                            </Editable>
                        </>
                    );
                },
            },
        ],
        [],
    );

    return (
        <>
            <Divider mt="8" />
            <Heading as="h3" size="lg">
                Balances
            </Heading>
            <TableContainer
                columns={balancesColumns}
                data={balancesTableData}
                search={{
                    placeholder: 'Search for balances',
                    selectConfig: {
                        columAccessor: 'state',
                        selectOptions: <StateOptions />,
                    },
                }}
                hiddenColumns={[]}
                showColoredState={true}
                coloredStateMethod={colorForState}
                initialState={{
                    sortBy: [{ id: 'order', desc: true }],
                }}
                defaultPageSize={20}
            />
            <Button onClick={createBalance} colorScheme="blue" mr="10">
                Create new Balance
            </Button>
        </>
    );
};
