import React, { type ChangeEvent, type FunctionComponent, useState } from 'react';
import { type AppData } from '../../interface/AppData';
import { Input, Text, VStack, useBoolean, useToast } from '@chakra-ui/react';
import { labelForPlatform } from './Helper';
import { InputForm } from './InputForm';
import { Request as apiRequest, getErrorMessage } from '../../helper';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    appData: AppData;
    onCreate: (appData: AppData) => void;
}

export const CreateVersionForm: FunctionComponent<Props> = (props) => {
    const { isOpen, onClose, appData, onCreate } = props;
    const path = `/apps/${appData.id}/createVersion`;

    const [version, setVersion] = useState('');
    const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setVersion(event.target.value);
    };
    const isCreateDisabled = version.length === 0;

    const [isCreating, setCreating] = useBoolean();
    const toast = useToast({ position: 'top' });

    const onSubmit = async (): Promise<void> => {
        try {
            setCreating.on();
            const response = await apiRequest.post<AppData>({
                path,
                data: { versionString: version },
            });

            const newVersion = response.data.versions[0];
            const name = `${appData.name} ${labelForPlatform(newVersion.platform)} ${newVersion.versionString}`;

            onCreate(response.data);
            onClose();
            toast({
                title: 'Success!',
                description: `Created version ${name}.`,
                status: 'success',
                isClosable: true,
            });
        } catch (e) {
            toast({
                title: 'Failure!',
                description: `Could not create new version for ${appData.name}: ${getErrorMessage(e)}.`,
                status: 'error',
                isClosable: true,
            });
        }
        setCreating.off();
    };

    return (
        <InputForm
            title={appData.name}
            subtitle="Create new version"
            isOpen={isOpen}
            onClose={onClose}
            saveTitle="Create"
            isSaveDisabled={isCreateDisabled}
            onSave={onSubmit}
        >
            <VStack align="justify" spacing="0">
                <Text>Version</Text>
                <Input value={version} onChange={onChange} isDisabled={isCreating} />
            </VStack>
        </InputForm>
    );
};
