import React, { type FunctionComponent, useState, useMemo, useEffect } from 'react';
import { ContentContainer } from '../../components/ContentContainer';
import { type Column } from 'react-table';
import { TableContainer } from '../../components/table/TableContainer';
import { Request as apiRequest } from '../../helper';
import { type NewIntegrationData } from '../../interface/Issue';
import { Badge, Box, Flex, Link, Spacer } from '@chakra-ui/react';
import { IoPhonePortraitOutline, IoBrowsersOutline } from 'react-icons/io5';

export const NewIntegrationList: FunctionComponent = () => {
    const [issues, setIssues] = useState<NewIntegrationData[]>();

    useEffect(() => {
        setIssues(undefined);
        async function getIssues(): Promise<void> {
            const response = await apiRequest.get<NewIntegrationData[]>({ path: '/gitlab/issue/newintegration' });
            setIssues(response.data);
        }
        getIssues();
    }, []);

    const tableData = useMemo(() => issues, [issues]);
    const columns: Array<Column<NewIntegrationData>> = useMemo(
        () => [
            {
                Header: 'state',
                accessor: 'state',
            },
            {
                Header: 'order',
                accessor: 'order',
            },
            {
                Header: 'Country',
                accessor: (row) => row.country,
                maxWidth: 100,
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Support Tickets',
                accessor: (row) => row.countTickets,
                textAlign: 'right',
            },
            {
                Header: 'Issue',
                accessor: (row) => row.iid,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: (cell: any) => {
                    return (
                        <Link
                            href={`https://gitlab.outbankapp.com/outbank/webconnectscripts/-/issues/${cell.row.original.iid}`}
                            isExternal
                        >
                            {`#${cell.row.original.iid}`}
                        </Link>
                    );
                },
                textAlign: 'right',
            },
            {
                Header: 'Data Access',
                accessor: (row) => {
                    return (
                        <Flex>
                            <Spacer />
                            {row.accessData.split(' ').map((item) => {
                                if (item.toLocaleLowerCase() === 'app')
                                    return (
                                        <Box paddingLeft={2}>
                                            <IoPhonePortraitOutline size={20} />
                                        </Box>
                                    );
                                if (item.toLocaleLowerCase() === 'website')
                                    return (
                                        <Box paddingLeft={2}>
                                            <IoBrowsersOutline size={20} />
                                        </Box>
                                    );
                                return null;
                            })}
                        </Flex>
                    );
                },
                textAlign: 'right',
            },
            {
                Header: 'Hars',
                accessor: (row) => row.harIds.length,
                Cell: (cell: any) => {
                    if (cell.row.original.harIds.length === 0) return '';
                    return (
                        <Link href={`/har/${cell.row.original.iid}`}>
                            <Badge variant="outline" colorScheme="blue">
                                {`${cell.row.original.harIds.length}`}
                            </Badge>
                        </Link>
                    );
                },
                textAlign: 'right',
            },
        ],
        [issues],
    );

    return (
        <ContentContainer
            headline="New Integrations"
            description="List of all issues with label 'newintegration'"
            showSpinner={!issues}
        >
            {tableData && (
                <TableContainer
                    columns={columns}
                    data={tableData}
                    search={{
                        placeholder: 'Search for new integration',
                        selectConfig: {
                            columAccessor: 'state',
                            selectOptions: <StateOptions />,
                        },
                    }}
                    hiddenColumns={['state', 'order']}
                    showColoredState={true}
                    coloredStateMethod={colorForState}
                    initialState={{
                        sortBy: [{ id: 'order', desc: true }],
                    }}
                    defaultPageSize={20}
                />
            )}
        </ContentContainer>
    );
};

enum StateType {
    InProgress = 3,
    Todo = 2,
    Blocked = 1,
}

const allStates = [StateType.InProgress, StateType.Todo, StateType.Blocked];

const colorForState = (state: StateType): string => {
    switch (state) {
        case StateType.Todo:
            return 'blue.500';
        case StateType.InProgress:
            return 'yellow.500';
        case StateType.Blocked:
            return 'red.300';
    }
};

const labelForState = (state: StateType): string => {
    switch (state) {
        case StateType.InProgress:
            return 'In Progress';
        case StateType.Todo:
            return 'Todo';
        case StateType.Blocked:
            return 'Blocked';
    }
};

const StateOptions: FunctionComponent = () => {
    return (
        <>
            <option value="">All</option>
            {allStates.map((state: StateType) => (
                <option value={state} key={state}>
                    {labelForState(state)}
                </option>
            ))}
        </>
    );
};
