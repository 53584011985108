import React, { type FunctionComponent, type ReactNode } from 'react';
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    DrawerFooter,
} from '@chakra-ui/react';
import { type Size } from '../../interface/Chakra';
import LoadingIndicator from '../LoadingIndicator';
import { type CombinedError } from 'urql';
import ErrorBox from '../ErrorBox';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    title: ReactNode;
    footer?: ReactNode;
    children: ReactNode;
    size?: Size;
    showSpinner?: boolean;
    error?: CombinedError;
}

export const DetailsDrawer: FunctionComponent<Props> = (props: Props) => {
    const { size = 'md', showSpinner = false, error } = props;

    if (showSpinner) return <LoadingIndicator />;
    if (error) return <ErrorBox title={error.name} message={error.message} />;

    return (
        <Drawer isOpen={props.isOpen} placement="right" onClose={props.onClose} size={size}>
            <DrawerOverlay>
                <DrawerContent>
                    <DrawerCloseButton mt={2} />
                    <DrawerHeader>{props.title}</DrawerHeader>
                    <DrawerBody>{props.children}</DrawerBody>
                    {props.footer && <DrawerFooter>{props.footer}</DrawerFooter>}
                </DrawerContent>
            </DrawerOverlay>
        </Drawer>
    );
};
