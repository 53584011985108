import React, { type FunctionComponent, type ReactNode } from 'react';
import { Menu, MenuButton, MenuList, Button } from '@chakra-ui/react';
import { IoChevronDown } from 'react-icons/io5';

interface Props {
    label?: string;
    isDisabled?: boolean;
    children: ReactNode;
}

export const ActionMenu: FunctionComponent<Props> = (props: Props) => {
    return (
        <Menu isLazy>
            <MenuButton
                as={Button}
                isDisabled={props.isDisabled}
                size="sm"
                variant="outline"
                rightIcon={<IoChevronDown />}
            >
                {props.label ?? 'Actions'}
            </MenuButton>
            <MenuList>{props.children}</MenuList>
        </Menu>
    );
};
