import React, { type FunctionComponent } from 'react';
import {
    Stack,
    Text,
    HStack,
    Accordion,
    AccordionItem,
    AccordionButton,
    Box,
    AccordionIcon,
    AccordionPanel,
    UnorderedList,
    ListItem,
} from '@chakra-ui/react';
import { DetailsDrawer } from '../../components/detailsDrawer/DetailsDrawer';
import { DetailsElement } from '../../components/detailsDrawer/DetailsElement';
import { type IntegrationData } from '../../interface/Integration';
import moment from 'moment';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    integrationDetails: IntegrationData;
}

export const Details: FunctionComponent<Props> = (props: Props) => {
    const { integrationDetails, isOpen, onClose } = props;
    const { key, name, server, hybrid, countries, mode, advice, remarks, commits, twoFAMethods, jsInjectionTypes } =
        integrationDetails;

    return (
        <DetailsDrawer
            isOpen={isOpen}
            onClose={onClose}
            title={
                <HStack>
                    <Text>Integration details</Text>
                </HStack>
            }
            size="lg"
        >
            <Stack>
                <DetailsElement label="Key" value={key} />
                <DetailsElement label="Name" value={name} />
                <DetailsElement label="Server" value={server} />
                {hybrid && <DetailsElement label="Hybrid" value={hybrid ? 'True' : ''} />}
                {countries && <DetailsElement label="Countries" value={countries} />}
                {mode && <DetailsElement label="Mode" value={mode} />}
                {advice && <DetailsElement label="Advice" value={advice} />}
                <DetailsElement label="Remarks">
                    {remarks?.split('\n').map(function (item, idx) {
                        return (
                            <Text key={idx}>
                                {item}
                                <br />
                            </Text>
                        );
                    })}
                </DetailsElement>
                {twoFAMethods.length > 0 && (
                    <>
                        <DetailsElement label="2FA Methods" />
                        <Box pb={4}>
                            <UnorderedList>
                                {twoFAMethods.map((item: string) => {
                                    return <ListItem key={item}>{item}</ListItem>;
                                })}
                            </UnorderedList>
                        </Box>
                    </>
                )}
                {jsInjectionTypes.length > 0 && (
                    <>
                        <DetailsElement label="JS-Injection Methods" />
                        <Box pb={4}>
                            <UnorderedList>
                                {jsInjectionTypes.map((item: string) => {
                                    return <ListItem key={item}>{item}</ListItem>;
                                })}
                            </UnorderedList>
                        </Box>
                    </>
                )}
                {commits.length > 0 && (
                    <>
                        <DetailsElement label="Commits" />
                        <Accordion allowToggle>
                            {commits.map((item, index) => {
                                return (
                                    <AccordionItem key={index}>
                                        <h2>
                                            <AccordionButton>
                                                <Box as="span" flex="1" textAlign="left">
                                                    {`${moment(item.created_at).format('YYYY-MM-DD')} ${item.title}`}
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4}>
                                            <Box>{item.name}</Box>
                                            <Box>{item.message}</Box>
                                        </AccordionPanel>
                                    </AccordionItem>
                                );
                            })}
                        </Accordion>
                    </>
                )}
            </Stack>
        </DetailsDrawer>
    );
};
