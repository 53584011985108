import React, { type FunctionComponent, useMemo, useState, useEffect } from 'react';
import { ContentContainer } from '../../components/ContentContainer';
import { type Column } from 'react-table';
import { TableContainer } from '../../components/table/TableContainer';
import { type CrashFileStatistic, type EvaluationData } from '../../interface/Evaluation';
import { Request as apiRequest } from '../../helper';
import { Box } from '@chakra-ui/react';

export const List: FunctionComponent = () => {
    const [responseData, setResponseData] = useState<EvaluationData>();

    useEffect(() => {
        setResponseData(undefined);
        async function getIntegrations(): Promise<void> {
            const response = await apiRequest.get<EvaluationData>({ path: '/webconnect/evaluation/crash_file' });
            setResponseData(response.data);
        }
        getIntegrations();
    }, []);

    const tableData = useMemo(() => responseData?.crashFileStatistic, [responseData?.crashFileStatistic]);
    const columns: Array<Column<CrashFileStatistic>> = useMemo(
        () => [
            {
                Header: 'File',
                accessor: (row) => row.name,
            },
            {
                Header: 'All crashes (count)',
                accessor: (row) => row.fileCrashesAll,
                textAlign: 'right',
            },
            {
                Header: 'All crashes (%)',
                accessor: (row) => `${row.percentageAll.toFixed(2)} %`,
                textAlign: 'right',
            },
            {
                Header: 'Unclosed crashes (count)',
                accessor: (row) => (row.fileCrashesOpen === -1 ? '' : row.fileCrashesOpen),
                textAlign: 'right',
            },
            {
                Header: 'Unclosed crashes (%)',
                accessor: (row) => (row.fileCrashesOpen === -1 ? '' : `${row.percentageOpen.toFixed(2)} %`),
                textAlign: 'right',
            },
        ],
        [responseData?.crashFileStatistic],
    );

    return (
        <ContentContainer headline="Crash evaluation" description="List all crashing files">
            <Box>
                <Box>Count all crashes: {responseData?.crash.all}</Box>
                <Box>Count all unclosed crashes: {responseData?.crash.allOpen}</Box>
            </Box>
            {tableData && (
                <>
                    <TableContainer
                        columns={columns}
                        data={tableData}
                        initialState={{
                            sortBy: [{ id: 'Unclosed crashes (count)', desc: true }],
                        }}
                        search={{
                            placeholder: 'Search for values',
                        }}
                    />
                </>
            )}
        </ContentContainer>
    );
};
