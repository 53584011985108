import React, { type FunctionComponent, useState, useEffect } from 'react';
import { ContentContainer } from '../../components/ContentContainer';
import {
    useGetCrashesByCrashGroupQuery,
    useGetCrashesByUserQuery,
    useGetCrashesByIdQuery,
} from '../../generated/types';
import { useLocation, useParams } from 'react-router';
import { CrashTable } from './CrashTable';

interface SerachValue {
    crashId?: string;
    crashGroupId?: string;
    fileVersion?: string;
    userId?: string;
}

export const CrashList: FunctionComponent = () => {
    const { id: paramId } = useParams<Record<string, string | undefined>>();
    const location = useLocation();

    const [initialSearchValue, setInitialSearchValue] = useState<SerachValue>({});
    let resultCrashes = null;

    useEffect(() => {
        function filterById(): void {
            if (location.pathname.startsWith('/crashlist/crashgroups') && paramId) {
                const regexpSize = /.*@(.*):(.*)/;
                const match = paramId.match(regexpSize);
                if (match) {
                    setInitialSearchValue({
                        crashGroupId: match[2],
                        fileVersion: match[1],
                    });
                }
            } else if (location.pathname.startsWith('/crashlist/user')) {
                setInitialSearchValue({
                    userId: paramId,
                });
            } else if (paramId) {
                setInitialSearchValue({
                    crashId: paramId,
                });
            }
        }
        filterById();
    }, []);

    if (initialSearchValue.userId) {
        [resultCrashes] = useGetCrashesByUserQuery({
            variables: {
                userId: initialSearchValue.userId,
            },
        });
    } else if (initialSearchValue.crashId) {
        [resultCrashes] = useGetCrashesByIdQuery({
            variables: {
                id: initialSearchValue.crashId,
            },
        });
    } else {
        [resultCrashes] = useGetCrashesByCrashGroupQuery({
            variables: {
                crashGroupId: initialSearchValue.crashGroupId ?? '',
                fileVersion: initialSearchValue.fileVersion ?? '',
            },
        });
    }
    const { data, fetching, error } = resultCrashes;

    return (
        <ContentContainer
            headline="Crashlist"
            description="Crashlist grouped by crashid and message"
            showSpinner={fetching}
            error={error}
        >
            {data && <CrashTable data={data} initialSearchValue={initialSearchValue} />}
        </ContentContainer>
    );
};
