import React, { type FunctionComponent } from 'react';
import { Stack, Link, Alert, AlertIcon, ButtonGroup, Text, HStack, Box } from '@chakra-ui/react';
import { DownloadButton } from '../../components/DownloadButton';
import { DetailsDrawer } from '../../components/detailsDrawer/DetailsDrawer';
import { DetailsElement } from '../../components/detailsDrawer/DetailsElement';
import { StateIcon } from '../../components/StateIcon';
import moment from 'moment';
import { type Har } from '../../generated/types';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    harDetails: Har;
    actionMenu: (selectedHar: Har) => JSX.Element;
}

export const Details: FunctionComponent<Props> = (props: Props) => {
    const { harDetails } = props;
    return (
        <DetailsDrawer
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={
                <HStack>
                    <Text>HAR details</Text>
                    {harDetails.state && <StateIcon state={harDetails.state} size={4} />}
                </HStack>
            }
            footer={
                <Alert status="warning">
                    <AlertIcon />
                    Attention: Handle these files carefully. They contain user data and could contain login credentials.
                </Alert>
            }
        >
            <Stack>
                <Box pb="4">{props.actionMenu(harDetails)}</Box>
                <DetailsElement label="E-Mail" value={harDetails.email} />
                {harDetails.issue && (
                    <DetailsElement label="Issue">
                        <Link
                            href={`https://gitlab.outbankapp.com/outbank/webconnectscripts/-/issues/${harDetails.issue.replace(
                                '#',
                                '',
                            )}`}
                            isExternal
                        >
                            {harDetails.issue}
                        </Link>
                    </DetailsElement>
                )}
                <DetailsElement label="Bank" value={harDetails.bank} />
                <DetailsElement label="Since" value={moment(harDetails.since ?? '').format('YYYY-MM-DD')} />
                <DetailsElement label="Session Warning" value={harDetails.sessionwarning ? 'True' : 'False'} />
                <DetailsElement
                    label="Authorize Credentials"
                    value={harDetails.authorizecredentials ? 'True' : 'False'}
                />
                <DetailsElement label="Authorize Testing" value={harDetails.authorizetesting ? 'True' : 'False'} />
                {harDetails.note && <DetailsElement label="Note" value={harDetails.note} />}
                <DetailsElement label="Files">
                    <ButtonGroup spacing="2" variant="outline" colorScheme="blue">
                        {harDetails.files?.nodes.map(
                            (file) =>
                                file && (
                                    <DownloadButton
                                        key={file.id}
                                        buttonText={file.suffix}
                                        fileName={`${file.name}.${file.suffix}`}
                                        path={`/file/${file.id}`}
                                    />
                                ),
                        )}
                    </ButtonGroup>
                </DetailsElement>
            </Stack>
        </DetailsDrawer>
    );
};
