import React, { useState, type FunctionComponent } from 'react';
import { IoLogoApple } from 'react-icons/io5';
import {
    Box,
    Button,
    HStack,
    Heading,
    Icon,
    Input,
    Square,
    Stack,
    useColorModeValue,
    useToast,
} from '@chakra-ui/react';
import { Request } from '../../helper';
import { Parser } from '@json2csv/plainjs';
import { download } from './AppleAds';

export const Keyword: FunctionComponent = (props) => {
    const toast = useToast();
    const [startDateCampaignKeyword, setStartDateCampainKeyword] = useState('');
    const [endDateCampainKeyword, setEndDateCampainKeyword] = useState('');

    const fetchCampaignReportGroupedByKeywords = async (): Promise<void> => {
        const reDateFormat = /\d{4}-\d{2}-\d{2}/;
        if (!startDateCampaignKeyword.match(reDateFormat) || !startDateCampaignKeyword.match(reDateFormat)) {
            toast({
                position: 'top',
                title: 'Start/End - Date is invalid!',
                status: 'error',
                isClosable: true,
                duration: 1000,
            });
        } else {
            toast({
                position: 'top',
                title: 'Start creating CSV Data! This will take a while.',
                status: 'info',
                isClosable: true,
                duration: 5000,
            });
            const response = await Request.get<any>({
                path: `/ads/apple/campaigns?startDate=${startDateCampaignKeyword}&endDate=${endDateCampainKeyword}&reportType=keyword`,
            });

            try {
                const opts = {};
                const parser = new Parser(opts);
                const csv = parser.parse(response.data.result);
                download(csv, `${startDateCampaignKeyword}-${endDateCampainKeyword}_CampaignReportByKeywords.csv`);
                toast({
                    position: 'top',
                    title: 'CSV download finished!',
                    status: 'success',
                    isClosable: true,
                    duration: 1000,
                });
            } catch (err) {
                toast({
                    position: 'top',
                    title: 'CSV conversion failed',
                    status: 'error',
                    isClosable: true,
                    duration: 1000,
                });
            }
        }
    };

    return (
        <Box
            px={{ base: '4', md: '6' }}
            py={{ base: '5', md: '6' }}
            bg="bg-surface"
            borderRadius="lg"
            boxShadow={{ base: 'none', sm: 'md' }}
        >
            <Stack spacing={{ base: '5', md: '6' }}>
                <Stack direction="row" justify="space-between">
                    <HStack spacing="4">
                        <Square
                            size="12"
                            bg={useColorModeValue('gray.200', 'gray.700')}
                            borderRadius="md"
                            cursor="pointer"
                        >
                            <Icon as={IoLogoApple} boxSize="6" />
                        </Square>
                        <Heading as="h4" size="md">
                            Campaign report by keywords
                        </Heading>
                    </HStack>
                </Stack>
                <Stack>
                    <Input
                        placeholder="Start-Date (YYYY-MM-DD)"
                        onChange={(event) => {
                            setStartDateCampainKeyword(event.target.value);
                        }}
                    />
                    <Input
                        placeholder="End-Date (YYYY-MM-DD)"
                        onChange={(event) => {
                            setEndDateCampainKeyword(event.target.value);
                        }}
                    />
                </Stack>
                <Stack spacing="4">
                    <Button onClick={fetchCampaignReportGroupedByKeywords}>download</Button>
                </Stack>
            </Stack>
        </Box>
    );
};
