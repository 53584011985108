import React, { type FunctionComponent, useMemo, useState, useEffect } from 'react';
import { ContentContainer } from '../../components/ContentContainer';
import { type Column } from 'react-table';
import { TableContainer } from '../../components/table/TableContainer';
import { type UserIntegrationStatistics, type EvaluationData } from '../../interface/Evaluation';
import { Request as apiRequest } from '../../helper';

export const List: FunctionComponent = () => {
    const [responseData, setResponseData] = useState<EvaluationData>();

    useEffect(() => {
        setResponseData(undefined);
        async function getIntegrations(): Promise<void> {
            const response = await apiRequest.get<EvaluationData>({ path: '/webconnect/evaluation/user_integration' });
            setResponseData(response.data);
        }
        getIntegrations();
    }, []);

    const tableData = useMemo(() => responseData?.userIntegrationStatistic, [responseData?.userIntegrationStatistic]);
    const columns: Array<Column<UserIntegrationStatistics>> = useMemo(
        () => [
            {
                Header: 'email',
                accessor: (row) => row.email,
            },
            {
                Header: 'Count',
                accessor: (row) => row.countFiles,
                textAlign: 'right',
            },
            {
                Header: 'Files',
                accessor: (row) => row.listFiles,
                textAlign: 'right',
            },
        ],
        [responseData?.userIntegrationStatistic],
    );

    return (
        <ContentContainer headline="Show known Integrations to a user">
            {tableData && (
                <>
                    <TableContainer
                        columns={columns}
                        data={tableData}
                        initialState={{
                            sortBy: [{ id: 'Count', desc: true }],
                        }}
                        search={{
                            placeholder: 'Search for values',
                        }}
                    />
                </>
            )}
        </ContentContainer>
    );
};
