import React, { type FunctionComponent } from 'react';
import { Center, Spinner } from '@chakra-ui/react';

const LoadingIndicator: FunctionComponent = () => {
    return (
        <Center h="40vh">
            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        </Center>
    );
};

export default LoadingIndicator;
