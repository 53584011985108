import React, { type FunctionComponent } from 'react';
import { Icon } from '@chakra-ui/react';

interface Props {
    color: string;
    boxSize: number;
}

export const CircleIcon: FunctionComponent<Props> = (props: Props) => (
    <Icon viewBox="0 0 200 200" {...props}>
        <path fill="currentColor" d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0" />
    </Icon>
);
