import React, { type FunctionComponent, useState, useEffect } from 'react';
import { ContentContainer } from '../../components/ContentContainer';
import { Request as apiRequest } from '../../helper';
import { type IntegrationData } from '../../interface/Integration';
import { ListComponent } from './ListComponent';

export const List: FunctionComponent = () => {
    const [integrations, setIntegrations] = useState<IntegrationData[]>();

    useEffect(() => {
        setIntegrations(undefined);
        async function getIntegrations(): Promise<void> {
            const response = await apiRequest.get<IntegrationData[]>({ path: '/gitlab/file/integrations/true' });
            setIntegrations(response.data);
        }
        getIntegrations();
    }, []);

    return (
        <ContentContainer headline="Integrations" description="List of all integrations" showSpinner={!integrations}>
            {integrations && <ListComponent integrations={integrations} extended={true} />}
        </ContentContainer>
    );
};
