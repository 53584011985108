/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { type FunctionComponent, useMemo } from 'react';
import { type Column } from 'react-table';
import { TableContainer } from '../table/TableContainer';
import { Box, Code } from '@chakra-ui/react';
import { allBkLogTypes, type BkLogRaw, type ParsedBkLog } from '../../screen/protocol/Details';
import { BKProtocolMethods } from './BKProtocolMethods';

interface Props {
    bkLogSplittedEntries: BkLogRaw[];
    bkLogFilteredMessages: ParsedBkLog;
}

export const BKProtocol: FunctionComponent<Props> = (props: Props) => {
    const { bkLogSplittedEntries, bkLogFilteredMessages } = props;

    const modifyMessageForView = (messages: string): any => {
        let splittedMessages = messages.split('\n');

        splittedMessages = [
            splittedMessages[0],
            ...splittedMessages[1].split('] HNHBK:').map((item, index) => {
                return index === 1 ? `HNHBK:${item}` : `${item}]`;
            }),
            ...splittedMessages.slice(2),
        ];

        const result = [];
        result.push(<Box>{splittedMessages[0]}</Box>);
        result.push(<Box>{splittedMessages[1]}</Box>);

        for (const item of splittedMessages.slice(2)) {
            const subElements = [];
            for (const _item of item.split('\n')) {
                subElements.push(
                    <Code
                        colorScheme={
                            [
                                'DIBUM',
                                'HKSYN:',
                                'DKBKD:',
                                'DKBKU:',
                                'DKBUM:',
                                'DKKKS:',
                                'DKKKU:',
                                'HKBBS:',
                                'HKBBS:',
                                'HKBMB:',
                                'HKBME:',
                                'HKBML:',
                                'HKBSA:',
                                'HKBSE:',
                                'HKBSL:',
                                'HKCAZ:',
                                'HKCCS:',
                                'HKCDB:',
                                'HKCDE:',
                                'HKCDL:',
                                'HKCDN:',
                                'HKCMU:',
                                'HKCSA:',
                                'HKCSB:',
                                'HKCSE:',
                                'HKCSL:',
                                'HKCSU:',
                                'HKCUB:',
                                'HKCUM:',
                                'HKDBS:',
                                'HKDMB:',
                                'HKDMC:',
                                'HKDME:',
                                'HKDML:',
                                'HKDSA:',
                                'HKDSB:',
                                'HKDSC:',
                                'HKDSE:',
                                'HKDSL:',
                                'HKDSW:',
                                'HKFGB:',
                                'HKIDN:',
                                'HKKAZ:',
                                'HKKIF:',
                                'HKSAL:',
                                'HKSPA:',
                                'HKSYN:',
                                'HKTAB:',
                                'HKTAN:',
                                'HKWPD:',
                            ].some((x) => _item.includes(x))
                                ? 'red'
                                : 'gray'
                        }
                    >
                        {item}
                    </Code>,
                );
            }
            result.push(<Box>{subElements}</Box>);
        }
        return result;
    };

    const tableData = useMemo(() => bkLogSplittedEntries, bkLogSplittedEntries);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columns: Array<Column<BkLogRaw>> = useMemo(
        () => [
            {
                Header: 'time',
                accessor: (row) => row.time.split('T').join('\n'),
            },
            {
                Header: 'type',
                accessor: 'type',
            },
            {
                Header: 'level',
                accessor: 'level',
            },
            {
                Header: 'message',
                accessor: (row) => (row.type === 'FINTS' ? modifyMessageForView(row.message) : row.message),
            },
        ],
        [bkLogSplittedEntries],
    );

    return (
        <>
            <BKProtocolMethods bkLogFilteredMessages={bkLogFilteredMessages} />
            {tableData && (
                <TableContainer
                    columns={columns}
                    data={tableData}
                    search={{
                        placeholder: 'Search',
                        selectConfig: {
                            columAccessor: 'type',
                            selectOptions: <MethodType />,
                        },
                    }}
                />
            )}
        </>
    );
};

const MethodType: FunctionComponent = () => {
    return (
        <>
            <option value="">All</option>
            {allBkLogTypes.map((item: string) => (
                <option value={item} key={item}>
                    {item}
                </option>
            ))}
        </>
    );
};
