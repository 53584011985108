/* eslint-disable react/display-name */
import React from 'react';
import { Alert, AlertIcon, AlertTitle, AlertDescription } from '@chakra-ui/react';

export interface ErrorBoxConfig {
    title?: string;
    message?: string;
}

const ErrorBox = (config: ErrorBoxConfig): JSX.Element | null => {
    const { title, message } = config;
    return (
        <>
            <Alert
                status="error"
                variant="subtle"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
            >
                <AlertIcon boxSize="40px" mr={0} />
                <AlertTitle mt={4} mb={1} fontSize="lg">
                    {title ?? 'Unknown Error'}
                </AlertTitle>
                <AlertDescription>{message ?? 'Network error'}</AlertDescription>
            </Alert>
        </>
    );
};

export default ErrorBox;
