import { type UseToastOptions } from '@chakra-ui/react';

export const copy = (value: string): void => {
    navigator.clipboard.writeText(value);
};

export const copyWithFeedback = (
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    toast: any,
    toastSettings: UseToastOptions = {
        title: 'copied',
        status: 'success',
        duration: 1000,
        isClosable: true,
    },
): void => {
    navigator.clipboard.writeText(value);
    toast(toastSettings);
};
