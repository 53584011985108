/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { type FunctionComponent, useEffect, useMemo, useState } from 'react';
import {
    Text,
    HStack,
    useDisclosure,
    Stack,
    StackDivider,
    Icon,
    Button,
    useToast,
    Box,
    AlertIcon,
} from '@chakra-ui/react';
import { TableContainer } from '../../components/table/TableContainer';
import { IoShareOutline } from 'react-icons/io5';
import { DetailsDrawer } from '../../components/detailsDrawer/DetailsDrawer';
import { type Size } from '../../interface/Chakra';
import { Request as apiRequest, copyWithFeedback } from '../../helper';
import { type Crash, type Request } from '../../interface/Crash';
import { type Column } from 'react-table';
import { getColorForResponseCode } from '../../helper/ResponseCode';
import { type Maybe } from '../../generated/types';
import { BindingsItem } from '../../components/report/BindingsItem';
import { DetailsElement } from '../../components/detailsDrawer/DetailsElement';
import { RoundedBox } from '../../components/RoundedBox';
import moment from 'moment';
import { Detail } from '../../components/requestDetail/Detail';
import { convertCrashToHar } from '../../helper/HarConverter';
import {
    RequestMethodTag,
    RequestStatusTag,
    RequestTypeTag,
    type RequestType,
    type RequestMethod,
} from '../../components/RequestTags';
import { WarningTwoIcon } from '@chakra-ui/icons';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    id: string;
    size?: Size;
}

export const CrashDetail: FunctionComponent<Props> = (props: Props) => {
    const { id } = props;
    const [details, setDetails] = useState<Crash>();
    const [selectedItemId, setSelectedItemId] = useState<Maybe<number> | undefined>();
    const { isOpen: isOpenDetails, onOpen: onOpenDetails, onClose: onCloseDetails } = useDisclosure();
    const toast = useToast();

    useEffect(() => {
        setDetails(undefined);
        async function getDetails(): Promise<void> {
            if (id) {
                const response = await apiRequest.get<Crash>({ path: `/report/crash/${id}` });
                setDetails(response.data);
            }
        }
        getDetails();
    }, [id]);

    if (details) {
        console.log(details);
    }

    const _requests = details ? details.log.frames.find((item) => item.name === 'requests') : details;
    let selectedItem: Request | undefined;
    if (_requests?.bindings.requests)
        selectedItem = _requests?.bindings.requests.find((item) => item.id === selectedItemId);

    const openDetails = (_selectedItem: Request): void => {
        // id start with 0. So !_selectedItem.id is false if value is 0
        if ('id' in _selectedItem) {
            setSelectedItemId(_selectedItem.id);
            onOpenDetails();
        }
    };

    const copyLinkClipboard = async (id: string): Promise<void> => {
        const url = window.location.protocol + '//' + window.location.host;
        navigator.clipboard.writeText(`${url}/crashlist/${id}`);
    };

    const tableData = useMemo(() => _requests?.bindings.requests, [_requests?.bindings.requests]);
    const columns = useMemo(
        () => [
            {
                Header: 'state',
                accessor: (row) => row.response?.code ?? '',
            },
            {
                Header: 'Code',
                accessor: (row) => row.response?.code ?? '',
                disableSortBy: true,
                Cell: ({ cell: { value } }: { cell: { value: string } }) => (
                    <RequestStatusTag value={value as RequestType} />
                ),
            },
            {
                Header: 'Method',
                accessor: 'method',
                disableSortBy: true,
                Cell: ({ cell: { value } }: { cell: { value: string } }) => (
                    <RequestMethodTag value={value as RequestMethod} />
                ),
            },
            {
                Header: 'Type',
                accessor: (row) => row.type || '',
                disableSortBy: true,
                Cell: ({ cell: { value } }: { cell: { value: string } }) => (
                    <RequestTypeTag value={value as RequestType} />
                ),
            },
            {
                Header: 'URL',
                accessor: (row) => {
                    const _url = row.url;
                    if (_url.length > 200) return `${_url.slice(0, 150)}...`;
                    return _url;
                },
                disableSortBy: true,
            },
            {
                Header: 'Date',
                accessor: (row) =>
                    row.response?.headers && 'date' in row.response.headers
                        ? moment(row.response?.headers.date).format('YY-MM-DD HH:mm:ss')
                        : '',
                disableSortBy: true,
            },
        ],
        [_requests?.bindings.requests],
    ) as Array<Column<Request>>;

    const bindings = details ? details.log.frames.filter((item) => item.name !== 'requests') : details;

    const requestToHarConverter = (): void => {
        if (details) {
            const requests = details?.log.frames.find((item) => item.name === 'requests')?.bindings.requests;
            const file = new Blob([JSON.stringify(convertCrashToHar(requests ?? []), null, 4)], { type: 'text/plain' });
            const element = document.createElement('a');
            element.href = URL.createObjectURL(file);
            element.download =
                `${details?.log.file}@${details?.log.fileVersion}:${details?.log.lineNumber}_` +
                moment().format('YY-MM-DD_HH-mm-ss') +
                '.har';

            // simulate link click
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
            // remove link element
            element?.parentNode?.removeChild(element);
        }
    };

    if (details && !Object.keys(details?.app ?? []).includes('platformVersion')) {
        details.app.platformVersion = details.osversion;
    }

    if (details && !Object.keys(details?.app ?? []).includes('platformType')) {
        details.app.platformType = details.model;
    }

    return (
        <DetailsDrawer
            {...props}
            title={
                <Text fontWeight="bold">{`${details?.log.file}@${details?.log.fileVersion}:${details?.log.lineNumber}`}</Text>
            }
            showSpinner={!details}
        >
            {details && (
                <>
                    <RoundedBox>
                        <HStack>
                            <DetailsElement pb={0} boxProps={{ flex: '1' }} label="Email" value={details.email} />
                            <DetailsElement
                                pb={0}
                                boxProps={{ flex: '1' }}
                                label="CustomerId"
                                value={details.customerid}
                            />
                            <Button
                                rightIcon={<Icon as={IoShareOutline} />}
                                borderColor="black"
                                variant="outline"
                                onClick={async () => {
                                    await copyLinkClipboard(id);
                                }}
                            >
                                Crash path
                            </Button>
                            <Button
                                borderColor="black"
                                variant="outline"
                                onClick={() => {
                                    requestToHarConverter();
                                }}
                            >
                                Download as Har
                            </Button>
                            <Button
                                borderColor="black"
                                variant="outline"
                                onClick={() => {
                                    copyWithFeedback(JSON.stringify(details), toast);
                                }}
                            >
                                Crash Object
                            </Button>
                        </HStack>
                    </RoundedBox>
                    <RoundedBox>
                        <HStack>
                            {Object.entries(details.app).map(([key, value]) => (
                                <DetailsElement
                                    key={key}
                                    pb={0}
                                    boxProps={{ flex: '1' }}
                                    label={
                                        {
                                            appVersion: 'App-Version',
                                            platformEnvironment: 'App-Platform',
                                            platformType: 'Platform-Type',
                                            platformVendor: 'Platform-Vendor',
                                            platformVersion: 'Platform-Version',
                                            webEngineVersion: 'Web-View',
                                            isOsOld: 'Os-Old',
                                            isAppOld: 'App-Old',
                                        }[key] ?? ''
                                    }
                                >
                                    {typeof value === 'boolean' ? (
                                        <Box>
                                            {!!value && (
                                                <>
                                                    <WarningTwoIcon mt={-1} color={'#DD6B20'} />{' '}
                                                </>
                                            )}
                                            {value.toString()}
                                        </Box>
                                    ) : (
                                        value.toString()
                                    )}
                                </DetailsElement>
                            ))}
                        </HStack>
                    </RoundedBox>
                    <RoundedBox bg="yellow.200" color="black">
                        {details.log.message}
                    </RoundedBox>
                    <RoundedBox headline="Log messages" collapseAble collapseInitial={true}>
                        <Stack spacing="2" py="2" px="8" divider={<StackDivider />}>
                            {details?.log.log.map((item, index) => <p key={`${item}_${index}`}>{item}</p>)}
                        </Stack>
                    </RoundedBox>
                    <RoundedBox headline="Requests" collapseAble>
                        {tableData && (
                            <TableContainer
                                columns={columns}
                                data={tableData}
                                hiddenColumns={['state']}
                                showColoredState={true}
                                coloredStateMethod={getColorForResponseCode}
                                search={{
                                    placeholder: 'Search for request',
                                }}
                                rowClick={openDetails}
                                searchBarStyles={{ paddingLeft: '25px' }}
                            />
                        )}
                    </RoundedBox>
                    <RoundedBox
                        headline={`Bindings ${
                            bindings && bindings?.length > 30 ? '(More than 30 Bindings, no script evaluation)' : ''
                        }`.trim()}
                        collapseAble
                        collapseInitial={true}
                    >
                        <Stack spacing={2} mt={2} divider={<StackDivider pb={0} />}>
                            {details &&
                                bindings?.map((item, index) => {
                                    const version = item.version === details.log.fileVersion;
                                    const line = item.currentline === details.log.lineNumber;
                                    return (
                                        <BindingsItem
                                            key={index}
                                            frame={item}
                                            codeColor={version && line ? 'red' : undefined}
                                        />
                                    );
                                })}
                        </Stack>
                    </RoundedBox>
                </>
            )}
            {selectedItem && (
                <DetailsDrawer isOpen={isOpenDetails} size="xl" onClose={onCloseDetails} title="">
                    <Detail request={selectedItem} reportId={id} />
                </DetailsDrawer>
            )}
        </DetailsDrawer>
    );
};
