import React, { type FunctionComponent } from 'react';
import { useParams } from 'react-router';
import { ContentContainer } from '../../components/ContentContainer';
import { useGetLastCrashesQuery } from '../../generated/types';
import { CrashTable } from './CrashTable';

export const List: FunctionComponent = () => {
    const { quantity } = useParams<Record<string, string | undefined>>();
    const [resultQuery] = useGetLastCrashesQuery({
        variables: { count: quantity ? parseInt(quantity) : 100 },
    });
    const { data, fetching, error } = resultQuery;

    return (
        <ContentContainer
            headline="Last crashes"
            description="A list of all the last crashes"
            showSpinner={fetching}
            error={error}
        >
            {data && (
                <CrashTable
                    data={data}
                    initialState={{
                        sortBy: [{ id: 'groupCount', desc: true }],
                    }}
                />
            )}
        </ContentContainer>
    );
};
