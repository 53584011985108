import React, { type FunctionComponent } from 'react';
import { UnorderedList, OrderedList, ListItem, Heading } from '@chakra-ui/react';
import Markdown from 'react-markdown';

interface Props {
    markdown: string;
}

export const MarkDownBox: FunctionComponent<Props> = (props: Props) => {
    return (
        <Markdown
            components={{
                ul: UnorderedList,
                ol: OrderedList,
                li: ListItem,
                h1(props) {
                    /* eslint-disable-next-line react/prop-types */
                    const { node, ...rest } = props;
                    return <Heading as="h1" size="lg" {...rest} />;
                },
                h2(props) {
                    /* eslint-disable-next-line react/prop-types */
                    const { node, ...rest } = props;
                    return <Heading as="h2" size="md" {...rest} />;
                },
                h3(props) {
                    /* eslint-disable-next-line react/prop-types */
                    const { node, ...rest } = props;
                    return <Heading as="h3" size="sm" {...rest} />;
                },
            }}
        >
            {props.markdown}
        </Markdown>
    );
};
