import React, { type FunctionComponent, useMemo } from 'react';
import { type IContractsData } from '../../../interface/SampleData';
import { Button, Divider, Heading } from '@chakra-ui/react';
import { type Column } from 'react-table';
import { StateOptions } from '../../../components/table/StateOptions';
import { colorForState } from '../../../helper';
import { TableContainer } from '../../../components/table/TableContainer';

interface ContractTableProps {
    contractsData: IContractsData[];
    openEditContracts: (transaction: IContractsData) => void;
    addContract: (newContract: IContractsData) => void;
}

export const ContractTable: FunctionComponent<ContractTableProps> = ({
    contractsData,
    openEditContracts,
    addContract,
}: ContractTableProps) => {
    const createContract = (): void => {
        const newContract: IContractsData = {
            id: 'newId',
            amounts: [
                {
                    key: 'newAmount',
                    value: 0,
                    currency: 'newCurrency',
                },
            ],
            fields: [
                {
                    key: 'newKey',
                    value: 'newValue',
                },
            ],
        };
        addContract(newContract);
    };

    const contractsTableData = useMemo(() => contractsData, [contractsData]);
    const contractsColumns: Array<Column<IContractsData>> = useMemo(
        () => [
            {
                Header: 'id',
                accessor: 'id',
            },
            {
                Header: 'amounts',
                accessor: 'amounts',
                Cell: (cell) => {
                    return (
                        <>
                            {cell.row.original.amounts.map((amount: any, index: any) => (
                                <p key={index}>{amount.value + ' ' + amount.currency}</p>
                            ))}
                        </>
                    );
                },
            },
            {
                Header: 'fields',
                accessor: 'fields',
                Cell: (cell) => {
                    return (
                        <>
                            {cell.row.original.fields.map((field: any, index: any) => (
                                <p key={index}>{field.key + ' ' + field.value}</p>
                            ))}
                        </>
                    );
                },
            },
        ],
        [],
    );

    return (
        <>
            <Divider mt="8" />
            <Heading as="h3" size="lg">
                Contracts
            </Heading>
            <TableContainer
                columns={contractsColumns}
                data={contractsTableData}
                search={{
                    placeholder: 'Search for contracts',
                    selectConfig: {
                        columAccessor: 'state',
                        selectOptions: <StateOptions />,
                    },
                }}
                hiddenColumns={[]}
                showColoredState={true}
                coloredStateMethod={colorForState}
                initialState={{
                    sortBy: [{ id: 'order', desc: true }],
                }}
                rowClick={openEditContracts}
                defaultPageSize={20}
            />
            <Button onClick={createContract} colorScheme="blue" mr="10">
                Create new Contract
            </Button>
            <Divider mt="8" />
        </>
    );
};
