import React, { type FunctionComponent, useMemo, useState, useEffect } from 'react';
import { ContentContainer } from '../../components/ContentContainer';
import { type Column } from 'react-table';
import { TableContainer } from '../../components/table/TableContainer';
import { useGetAllUsersQuery, type UserFragment } from '../../generated/types';
import { Request } from '../../helper';
import { useParams } from 'react-router';
import { Badge, Link, MenuItem, useToast } from '@chakra-ui/react';
import { ActionMenu } from '../../components/ActionMenu';
import { IoTrashOutline } from 'react-icons/io5';
import { Dialog } from '../../components/Dialog';

export const UserList: FunctionComponent = () => {
    const { id: paramId } = useParams<Record<string, string | undefined>>();
    const [resultUsersQuery, reexecuteGetUsers] = useGetAllUsersQuery();
    const { data, fetching, error } = resultUsersQuery;
    const [startSearchValue, setStartSearchValue] = useState('');
    const toast = useToast();

    useEffect(() => {
        function filterById(): void {
            if (paramId) setStartSearchValue(paramId);
        }
        filterById();
    }, []);

    const countElement = (userId: string, count: number, path: string): JSX.Element => (
        <Link href={'/' + path + '/' + userId}>
            <Badge variant="outline" colorScheme="blue">
                {count}
            </Badge>
        </Link>
    );

    const [selectedItemEmail, setSelectedItemEmail] = useState<string>();
    const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
    const onCloseDeleteDialog = async (email?: string): Promise<void> => {
        if (!email) return;
        setIsOpenDeleteDialog(false);
        const response = await Request.delete<Response>({
            path: `/privacy/6a858135-4ae8-4274-bfd5-57362b81eec4/${email}`,
        });

        if (response.status === 200) {
            toast({
                title: `Following data are deleted: ${JSON.stringify(response.data)}`,
                status: 'success',
                duration: 4000,
                isClosable: true,
                position: 'top',
            });
            reexecuteGetUsers({ requestPolicy: 'network-only' });
        } else {
            toast({
                title: 'Unexpected error',
                status: 'error',
                duration: 2000,
                isClosable: true,
                position: 'top',
            });
        }
    };

    const actionMenu = (_selectedItem: UserFragment): JSX.Element => (
        <ActionMenu>
            <MenuItem
                icon={<IoTrashOutline />}
                onClick={() => {
                    setSelectedItemEmail(_selectedItem.email);
                    setIsOpenDeleteDialog(true);
                }}
            >
                Delete
            </MenuItem>
        </ActionMenu>
    );

    const tableData = useMemo(() => data?.outbankUsers?.nodes, [data?.outbankUsers?.nodes]);
    const columns: Array<Column<UserFragment>> = useMemo(
        () => [
            {
                Header: 'User id',
                accessor: (row) => row?.id,
            },
            {
                Header: 'E-Mail',
                accessor: (row) => row?.email ?? row?.id,
            },
            {
                Header: 'Source',
                accessor: (row) => row?.appSource?.name ?? '',
            },
            {
                Header: 'Crashes',
                accessor: (row) => row.crashes.totalCount,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: (cell: any) =>
                    countElement(cell.row.original.id, cell.row.original.crashes.totalCount, 'crashlist/user'),
                textAlign: 'right',
            },
            {
                Header: 'Protocols',
                accessor: (row) => row.protocols.totalCount,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: (cell: any) =>
                    countElement(cell.row.original.id, cell.row.original.protocols.totalCount, 'protocols'),
                textAlign: 'right',
            },
            {
                Header: 'HARs',
                accessor: (row) => row.hars.totalCount,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: (cell: any) => countElement(cell.row.original.id, cell.row.original.hars.totalCount, 'har'),
                textAlign: 'right',
            },
            {
                Header: '',
                accessor: 'id',
                disableSortBy: true,
                // eslint-disable-next-line react/display-name
                Cell: (cell) => actionMenu(cell.row.original),
                textAlign: 'right',
                hasCellClick: false,
            },
        ],
        [data?.outbankUsers?.nodes],
    );

    return (
        <ContentContainer headline="Users" description="List of all Users" showSpinner={fetching} error={error}>
            {tableData && (
                <>
                    <TableContainer
                        columns={columns}
                        data={tableData}
                        search={{
                            startSearchValue,
                            placeholder: 'Search for User',
                        }}
                        initialState={{
                            sortBy: [{ id: 'email', desc: true }],
                        }}
                        hiddenColumns={['User id']}
                    />

                    <Dialog
                        isOpen={isOpenDeleteDialog}
                        onClose={async () => {
                            await onCloseDeleteDialog(selectedItemEmail);
                        }}
                        title="Delete HAR"
                        content={`Delete entire User (${selectedItemEmail})? All HARs, Crashes, Protocolls will be deleted.`}
                        okButtonTitle="Delete"
                        isDestructive
                    />
                </>
            )}
        </ContentContainer>
    );
};
