import React, { type FunctionComponent, useState, useEffect } from 'react';
import { ContentContainer } from '../../components/ContentContainer';
import { useGetAllUnClosedCrashGroupsQuery } from '../../generated/types';
import { useParams } from 'react-router';
import { CrashGroupList } from './CrashGroupList';

export const List: FunctionComponent = () => {
    const { id: paramId } = useParams<Record<string, string | undefined>>();
    const [resultCrashGroupsQuery] = useGetAllUnClosedCrashGroupsQuery();
    const { data, fetching, error } = resultCrashGroupsQuery;

    const [startSearchValue, setStartSearchValue] = useState('');

    useEffect(() => {
        function filterById(): void {
            if (paramId) setStartSearchValue(paramId);
        }
        filterById();
    }, []);

    return (
        <ContentContainer
            headline="Crashgroups"
            description="Crashgroups are based on crashes. Crashes are grouped by filename and code line."
            showSpinner={fetching}
            error={error}
        >
            {data && <CrashGroupList data={data} startSearchValue={startSearchValue} />}
        </ContentContainer>
    );
};
