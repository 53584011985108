import React, { type FunctionComponent } from 'react';
import { ContentContainer } from '../../components/ContentContainer';
import { useGetFeatureRequestsQuery } from '../../generated/types';
import { CrashTable } from './CrashTable';

export const List: FunctionComponent = () => {
    const [resultQuery] = useGetFeatureRequestsQuery();
    const { data, fetching, error } = resultQuery;

    return (
        <ContentContainer
            headline="Feature Requests"
            description="A list of all sent Feature Requests"
            showSpinner={fetching}
            error={error}
        >
            {data && (
                <CrashTable
                    data={data}
                    initialState={{
                        sortBy: [{ id: 'groupCount', desc: true }],
                    }}
                />
            )}
        </ContentContainer>
    );
};
