import React from 'react';
import { Tag } from '@chakra-ui/react';

export type RequestType = 'JSI' | 'HTTP' | 'WS' | 'Starfish';
const RequestTypeColors: Record<RequestType, string> = {
    JSI: 'yellow',
    HTTP: 'blue',
    WS: 'purple',
    Starfish: 'pink',
};

export type RequestMethod = 'GET' | 'POST';
const RequestMethodColors: Record<RequestMethod, string> = {
    GET: 'green',
    POST: 'pink',
};

interface RequestTagProps {
    value: RequestType | '';
}

interface RequestMethodTagProps {
    value: RequestMethod | '';
}

export const RequestTypeTag: React.FC<RequestTagProps> = (props) => {
    const value = props.value;
    if (value === '' || value === undefined || value === null) {
        return <></>;
    }
    const color = RequestTypeColors[value] || 'gray';
    return (
        <Tag variant="subtle" colorScheme={color}>
            {value}
        </Tag>
    );
};

export const RequestMethodTag: React.FC<RequestMethodTagProps> = ({ value }) => {
    if (value === '' || value === undefined || value === null) {
        return <></>;
    }
    const color = RequestMethodColors[value] || 'gray';
    return (
        <Tag variant="outline" colorScheme={color}>
            {value}
        </Tag>
    );
};

export const RequestStatusTag: React.FC<RequestTagProps> = ({ value }) => {
    if (value === '' || value === undefined || value === null) {
        return <></>;
    }
    let color = 'gray';
    const code = parseInt(value);
    if (code < 100) {
        color = 'white';
    } else if (code < 200) {
        color = 'cyan';
    } else if (code < 300) {
        color = 'green';
    } else if (code < 400) {
        color = 'blue';
    } else if (code < 500) {
        color = 'yellow';
    } else if (code < 600) {
        color = 'red';
    }
    return (
        <Tag variant="solid" colorScheme={color}>
            {value}
        </Tag>
    );
};
