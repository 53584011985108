import React, { type FunctionComponent, useEffect, useState } from 'react';
import {
    Box,
    Flex,
    Heading,
    HStack,
    Icon,
    MenuItem,
    Skeleton,
    SkeletonCircle,
    SkeletonText,
    Spacer,
    Spinner,
    Square,
    Stack,
    Text,
    useToast,
    useBoolean,
    useDisclosure,
    useColorModeValue,
} from '@chakra-ui/react';
import { type AppData, type AppStoreVersion } from '../../interface/AppData';
import { ActionMenu } from '../../components/ActionMenu';
import { LocalizedAppTextsForm } from './LocalizedAppTextsForm';
import { ReleasenotesForm } from './ReleasenotesForm';
import { CreateVersionForm } from './CreateVersionForm';
import { SubmitVersionForm } from './SubmitVersionForm';
import { labelForPlatform, iconForState, iconForPlatform, storeNameForPlatform } from './Helper';
import { Request as apiRequest, getErrorMessage } from '../../helper';
import ErrorBox from '../../components/ErrorBox';

interface Props {
    appId: string;
}

export const OverviewBox: FunctionComponent<Props> = (props) => {
    const [appData, setAppData] = useState<AppData>();
    const [error, setError] = useState<{ title: string; message: string }>();

    useEffect(() => {
        setAppData(undefined);
        setError(undefined);
        async function getAppData(): Promise<void> {
            try {
                const response = await apiRequest.get<AppData>({ path: `/apps/${props.appId}/details` });
                setAppData(response.data);
            } catch (e) {
                setError({ title: 'Failed to load app', message: getErrorMessage(e) });
            }
        }
        getAppData();
    }, []);

    const [isLoading, setLoading] = useBoolean();
    const releaseNotesFormDisclosure = useDisclosure();
    const promotionalTextsFormDisclosure = useDisclosure();
    const keywordsFormDisclosure = useDisclosure();
    const submitFormDisclosure = useDisclosure();
    const betaFormDisclosure = useDisclosure();
    const createFormDisclosure = useDisclosure();
    const toast = useToast({ position: 'top' });

    const platform = appData ? labelForPlatform(appData.platform) : '';
    const clickedCreateVersion = async (appData: AppData): Promise<void> => {
        const name = `${appData.name} ${platform}`;
        try {
            setLoading.on();
            const response = await apiRequest.post<AppData>({
                path: `/apps/${appData.id}/createVersion`,
                data: { versionString: '' },
            });

            toast({
                title: 'Success!',
                description: `Created a new version for ${name}`,
                status: 'success',
                isClosable: true,
            });

            setLoading.off();
            setAppData(response.data);
        } catch (e) {
            toast({
                title: 'Failed!',
                description: `Could not create a new version for ${name}: ${getErrorMessage(e)}`,
                status: 'error',
                isClosable: true,
            });

            setLoading.off();
        }
    };

    const clickedCancelReview = async (appData: AppData, version: AppStoreVersion): Promise<void> => {
        const name = appData.name + ' ' + platform + ' ' + version.versionString;
        try {
            setLoading.on();
            const response = await apiRequest.post<AppData>({
                path: `/apps/${appData.id}/cancelReview`,
            });

            toast({
                title: 'Success!',
                description: `Cancel review of ${name}.`,
                status: 'success',
                isClosable: true,
            });

            setLoading.off();
            setAppData(response.data);
        } catch (e) {
            toast({
                title: 'Failed!',
                description: `Could not cancel review of ${name}: ${getErrorMessage(e)}`,
                status: 'error',
                isClosable: true,
            });

            setLoading.off();
        }
    };

    const clickedReleaseVersion = async (appData: AppData, version: AppStoreVersion): Promise<void> => {
        const name = appData.name + ' ' + platform + ' ' + version.versionString;
        try {
            setLoading.on();
            const response = await apiRequest.post<AppData>({
                path: '/apps/release?appid=' + appData.id + '&versionid=' + version.id,
            });

            toast({
                title: 'Success!',
                description: name + ' has been released.',
                status: 'success',
                isClosable: true,
            });

            setLoading.off();
            setAppData(response.data);
        } catch (e) {
            toast({
                title: 'Failed!',
                description: name + ' has not been released: ' + getErrorMessage(e),
                status: 'error',
                isClosable: true,
            });

            setLoading.off();
        }
    };

    const betaReleasableVersion = appData?.versions.find((version) => version.status.isBetaReleasable);
    const reviewableVersion = appData?.versions.find((version) => version.status.isReviewable);
    const editableVersion = appData?.versions.find((version) => version.status.isEditable);
    const pendingReviewVersion = appData?.versions.find((version) => version.status.isPendingReview);
    const releaseableVersion = appData?.versions.find((version) => version.status.isReleasable);
    const hasActions =
        (appData?.canCreateNewVersion ?? false) ||
        betaReleasableVersion !== undefined ||
        reviewableVersion !== undefined ||
        editableVersion !== undefined ||
        pendingReviewVersion !== undefined ||
        releaseableVersion !== undefined;

    const actionMenu = (appData: AppData): JSX.Element => (
        <ActionMenu isDisabled={!hasActions}>
            {appData.canCreateNewVersion && (
                <MenuItem
                    closeOnSelect={true}
                    onClick={() => {
                        if (appData.platform === 'AMAZON') {
                            clickedCreateVersion(appData);
                        } else {
                            createFormDisclosure.onOpen();
                        }
                    }}
                >
                    Create new version
                </MenuItem>
            )}
            {editableVersion && (
                <MenuItem
                    closeOnSelect={true}
                    onClick={() => {
                        promotionalTextsFormDisclosure.onOpen();
                    }}
                >
                    Edit promotional texts
                </MenuItem>
            )}
            {editableVersion && (
                <MenuItem
                    closeOnSelect={true}
                    onClick={() => {
                        keywordsFormDisclosure.onOpen();
                    }}
                >
                    Edit keywords
                </MenuItem>
            )}
            {editableVersion && (
                <MenuItem
                    closeOnSelect={true}
                    onClick={() => {
                        releaseNotesFormDisclosure.onOpen();
                    }}
                >
                    Edit releasenotes
                </MenuItem>
            )}
            {betaReleasableVersion && (
                <MenuItem
                    closeOnSelect={true}
                    onClick={() => {
                        betaFormDisclosure.onOpen();
                    }}
                >
                    Release {betaReleasableVersion.versionString} as beta
                </MenuItem>
            )}
            {reviewableVersion && (
                <MenuItem
                    closeOnSelect={true}
                    onClick={() => {
                        submitFormDisclosure.onOpen();
                    }}
                >
                    {`Submit ${reviewableVersion.versionString} to ${storeNameForPlatform(appData.platform)} review`}
                </MenuItem>
            )}
            {pendingReviewVersion && (
                <MenuItem
                    closeOnSelect={true}
                    onClick={async () => {
                        await clickedCancelReview(appData, pendingReviewVersion);
                    }}
                >
                    {'Cancel App Store review'}
                </MenuItem>
            )}
            {releaseableVersion && (
                <MenuItem
                    closeOnSelect={true}
                    onClick={async () => {
                        await clickedReleaseVersion(appData, releaseableVersion);
                    }}
                >
                    {`Release ${releaseableVersion.versionString} to App Store`}
                </MenuItem>
            )}
        </ActionMenu>
    );

    const appStoreVersionLabel = (version: AppStoreVersion): JSX.Element => (
        <HStack key={version.id} spacing="2">
            <Icon boxSize="4" {...iconForState(version.status ?? '')} />
            <Text as="b" fontSize="sm">
                {version.versionString}
            </Text>
            <Box color="gray.400" fontSize="sm">
                {version.status.description}
            </Box>
        </HStack>
    );

    const loadingIndicator = (): JSX.Element => (
        <Box
            borderRadius="lg"
            background="#88888860"
            style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                transform: 'translateY(-100%)',
                overflow: 'hidden',
            }}
        >
            <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
                style={{
                    position: 'absolute',
                    left: '0',
                    right: '0',
                    top: '0',
                    bottom: '0',
                    margin: 'auto',
                }}
            />
        </Box>
    );

    const squareColor = useColorModeValue('gray.100', 'gray.700');
    const content = (appData: AppData): JSX.Element => (
        <Stack spacing="4">
            <Flex align="center">
                <HStack spacing="4">
                    <Square size="8" bg={squareColor} borderRadius="lg" overflow="hidden">
                        <Icon boxSize="6" {...iconForPlatform(appData.platform)} />
                    </Square>
                    <Heading as="h5" size="md" mt={2}>
                        {labelForPlatform(appData.platform)}
                    </Heading>
                </HStack>
                <Spacer />
                {actionMenu(appData)}
            </Flex>
            <Stack>{appData.versions.map((v) => appStoreVersionLabel(v))}</Stack>
        </Stack>
    );

    const contentSkeleton = (): JSX.Element => (
        <Stack spacing="6">
            <HStack spacing="4">
                <Skeleton borderRadius="lg">
                    <Square size="8" />
                </Skeleton>
                <Skeleton>
                    <Heading as="h5" size="md">
                        Platform Name
                    </Heading>
                </Skeleton>
            </HStack>
            <Stack spacing="4">
                <HStack spacing="2">
                    <SkeletonCircle size="3" />
                    <SkeletonText noOfLines={1}>1.0.0 current app state</SkeletonText>
                </HStack>
                <HStack spacing="2">
                    <SkeletonCircle size="3" />
                    <SkeletonText noOfLines={1}>1.0.0 ready</SkeletonText>
                </HStack>
            </Stack>
        </Stack>
    );

    let visibleContent = contentSkeleton();
    if (error) visibleContent = <ErrorBox title={error.title} message={error.message} />;
    if (appData) visibleContent = content(appData);

    return (
        <>
            <Box bg="bg-surface" borderRadius="lg" boxShadow={{ base: 'none', sm: 'md' }} overflow="hidden">
                <Box px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                    {visibleContent}
                </Box>
                {isLoading && loadingIndicator()}
            </Box>

            {appData?.canCreateNewVersion && (
                <CreateVersionForm
                    isOpen={createFormDisclosure.isOpen}
                    onClose={createFormDisclosure.onClose}
                    appData={appData}
                    onCreate={setAppData}
                />
            )}

            {appData && editableVersion && (
                <ReleasenotesForm
                    isOpen={releaseNotesFormDisclosure.isOpen}
                    onClose={releaseNotesFormDisclosure.onClose}
                    appData={appData}
                    version={editableVersion}
                />
            )}

            {appData && editableVersion && (
                <LocalizedAppTextsForm
                    isOpen={promotionalTextsFormDisclosure.isOpen}
                    onClose={promotionalTextsFormDisclosure.onClose}
                    appData={appData}
                    version={editableVersion}
                    type="PROMOTIONAL_TEXT"
                />
            )}

            {appData && editableVersion && (
                <LocalizedAppTextsForm
                    isOpen={keywordsFormDisclosure.isOpen}
                    onClose={keywordsFormDisclosure.onClose}
                    appData={appData}
                    version={editableVersion}
                    type="KEYWORDS"
                />
            )}

            {appData && betaReleasableVersion && (
                <SubmitVersionForm
                    isOpen={betaFormDisclosure.isOpen}
                    onClose={betaFormDisclosure.onClose}
                    appData={appData}
                    version={betaReleasableVersion}
                    type="BETA"
                    onSubmit={setAppData}
                />
            )}

            {appData && reviewableVersion && (
                <SubmitVersionForm
                    isOpen={submitFormDisclosure.isOpen}
                    onClose={submitFormDisclosure.onClose}
                    appData={appData}
                    version={reviewableVersion}
                    type="STORE"
                    onSubmit={setAppData}
                />
            )}
        </>
    );
};
