import React, { type FunctionComponent, useMemo, useEffect, useState } from 'react';
import { type ISampleData, type ISharesData } from '../../../interface/SampleData';
import { Button, Divider, Editable, EditablePreview, EditableTextarea, Heading } from '@chakra-ui/react';
import { TableContainer } from '../../../components/table/TableContainer';
import { StateOptions } from '../../../components/table/StateOptions';
import { colorForState } from '../../../helper';
import { type Column } from 'react-table';

interface SharesTableProps {
    sharesData: ISharesData[];
    saveInput: (value: any, key: keyof ISampleData, arrayIndex?: number, arrayName?: keyof ISampleData) => boolean;
    addShare: (newShare: ISharesData) => void;
}

export const SharesTable: FunctionComponent<SharesTableProps> = ({
    sharesData,
    saveInput,
    addShare,
}: SharesTableProps) => {
    const [sharesTableData, setSharesTableData] = useState<ISharesData[]>(sharesData);
    const createShare = (): void => {
        const newShare: ISharesData = {
            name: 'newName',
            buyingDate: 'newDate',
            buyingPrice: 0,
            price: 0,
            quantity: 0,
            currency: 'newCurrency',
        };
        addShare(newShare);
    };

    useEffect(() => {
        setSharesTableData(sharesData);
    }, [sharesData]);

    const sharesColumns: Array<Column<ISharesData>> = useMemo(
        () => [
            {
                Header: 'name',
                accessor: 'name',
                Cell: (cell) => {
                    return (
                        <>
                            <Editable
                                defaultValue={cell.row.original.name}
                                onSubmit={(value) =>
                                    saveInput(
                                        value,
                                        cell.column.Header
                                            ? (cell.column.Header as keyof ISampleData)
                                            : ('' as keyof ISampleData),
                                        cell.row.index,
                                        'shares' as keyof ISampleData,
                                    )
                                }
                            >
                                <EditablePreview />
                                <EditableTextarea />
                            </Editable>
                        </>
                    );
                },
            },
            {
                Header: 'buyingDate',
                accessor: 'buyingDate',
                Cell: (cell) => {
                    return (
                        <>
                            <Editable
                                defaultValue={cell.row.original.buyingDate}
                                onSubmit={(value) =>
                                    saveInput(
                                        value,
                                        cell.column.Header
                                            ? (cell.column.Header as keyof ISampleData)
                                            : ('' as keyof ISampleData),
                                        cell.row.index,
                                        'shares' as keyof ISampleData,
                                    )
                                }
                            >
                                <EditablePreview />
                                <EditableTextarea />
                            </Editable>
                        </>
                    );
                },
            },
            {
                Header: 'buyingPrice',
                accessor: 'buyingPrice',
                Cell: (cell) => {
                    return (
                        <>
                            <Editable
                                defaultValue={cell.row.original.buyingPrice.toString()}
                                onSubmit={(value) =>
                                    saveInput(
                                        value,
                                        cell.column.Header
                                            ? (cell.column.Header as keyof ISampleData)
                                            : ('' as keyof ISampleData),
                                        cell.row.index,
                                        'shares' as keyof ISampleData,
                                    )
                                }
                            >
                                <EditablePreview />
                                <EditableTextarea />
                            </Editable>
                        </>
                    );
                },
            },
            {
                Header: 'price',
                accessor: 'price',
                Cell: (cell) => {
                    return (
                        <>
                            <Editable
                                defaultValue={cell.row.original.price.toString()}
                                onSubmit={(value) =>
                                    saveInput(
                                        value,
                                        cell.column.Header
                                            ? (cell.column.Header as keyof ISampleData)
                                            : ('' as keyof ISampleData),
                                        cell.row.index,
                                        'shares' as keyof ISampleData,
                                    )
                                }
                            >
                                <EditablePreview />
                                <EditableTextarea />
                            </Editable>
                        </>
                    );
                },
            },
            {
                Header: 'quantity',
                accessor: 'quantity',
                Cell: (cell) => {
                    return (
                        <>
                            <Editable
                                defaultValue={cell.row.original.quantity.toString()}
                                onSubmit={(value) =>
                                    saveInput(
                                        value,
                                        cell.column.Header
                                            ? (cell.column.Header as keyof ISampleData)
                                            : ('' as keyof ISampleData),
                                        cell.row.index,
                                        'shares' as keyof ISampleData,
                                    )
                                }
                            >
                                <EditablePreview />
                                <EditableTextarea />
                            </Editable>
                        </>
                    );
                },
            },
            {
                Header: 'currency',
                accessor: 'currency',
                Cell: (cell) => {
                    return (
                        <>
                            <Editable
                                defaultValue={cell.row.original.currency}
                                onSubmit={(value) =>
                                    saveInput(
                                        value,
                                        cell.column.Header
                                            ? (cell.column.Header as keyof ISampleData)
                                            : ('' as keyof ISampleData),
                                        cell.row.index,
                                        'shares' as keyof ISampleData,
                                    )
                                }
                            >
                                <EditablePreview />
                                <EditableTextarea />
                            </Editable>
                        </>
                    );
                },
            },
        ],
        [],
    );
    return (
        <>
            <Divider mt="8" />
            <Heading as="h3" size="lg" mt="8">
                Shares
            </Heading>
            <TableContainer
                columns={sharesColumns}
                data={sharesTableData}
                search={{
                    placeholder: 'Search for shares',
                    selectConfig: {
                        columAccessor: 'state',
                        selectOptions: <StateOptions />,
                    },
                }}
                hiddenColumns={[]}
                showColoredState={true}
                coloredStateMethod={colorForState}
                initialState={{
                    sortBy: [{ id: 'order', desc: true }],
                }}
                defaultPageSize={20}
            />

            <Button onClick={createShare} colorScheme="blue" mr="10">
                Create new Share
            </Button>
            <Divider mt="8" />
        </>
    );
};
