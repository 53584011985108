import React, { type FunctionComponent, useState, useMemo, useEffect } from 'react';
import { MenuItem, useDisclosure } from '@chakra-ui/react';
import { ContentContainer } from '../../components/ContentContainer';
import { type Column } from 'react-table';
import { TableContainer } from '../../components/table/TableContainer';
import {
    type Maybe,
    useGetAllProtocolsQuery,
    type ProtocolElementFragment,
    type StateType,
    useUpdateProtocolMutation,
    type Protocol,
} from '../../generated/types';
import moment from 'moment';
import { useParams } from 'react-router';
import { StateOptions } from '../../components/table/StateOptions';
import { ActionMenu } from '../../components/ActionMenu';
import { IoClipboardOutline, IoGitCommit } from 'react-icons/io5';
import { StateMenuItem } from '../../components/StateMenuItem';
import { allStates, copy } from '../../helper';
import { Details } from './Details';

export type CrashGroup = ProtocolElementFragment & { state: string };

export enum ProtocolField {
    state,
}

const appVersionString = (appVersion: string): string => {
    if (appVersion === 'UNKNOWN') return '';
    const matchString = appVersion.match(/\d+\.\d+\.\d+/);
    if (matchString) return matchString[0];
    return '';
};

export const ProtocolList: FunctionComponent = () => {
    const { protocolId } = useParams<Record<string, string | undefined>>();
    const [initialSearchValue, setInitialSearchValue] = useState('');
    const [resultGetAllProtocols] = useGetAllProtocolsQuery();
    const [, updateProtocol] = useUpdateProtocolMutation();
    const { data, fetching, error } = resultGetAllProtocols;
    const [selectedItemId, setSelectedItemId] = useState<Maybe<string> | undefined>();

    const selectedItem = data?.protocols?.nodes.find((item) => item?.id === selectedItemId);
    const { isOpen: isOpenDetails, onOpen: onOpenDetails, onClose: onCloseDetails } = useDisclosure();
    const openDetails = (protocol: ProtocolElementFragment): void => {
        if (protocol.id) {
            setSelectedItemId(protocol.id);
        }
        onOpenDetails();
    };

    useEffect(() => {
        function filterById(): void {
            if (protocolId) {
                setInitialSearchValue(protocolId);
            }
        }
        filterById();
    }, []);

    const changeValue = async (recordId: string, newValue: string, field: ProtocolField): Promise<void> => {
        await updateProtocol({
            id: recordId,
            patch: {
                [ProtocolField[field]]: newValue,
            },
        });
    };

    const generateCommitMessage = async (item: ProtocolElementFragment): Promise<void> => {
        const url = window.location.protocol + '//' + window.location.host;
        const message = `<a href='${url}/protocols/${item.id}' target="_blank">Protocol (${moment(
            item.createdAt,
        ).format('YY-MM-DD HH:mm')})</a>`;
        navigator.clipboard.writeText(message);
    };

    const actionMenu = (item: ProtocolElementFragment): JSX.Element => (
        <ActionMenu>
            {allStates.map((state: StateType) => (
                <StateMenuItem
                    state={state}
                    currentState={item.state}
                    recordId={item.id}
                    additionalClickArgs={[ProtocolField.state]}
                    click={changeValue}
                    key={state}
                />
            ))}
            <MenuItem
                icon={<IoClipboardOutline />}
                onClick={() => {
                    copy(`${window.location.protocol + '//' + window.location.host}/protocols/${item?.id}`);
                }}
            >
                Copy link to clipboard
            </MenuItem>
            <MenuItem
                icon={<IoGitCommit />}
                onClick={async () => {
                    await generateCommitMessage(item);
                }}
            >
                Generate commit message
            </MenuItem>
        </ActionMenu>
    );

    const tableData = useMemo(() => data?.protocols?.nodes, [data?.protocols?.nodes]);
    const columns: Array<Column<ProtocolElementFragment>> = useMemo(
        () => [
            {
                Header: 'state',
                accessor: 'state',
            },
            {
                Header: 'Id',
                accessor: (row) => row.id,
            },
            {
                Header: 'Email',
                accessor: (row) => row.outbankUser?.email ?? row.outbankUser?.id,
            },
            {
                Header: 'App Version',
                accessor: (row) => appVersionString(row.appVersion),
                textAlign: 'left',
            },
            {
                Header: 'Source',
                accessor: (row) => row.outbankUser?.appSource?.name,
                textAlign: 'right',
            },
            {
                Header: 'Date',
                accessor: (row) => moment(row.createdAt).format('YY-MM-DD HH:mm:ss'),
                textAlign: 'right',
            },
            {
                Header: 'UserId',
                accessor: (row) => row.outbankUser?.id,
            },
            {
                Header: '',
                accessor: 'id',
                disableSortBy: true,
                // eslint-disable-next-line react/display-name
                Cell: (cell) => actionMenu(cell.row.original),
                textAlign: 'right',
                hasCellClick: false,
            },
        ],
        [data?.protocols?.nodes],
    );

    return (
        <ContentContainer
            headline="Protocols"
            description="A list of all Protocols"
            showSpinner={fetching}
            error={error}
        >
            {tableData && (
                <TableContainer
                    columns={columns}
                    data={tableData}
                    search={{
                        startSearchValue: initialSearchValue,
                        placeholder: 'Search for Protocol',
                        selectConfig: {
                            columAccessor: 'state',
                            selectOptions: <StateOptions />,
                        },
                    }}
                    hiddenColumns={['Id', 'state', 'UserId']}
                    rowClick={openDetails}
                    showColoredState={true}
                    initialState={{
                        sortBy: [{ id: 'Date', desc: true }],
                    }}
                />
            )}
            {selectedItemId && selectedItem && (
                <Details
                    isOpen={isOpenDetails}
                    onClose={onCloseDetails}
                    data={selectedItem as Protocol}
                    actionMenu={actionMenu}
                />
            )}
        </ContentContainer>
    );
};
