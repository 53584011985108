import React, { type FunctionComponent } from 'react';
import { type StateType } from '../../generated/types';
import { labelForState, allStates } from '../../helper';

export const StateOptions: FunctionComponent = () => {
    return (
        <>
            <option value="">All</option>
            {allStates.map((state: StateType) => (
                <option value={state} key={state}>
                    {labelForState(state)}
                </option>
            ))}
        </>
    );
};
