import React, { type ChangeEvent, useState, type FunctionComponent } from 'react';
import { Textarea } from '@chakra-ui/react';
import { ContentContainer } from '../../components/ContentContainer';
import { Code } from '../../components/Code';
import { Request } from '../../helper';

interface FigmaInfo {
    title: string;
    web_url: string;
    app_url: string;
    image_url: string;
}

export const FigmaPreview: FunctionComponent = () => {
    const [previewCode, setPreviewCode] = useState<string>('');

    const updateCode = async (e: ChangeEvent<HTMLTextAreaElement>): Promise<void> => {
        const value = e.target.value;
        let figmaInfo;
        try {
            figmaInfo = await requestFigmaInfo(value);
        } catch (exp) {
            return;
        }

        if (figmaInfo.length === 0) setPreviewCode('');

        let headerRow = '';
        let seperatorRow = '';
        let imageRow = '';

        for (let i = 0; i < figmaInfo.length; i++) {
            const info = figmaInfo[i];
            if (i > 0) {
                headerRow += ' ';
                seperatorRow += ' ';
                imageRow += ' ';
            }
            headerRow += `| ${i + 1}) ${info.title} `;
            headerRow += '(';
            headerRow += `[Web](${info.web_url}), `;
            headerRow += `[App](${info.app_url}), `;
            headerRow += `[Dev](${info.web_url}&m=dev), `;
            headerRow += `[App Dev](${info.app_url}&m=dev)`;
            headerRow += ')';
            seperatorRow += '| ------';
            imageRow += `| ![Demo Image](${info.image_url})`;
        }

        headerRow += ' |';
        seperatorRow += ' |';
        imageRow += ' |';

        setPreviewCode(headerRow + '\n' + seperatorRow + '\n' + imageRow);
    };

    const requestFigmaInfo = async (text: string): Promise<FigmaInfo[]> => {
        const urls = Array.from(text.matchAll(/(https:\/\/www.figma.com\/[-a-zA-Z0-9()@:%_+.~#?&//=]*)/g)).map(
            (value) => value[1],
        );
        const response = await Request.post<FigmaInfo[]>({ path: '/figma/info', data: { urls } });
        return response.data;
    };

    return (
        <ContentContainer headline="Figma Preview" description="Generates a markdown preview table from Figma links">
            <Textarea h="200px" placeholder="Input Figma links here..." onChange={updateCode} />
            {previewCode && (
                <Code w="100%" mt={5} p={3} textToCopy={previewCode}>
                    {previewCode}
                </Code>
            )}
        </ContentContainer>
    );
};
