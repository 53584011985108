import React, { type FunctionComponent, type ChangeEvent, useState, useMemo, useEffect, useCallback } from 'react';
import { ContentContainer } from '../../components/ContentContainer';
import { type Column } from 'react-table';
import { TableContainer } from '../../components/table/TableContainer';
import type { Project, IProjects, Language, Translation } from '../../interface/Localization';
import { Input, Progress } from '@chakra-ui/react';
import { Request as apiRequest } from '../../helper';
import { useNavigate } from 'react-router';
import { LocalizationsTableComponent } from './LocalizationsTableComponent';

export const LocalizationsDashboardList: FunctionComponent = () => {
    const [projects, setProjects] = useState<Project[]>();
    const [data, setData] = useState<Record<string, Translation[]>>();
    const [filteredData, setFilteredData] = useState<Record<string, Translation[]>>();
    const [searchQuery, setSearchQuery] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        setProjects(undefined);
        async function getProjects(): Promise<void> {
            const response = await apiRequest.get<IProjects>({ path: '/localization/project' });
            const projects = response.data.result;
            projects.forEach((project) => {
                project.state = project.percent_done === 100 ? 2 : project.percent_done > 0 ? 1 : 0;
            });
            setProjects(response.data.result);

            const translations: Translation[] = [];
            projects.forEach((project) => {
                const languages = project.language;
                languages?.forEach((language) => {
                    language.translation?.forEach((trans) => {
                        trans.placeholder = trans.value;
                        trans.state = trans.value?.trim() ? 2 : 1;
                        trans.language = language.id;
                        trans.project = project.name;
                        trans.project_id = project.id;
                        translations.push(trans);
                    });
                });
            });

            const groupedItems = translations.reduce<Record<string, Translation[]>>((acc, translation) => {
                if (!acc[translation.id]) {
                    acc[translation.id] = [];
                }
                acc[translation.id].push(translation);
                return acc;
            }, {});
            setData(groupedItems);
            if (searchQuery) {
                const filteredTranslations = filterTranslations(groupedItems, searchQuery);
                setFilteredData(filteredTranslations);
            }
        }
        getProjects();
    }, []);

    const filterTranslations = (
        translations: Record<string, Translation[]>,
        queryString: string,
    ): Record<string, Translation[]> => {
        const filteredEntries: any = [];
        queryString = queryString.toLowerCase();
        Object.entries(translations).forEach((translation) => {
            if (
                translation[1].some(
                    (trans) =>
                        (trans.value?.toLowerCase().includes(queryString) ?? false) ||
                        trans.key.toLowerCase().includes(queryString),
                )
            ) {
                filteredEntries.push(translation);
            }
        });

        return Object.fromEntries(filteredEntries);
    };

    const filterData = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const query = event.target.value;
            setSearchQuery(query);
            if (query) {
                if (data) {
                    const filteredResults = filterTranslations(data, query);
                    setFilteredData(filteredResults);
                }
            } else {
                setFilteredData(data);
            }
        },
        [data],
    );

    const openDetails = (selectedProject: Project): void => {
        navigate('/localizations/' + selectedProject.id);
    };

    const tableData = useMemo(() => projects, [projects]);
    const columns: Array<Column<Project>> = useMemo(
        () => [
            {
                Header: 'state',
                accessor: 'state',
            },
            {
                Header: 'ID',
                accessor: 'id',
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Description',
                accessor: 'description',
            },
            {
                Header: 'Created At',
                accessor: 'created_at',
            },
            {
                Header: 'Updated At',
                accessor: 'updated_at',
            },
            {
                Header: 'value',
                accessor: 'language',
                Cell: (cell: any) => {
                    const language: Language[] = cell.row.original.language;
                    let displayLanguage = '';
                    language.forEach((element) => {
                        displayLanguage += element.code.toUpperCase() + ', ';
                    });
                    return <p>{displayLanguage}</p>;
                },
            },
            {
                Header: 'Progress',
                accessor: 'percent_done',
                Cell: (cell: any) => {
                    return <Progress value={cell.row.original.percent_done}></Progress>;
                },
            },
        ],
        [projects],
    );

    return (
        <ContentContainer headline="Localizations" description="List of all Projects" showSpinner={!projects}>
            <Input
                placeholder="Search for localization"
                size="md"
                type="search"
                onChange={filterData}
                value={searchQuery}
            />
            {!searchQuery && tableData ? (
                <TableContainer
                    columns={columns}
                    data={tableData}
                    rowClick={openDetails}
                    hiddenColumns={['state', 'id', 'created_at', 'updated_at', 'description']}
                    showColoredState={true}
                    coloredStateMethod={colorForState}
                    initialState={{
                        sortBy: [{ id: 'order', desc: true }],
                    }}
                    defaultPageSize={20}
                />
            ) : (
                filteredData &&
                searchQuery && <LocalizationsTableComponent translationsData={filteredData} searchQuery={true} />
            )}
        </ContentContainer>
    );
};

enum StateType {
    NotStarted = 0,
    InProgress = 1,
    Done = 2,
}

const colorForState = (state: StateType): string => {
    switch (state) {
        case StateType.Done:
            return 'green.500';
        case StateType.InProgress:
            return 'blue.500';
        case StateType.NotStarted:
            return 'red.500';
    }
};
