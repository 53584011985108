import React, { type FunctionComponent, useState, useMemo } from 'react';
import { MenuItem, useDisclosure } from '@chakra-ui/react';
import { type Column } from 'react-table';
import { TableContainer } from '../../components/table/TableContainer';
import { Details } from './Details';
import {
    type CrashGroupFragment,
    type Maybe,
    StateType,
    useUpdateCrashStateMutation,
    useUpdateCrashGroupMutation,
    type GetCrashGroupQuery,
} from '../../generated/types';
import { ActionMenu } from '../../components/ActionMenu';
import { StateMenuItem } from '../../components/StateMenuItem';
import { IoPencil } from 'react-icons/io5';
import { Dialog } from '../../components/Dialog';
import { CallMethodIcon, CallMethodSortedArray } from '../../components/CallMethodIcon';

export type CrashGroup = CrashGroupFragment & { state: string };

interface Props {
    data: GetCrashGroupQuery;
    showSearch?: boolean;
    startSearchValue?: string;
}

export const CrashGroupList: FunctionComponent<Props> = (props) => {
    const { data, startSearchValue, showSearch = true } = props;
    const [, updateCrashState] = useUpdateCrashStateMutation();
    const [, useUpdateCrashGroup] = useUpdateCrashGroupMutation();
    const [selectedItemId, setSelectedItemId] = useState<Maybe<string> | undefined>();
    const { isOpen: isOpenDetails, onOpen: onOpenDetails, onClose: onCloseDetails } = useDisclosure();

    const selectedItem = data?.crashGroups?.nodes.find((item) => item?.id === selectedItemId);
    const selectedItemCrashes = selectedItem?.crashes.nodes;
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const onCloseDialog = (newIssue?: string): void => {
        setIsOpenDialog(false);
        if (newIssue !== undefined && selectedItemId && newIssue !== selectedItem?.issue) {
            changeCrashGroupIssue(selectedItemId, newIssue);
        }
    };

    const openDetails = (selectedCrashGroup: CrashGroup): void => {
        if (selectedCrashGroup.id) {
            setSelectedItemId(selectedCrashGroup.id);
            onOpenDetails();
        }
    };

    const changeCrashGroupIssue = async (recordId: string, value: string): Promise<void> => {
        await useUpdateCrashGroup({ id: recordId, patch: { issue: value } });
    };

    const changeCrashesState = async (recordId: string, value: string): Promise<void> => {
        await updateCrashState({ id: recordId, state: value });
    };

    const actionMenu = (item: CrashGroup): JSX.Element => (
        <ActionMenu>
            <MenuItem
                icon={<IoPencil />}
                onClick={() => {
                    setSelectedItemId(item.id);
                    setIsOpenDialog(true);
                }}
            >
                Add / Edit issue
            </MenuItem>
            {[StateType.Open, StateType.Inprogress, StateType.Closed].map((state: StateType) => {
                let _state = StateType.Open;
                if (item.countInprogress && item.countInprogress > 0) _state = StateType.Inprogress;
                if (item.countClosed && item.countClosed > 0) _state = StateType.Closed;
                return (
                    <StateMenuItem
                        state={state}
                        currentState={_state}
                        recordId={item.id}
                        click={changeCrashesState}
                        key={state}
                        showAll={true}
                    />
                );
            })}
        </ActionMenu>
    );

    const tableData = useMemo(() => data?.crashGroups?.nodes, [data?.crashGroups?.nodes]);
    const columns: Array<Column<CrashGroup>> = useMemo(
        () => [
            {
                Header: 'state',
                accessor: (row) => {
                    if (row.countInprogress && row.countInprogress > 0) return StateType.Inprogress;
                    if (row.countOpen && row.countOpen > 0) return StateType.Open;
                    return StateType.Unread;
                },
            },
            {
                Header: 'S/R/P',
                accessor: (row) => <CallMethodIcon crashes={row.crashes.nodes} />,
                hasCellClick: false,
                disableSortBy: true,
                minWidth: 75,
            },
            {
                Header: 'method',
                accessor: (row) => CallMethodSortedArray(row.crashes.nodes),
                hasCellClick: false,
            },
            {
                Header: 'File',
                accessor: (row) => row.file.split('/').pop(),
            },
            {
                Header: 'Message',
                accessor: 'message',
            },
            {
                Header: 'Code line',
                accessor: 'codeLine',
            },
            {
                Header: 'Issue',
                accessor: 'issue',
                minWidth: 75,
            },
            {
                Header: 'Open',
                accessor: (row) => row.countOpen,
                textAlign: 'right',
                minWidth: 75,
            },
            {
                Header: 'Progress',
                accessor: (row) => row.countInprogress,
                textAlign: 'right',
                minWidth: 75,
            },
            {
                Header: 'Closed',
                accessor: (row) => row.countClosed,
                textAlign: 'right',
                minWidth: 75,
            },
            {
                Header: 'OpenClose',
                accessor: (row) => {
                    let result = 0;
                    if (row.countOpen) result += row.countOpen;
                    if (row.countInprogress) result += row.countInprogress;
                    return result;
                },
            },
            {
                Header: '',
                accessor: 'id',
                disableSortBy: true,
                // eslint-disable-next-line react/display-name
                Cell: (cell) => actionMenu(cell.row.original),
                textAlign: 'right',
                hasCellClick: false,
            },
        ],
        [data?.crashGroups?.nodes],
    );

    let search;
    if (showSearch) {
        search = {
            placeholder: 'Search for Crash',
            selectConfig: {
                columAccessor: 'method',
                selectOptions: <CallMethod />,
            },
            startSearchValue,
        };
    }
    return (
        <>
            {tableData && (
                <TableContainer
                    columns={columns}
                    data={tableData}
                    search={search}
                    rowClick={openDetails}
                    hiddenColumns={['state', 'OpenClose', 'method']}
                    initialState={{
                        sortBy: [{ id: 'OpenClose', desc: true }],
                    }}
                    showColoredState={true}
                />
            )}
            {selectedItemCrashes && (
                <>
                    <Details
                        isOpen={isOpenDetails}
                        onClose={onCloseDetails}
                        actionMenu={actionMenu}
                        crashGroup={selectedItem as CrashGroup}
                        size="xl"
                    />
                    <Dialog
                        isOpen={isOpenDialog}
                        onClose={onCloseDialog}
                        title={selectedItem?.issue ? 'Edit issue' : 'Add issue'}
                        okButtonTitle="Save"
                        input={{ placeholder: '#123', initialValue: selectedItem?.issue ?? '' }}
                    />
                </>
            )}
        </>
    );
};

export const CallMethod: FunctionComponent = () => {
    return (
        <>
            <option value="">All</option>
            {['synchronize', 'refresh', 'payment'].map((item: string) => (
                <option value={item} key={item}>
                    {`${item} (${item[0].toUpperCase()})`}
                </option>
            ))}
        </>
    );
};
