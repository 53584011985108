/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { type FunctionComponent } from 'react';
import { Stack, StackDivider } from '@chakra-ui/react';
import { type Request } from '../../interface/Crash';
import { BindingsItem } from '../../components/report/BindingsItem';
import { type ProtocolTrace } from '../../interface/Protocol';
import { BKTraceCallstackRequest } from './BKTraceCallstackRequest';

interface Props {
    protocolId: string;
    trace: ProtocolTrace;
    traceIndex: number;
}

export const BKTraceCallstack: FunctionComponent<Props> = (props: Props) => {
    const { trace, traceIndex, protocolId } = props;

    const _requests: Request[] = [];
    trace.body?.frames.forEach((item) => {
        if (item.name === 'requests' && item.bindings.requests) {
            _requests.push(item.bindings.requests[1]);
        }
    });

    return (
        <Stack spacing={2} mt={5} divider={<StackDivider pb={0} />}>
            {trace.body?.frames?.map((item, index) => {
                if (item.name === 'requests' && item.bindings.requests)
                    return (
                        <BKTraceCallstackRequest
                            key={index}
                            protocolId={protocolId}
                            traceIndex={traceIndex}
                            request={item.bindings.requests[1]}
                        />
                    );
                return <BindingsItem key={index} frame={item} />;
            })}
        </Stack>
    );
};
