import React, { type ReactNode, type FunctionComponent } from 'react';
import {
    useColorModeValue as mode,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    chakra,
    Flex,
    Select,
    IconButton,
    Tooltip,
    Text,
    Center,
} from '@chakra-ui/react';
import { colorForState } from '../../helper';
import { useTable, useSortBy, type Column, useGlobalFilter, useFilters, usePagination } from 'react-table';
import {
    TriangleDownIcon,
    TriangleUpIcon,
    ArrowRightIcon,
    ArrowLeftIcon,
    ChevronRightIcon,
    ChevronLeftIcon,
} from '@chakra-ui/icons';
import { Search } from '../../components/table/Search';

interface Props {
    // use React.useMemo here to ensure that our data isn't recreated on every render
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    columns: Array<Column<any>>;
    // use React.useMemo here to ensure that our data isn't recreated on every render
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any[];
    hiddenColumns?: string[];
    search?: {
        placeholder: string;
        startSearchValue?: string;
        selectConfig?: {
            columAccessor: string;
            selectOptions: ReactNode;
        };
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rowClick?: (original: any) => void;
    // 1 column has to be with 'state' as accessor to use this
    showColoredState?: boolean;
    // overload standard coloredState method
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    coloredStateMethod?: (value: any) => string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    initialState?: any;
    searchBarStyles?: Record<string, string>;
    defaultPageSize?: 10 | 15 | 20 | 25 | 50;
    showRecordCount?: boolean;
    showNoRecordsFound?: boolean;
    selectedRowItem?: {
        key: string;
        value: string | undefined;
    };
}

type PageSize = [10, 15, 20, 25, 50];

export const TableContainer: FunctionComponent<Props> = (props: Props) => {
    const pageSizes: PageSize = [10, 15, 20, 25, 50];
    const {
        columns,
        data,
        initialState = {},
        coloredStateMethod = colorForState,
        searchBarStyles,
        defaultPageSize = 15,
        showRecordCount = true,
        showNoRecordsFound = true,
        selectedRowItem,
    } = props;
    const hoverColor = mode('gray.50', 'gray.700');

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        setGlobalFilter,
        setFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        globalFilteredRows,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                hiddenColumns: props.hiddenColumns ?? [],
                pageIndex: 0,
                pageSize: defaultPageSize,
                ...initialState,
            },
            autoResetFilters: false,
            autoResetGlobalFilter: false,
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
    );

    return (
        <>
            {props.search && (
                <div style={searchBarStyles}>
                    <Search
                        placeholder={props.search?.placeholder}
                        setGlobalFilter={setGlobalFilter}
                        setFilter={setFilter}
                        selectConfig={props.search.selectConfig}
                        startSearchValue={props.search?.startSearchValue}
                    />
                </div>
            )}
            <Table {...getTableProps()} my="4">
                <Thead
                    bg={mode('gray.50', 'gray.800')}
                    borderLeft={props.showColoredState ? '6px solid' : 'none'}
                    borderColor={props.showColoredState ? mode('gray.50', 'gray.800') : 'none'}
                >
                    {headerGroups.map((headerGroup) => {
                        const headerGroupProps = headerGroup.getHeaderGroupProps();
                        return (
                            <Tr {...headerGroupProps} key={headerGroupProps.key}>
                                {headerGroup.headers.map((column) => {
                                    const headerProps = column.getHeaderProps(column.getSortByToggleProps());
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    const styles: any = {};
                                    if ('textAlign' in column) styles.textAlign = column.textAlign;
                                    headerProps.style = {
                                        ...headerProps.style,
                                        ...styles,
                                        ...{
                                            minWidth: column.minWidth,
                                            width: column.width,
                                            maxWidth: column.maxWidth,
                                        },
                                    };

                                    return (
                                        <Th {...headerProps} key={headerProps.key}>
                                            {column.render('Header')}
                                            {column.isSorted && (
                                                <chakra.span pl="4">
                                                    {column.isSortedDesc ? (
                                                        <TriangleDownIcon aria-label="sorted descending" />
                                                    ) : (
                                                        <TriangleUpIcon aria-label="sorted ascending" />
                                                    )}
                                                </chakra.span>
                                            )}
                                        </Th>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        const rowProps = row.getRowProps();
                        return (
                            <Tr
                                {...rowProps}
                                key={rowProps.key}
                                borderLeft={props.showColoredState ? '6px solid' : 'none'}
                                borderColor={props.showColoredState ? coloredStateMethod(row.values.state) : 'none'}
                                _hover={{
                                    background: hoverColor,
                                }}
                                backgroundColor={
                                    selectedRowItem && selectedRowItem.value === row.values[`${selectedRowItem.key}`]
                                        ? hoverColor
                                        : 'none'
                                }
                            >
                                {row.cells.map((cell) => {
                                    const cellProps = cell.getCellProps();
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    const styles: any = {};
                                    if ('textAlign' in cell.column) styles.textAlign = cell.column.textAlign;
                                    cellProps.style = {
                                        ...cellProps.style,
                                        ...styles,
                                        ...{
                                            minWidth: cell.column.minWidth,
                                            width: cell.column.width,
                                            maxWidth: cell.column.maxWidth,
                                        },
                                    };

                                    return (
                                        <Td
                                            {...cellProps}
                                            key={cellProps.key}
                                            cursor={props.rowClick ? 'pointer' : ''}
                                            onClick={() => {
                                                if (
                                                    props.rowClick &&
                                                    ('hasCellClick' in cell.column ? cell.column.hasCellClick : true)
                                                ) {
                                                    props.rowClick(row.original);
                                                }
                                            }}
                                        >
                                            {cell.render('Cell')}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>

            {globalFilteredRows.length > pageSizes[0] && (
                <Flex justifyContent="space-between" alignItems="center">
                    <Flex>
                        <Tooltip label="First Page">
                            <IconButton
                                onClick={() => {
                                    gotoPage(0);
                                }}
                                isDisabled={!canPreviousPage}
                                icon={<ArrowLeftIcon h={3} w={3} />}
                                mr={2}
                                aria-label="First Page"
                            />
                        </Tooltip>
                        <Tooltip label="Previous Page">
                            <IconButton
                                onClick={previousPage}
                                isDisabled={!canPreviousPage}
                                icon={<ChevronLeftIcon h={6} w={6} />}
                                aria-label="Previous Page"
                            />
                        </Tooltip>
                    </Flex>
                    <Flex alignItems="center" paddingX="2">
                        {showRecordCount && (
                            <Text mr={6}>
                                Records:
                                <Text as="strong"> {globalFilteredRows.length}</Text>
                            </Text>
                        )}
                        <Text mr={6}>
                            Page <Text as="strong">{pageIndex + 1}</Text> of{' '}
                            <Text as="strong">{pageOptions.length}</Text>
                        </Text>
                        <Select
                            w={32}
                            value={pageSize}
                            onChange={(e) => {
                                setPageSize(Number(e.target.value));
                            }}
                        >
                            {pageSizes.map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </Select>
                    </Flex>
                    <Flex>
                        <Tooltip label="Next Page">
                            <IconButton
                                onClick={nextPage}
                                isDisabled={!canNextPage}
                                icon={<ChevronRightIcon h={6} w={6} />}
                                aria-label="Next Page"
                            />
                        </Tooltip>
                        <Tooltip label="Last Page">
                            <IconButton
                                onClick={() => {
                                    gotoPage(pageCount - 1);
                                }}
                                isDisabled={!canNextPage}
                                icon={<ArrowRightIcon h={3} w={3} />}
                                ml={2}
                                aria-label="Last Page"
                            />
                        </Tooltip>
                    </Flex>
                </Flex>
            )}
            {showNoRecordsFound && globalFilteredRows.length === 0 && <Center pt={3}>No records</Center>}
        </>
    );
};
