import { Box, Stack, Text } from '@chakra-ui/react';
import React, { type FunctionComponent } from 'react';

interface Props {
    label: string;
    children: React.ReactNode;
}

export const NavGroup: FunctionComponent<Props> = (props: Props) => {
    const { label, children } = props;
    return (
        <Box>
            <Text
                px="3"
                fontSize="xs"
                fontWeight="semibold"
                textTransform="uppercase"
                letterSpacing="widest"
                color="gray.500"
                mb="3"
            >
                {label}
            </Text>
            <Stack spacing="1">{children}</Stack>
        </Box>
    );
};
