/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { type FunctionComponent, useMemo } from 'react';
import { type Column } from 'react-table';
import { TableContainer } from '../table/TableContainer';
import { type GroundControlIndex, type Protocol } from '../../interface/Protocol';
import moment from 'moment';
import { Box, HStack, Tooltip } from '@chakra-ui/react';
import { DetailsElement } from '../detailsDrawer/DetailsElement';
import { RoundedBox } from '../RoundedBox';

interface Props {
    details: Protocol;
}

export const BKHeader: FunctionComponent<Props> = (props: Props) => {
    const { details } = props;

    const tableData = useMemo(() => details?.header.groundControlIndex, [details?.header.groundControlIndex]);
    const columns: Array<Column<GroundControlIndex>> = useMemo(
        () => [
            {
                Header: 'name',
                accessor: 'name',
            },
            {
                Header: 'extension',
                accessor: 'extension',
            },
            {
                Header: 'hashValue',
                accessor: 'hashValue',
            },
            {
                Header: 'version',
                accessor: 'version',
                textAlign: 'right',
            },
            {
                Header: 'lastFailed',
                accessor: (row) => (row.lastFailed ? moment(row.lastFailed).format('YY-MM-DD HH:mm:ss') : ''),
                textAlign: 'right',
            },
            {
                Header: 'Date',
                accessor: (row) => (row.lastUpdated ? moment(row.lastUpdated).format('YY-MM-DD HH:mm:ss') : ''),
                textAlign: 'right',
            },
        ],
        [details?.header.groundControlIndex],
    );

    return (
        <>
            <RoundedBox>
                <HStack>
                    <DetailsElement
                        pb={0}
                        boxProps={{ flex: '1' }}
                        label="AppVersion"
                        value={details.header.appVersion}
                    />
                    <DetailsElement
                        pb={0}
                        boxProps={{ flex: '1' }}
                        label="AppPlatform"
                        value={details.header.platform}
                    />
                    <DetailsElement pb={0} boxProps={{ flex: '1' }} label="Mandat" value={details.header.mandant} />
                    <DetailsElement
                        pb={0}
                        boxProps={{ flex: '1' }}
                        label="LocalizationIdentifier"
                        value={details.header.localizationIdentifier}
                    />
                    <DetailsElement pb={0} boxProps={{ flex: '1' }} label="Locale" value={details.header.locale} />
                    <DetailsElement pb={0} boxProps={{ flex: '1' }} label="UsedAppVersions">
                        {'usedAppVersions' in details.header && (
                            <Tooltip label={details.header.usedAppVersions.join(', ')}>
                                {`${details.header.usedAppVersions[0]}, ...`}
                            </Tooltip>
                        )}
                    </DetailsElement>
                </HStack>
            </RoundedBox>
            <RoundedBox
                headline="Application Logins"
                collapseAble
                collapseInitial={details.header.applicationLogins && details.header.applicationLogins.length > 0}
            >
                <Box>
                    {details?.header.applicationLogins?.map((item, index) => {
                        if (!item.kernelObjectID) return null;
                        return (
                            <Box key={index}>
                                {item.accounts.map((account, indexAccount) => {
                                    return (
                                        <RoundedBox
                                            key={indexAccount}
                                            headline={item.kernelObjectID}
                                            collapseAble
                                            collapseInitial={false}
                                        >
                                            <Box pt={5}>
                                                {Object.entries(account).map(([key, value]) => {
                                                    return (
                                                        <DetailsElement
                                                            key={`${indexAccount}_${key}_${value}`}
                                                            label={key}
                                                            value={JSON.stringify(value)}
                                                        />
                                                    );
                                                })}
                                            </Box>
                                        </RoundedBox>
                                    );
                                })}
                            </Box>
                        );
                    })}
                </Box>
            </RoundedBox>
            <RoundedBox
                headline="Kernel Logins"
                collapseAble
                collapseInitial={details.header.kernelLogins && details.header.kernelLogins.length > 0}
            >
                <Box>
                    {details?.header.kernelLogins?.map((item, index) => (
                        <RoundedBox
                            key={index}
                            headline={`${item.kernelObjectID} (${!item.url.trim() ? 'WC' : 'FinTS'})`}
                            collapseAble
                            collapseInitial={false}
                        >
                            <Box pt={5}>
                                {Object.entries(item).map(([key, value]) => {
                                    return (
                                        <DetailsElement
                                            key={`${index}_${key}_${value}`}
                                            label={key}
                                            value={JSON.stringify(value)}
                                        />
                                    );
                                })}
                            </Box>
                        </RoundedBox>
                    ))}
                </Box>
            </RoundedBox>
            <RoundedBox headline="Ground Control Index" collapseAble collapseInitial={false}>
                {tableData && (
                    <TableContainer
                        columns={columns}
                        data={tableData}
                        search={{
                            placeholder: 'Search',
                        }}
                    />
                )}
            </RoundedBox>
        </>
    );
};
