import { Tooltip } from '@chakra-ui/react';
import React, { type FunctionComponent } from 'react';
import { type CrashGroupsCrashesFragment } from '../generated/types';

interface Props {
    crashes: CrashGroupsCrashesFragment[];
}

export const CallMethodIcon: FunctionComponent<Props> = (props: Props) => {
    const { crashes } = props;

    const methods: string[] = CallMethodSortedArray(crashes);
    if (methods.length === 1 && methods[0] === 'UNKNOWN') return null;
    return (
        <Tooltip label={methods.join(', ').toLowerCase()}>
            {methods
                .filter((item) => item !== 'UNKNOWN')
                .map((item) => item[0])
                .join(', ')}
        </Tooltip>
    );
};

export const CallMethodSortedArray = (crashes: CrashGroupsCrashesFragment[]): string[] => {
    const methods: string[] = [];
    for (const crash of crashes) {
        if (methods.includes(crash.callMethod)) continue;
        methods.push(crash.callMethod);
    }
    return methods.sort();
};
