import React, { type FunctionComponent, useEffect, useState, useMemo } from 'react';
import { useDisclosure, MenuItem } from '@chakra-ui/react';
import { Request, allStates } from '../../helper';
import { type Column } from 'react-table';
import { IoClipboardOutline, IoTrashOutline, IoPencil, IoGitCommit } from 'react-icons/io5';
import { ContentContainer } from '../../components/ContentContainer';
import { ActionMenu } from '../../components/ActionMenu';
import { Details } from './Details';
import { TableContainer } from '../../components/table/TableContainer';
import { StateMenuItem } from '../../components/StateMenuItem';
import { Dialog } from '../../components/Dialog';
import { useParams } from 'react-router-dom';
import { useGetAllHarsQuery, useUpdateHarMutation, type Har, type Maybe, StateType } from '../../generated/types';
import moment from 'moment';

export enum HarField {
    state,
    issue,
}

export const HarList: FunctionComponent = () => {
    const { id: paramId } = useParams<Record<string, string | undefined>>();
    const [resultHarsQuery, reexecuteGetHars] = useGetAllHarsQuery();
    const [, updateHar] = useUpdateHarMutation();
    const { data, fetching, error } = resultHarsQuery;
    const [selectedItemId, setSelectedItemId] = useState<Maybe<string> | undefined>();
    const [startSearchValue, setStartSearchValue] = useState('');

    const refresh = (): void => {
        // Refetch the query and skip the cache
        reexecuteGetHars({ requestPolicy: 'network-only' });
    };

    const selectedItem = data?.hars?.nodes.find((item) => item?.id === selectedItemId);
    const { isOpen: isOpenDetails, onOpen: onOpenDetails, onClose: onCloseDetails } = useDisclosure();
    const openDetails = (_selectedHar: Har): void => {
        setSelectedItemId(_selectedHar.id);
        onOpenDetails();
    };

    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const onCloseDialog = (newIssue?: string): void => {
        setIsOpenDialog(false);
        if (newIssue !== undefined && selectedItemId && newIssue !== selectedItem?.issue) {
            changeValue(selectedItemId, newIssue, HarField.issue);
        }
    };

    const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
    const onCloseDeleteDialog = async (id: string): Promise<void> => {
        setIsOpenDeleteDialog(false);
        await Request.delete<Response>({ path: `/har/${id}` });
        refresh();
    };

    useEffect(() => {
        function filterById(): void {
            if (paramId) setStartSearchValue(paramId);
        }
        filterById();
    }, []);

    useEffect(() => {
        // if a unread Har is viewed, set state to open
        if (selectedItemId && selectedItem?.state === StateType.Unread) {
            changeValue(selectedItemId, StateType.Open, HarField.state);
        }
    }, [selectedItemId]);

    const copyLinkClipboard = async (recordId: string): Promise<void> => {
        const url = window.location.protocol + '//' + window.location.host;
        navigator.clipboard.writeText(`${url}/har/${recordId}`);
    };

    const changeValue = async (recordId: string, newValue: string, field: HarField): Promise<void> => {
        await updateHar({
            id: recordId,
            patch: {
                [HarField[field]]: newValue,
            },
        });
    };

    const generateCommitMessage = async (item: Har): Promise<void> => {
        const url = window.location.protocol + '//' + window.location.host;
        const message = `<a href='${url}/har/${item.id}' target="_blank">Har (${moment(item.createdAt).format(
            'YY-MM-DD HH:mm',
        )})</a>`;
        navigator.clipboard.writeText(message);
    };

    const actionMenu = (_selectedHar: Har): JSX.Element => (
        <ActionMenu>
            <MenuItem
                icon={<IoPencil />}
                onClick={() => {
                    setSelectedItemId(_selectedHar.id);
                    setIsOpenDialog(true);
                }}
            >
                Add / Edit issue
            </MenuItem>
            {allStates.map((state: StateType) => (
                <StateMenuItem
                    state={state}
                    currentState={_selectedHar.state}
                    recordId={_selectedHar.id}
                    additionalClickArgs={[HarField.state]}
                    click={changeValue}
                    key={state}
                />
            ))}
            <MenuItem
                icon={<IoClipboardOutline />}
                onClick={async () => {
                    await copyLinkClipboard(_selectedHar.id);
                }}
            >
                Copy link to clipboard
            </MenuItem>
            <MenuItem
                icon={<IoGitCommit />}
                onClick={async () => {
                    await generateCommitMessage(_selectedHar);
                }}
            >
                Generate commit message
            </MenuItem>
            <MenuItem
                icon={<IoTrashOutline />}
                onClick={() => {
                    setSelectedItemId(_selectedHar.id);
                    setIsOpenDeleteDialog(true);
                }}
            >
                Delete
            </MenuItem>
        </ActionMenu>
    );

    const tableData = useMemo(() => data?.hars?.nodes, [data?.hars?.nodes]);

    const columns: Array<Column<Har>> = useMemo(
        () => [
            {
                Header: 'State',
                accessor: 'state',
            },
            {
                Header: 'E-Mail',
                accessor: (row) => {
                    return row.email.toLowerCase();
                },
                minWidth: 100,
                maxWidth: 100,
            },
            {
                Header: 'Bank',
                accessor: (row) => row.bank.charAt(0).toUpperCase() + row.bank.slice(1),
            },
            {
                Header: 'Issue',
                accessor: (row) => row.issue ?? '',
                minWidth: 20,
                maxWidth: 20,
            },
            {
                Header: 'Upload Date',
                accessor: (row) => moment(row.createdAt).format('YY-MM-DD HH:mm'),
                minWidth: 50,
                maxWidth: 50,
            },
            {
                Header: 'User id',
                accessor: (row) => row.outbankUser?.id,
            },
            {
                Header: '',
                accessor: 'id',
                disableSortBy: true,
                // eslint-disable-next-line react/display-name
                Cell: (cell) => actionMenu(cell.row.original),
                textAlign: 'right',
                hasCellClick: false,
                minWidth: 10,
                maxWidth: 10,
            },
        ],
        [data?.hars?.nodes],
    );

    return (
        <ContentContainer
            headline="Uploaded HAR Files"
            description="HAR Files are sent by Outbank Customers to us."
            showSpinner={fetching}
            error={error}
        >
            {tableData && (
                <TableContainer
                    columns={columns}
                    data={tableData}
                    search={{
                        startSearchValue,
                        placeholder: 'Search for HAR',
                    }}
                    hiddenColumns={['state', 'User id']}
                    rowClick={openDetails}
                    showColoredState={true}
                    initialState={{
                        sortBy: [{ id: 'Upload Date', desc: true }],
                    }}
                />
            )}
            {selectedItemId && selectedItem && (
                <>
                    <Details
                        isOpen={isOpenDetails}
                        onClose={onCloseDetails}
                        harDetails={selectedItem as Har}
                        actionMenu={actionMenu}
                    />
                    <Dialog
                        isOpen={isOpenDialog}
                        onClose={onCloseDialog}
                        title={selectedItem?.issue ? 'Edit issue' : 'Add issue'}
                        okButtonTitle="Save"
                        input={{ placeholder: '#123', initialValue: selectedItem?.issue ?? '' }}
                    />
                    <Dialog
                        isOpen={isOpenDeleteDialog}
                        onClose={async (): Promise<void> => {
                            await onCloseDeleteDialog(selectedItemId);
                        }}
                        title="Delete HAR"
                        content={`Really delete HAR ${selectedItem?.bank}
                        from ${selectedItem?.email}
                        created on ${moment(selectedItem?.createdAt).format('YY-MM-DD HH:mm')}`}
                        okButtonTitle="Delete"
                        isDestructive
                    />
                </>
            )}
        </ContentContainer>
    );
};
