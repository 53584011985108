import React, { type FunctionComponent, useMemo, useState, useEffect } from 'react';
import { ContentContainer } from '../../components/ContentContainer';
import { type Column } from 'react-table';
import { TableContainer } from '../../components/table/TableContainer';
import { type CrashUserStatistic, type EvaluationData } from '../../interface/Evaluation';
import { Request as apiRequest } from '../../helper';
import { Box } from '@chakra-ui/react';

export const List: FunctionComponent = () => {
    const [responseData, setResponseData] = useState<EvaluationData>();

    useEffect(() => {
        setResponseData(undefined);
        async function getIntegrations(): Promise<void> {
            const response = await apiRequest.get<EvaluationData>({ path: '/webconnect/evaluation/crash_user' });
            setResponseData(response.data);
        }
        getIntegrations();
    }, []);

    const tableData = useMemo(() => responseData?.crashUserCrashStatistic, [responseData?.crashUserCrashStatistic]);
    const columns: Array<Column<CrashUserStatistic>> = useMemo(
        () => [
            {
                Header: 'File',
                accessor: (row) => row.name,
            },
            {
                Header: 'All Crashes (User-Count)',
                accessor: (row) => row.userCountAll,
                textAlign: 'right',
            },
            {
                Header: 'All Crashes (%)',
                accessor: (row) => `${row.percentageAll.toFixed(2)} %`,
                textAlign: 'right',
            },
            {
                Header: 'Unclosed crashes (User-Count)',
                accessor: (row) => (row.userCountOpen === -1 ? '' : row.userCountOpen),
                textAlign: 'right',
            },
            {
                Header: 'Unclosed crashes (%)',
                accessor: (row) => (row.userCountOpen === -1 ? '' : `${row.percentageOpen.toFixed(2)} %`),
                textAlign: 'right',
            },
        ],
        [responseData?.crashUserCrashStatistic],
    );

    return (
        <ContentContainer headline="Crash User evaluation" description="List all crashing files and show related user">
            <Box>
                <Box>Count all user with crashes: {responseData?.user.all}</Box>
                <Box>Count all user with unclosed crashes: {responseData?.user.allOpen}</Box>
            </Box>
            {tableData && (
                <>
                    <TableContainer
                        columns={columns}
                        data={tableData}
                        initialState={{
                            sortBy: [{ id: 'Unclosed crashes (User-Count)', desc: true }],
                        }}
                        search={{
                            placeholder: 'Search for values',
                        }}
                    />
                </>
            )}
        </ContentContainer>
    );
};
