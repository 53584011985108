/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { type FunctionComponent, useState } from 'react';
import { Box, Code, Divider } from '@chakra-ui/react';
import { DetailsElement } from '../detailsDrawer/DetailsElement';
import { RoundedBox } from '../RoundedBox';
import { type ParsedBkLog } from '../../screen/protocol/Details';
import { v4 as uuidv4 } from 'uuid';

interface Props {
    bkLogFilteredMessages: ParsedBkLog;
    details?: boolean;
    collapseInitial?: boolean;
}

export const BKProtocolMethods: FunctionComponent<Props> = (props: Props) => {
    const { bkLogFilteredMessages, details = true, collapseInitial = false } = props;
    const BkLog: React.ReactNode[] = [];
    for (const [key, methods] of Object.entries(bkLogFilteredMessages)) {
        const subBkLog: React.ReactNode[] = [];
        methods?.methods?.forEach((mIndex: any) => {
            const method = methods?.methods[mIndex];
            if (
                details ||
                ['fetch', 'synchronizeLogin', 'createLogin', 'setLoggingEnabled', 'APP STARTED'].includes(
                    method?.method,
                )
            ) {
                subBkLog.push(
                    <Box key={`${key}_${mIndex}_${uuidv4()}`}>
                        <DetailsElement label="Method" value={method?.method} />
                        {// eslint-disable-next-line @typescript-eslint/no-explicit-any
                        method?.params?.map((item: any, index: number) => {
                            if (item !== key) {
                                return (
                                    <DetailsElement key={`${key}_${method?.method}_${index}`} label="Param">
                                        <Code key={index}>{JSON.stringify(item)}</Code>
                                    </DetailsElement>
                                );
                            }
                            return null;
                        })}
                        <Divider />
                    </Box>,
                );
            }
        });

        if (subBkLog.length > 0) {
            BkLog.push(
                <RoundedBox
                    key={key}
                    headline={`${methods.time}: ${methods.methods[0].method}`}
                    collapseAble={true}
                    collapseInitial={collapseInitial}
                >
                    <Box p={2}>{subBkLog}</Box>
                </RoundedBox>,
            );
        }
    }

    return BkLog;
};
