/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { type FunctionComponent } from 'react';
import { type Request } from '../../interface/Crash';
import { convertObjectToHeaderArray } from './RequestHeaderView';
import { RequestTableView } from './requestTableView';

interface Props {
    request: Request;
}

export const RequestCookieView: FunctionComponent<Props> = (props: Props) => {
    const { request } = props;

    if (!request.headers) return <></>;

    const requestCookieElement = convertObjectToHeaderArray(request.headers).find(
        (item: string[]) => item[0].toLowerCase() === 'cookie',
    );
    let requestCookieItems: string[][] = [];
    if (requestCookieElement) {
        requestCookieItems = requestCookieElement[1]
            .split(';')
            .map((item: string) => item.trim())
            .filter((item: string) => item)
            .map((item: string) => item.split('='));
    }
    return <RequestTableView data={requestCookieItems} headers={['name', 'value']} />;
};
