/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { type FunctionComponent } from 'react';
import { type Request } from '../../interface/Crash';
import { Button, HStack, Spacer, useToast } from '@chakra-ui/react';
import { copyWithFeedback, openInNewTabWithBearerToken } from '../../helper';
import { Code } from '../Code';
import { Buffer } from 'buffer';

interface Props {
    request: Request;
    reportId?: string;
    prorocolId?: string;
    protocolTraceIndex?: number;
}

export const ResponseBodyView: FunctionComponent<Props> = (props: Props) => {
    const { request, reportId, prorocolId, protocolTraceIndex } = props;
    const toast = useToast();
    const url = prorocolId
        ? `/report/protocol/responsebody/${prorocolId}/${protocolTraceIndex}`
        : `/report/crash/responsebody/${reportId}/${request.id}`;

    const fakeUrl = `/report${process.env.REACT_APP_SERVER_FACE_REQUEST_PATH}/${
        prorocolId ? `protocol/${prorocolId}/${protocolTraceIndex}` : `crash/${reportId}/${request.id}`
    }`;
    let responseBody;

    if (request.response?.body && 'bytes' in request.response.body)
        responseBody = Buffer.from(request.response.body.bytes, 'base64').toString();
    else if (request.response?.result && 'bytes' in request.response.result)
        responseBody = Buffer.from(request.response.result.bytes, 'base64').toString();

    return (
        <>
            {responseBody && (
                <>
                    <HStack>
                        <Spacer />
                        <Button
                            onClick={() => {
                                copyWithFeedback(
                                    `self.remote_fake_request("${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_API_PATH}${fakeUrl}")`,
                                    toast,
                                );
                            }}
                        >
                            FakeRequest
                        </Button>
                        <Button
                            onClick={() => {
                                openInNewTabWithBearerToken(url);
                            }}
                        >
                            Open
                        </Button>
                    </HStack>
                    <Code mt={5} p={3} textToCopy={responseBody}>
                        {responseBody}
                    </Code>
                </>
            )}
        </>
    );
};
