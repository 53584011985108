import React, { useMemo } from 'react';
import type { FunctionComponent } from 'react';
import type { Translation } from '../../interface/Localization';
import { Text } from '@chakra-ui/react';
import { TableContainer } from '../../components/table/TableContainer';
import type { Column } from 'react-table';
import { FormComponent } from './FormComponent';
import { useParams } from 'react-router';

interface Props {
    translationsData: Record<string, Translation[]>;
    searchQuery: boolean;
}

export const LocalizationsTableComponent: FunctionComponent<Props> = (props) => {
    const { translationsData, searchQuery } = props;
    const { projectId } = useParams();

    const tableData = useMemo(
        () => (translationsData ? Object.entries(translationsData) : undefined),
        [translationsData],
    );

    let columns: Array<Column<Record<string, Translation[]>>> = useMemo(
        () => [
            {
                Header: 'Localization',
                accessor: (record) => record[1][0].key,
                id: 'Localization',
                Cell: (cell: any) => {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    return <FormComponent translationData={cell.row.original[1]} projectId={projectId!} />;
                },
            },
            {
                Header: 'Updated at',
                accessor: (record) => record[1][0].updated_at,
                minWidth: 60,
                maxWidth: 60,
                Cell: (cell: any) => {
                    return (
                        <>
                            {cell.row.original[1].map((trans: Translation, index: number) => (
                                <Text
                                    key={trans.id + '/' + trans.language}
                                    marginBottom={2}
                                    height={10}
                                    // Adding margin to the first updated_at to align with the values of the translation
                                    marginTop={index === 0 ? 18 : undefined}
                                >
                                    {trans.updated_at}
                                </Text>
                            ))}
                        </>
                    );
                },
            },
        ],
        [translationsData],
    );

    const button: Array<Column<Record<string, Translation[]>>> = useMemo(
        () => [
            {
                Header: 'state',
                accessor: (record) =>
                    record[1].every((translation) => translation.state === 2)
                        ? 2
                        : record[1].every((translation) => translation.state === 1)
                        ? 0
                        : 1,
            },
            {
                Header: 'Save',
                accessor: 'button',
            },
            {
                Header: 'Placeholder',
                accessor: 'placeholder',
            },
            {
                Header: 'searchfield',
                accessor: (record: any) => {
                    return record[1].map((trans: Translation) => trans.value).join(' ');
                },
            },
        ],
        [translationsData],
    );

    columns = columns.concat(button);

    return tableData ? (
        <TableContainer
            key="localizationsTable"
            columns={columns}
            data={tableData}
            search={
                !searchQuery
                    ? {
                          placeholder: 'Search for localization',
                          selectConfig: {
                              columAccessor: 'state',
                              selectOptions: <StateOptions />,
                          },
                      }
                    : undefined
            }
            hiddenColumns={['placeholder', 'state', 'searchfield', 'button']}
            showColoredState={true}
            coloredStateMethod={colorForState}
            initialState={{
                sortBy: [{ id: 'Localization', desc: false }],
            }}
            defaultPageSize={20}
        />
    ) : (
        <></>
    );
};

enum StateType {
    NotStarted = 0,
    InProgress = 1,
    Finished = 2,
}

const allStates = [StateType.NotStarted, StateType.InProgress, StateType.Finished];

const colorForState = (state: StateType): string => {
    switch (state) {
        case StateType.NotStarted:
            return 'red.500';
        case StateType.InProgress:
            return 'blue.500';
        case StateType.Finished:
            return 'green.500';
    }
};

const labelForState = (state: StateType): string => {
    switch (state) {
        case StateType.NotStarted:
            return 'Not Started';
        case StateType.InProgress:
            return 'In Progress';
        case StateType.Finished:
            return 'Finished';
    }
};

const StateOptions: FunctionComponent = () => {
    return (
        <>
            <option value="">All</option>
            {allStates.map((state: StateType) => (
                <option value={state} key={state}>
                    {labelForState(state)}
                </option>
            ))}
        </>
    );
};
