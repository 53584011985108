import React, { useState, type FunctionComponent, useEffect, useMemo } from 'react';
import { ContentContainer } from '../../components/ContentContainer';
import { type OutbankMilestoneData } from '../../interface/Milestone';
import { Request as apiRequest } from '../../helper';
import { type Column } from 'react-table';
import { TableContainer } from '../../components/table/TableContainer';
import { Button, Link, useDisclosure } from '@chakra-ui/react';
import { type Maybe } from '../../generated/types';
import { Details } from './Details';

export const MilestoneList: FunctionComponent = () => {
    const [milestones, setMilestones] = useState<OutbankMilestoneData[]>();
    const [selectedItemId, setSelectedItemId] = useState<Maybe<number> | undefined>();
    const { isOpen: isOpenDetails, onOpen: onOpenDetails, onClose: onCloseDetails } = useDisclosure();

    const selectedItem = milestones?.find((item) => item?.iid === selectedItemId);
    const openDetails = (selectedMilestone: OutbankMilestoneData): void => {
        setSelectedItemId(selectedMilestone.iid);
        onOpenDetails();
    };

    useEffect(() => {
        setMilestones(undefined);
        async function getMilestones(): Promise<void> {
            const response = await apiRequest.get<OutbankMilestoneData[]>({ path: '/gitlab/milestone' });
            setMilestones(response.data);
        }
        getMilestones();
    }, []);

    const tableData = useMemo(() => milestones, [milestones]);
    const columns: Array<Column<OutbankMilestoneData>> = useMemo(
        () => [
            {
                Header: 'Version',
                accessor: 'version',
            },
            {
                Header: 'Date',
                accessor: 'releaseDate',
            },
            {
                Header: 'Title',
                accessor: 'title',
            },
            {
                Header: 'fullTitle',
                accessor: 'fullTitle',
            },
            {
                Header: 'description',
                accessor: 'description',
            },
            {
                Header: 'wikiSearchString',
                accessor: 'wikiSearchString',
            },
            {
                Header: 'Link',
                accessor: (row) => row.iid,
                disableSortBy: true,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: (cell: any) => {
                    return (
                        <Button>
                            <Link href={cell.row.original.link} isExternal>
                                Milestone
                            </Link>
                        </Button>
                    );
                },
                hasCellClick: false,
            },
            {
                Header: 'Issues',
                accessor: (row) => row.iid,
                disableSortBy: true,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: (cell: any) => {
                    return (
                        <Button>
                            <Link
                                href={`https://gitlab.outbankapp.com/groups/outbank/-/issues/?state=all&milestone_title=${cell.row.original.fullTitle}&first_page_size=100`}
                                isExternal
                            >
                                Issues
                            </Link>
                        </Button>
                    );
                },
                hasCellClick: false,
            },
        ],
        [milestones],
    );

    return (
        <ContentContainer headline="Milestones" description="List of all Outbank milestones" showSpinner={!milestones}>
            {tableData && (
                <TableContainer
                    columns={columns}
                    data={tableData}
                    search={{
                        placeholder: 'Search for Milestone',
                    }}
                    initialState={{
                        sortBy: [{ id: 'releaseDate', desc: true }],
                    }}
                    hiddenColumns={['fullTitle', 'description', 'wikiSearchString']}
                    defaultPageSize={20}
                    rowClick={openDetails}
                />
            )}
            {selectedItem && selectedItem && (
                <Details isOpen={isOpenDetails} onClose={onCloseDetails} milestoneData={selectedItem} />
            )}
        </ContentContainer>
    );
};
