/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { type FunctionComponent } from 'react';
import { type Request } from '../../interface/Crash';
import { RequestTableView } from './requestTableView';

interface Props {
    request: Request;
}

export const RequestHeaderView: FunctionComponent<Props> = (props: Props) => {
    const { request } = props;
    if (!request.headers) return <></>;

    return <RequestTableView data={convertObjectToHeaderArray(request.headers)} headers={['name', 'value']} />;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertObjectToHeaderArray = (headers: string[][] | Record<string, string>): any => {
    const data: string[][] = [];
    if (typeof data === 'object' && !Array.isArray(headers)) {
        Object.keys(headers).map((key) => data.push([key, headers[key]]));
        return data;
    }
    return headers;
};
