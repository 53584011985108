import React, { type FunctionComponent, useState, useEffect } from 'react';
import { ContentContainer } from '../../components/ContentContainer';
import { Request as apiRequest, getErrorMessage } from '../../helper';
import { type Apps, type AppDataSection } from '../../interface/AppData';
import { Heading, HStack, SimpleGrid, Square, Stack, useColorModeValue } from '@chakra-ui/react';
import { type CombinedError } from 'urql';
import { OverviewBox } from './OverviewBox';
import { storedSessionUser } from '../../keycloak';
import { AppIcon } from './AppIcon';

export const Overview: FunctionComponent = () => {
    const allowedEmails = [
        'krisztina@outbankapp.com',
        'christine@outbankapp.com',
        'ralf@outbankapp.com',
        'christians@outbankapp.com',
        'lukas@outbankapp.com',
        'marek@outbankapp.com',
    ];
    const allowed = storedSessionUser && allowedEmails.includes(storedSessionUser.email);

    const [apps, setApps] = useState<Apps>();
    const [error, setError] = useState<CombinedError>();

    useEffect(() => {
        setApps(undefined);
        setError(undefined);
        async function getApps(): Promise<void> {
            try {
                const response = await apiRequest.get<Apps>({ path: '/apps/all' });
                setApps(response.data);
            } catch (e) {
                setError({ name: 'Failed to load apps', message: getErrorMessage(e), graphQLErrors: [] });
            }
        }
        if (allowed) {
            getApps();
        } else {
            setApps({ sections: [] });
        }
    }, []);

    const squareColor = useColorModeValue('gray.100', 'gray.700');
    const section = (section: AppDataSection): JSX.Element => (
        <Stack key={section.mandant} spacing="2">
            <HStack spacing="2">
                <Square size="10" bg={squareColor} borderRadius="lg" overflow="hidden">
                    <AppIcon mandant={section.mandant} />
                </Square>
                <Heading as="h4" size="md" mt={2}>
                    {section.mandant}
                </Heading>
            </HStack>

            <SimpleGrid columns={{ base: 1, md: 3 }} gap={{ base: '0', md: '6' }}>
                {section.apps.map((app) => (
                    <OverviewBox key={app} appId={app} />
                ))}
            </SimpleGrid>
        </Stack>
    );

    return (
        <ContentContainer headline="App Releases" description="List of all apps" showSpinner={!apps} error={error}>
            <Stack spacing="16">{apps?.sections.map((s) => section(s))}</Stack>
        </ContentContainer>
    );
};
