import React, { type FunctionComponent, type ChangeEvent } from 'react';
import { Text, Textarea, VStack } from '@chakra-ui/react';

interface Props {
    title: string;
    name: string;
    value: string;
    maxLength: number;
    height: string;
    isDisabled: boolean;
    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

export const InputArea: FunctionComponent<Props> = (props) => {
    const { title, name, value, maxLength, height, isDisabled, onChange } = props;
    const isInvalid = value.length > maxLength;

    return (
        <VStack align="justify" spacing="0">
            <Text as="b">{title}</Text>
            <Textarea
                height={height}
                value={value}
                name={name}
                onChange={onChange}
                isInvalid={isInvalid}
                isDisabled={isDisabled}
            />
            <Text align="right" fontSize="xs" color={isInvalid ? 'red' : 'gray.400'}>
                {maxLength - value.length}
            </Text>
        </VStack>
    );
};
