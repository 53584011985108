/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { type FunctionComponent } from 'react';
import { Tabs, TabList, Tab, TabPanels, TabPanel, Code } from '@chakra-ui/react';
import { type Request } from '../../interface/Crash';
import { DetailsElement } from '../detailsDrawer/DetailsElement';
import { RequestHeaderView } from './RequestHeaderView';
import { RequestCookieView } from './RequestCookieView';
import { RequestBodyView } from './RequestBodyView';
import { ResponseHeaderView } from './ResponseHeaderView';
import { ResponseCookieView } from './ResponseCookieView';
import { ResponseBodyView } from './ResponseBodyView';
import { getCodeColorForResponseCode } from '../../helper/ResponseCode';

interface Props {
    request: Request;
    reportId?: string;
    protocolId?: string;
    traceIndex?: number;
}

export const Detail: FunctionComponent<Props> = (props: Props) => {
    const { request, reportId, traceIndex, protocolId } = props;

    return (
        <>
            <DetailsElement label="Code">
                <Code
                    colorScheme={
                        request.response?.code ? getCodeColorForResponseCode(request.response.code) : undefined
                    }
                    p={1}
                >
                    {request.response?.code}
                </Code>
            </DetailsElement>
            <DetailsElement label="Method" value={request.method} />
            <DetailsElement label="Url" value={request.url} />
            <Tabs defaultIndex={5} pt={25}>
                <TabList>
                    <Tab>Request Headers</Tab>
                    <Tab>Request Cookies</Tab>
                    <Tab>Request Body</Tab>
                    <Tab>Response Headers</Tab>
                    <Tab>Response Cookies</Tab>
                    <Tab>Response Body</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <RequestHeaderView request={request} />
                    </TabPanel>
                    <TabPanel>
                        <RequestCookieView request={request} />
                    </TabPanel>
                    <TabPanel>
                        <RequestBodyView request={request} />
                    </TabPanel>
                    <TabPanel>
                        <ResponseHeaderView request={request} />
                    </TabPanel>
                    <TabPanel>
                        <ResponseCookieView request={request} />
                    </TabPanel>
                    <TabPanel>
                        <ResponseBodyView
                            request={request}
                            reportId={reportId}
                            prorocolId={protocolId}
                            protocolTraceIndex={traceIndex}
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    );
};
