export enum ResponseCode {
    Informational = 'INFORMATIONAL',
    OK = 'OK',
    Redirect = 'REDIRECT',
    ClientError = 'CLIENTERROR',
    ServerError = 'SERVERERROR',
    Unknown = 'UNKNOWN',
}

export const colorForResponseCode = (code: ResponseCode): string => {
    switch (code) {
        case ResponseCode.Informational:
            return 'gray.300';
        case ResponseCode.OK:
            return 'green.500';
        case ResponseCode.Redirect:
            return 'blue.500';
        case ResponseCode.ClientError:
            return 'yellow.500';
        case ResponseCode.ServerError:
            return 'red.500';
        default:
            return 'black.500';
    }
};

export const codeColorForResponseCode = (code: ResponseCode): string => {
    switch (code) {
        case ResponseCode.Informational:
            return 'gray';
        case ResponseCode.OK:
            return 'green';
        case ResponseCode.Redirect:
            return 'blue';
        case ResponseCode.ClientError:
            return 'yellow';
        case ResponseCode.ServerError:
            return 'red';
        default:
            return 'gray';
    }
};

export const convertResponseCodeNumber = (code: number): ResponseCode => {
    if (code >= 0 && code < 200) return ResponseCode.Informational;
    if (code >= 200 && code < 300) return ResponseCode.OK;
    if (code >= 300 && code < 400) return ResponseCode.Redirect;
    if (code >= 400 && code < 500) return ResponseCode.ClientError;
    if (code >= 500 && code < 600) return ResponseCode.ServerError;
    return ResponseCode.Unknown;
};

export const getColorForResponseCode = (code: number): string => {
    return colorForResponseCode(convertResponseCodeNumber(code));
};

export const getCodeColorForResponseCode = (code: number): string => {
    return codeColorForResponseCode(convertResponseCodeNumber(code));
};
