import { SimpleGrid } from '@chakra-ui/react';
import React, { type FunctionComponent } from 'react';
import { IoDocumentText, IoMail, IoLayersSharp, IoNewspaper } from 'react-icons/io5';
import { ContentContainer } from '../../components/ContentContainer';
import { StatBox } from '../../components/StatBox';
import { useGetDashboardCountsQuery } from '../../generated/types';

export const DashboardWebconnect: FunctionComponent = () => {
    const [resultQuery] = useGetDashboardCountsQuery();
    const { data, fetching, error } = resultQuery;

    return (
        <ContentContainer
            headline="Dashboard"
            description="Welcome to Outbank ServicePortal"
            showSpinner={fetching}
            error={error}
        >
            <SimpleGrid columns={{ base: 1, md: 3 }} gap={{ base: '5', md: '6' }} mt={5}>
                <StatBox
                    title="Crashes"
                    link="/lastcrashes/200"
                    icon={IoLayersSharp}
                    count={data?.crashes?.totalCount ?? 0}
                />
                <StatBox
                    title="Protocols"
                    link="/protocols"
                    icon={IoDocumentText}
                    count={data?.protocols?.totalCount ?? 0}
                />
                <StatBox title="Har Files" link="/har" icon={IoMail} count={data?.hars?.totalCount ?? 0} />
                <StatBox
                    title="Feature Requests"
                    link="/featurerequests"
                    icon={IoNewspaper}
                    count={data?.fr?.totalCount ?? 0}
                />
                <StatBox
                    title="Importer Errors"
                    link="/importererror"
                    icon={IoNewspaper}
                    count={data?.importer?.totalCount ?? 0}
                />
            </SimpleGrid>
        </ContentContainer>
    );
};
