import React, { type FunctionComponent } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Sidebar } from './components/navigation/Sidebar';
import { Topbar } from './components/navigation/Topbar';
import { Routes } from './components/navigation/Routes';
import { Login } from './screen/Login';
import { useKeycloak } from '@react-keycloak/web';

export const App: FunctionComponent = () => {
    const { keycloak, initialized } = useKeycloak();

    if (!initialized) {
        return null;
    }
    return (
        <Flex h="100vh" flexDirection="column">
            <Topbar />
            <Flex flex="1">
                <Sidebar display={{ base: 'none', md: 'flex' }} />
                <Flex flex="1" px="8" pb="6" pt="4" overflowX="auto">
                    <Box h="full" w="full">
                        {keycloak.authenticated ? <Routes /> : <Login />}
                    </Box>
                </Flex>
            </Flex>
        </Flex>
    );
};
