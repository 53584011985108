import React, { type FunctionComponent, type ChangeEvent, useState, useEffect } from 'react';
import { type AppData, type AppStoreVersion, type LocalizedAppTexts } from '../../interface/AppData';
import { VStack, useBoolean, useToast } from '@chakra-ui/react';
import { labelForPlatform } from './Helper';
import { InputArea } from './InputArea';
import { InputForm } from './InputForm';
import { Request as apiRequest, getErrorMessage } from '../../helper';
import LoadingIndicator from '../../components/LoadingIndicator';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    appData: AppData;
    version: AppStoreVersion;
    type: 'KEYWORDS' | 'PROMOTIONAL_TEXT';
}

export const LocalizedAppTextsForm: FunctionComponent<Props> = (props) => {
    const { isOpen, onClose, appData, version, type } = props;
    const versionName = `${appData.name} ${labelForPlatform(version.platform)} ${version.versionString}`;

    let path: string;
    let maxLength = 100;
    switch (type) {
        case 'KEYWORDS':
            path = `/apps/${appData.id}/keywords/${version.id}`;
            maxLength = 100;
            break;
        case 'PROMOTIONAL_TEXT':
            path = `/apps/${appData.id}/promotionalTexts/${version.id}`;
            maxLength = 170;
            break;
    }

    const [isLoading, setLoading] = useBoolean();
    const toast = useToast({ position: 'top' });

    const [texts, setTexts] = useState<LocalizedAppTexts>();
    const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>): any => {
        const element = e.target;
        setTexts((texts: LocalizedAppTexts | undefined): LocalizedAppTexts | undefined => {
            if (texts) {
                const newTexts = { ...texts };
                for (const entry of Object.entries(newTexts.texts)) {
                    if (entry[0] === element.name && entry[1]) {
                        entry[1].text = element.value;
                    }
                }
                return newTexts;
            }
            return texts;
        });
    };

    const getTexts = async (reload: boolean): Promise<void> => {
        try {
            const response = await apiRequest.get<LocalizedAppTexts>({ path });
            setTexts(response.data);
        } catch (e) {
            if (reload) {
                toast({
                    title: 'Failure!',
                    description: `Could not load texts for ${versionName}: ${getErrorMessage(e)}.`,
                    status: 'error',
                    isClosable: true,
                });
            }
        }
    };

    useEffect(() => {
        setLoading.off();
        setTexts(undefined);
        getTexts(false);
    }, []);

    /* eslint-disable @typescript-eslint/no-explicit-any */
    let isSaveDisabled = true;
    if (texts) {
        isSaveDisabled = Object.entries(texts)
            .filter((e: [string, any]) => e[1]?.text !== undefined)
            .some((e: [string, any]) => e[1].text.length > maxLength);
    }
    /* eslint-enable @typescript-eslint/no-explicit-any */

    const onReload = async (): Promise<void> => {
        setLoading.on();
        await getTexts(true);
        setLoading.off();
    };

    const onSave = async (): Promise<void> => {
        try {
            setLoading.on();
            const response = await apiRequest.post<LocalizedAppTexts>({
                path,
                data: texts,
            });
            setTexts(response.data);
            onClose();

            toast({
                title: 'Success!',
                description: `Keywords for ${versionName} saved.`,
                status: 'success',
                isClosable: true,
            });
        } catch (e) {
            toast({
                title: 'Failure!',
                description: `Could not save keywords for ${versionName}: ${getErrorMessage(e)}.`,
                status: 'error',
                isClosable: true,
            });
        }

        setLoading.off();
    };

    const titleForKey = (key: string): string => {
        switch (String(key)) {
            case 'de':
                return 'German';
            case 'us':
                return 'English (US)';
            case 'gb':
                return 'English (GB)';
            default:
                return '';
        }
    };

    const inputContainer = (key: string, value: string): JSX.Element => (
        <InputArea
            key={key}
            title={titleForKey(key)}
            name={key}
            value={value}
            maxLength={maxLength}
            isDisabled={isLoading}
            onChange={handleInputChange}
            height="50"
        />
    );

    const loadedContent = (texts: LocalizedAppTexts): JSX.Element => (
        <VStack align="justify" spacing="4">
            {Object.entries(texts.texts).map((entry) => {
                return entry[1] && inputContainer(entry[0], entry[1].text);
            })}
        </VStack>
    );

    let content;
    if (texts) content = loadedContent(texts);
    else content = <LoadingIndicator />;

    let subtitle;
    switch (type) {
        case 'KEYWORDS':
            subtitle = 'Keywords';
            break;
        case 'PROMOTIONAL_TEXT':
            subtitle = 'Promotional Texts';
            break;
    }

    return (
        <InputForm
            title={versionName}
            subtitle={subtitle}
            isOpen={isOpen}
            onClose={onClose}
            onReload={onReload}
            isSaveDisabled={isSaveDisabled}
            onSave={onSave}
        >
            {content}
        </InputForm>
    );
};
