/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { type FunctionComponent, useState } from 'react';
import { type Request } from '../../interface/Crash';
import { HStack, Box, Heading } from '@chakra-ui/react';
import { IoChevronDown, IoChevronForward } from 'react-icons/io5';
import { Detail } from '../requestDetail/Detail';

interface Props {
    protocolId: string;
    request: Request;
    traceIndex: number;
}

export const BKTraceCallstackRequest: FunctionComponent<Props> = (props: Props) => {
    const { request, traceIndex, protocolId } = props;
    const [showDetails, setShowDetails] = useState(false);

    return (
        <>
            <HStack
                spacing="1"
                onClick={() => {
                    setShowDetails(!showDetails);
                }}
                cursor="pointer"
            >
                <Box style={{ cursor: 'pointer' }}>
                    {showDetails ? <IoChevronDown size="25" /> : <IoChevronForward size="25" />}
                </Box>
                <Heading as="h4" size="sm">
                    Request
                </Heading>
            </HStack>
            {showDetails && (
                <Box mt={4}>
                    <Detail protocolId={protocolId} request={request} traceIndex={traceIndex} />
                </Box>
            )}
        </>
    );
};
