import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A floating point number that requires more precision than IEEE 754 binary 64 */
  BigFloat: { input: any; output: any; }
  /**
   * A signed eight-byte integer. The upper big integer values are greater than the
   * max value for a JavaScript number. Therefore all big integers will be output as
   * strings and not numbers.
   */
  BigInt: { input: any; output: any; }
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: { input: any; output: any; }
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: { input: any; output: any; }
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: { input: any; output: any; }
};

export type AppSource = Node & {
  __typename?: 'AppSource';
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads and enables pagination through a set of `OutbankUser`. */
  outbankUsers: OutbankUsersConnection;
};


export type AppSourceOutbankUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<OutbankUserCondition>;
  filter?: InputMaybe<OutbankUserFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OutbankUsersOrderBy>>;
};

export type AppSourceAggregates = {
  __typename?: 'AppSourceAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<AppSourceDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * A condition to be used against `AppSource` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AppSourceCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
};

export type AppSourceDistinctCountAggregates = {
  __typename?: 'AppSourceDistinctCountAggregates';
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `AppSource` object types. All fields are combined with a logical ‘and.’ */
export type AppSourceFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AppSourceFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AppSourceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AppSourceFilter>>;
};

/** An input for mutations affecting `AppSource` */
export type AppSourceInput = {
  id?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
};

/** Represents an update to a `AppSource`. Fields that are set will be updated. */
export type AppSourcePatch = {
  id?: InputMaybe<Scalars['UUID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of `AppSource` values. */
export type AppSourcesConnection = {
  __typename?: 'AppSourcesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<AppSourceAggregates>;
  /** A list of edges which contains the `AppSource` and cursor to aid in pagination. */
  edges: Array<AppSourcesEdge>;
  /** A list of `AppSource` objects. */
  nodes: Array<AppSource>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AppSource` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `AppSource` edge in the connection. */
export type AppSourcesEdge = {
  __typename?: 'AppSourcesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `AppSource` at the end of the edge. */
  node: AppSource;
};

/** Methods to use when ordering `AppSource`. */
export enum AppSourcesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  OutbankUsersAverageAppSourceIdAsc = 'OUTBANK_USERS_AVERAGE_APP_SOURCE_ID_ASC',
  OutbankUsersAverageAppSourceIdDesc = 'OUTBANK_USERS_AVERAGE_APP_SOURCE_ID_DESC',
  OutbankUsersAverageCreatedAtAsc = 'OUTBANK_USERS_AVERAGE_CREATED_AT_ASC',
  OutbankUsersAverageCreatedAtDesc = 'OUTBANK_USERS_AVERAGE_CREATED_AT_DESC',
  OutbankUsersAverageEmailAsc = 'OUTBANK_USERS_AVERAGE_EMAIL_ASC',
  OutbankUsersAverageEmailDesc = 'OUTBANK_USERS_AVERAGE_EMAIL_DESC',
  OutbankUsersAverageIdAsc = 'OUTBANK_USERS_AVERAGE_ID_ASC',
  OutbankUsersAverageIdDesc = 'OUTBANK_USERS_AVERAGE_ID_DESC',
  OutbankUsersAverageUpdatedAtAsc = 'OUTBANK_USERS_AVERAGE_UPDATED_AT_ASC',
  OutbankUsersAverageUpdatedAtDesc = 'OUTBANK_USERS_AVERAGE_UPDATED_AT_DESC',
  OutbankUsersCountAsc = 'OUTBANK_USERS_COUNT_ASC',
  OutbankUsersCountDesc = 'OUTBANK_USERS_COUNT_DESC',
  OutbankUsersDistinctCountAppSourceIdAsc = 'OUTBANK_USERS_DISTINCT_COUNT_APP_SOURCE_ID_ASC',
  OutbankUsersDistinctCountAppSourceIdDesc = 'OUTBANK_USERS_DISTINCT_COUNT_APP_SOURCE_ID_DESC',
  OutbankUsersDistinctCountCreatedAtAsc = 'OUTBANK_USERS_DISTINCT_COUNT_CREATED_AT_ASC',
  OutbankUsersDistinctCountCreatedAtDesc = 'OUTBANK_USERS_DISTINCT_COUNT_CREATED_AT_DESC',
  OutbankUsersDistinctCountEmailAsc = 'OUTBANK_USERS_DISTINCT_COUNT_EMAIL_ASC',
  OutbankUsersDistinctCountEmailDesc = 'OUTBANK_USERS_DISTINCT_COUNT_EMAIL_DESC',
  OutbankUsersDistinctCountIdAsc = 'OUTBANK_USERS_DISTINCT_COUNT_ID_ASC',
  OutbankUsersDistinctCountIdDesc = 'OUTBANK_USERS_DISTINCT_COUNT_ID_DESC',
  OutbankUsersDistinctCountUpdatedAtAsc = 'OUTBANK_USERS_DISTINCT_COUNT_UPDATED_AT_ASC',
  OutbankUsersDistinctCountUpdatedAtDesc = 'OUTBANK_USERS_DISTINCT_COUNT_UPDATED_AT_DESC',
  OutbankUsersMaxAppSourceIdAsc = 'OUTBANK_USERS_MAX_APP_SOURCE_ID_ASC',
  OutbankUsersMaxAppSourceIdDesc = 'OUTBANK_USERS_MAX_APP_SOURCE_ID_DESC',
  OutbankUsersMaxCreatedAtAsc = 'OUTBANK_USERS_MAX_CREATED_AT_ASC',
  OutbankUsersMaxCreatedAtDesc = 'OUTBANK_USERS_MAX_CREATED_AT_DESC',
  OutbankUsersMaxEmailAsc = 'OUTBANK_USERS_MAX_EMAIL_ASC',
  OutbankUsersMaxEmailDesc = 'OUTBANK_USERS_MAX_EMAIL_DESC',
  OutbankUsersMaxIdAsc = 'OUTBANK_USERS_MAX_ID_ASC',
  OutbankUsersMaxIdDesc = 'OUTBANK_USERS_MAX_ID_DESC',
  OutbankUsersMaxUpdatedAtAsc = 'OUTBANK_USERS_MAX_UPDATED_AT_ASC',
  OutbankUsersMaxUpdatedAtDesc = 'OUTBANK_USERS_MAX_UPDATED_AT_DESC',
  OutbankUsersMinAppSourceIdAsc = 'OUTBANK_USERS_MIN_APP_SOURCE_ID_ASC',
  OutbankUsersMinAppSourceIdDesc = 'OUTBANK_USERS_MIN_APP_SOURCE_ID_DESC',
  OutbankUsersMinCreatedAtAsc = 'OUTBANK_USERS_MIN_CREATED_AT_ASC',
  OutbankUsersMinCreatedAtDesc = 'OUTBANK_USERS_MIN_CREATED_AT_DESC',
  OutbankUsersMinEmailAsc = 'OUTBANK_USERS_MIN_EMAIL_ASC',
  OutbankUsersMinEmailDesc = 'OUTBANK_USERS_MIN_EMAIL_DESC',
  OutbankUsersMinIdAsc = 'OUTBANK_USERS_MIN_ID_ASC',
  OutbankUsersMinIdDesc = 'OUTBANK_USERS_MIN_ID_DESC',
  OutbankUsersMinUpdatedAtAsc = 'OUTBANK_USERS_MIN_UPDATED_AT_ASC',
  OutbankUsersMinUpdatedAtDesc = 'OUTBANK_USERS_MIN_UPDATED_AT_DESC',
  OutbankUsersStddevPopulationAppSourceIdAsc = 'OUTBANK_USERS_STDDEV_POPULATION_APP_SOURCE_ID_ASC',
  OutbankUsersStddevPopulationAppSourceIdDesc = 'OUTBANK_USERS_STDDEV_POPULATION_APP_SOURCE_ID_DESC',
  OutbankUsersStddevPopulationCreatedAtAsc = 'OUTBANK_USERS_STDDEV_POPULATION_CREATED_AT_ASC',
  OutbankUsersStddevPopulationCreatedAtDesc = 'OUTBANK_USERS_STDDEV_POPULATION_CREATED_AT_DESC',
  OutbankUsersStddevPopulationEmailAsc = 'OUTBANK_USERS_STDDEV_POPULATION_EMAIL_ASC',
  OutbankUsersStddevPopulationEmailDesc = 'OUTBANK_USERS_STDDEV_POPULATION_EMAIL_DESC',
  OutbankUsersStddevPopulationIdAsc = 'OUTBANK_USERS_STDDEV_POPULATION_ID_ASC',
  OutbankUsersStddevPopulationIdDesc = 'OUTBANK_USERS_STDDEV_POPULATION_ID_DESC',
  OutbankUsersStddevPopulationUpdatedAtAsc = 'OUTBANK_USERS_STDDEV_POPULATION_UPDATED_AT_ASC',
  OutbankUsersStddevPopulationUpdatedAtDesc = 'OUTBANK_USERS_STDDEV_POPULATION_UPDATED_AT_DESC',
  OutbankUsersStddevSampleAppSourceIdAsc = 'OUTBANK_USERS_STDDEV_SAMPLE_APP_SOURCE_ID_ASC',
  OutbankUsersStddevSampleAppSourceIdDesc = 'OUTBANK_USERS_STDDEV_SAMPLE_APP_SOURCE_ID_DESC',
  OutbankUsersStddevSampleCreatedAtAsc = 'OUTBANK_USERS_STDDEV_SAMPLE_CREATED_AT_ASC',
  OutbankUsersStddevSampleCreatedAtDesc = 'OUTBANK_USERS_STDDEV_SAMPLE_CREATED_AT_DESC',
  OutbankUsersStddevSampleEmailAsc = 'OUTBANK_USERS_STDDEV_SAMPLE_EMAIL_ASC',
  OutbankUsersStddevSampleEmailDesc = 'OUTBANK_USERS_STDDEV_SAMPLE_EMAIL_DESC',
  OutbankUsersStddevSampleIdAsc = 'OUTBANK_USERS_STDDEV_SAMPLE_ID_ASC',
  OutbankUsersStddevSampleIdDesc = 'OUTBANK_USERS_STDDEV_SAMPLE_ID_DESC',
  OutbankUsersStddevSampleUpdatedAtAsc = 'OUTBANK_USERS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  OutbankUsersStddevSampleUpdatedAtDesc = 'OUTBANK_USERS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  OutbankUsersSumAppSourceIdAsc = 'OUTBANK_USERS_SUM_APP_SOURCE_ID_ASC',
  OutbankUsersSumAppSourceIdDesc = 'OUTBANK_USERS_SUM_APP_SOURCE_ID_DESC',
  OutbankUsersSumCreatedAtAsc = 'OUTBANK_USERS_SUM_CREATED_AT_ASC',
  OutbankUsersSumCreatedAtDesc = 'OUTBANK_USERS_SUM_CREATED_AT_DESC',
  OutbankUsersSumEmailAsc = 'OUTBANK_USERS_SUM_EMAIL_ASC',
  OutbankUsersSumEmailDesc = 'OUTBANK_USERS_SUM_EMAIL_DESC',
  OutbankUsersSumIdAsc = 'OUTBANK_USERS_SUM_ID_ASC',
  OutbankUsersSumIdDesc = 'OUTBANK_USERS_SUM_ID_DESC',
  OutbankUsersSumUpdatedAtAsc = 'OUTBANK_USERS_SUM_UPDATED_AT_ASC',
  OutbankUsersSumUpdatedAtDesc = 'OUTBANK_USERS_SUM_UPDATED_AT_DESC',
  OutbankUsersVariancePopulationAppSourceIdAsc = 'OUTBANK_USERS_VARIANCE_POPULATION_APP_SOURCE_ID_ASC',
  OutbankUsersVariancePopulationAppSourceIdDesc = 'OUTBANK_USERS_VARIANCE_POPULATION_APP_SOURCE_ID_DESC',
  OutbankUsersVariancePopulationCreatedAtAsc = 'OUTBANK_USERS_VARIANCE_POPULATION_CREATED_AT_ASC',
  OutbankUsersVariancePopulationCreatedAtDesc = 'OUTBANK_USERS_VARIANCE_POPULATION_CREATED_AT_DESC',
  OutbankUsersVariancePopulationEmailAsc = 'OUTBANK_USERS_VARIANCE_POPULATION_EMAIL_ASC',
  OutbankUsersVariancePopulationEmailDesc = 'OUTBANK_USERS_VARIANCE_POPULATION_EMAIL_DESC',
  OutbankUsersVariancePopulationIdAsc = 'OUTBANK_USERS_VARIANCE_POPULATION_ID_ASC',
  OutbankUsersVariancePopulationIdDesc = 'OUTBANK_USERS_VARIANCE_POPULATION_ID_DESC',
  OutbankUsersVariancePopulationUpdatedAtAsc = 'OUTBANK_USERS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  OutbankUsersVariancePopulationUpdatedAtDesc = 'OUTBANK_USERS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  OutbankUsersVarianceSampleAppSourceIdAsc = 'OUTBANK_USERS_VARIANCE_SAMPLE_APP_SOURCE_ID_ASC',
  OutbankUsersVarianceSampleAppSourceIdDesc = 'OUTBANK_USERS_VARIANCE_SAMPLE_APP_SOURCE_ID_DESC',
  OutbankUsersVarianceSampleCreatedAtAsc = 'OUTBANK_USERS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  OutbankUsersVarianceSampleCreatedAtDesc = 'OUTBANK_USERS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  OutbankUsersVarianceSampleEmailAsc = 'OUTBANK_USERS_VARIANCE_SAMPLE_EMAIL_ASC',
  OutbankUsersVarianceSampleEmailDesc = 'OUTBANK_USERS_VARIANCE_SAMPLE_EMAIL_DESC',
  OutbankUsersVarianceSampleIdAsc = 'OUTBANK_USERS_VARIANCE_SAMPLE_ID_ASC',
  OutbankUsersVarianceSampleIdDesc = 'OUTBANK_USERS_VARIANCE_SAMPLE_ID_DESC',
  OutbankUsersVarianceSampleUpdatedAtAsc = 'OUTBANK_USERS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  OutbankUsersVarianceSampleUpdatedAtDesc = 'OUTBANK_USERS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type Crash = Node & {
  __typename?: 'Crash';
  appVersion: Scalars['String']['output'];
  callMethod: CrashCallMethod;
  /** Reads a single `CrashGroup` that is related to this `Crash`. */
  crashGroup?: Maybe<CrashGroup>;
  crashGroupId?: Maybe<Scalars['UUID']['output']>;
  createdAt: Scalars['Datetime']['output'];
  createdAtToInt?: Maybe<Scalars['Int']['output']>;
  file: Scalars['String']['output'];
  fileLineNumber: Scalars['Int']['output'];
  fileName: Scalars['String']['output'];
  fileVersion: Scalars['String']['output'];
  /** Reads and enables pagination through a set of `File`. */
  files: FilesConnection;
  id: Scalars['UUID']['output'];
  loggerInfo: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  osVersion: Scalars['String']['output'];
  /** Reads a single `OutbankUser` that is related to this `Crash`. */
  outbankUser?: Maybe<OutbankUser>;
  outbankUserId?: Maybe<Scalars['UUID']['output']>;
  state: StateType;
  updatedAt: Scalars['Datetime']['output'];
};


export type CrashFilesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FileCondition>;
  filter?: InputMaybe<FileFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

export type CrashAggregates = {
  __typename?: 'CrashAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CrashAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CrashDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CrashMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CrashMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CrashStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CrashStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CrashSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CrashVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CrashVarianceSampleAggregates>;
};

export type CrashAverageAggregates = {
  __typename?: 'CrashAverageAggregates';
  /** Mean average of this field across the matching connection. */
  createdAtToInt?: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of fileLineNumber across the matching connection */
  fileLineNumber?: Maybe<Scalars['BigFloat']['output']>;
};

export enum CrashCallMethod {
  /** payment */
  Payment = 'PAYMENT',
  /** refresh */
  Refresh = 'REFRESH',
  /** synchronize */
  Synchronize = 'SYNCHRONIZE',
  /** unknown */
  Unknown = 'UNKNOWN'
}

/** A filter to be used against CrashCallMethod fields. All fields are combined with a logical ‘and.’ */
export type CrashCallMethodFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<CrashCallMethod>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<CrashCallMethod>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<CrashCallMethod>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<CrashCallMethod>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<CrashCallMethod>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<CrashCallMethod>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<CrashCallMethod>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<CrashCallMethod>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<CrashCallMethod>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<CrashCallMethod>>;
};

/** A condition to be used against `Crash` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CrashCondition = {
  /** Checks for equality with the object’s `callMethod` field. */
  callMethod?: InputMaybe<CrashCallMethod>;
  /** Checks for equality with the object’s `crashGroupId` field. */
  crashGroupId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `file` field. */
  file?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `fileVersion` field. */
  fileVersion?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `message` field. */
  message?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `outbankUserId` field. */
  outbankUserId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<StateType>;
};

export type CrashDistinctCountAggregates = {
  __typename?: 'CrashDistinctCountAggregates';
  /** Distinct count of appVersion across the matching connection */
  appVersion?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of callMethod across the matching connection */
  callMethod?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of crashGroupId across the matching connection */
  crashGroupId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of this field across the matching connection. */
  createdAtToInt?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of file across the matching connection */
  file?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of fileLineNumber across the matching connection */
  fileLineNumber?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of fileName across the matching connection */
  fileName?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of fileVersion across the matching connection */
  fileVersion?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of loggerInfo across the matching connection */
  loggerInfo?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of message across the matching connection */
  message?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of osVersion across the matching connection */
  osVersion?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of outbankUserId across the matching connection */
  outbankUserId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of state across the matching connection */
  state?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `Crash` object types. All fields are combined with a logical ‘and.’ */
export type CrashFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CrashFilter>>;
  /** Filter by the object’s `callMethod` field. */
  callMethod?: InputMaybe<CrashCallMethodFilter>;
  /** Filter by the object’s `crashGroupId` field. */
  crashGroupId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdAtToInt` field. */
  createdAtToInt?: InputMaybe<IntFilter>;
  /** Filter by the object’s `file` field. */
  file?: InputMaybe<StringFilter>;
  /** Filter by the object’s `fileVersion` field. */
  fileVersion?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `message` field. */
  message?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CrashFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CrashFilter>>;
  /** Filter by the object’s `outbankUserId` field. */
  outbankUserId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<StateTypeFilter>;
};

export type CrashGroup = Node & {
  __typename?: 'CrashGroup';
  codeLine: Scalars['String']['output'];
  countClosed?: Maybe<Scalars['Int']['output']>;
  countInprogress?: Maybe<Scalars['Int']['output']>;
  countOpen?: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `Crash`. */
  crashes: CrashesConnection;
  createdAt: Scalars['Datetime']['output'];
  file: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  issue?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  stateLog?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Datetime']['output'];
};


export type CrashGroupCrashesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CrashCondition>;
  filter?: InputMaybe<CrashFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrashesOrderBy>>;
};

export type CrashGroupAggregates = {
  __typename?: 'CrashGroupAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CrashGroupAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CrashGroupDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CrashGroupMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CrashGroupMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CrashGroupStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CrashGroupStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CrashGroupSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CrashGroupVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CrashGroupVarianceSampleAggregates>;
};

export type CrashGroupAverageAggregates = {
  __typename?: 'CrashGroupAverageAggregates';
  /** Mean average of this field across the matching connection. */
  countClosed?: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of this field across the matching connection. */
  countInprogress?: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of this field across the matching connection. */
  countOpen?: Maybe<Scalars['BigFloat']['output']>;
};

/** Grouping methods for `Crash` for usage during aggregation. */
export enum CrashGroupBy {
  CallMethod = 'CALL_METHOD',
  CrashGroupId = 'CRASH_GROUP_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  File = 'FILE',
  FileVersion = 'FILE_VERSION',
  Message = 'MESSAGE',
  OutbankUserId = 'OUTBANK_USER_ID',
  State = 'STATE'
}

/**
 * A condition to be used against `CrashGroup` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CrashGroupCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
};

export type CrashGroupDistinctCountAggregates = {
  __typename?: 'CrashGroupDistinctCountAggregates';
  /** Distinct count of codeLine across the matching connection */
  codeLine?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of this field across the matching connection. */
  countClosed?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of this field across the matching connection. */
  countInprogress?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of this field across the matching connection. */
  countOpen?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of file across the matching connection */
  file?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of issue across the matching connection */
  issue?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of message across the matching connection */
  message?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of stateLog across the matching connection */
  stateLog?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `CrashGroup` object types. All fields are combined with a logical ‘and.’ */
export type CrashGroupFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CrashGroupFilter>>;
  /** Filter by the object’s `countClosed` field. */
  countClosed?: InputMaybe<IntFilter>;
  /** Filter by the object’s `countInprogress` field. */
  countInprogress?: InputMaybe<IntFilter>;
  /** Filter by the object’s `countOpen` field. */
  countOpen?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CrashGroupFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CrashGroupFilter>>;
};

/** An input for mutations affecting `CrashGroup` */
export type CrashGroupInput = {
  codeLine: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  file: Scalars['String']['input'];
  id?: InputMaybe<Scalars['UUID']['input']>;
  issue?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  stateLog?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

export type CrashGroupMaxAggregates = {
  __typename?: 'CrashGroupMaxAggregates';
  /** Maximum of this field across the matching connection. */
  countClosed?: Maybe<Scalars['Int']['output']>;
  /** Maximum of this field across the matching connection. */
  countInprogress?: Maybe<Scalars['Int']['output']>;
  /** Maximum of this field across the matching connection. */
  countOpen?: Maybe<Scalars['Int']['output']>;
};

export type CrashGroupMinAggregates = {
  __typename?: 'CrashGroupMinAggregates';
  /** Minimum of this field across the matching connection. */
  countClosed?: Maybe<Scalars['Int']['output']>;
  /** Minimum of this field across the matching connection. */
  countInprogress?: Maybe<Scalars['Int']['output']>;
  /** Minimum of this field across the matching connection. */
  countOpen?: Maybe<Scalars['Int']['output']>;
};

/** Represents an update to a `CrashGroup`. Fields that are set will be updated. */
export type CrashGroupPatch = {
  codeLine?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  file?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  issue?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  stateLog?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

export type CrashGroupStddevPopulationAggregates = {
  __typename?: 'CrashGroupStddevPopulationAggregates';
  /** Population standard deviation of this field across the matching connection. */
  countClosed?: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of this field across the matching connection. */
  countInprogress?: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of this field across the matching connection. */
  countOpen?: Maybe<Scalars['BigFloat']['output']>;
};

export type CrashGroupStddevSampleAggregates = {
  __typename?: 'CrashGroupStddevSampleAggregates';
  /** Sample standard deviation of this field across the matching connection. */
  countClosed?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of this field across the matching connection. */
  countInprogress?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of this field across the matching connection. */
  countOpen?: Maybe<Scalars['BigFloat']['output']>;
};

export type CrashGroupSumAggregates = {
  __typename?: 'CrashGroupSumAggregates';
  /** Sum of this field across the matching connection. */
  countClosed?: Maybe<Scalars['BigInt']['output']>;
  /** Sum of this field across the matching connection. */
  countInprogress?: Maybe<Scalars['BigInt']['output']>;
  /** Sum of this field across the matching connection. */
  countOpen?: Maybe<Scalars['BigInt']['output']>;
};

export type CrashGroupVariancePopulationAggregates = {
  __typename?: 'CrashGroupVariancePopulationAggregates';
  /** Population variance of this field across the matching connection. */
  countClosed?: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of this field across the matching connection. */
  countInprogress?: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of this field across the matching connection. */
  countOpen?: Maybe<Scalars['BigFloat']['output']>;
};

export type CrashGroupVarianceSampleAggregates = {
  __typename?: 'CrashGroupVarianceSampleAggregates';
  /** Sample variance of this field across the matching connection. */
  countClosed?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of this field across the matching connection. */
  countInprogress?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of this field across the matching connection. */
  countOpen?: Maybe<Scalars['BigFloat']['output']>;
};

/** A connection to a list of `CrashGroup` values. */
export type CrashGroupsConnection = {
  __typename?: 'CrashGroupsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CrashGroupAggregates>;
  /** A list of edges which contains the `CrashGroup` and cursor to aid in pagination. */
  edges: Array<CrashGroupsEdge>;
  /** A list of `CrashGroup` objects. */
  nodes: Array<CrashGroup>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CrashGroup` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `CrashGroup` edge in the connection. */
export type CrashGroupsEdge = {
  __typename?: 'CrashGroupsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `CrashGroup` at the end of the edge. */
  node: CrashGroup;
};

/** Methods to use when ordering `CrashGroup`. */
export enum CrashGroupsOrderBy {
  CrashesAverageAppVersionAsc = 'CRASHES_AVERAGE_APP_VERSION_ASC',
  CrashesAverageAppVersionDesc = 'CRASHES_AVERAGE_APP_VERSION_DESC',
  CrashesAverageCallMethodAsc = 'CRASHES_AVERAGE_CALL_METHOD_ASC',
  CrashesAverageCallMethodDesc = 'CRASHES_AVERAGE_CALL_METHOD_DESC',
  CrashesAverageCrashGroupIdAsc = 'CRASHES_AVERAGE_CRASH_GROUP_ID_ASC',
  CrashesAverageCrashGroupIdDesc = 'CRASHES_AVERAGE_CRASH_GROUP_ID_DESC',
  CrashesAverageCreatedAtAsc = 'CRASHES_AVERAGE_CREATED_AT_ASC',
  CrashesAverageCreatedAtDesc = 'CRASHES_AVERAGE_CREATED_AT_DESC',
  CrashesAverageFileAsc = 'CRASHES_AVERAGE_FILE_ASC',
  CrashesAverageFileDesc = 'CRASHES_AVERAGE_FILE_DESC',
  CrashesAverageFileLineNumberAsc = 'CRASHES_AVERAGE_FILE_LINE_NUMBER_ASC',
  CrashesAverageFileLineNumberDesc = 'CRASHES_AVERAGE_FILE_LINE_NUMBER_DESC',
  CrashesAverageFileNameAsc = 'CRASHES_AVERAGE_FILE_NAME_ASC',
  CrashesAverageFileNameDesc = 'CRASHES_AVERAGE_FILE_NAME_DESC',
  CrashesAverageFileVersionAsc = 'CRASHES_AVERAGE_FILE_VERSION_ASC',
  CrashesAverageFileVersionDesc = 'CRASHES_AVERAGE_FILE_VERSION_DESC',
  CrashesAverageIdAsc = 'CRASHES_AVERAGE_ID_ASC',
  CrashesAverageIdDesc = 'CRASHES_AVERAGE_ID_DESC',
  CrashesAverageLoggerInfoAsc = 'CRASHES_AVERAGE_LOGGER_INFO_ASC',
  CrashesAverageLoggerInfoDesc = 'CRASHES_AVERAGE_LOGGER_INFO_DESC',
  CrashesAverageMessageAsc = 'CRASHES_AVERAGE_MESSAGE_ASC',
  CrashesAverageMessageDesc = 'CRASHES_AVERAGE_MESSAGE_DESC',
  CrashesAverageOsVersionAsc = 'CRASHES_AVERAGE_OS_VERSION_ASC',
  CrashesAverageOsVersionDesc = 'CRASHES_AVERAGE_OS_VERSION_DESC',
  CrashesAverageOutbankUserIdAsc = 'CRASHES_AVERAGE_OUTBANK_USER_ID_ASC',
  CrashesAverageOutbankUserIdDesc = 'CRASHES_AVERAGE_OUTBANK_USER_ID_DESC',
  CrashesAverageStateAsc = 'CRASHES_AVERAGE_STATE_ASC',
  CrashesAverageStateDesc = 'CRASHES_AVERAGE_STATE_DESC',
  CrashesAverageUpdatedAtAsc = 'CRASHES_AVERAGE_UPDATED_AT_ASC',
  CrashesAverageUpdatedAtDesc = 'CRASHES_AVERAGE_UPDATED_AT_DESC',
  CrashesCountAsc = 'CRASHES_COUNT_ASC',
  CrashesCountDesc = 'CRASHES_COUNT_DESC',
  CrashesDistinctCountAppVersionAsc = 'CRASHES_DISTINCT_COUNT_APP_VERSION_ASC',
  CrashesDistinctCountAppVersionDesc = 'CRASHES_DISTINCT_COUNT_APP_VERSION_DESC',
  CrashesDistinctCountCallMethodAsc = 'CRASHES_DISTINCT_COUNT_CALL_METHOD_ASC',
  CrashesDistinctCountCallMethodDesc = 'CRASHES_DISTINCT_COUNT_CALL_METHOD_DESC',
  CrashesDistinctCountCrashGroupIdAsc = 'CRASHES_DISTINCT_COUNT_CRASH_GROUP_ID_ASC',
  CrashesDistinctCountCrashGroupIdDesc = 'CRASHES_DISTINCT_COUNT_CRASH_GROUP_ID_DESC',
  CrashesDistinctCountCreatedAtAsc = 'CRASHES_DISTINCT_COUNT_CREATED_AT_ASC',
  CrashesDistinctCountCreatedAtDesc = 'CRASHES_DISTINCT_COUNT_CREATED_AT_DESC',
  CrashesDistinctCountFileAsc = 'CRASHES_DISTINCT_COUNT_FILE_ASC',
  CrashesDistinctCountFileDesc = 'CRASHES_DISTINCT_COUNT_FILE_DESC',
  CrashesDistinctCountFileLineNumberAsc = 'CRASHES_DISTINCT_COUNT_FILE_LINE_NUMBER_ASC',
  CrashesDistinctCountFileLineNumberDesc = 'CRASHES_DISTINCT_COUNT_FILE_LINE_NUMBER_DESC',
  CrashesDistinctCountFileNameAsc = 'CRASHES_DISTINCT_COUNT_FILE_NAME_ASC',
  CrashesDistinctCountFileNameDesc = 'CRASHES_DISTINCT_COUNT_FILE_NAME_DESC',
  CrashesDistinctCountFileVersionAsc = 'CRASHES_DISTINCT_COUNT_FILE_VERSION_ASC',
  CrashesDistinctCountFileVersionDesc = 'CRASHES_DISTINCT_COUNT_FILE_VERSION_DESC',
  CrashesDistinctCountIdAsc = 'CRASHES_DISTINCT_COUNT_ID_ASC',
  CrashesDistinctCountIdDesc = 'CRASHES_DISTINCT_COUNT_ID_DESC',
  CrashesDistinctCountLoggerInfoAsc = 'CRASHES_DISTINCT_COUNT_LOGGER_INFO_ASC',
  CrashesDistinctCountLoggerInfoDesc = 'CRASHES_DISTINCT_COUNT_LOGGER_INFO_DESC',
  CrashesDistinctCountMessageAsc = 'CRASHES_DISTINCT_COUNT_MESSAGE_ASC',
  CrashesDistinctCountMessageDesc = 'CRASHES_DISTINCT_COUNT_MESSAGE_DESC',
  CrashesDistinctCountOsVersionAsc = 'CRASHES_DISTINCT_COUNT_OS_VERSION_ASC',
  CrashesDistinctCountOsVersionDesc = 'CRASHES_DISTINCT_COUNT_OS_VERSION_DESC',
  CrashesDistinctCountOutbankUserIdAsc = 'CRASHES_DISTINCT_COUNT_OUTBANK_USER_ID_ASC',
  CrashesDistinctCountOutbankUserIdDesc = 'CRASHES_DISTINCT_COUNT_OUTBANK_USER_ID_DESC',
  CrashesDistinctCountStateAsc = 'CRASHES_DISTINCT_COUNT_STATE_ASC',
  CrashesDistinctCountStateDesc = 'CRASHES_DISTINCT_COUNT_STATE_DESC',
  CrashesDistinctCountUpdatedAtAsc = 'CRASHES_DISTINCT_COUNT_UPDATED_AT_ASC',
  CrashesDistinctCountUpdatedAtDesc = 'CRASHES_DISTINCT_COUNT_UPDATED_AT_DESC',
  CrashesMaxAppVersionAsc = 'CRASHES_MAX_APP_VERSION_ASC',
  CrashesMaxAppVersionDesc = 'CRASHES_MAX_APP_VERSION_DESC',
  CrashesMaxCallMethodAsc = 'CRASHES_MAX_CALL_METHOD_ASC',
  CrashesMaxCallMethodDesc = 'CRASHES_MAX_CALL_METHOD_DESC',
  CrashesMaxCrashGroupIdAsc = 'CRASHES_MAX_CRASH_GROUP_ID_ASC',
  CrashesMaxCrashGroupIdDesc = 'CRASHES_MAX_CRASH_GROUP_ID_DESC',
  CrashesMaxCreatedAtAsc = 'CRASHES_MAX_CREATED_AT_ASC',
  CrashesMaxCreatedAtDesc = 'CRASHES_MAX_CREATED_AT_DESC',
  CrashesMaxFileAsc = 'CRASHES_MAX_FILE_ASC',
  CrashesMaxFileDesc = 'CRASHES_MAX_FILE_DESC',
  CrashesMaxFileLineNumberAsc = 'CRASHES_MAX_FILE_LINE_NUMBER_ASC',
  CrashesMaxFileLineNumberDesc = 'CRASHES_MAX_FILE_LINE_NUMBER_DESC',
  CrashesMaxFileNameAsc = 'CRASHES_MAX_FILE_NAME_ASC',
  CrashesMaxFileNameDesc = 'CRASHES_MAX_FILE_NAME_DESC',
  CrashesMaxFileVersionAsc = 'CRASHES_MAX_FILE_VERSION_ASC',
  CrashesMaxFileVersionDesc = 'CRASHES_MAX_FILE_VERSION_DESC',
  CrashesMaxIdAsc = 'CRASHES_MAX_ID_ASC',
  CrashesMaxIdDesc = 'CRASHES_MAX_ID_DESC',
  CrashesMaxLoggerInfoAsc = 'CRASHES_MAX_LOGGER_INFO_ASC',
  CrashesMaxLoggerInfoDesc = 'CRASHES_MAX_LOGGER_INFO_DESC',
  CrashesMaxMessageAsc = 'CRASHES_MAX_MESSAGE_ASC',
  CrashesMaxMessageDesc = 'CRASHES_MAX_MESSAGE_DESC',
  CrashesMaxOsVersionAsc = 'CRASHES_MAX_OS_VERSION_ASC',
  CrashesMaxOsVersionDesc = 'CRASHES_MAX_OS_VERSION_DESC',
  CrashesMaxOutbankUserIdAsc = 'CRASHES_MAX_OUTBANK_USER_ID_ASC',
  CrashesMaxOutbankUserIdDesc = 'CRASHES_MAX_OUTBANK_USER_ID_DESC',
  CrashesMaxStateAsc = 'CRASHES_MAX_STATE_ASC',
  CrashesMaxStateDesc = 'CRASHES_MAX_STATE_DESC',
  CrashesMaxUpdatedAtAsc = 'CRASHES_MAX_UPDATED_AT_ASC',
  CrashesMaxUpdatedAtDesc = 'CRASHES_MAX_UPDATED_AT_DESC',
  CrashesMinAppVersionAsc = 'CRASHES_MIN_APP_VERSION_ASC',
  CrashesMinAppVersionDesc = 'CRASHES_MIN_APP_VERSION_DESC',
  CrashesMinCallMethodAsc = 'CRASHES_MIN_CALL_METHOD_ASC',
  CrashesMinCallMethodDesc = 'CRASHES_MIN_CALL_METHOD_DESC',
  CrashesMinCrashGroupIdAsc = 'CRASHES_MIN_CRASH_GROUP_ID_ASC',
  CrashesMinCrashGroupIdDesc = 'CRASHES_MIN_CRASH_GROUP_ID_DESC',
  CrashesMinCreatedAtAsc = 'CRASHES_MIN_CREATED_AT_ASC',
  CrashesMinCreatedAtDesc = 'CRASHES_MIN_CREATED_AT_DESC',
  CrashesMinFileAsc = 'CRASHES_MIN_FILE_ASC',
  CrashesMinFileDesc = 'CRASHES_MIN_FILE_DESC',
  CrashesMinFileLineNumberAsc = 'CRASHES_MIN_FILE_LINE_NUMBER_ASC',
  CrashesMinFileLineNumberDesc = 'CRASHES_MIN_FILE_LINE_NUMBER_DESC',
  CrashesMinFileNameAsc = 'CRASHES_MIN_FILE_NAME_ASC',
  CrashesMinFileNameDesc = 'CRASHES_MIN_FILE_NAME_DESC',
  CrashesMinFileVersionAsc = 'CRASHES_MIN_FILE_VERSION_ASC',
  CrashesMinFileVersionDesc = 'CRASHES_MIN_FILE_VERSION_DESC',
  CrashesMinIdAsc = 'CRASHES_MIN_ID_ASC',
  CrashesMinIdDesc = 'CRASHES_MIN_ID_DESC',
  CrashesMinLoggerInfoAsc = 'CRASHES_MIN_LOGGER_INFO_ASC',
  CrashesMinLoggerInfoDesc = 'CRASHES_MIN_LOGGER_INFO_DESC',
  CrashesMinMessageAsc = 'CRASHES_MIN_MESSAGE_ASC',
  CrashesMinMessageDesc = 'CRASHES_MIN_MESSAGE_DESC',
  CrashesMinOsVersionAsc = 'CRASHES_MIN_OS_VERSION_ASC',
  CrashesMinOsVersionDesc = 'CRASHES_MIN_OS_VERSION_DESC',
  CrashesMinOutbankUserIdAsc = 'CRASHES_MIN_OUTBANK_USER_ID_ASC',
  CrashesMinOutbankUserIdDesc = 'CRASHES_MIN_OUTBANK_USER_ID_DESC',
  CrashesMinStateAsc = 'CRASHES_MIN_STATE_ASC',
  CrashesMinStateDesc = 'CRASHES_MIN_STATE_DESC',
  CrashesMinUpdatedAtAsc = 'CRASHES_MIN_UPDATED_AT_ASC',
  CrashesMinUpdatedAtDesc = 'CRASHES_MIN_UPDATED_AT_DESC',
  CrashesStddevPopulationAppVersionAsc = 'CRASHES_STDDEV_POPULATION_APP_VERSION_ASC',
  CrashesStddevPopulationAppVersionDesc = 'CRASHES_STDDEV_POPULATION_APP_VERSION_DESC',
  CrashesStddevPopulationCallMethodAsc = 'CRASHES_STDDEV_POPULATION_CALL_METHOD_ASC',
  CrashesStddevPopulationCallMethodDesc = 'CRASHES_STDDEV_POPULATION_CALL_METHOD_DESC',
  CrashesStddevPopulationCrashGroupIdAsc = 'CRASHES_STDDEV_POPULATION_CRASH_GROUP_ID_ASC',
  CrashesStddevPopulationCrashGroupIdDesc = 'CRASHES_STDDEV_POPULATION_CRASH_GROUP_ID_DESC',
  CrashesStddevPopulationCreatedAtAsc = 'CRASHES_STDDEV_POPULATION_CREATED_AT_ASC',
  CrashesStddevPopulationCreatedAtDesc = 'CRASHES_STDDEV_POPULATION_CREATED_AT_DESC',
  CrashesStddevPopulationFileAsc = 'CRASHES_STDDEV_POPULATION_FILE_ASC',
  CrashesStddevPopulationFileDesc = 'CRASHES_STDDEV_POPULATION_FILE_DESC',
  CrashesStddevPopulationFileLineNumberAsc = 'CRASHES_STDDEV_POPULATION_FILE_LINE_NUMBER_ASC',
  CrashesStddevPopulationFileLineNumberDesc = 'CRASHES_STDDEV_POPULATION_FILE_LINE_NUMBER_DESC',
  CrashesStddevPopulationFileNameAsc = 'CRASHES_STDDEV_POPULATION_FILE_NAME_ASC',
  CrashesStddevPopulationFileNameDesc = 'CRASHES_STDDEV_POPULATION_FILE_NAME_DESC',
  CrashesStddevPopulationFileVersionAsc = 'CRASHES_STDDEV_POPULATION_FILE_VERSION_ASC',
  CrashesStddevPopulationFileVersionDesc = 'CRASHES_STDDEV_POPULATION_FILE_VERSION_DESC',
  CrashesStddevPopulationIdAsc = 'CRASHES_STDDEV_POPULATION_ID_ASC',
  CrashesStddevPopulationIdDesc = 'CRASHES_STDDEV_POPULATION_ID_DESC',
  CrashesStddevPopulationLoggerInfoAsc = 'CRASHES_STDDEV_POPULATION_LOGGER_INFO_ASC',
  CrashesStddevPopulationLoggerInfoDesc = 'CRASHES_STDDEV_POPULATION_LOGGER_INFO_DESC',
  CrashesStddevPopulationMessageAsc = 'CRASHES_STDDEV_POPULATION_MESSAGE_ASC',
  CrashesStddevPopulationMessageDesc = 'CRASHES_STDDEV_POPULATION_MESSAGE_DESC',
  CrashesStddevPopulationOsVersionAsc = 'CRASHES_STDDEV_POPULATION_OS_VERSION_ASC',
  CrashesStddevPopulationOsVersionDesc = 'CRASHES_STDDEV_POPULATION_OS_VERSION_DESC',
  CrashesStddevPopulationOutbankUserIdAsc = 'CRASHES_STDDEV_POPULATION_OUTBANK_USER_ID_ASC',
  CrashesStddevPopulationOutbankUserIdDesc = 'CRASHES_STDDEV_POPULATION_OUTBANK_USER_ID_DESC',
  CrashesStddevPopulationStateAsc = 'CRASHES_STDDEV_POPULATION_STATE_ASC',
  CrashesStddevPopulationStateDesc = 'CRASHES_STDDEV_POPULATION_STATE_DESC',
  CrashesStddevPopulationUpdatedAtAsc = 'CRASHES_STDDEV_POPULATION_UPDATED_AT_ASC',
  CrashesStddevPopulationUpdatedAtDesc = 'CRASHES_STDDEV_POPULATION_UPDATED_AT_DESC',
  CrashesStddevSampleAppVersionAsc = 'CRASHES_STDDEV_SAMPLE_APP_VERSION_ASC',
  CrashesStddevSampleAppVersionDesc = 'CRASHES_STDDEV_SAMPLE_APP_VERSION_DESC',
  CrashesStddevSampleCallMethodAsc = 'CRASHES_STDDEV_SAMPLE_CALL_METHOD_ASC',
  CrashesStddevSampleCallMethodDesc = 'CRASHES_STDDEV_SAMPLE_CALL_METHOD_DESC',
  CrashesStddevSampleCrashGroupIdAsc = 'CRASHES_STDDEV_SAMPLE_CRASH_GROUP_ID_ASC',
  CrashesStddevSampleCrashGroupIdDesc = 'CRASHES_STDDEV_SAMPLE_CRASH_GROUP_ID_DESC',
  CrashesStddevSampleCreatedAtAsc = 'CRASHES_STDDEV_SAMPLE_CREATED_AT_ASC',
  CrashesStddevSampleCreatedAtDesc = 'CRASHES_STDDEV_SAMPLE_CREATED_AT_DESC',
  CrashesStddevSampleFileAsc = 'CRASHES_STDDEV_SAMPLE_FILE_ASC',
  CrashesStddevSampleFileDesc = 'CRASHES_STDDEV_SAMPLE_FILE_DESC',
  CrashesStddevSampleFileLineNumberAsc = 'CRASHES_STDDEV_SAMPLE_FILE_LINE_NUMBER_ASC',
  CrashesStddevSampleFileLineNumberDesc = 'CRASHES_STDDEV_SAMPLE_FILE_LINE_NUMBER_DESC',
  CrashesStddevSampleFileNameAsc = 'CRASHES_STDDEV_SAMPLE_FILE_NAME_ASC',
  CrashesStddevSampleFileNameDesc = 'CRASHES_STDDEV_SAMPLE_FILE_NAME_DESC',
  CrashesStddevSampleFileVersionAsc = 'CRASHES_STDDEV_SAMPLE_FILE_VERSION_ASC',
  CrashesStddevSampleFileVersionDesc = 'CRASHES_STDDEV_SAMPLE_FILE_VERSION_DESC',
  CrashesStddevSampleIdAsc = 'CRASHES_STDDEV_SAMPLE_ID_ASC',
  CrashesStddevSampleIdDesc = 'CRASHES_STDDEV_SAMPLE_ID_DESC',
  CrashesStddevSampleLoggerInfoAsc = 'CRASHES_STDDEV_SAMPLE_LOGGER_INFO_ASC',
  CrashesStddevSampleLoggerInfoDesc = 'CRASHES_STDDEV_SAMPLE_LOGGER_INFO_DESC',
  CrashesStddevSampleMessageAsc = 'CRASHES_STDDEV_SAMPLE_MESSAGE_ASC',
  CrashesStddevSampleMessageDesc = 'CRASHES_STDDEV_SAMPLE_MESSAGE_DESC',
  CrashesStddevSampleOsVersionAsc = 'CRASHES_STDDEV_SAMPLE_OS_VERSION_ASC',
  CrashesStddevSampleOsVersionDesc = 'CRASHES_STDDEV_SAMPLE_OS_VERSION_DESC',
  CrashesStddevSampleOutbankUserIdAsc = 'CRASHES_STDDEV_SAMPLE_OUTBANK_USER_ID_ASC',
  CrashesStddevSampleOutbankUserIdDesc = 'CRASHES_STDDEV_SAMPLE_OUTBANK_USER_ID_DESC',
  CrashesStddevSampleStateAsc = 'CRASHES_STDDEV_SAMPLE_STATE_ASC',
  CrashesStddevSampleStateDesc = 'CRASHES_STDDEV_SAMPLE_STATE_DESC',
  CrashesStddevSampleUpdatedAtAsc = 'CRASHES_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CrashesStddevSampleUpdatedAtDesc = 'CRASHES_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CrashesSumAppVersionAsc = 'CRASHES_SUM_APP_VERSION_ASC',
  CrashesSumAppVersionDesc = 'CRASHES_SUM_APP_VERSION_DESC',
  CrashesSumCallMethodAsc = 'CRASHES_SUM_CALL_METHOD_ASC',
  CrashesSumCallMethodDesc = 'CRASHES_SUM_CALL_METHOD_DESC',
  CrashesSumCrashGroupIdAsc = 'CRASHES_SUM_CRASH_GROUP_ID_ASC',
  CrashesSumCrashGroupIdDesc = 'CRASHES_SUM_CRASH_GROUP_ID_DESC',
  CrashesSumCreatedAtAsc = 'CRASHES_SUM_CREATED_AT_ASC',
  CrashesSumCreatedAtDesc = 'CRASHES_SUM_CREATED_AT_DESC',
  CrashesSumFileAsc = 'CRASHES_SUM_FILE_ASC',
  CrashesSumFileDesc = 'CRASHES_SUM_FILE_DESC',
  CrashesSumFileLineNumberAsc = 'CRASHES_SUM_FILE_LINE_NUMBER_ASC',
  CrashesSumFileLineNumberDesc = 'CRASHES_SUM_FILE_LINE_NUMBER_DESC',
  CrashesSumFileNameAsc = 'CRASHES_SUM_FILE_NAME_ASC',
  CrashesSumFileNameDesc = 'CRASHES_SUM_FILE_NAME_DESC',
  CrashesSumFileVersionAsc = 'CRASHES_SUM_FILE_VERSION_ASC',
  CrashesSumFileVersionDesc = 'CRASHES_SUM_FILE_VERSION_DESC',
  CrashesSumIdAsc = 'CRASHES_SUM_ID_ASC',
  CrashesSumIdDesc = 'CRASHES_SUM_ID_DESC',
  CrashesSumLoggerInfoAsc = 'CRASHES_SUM_LOGGER_INFO_ASC',
  CrashesSumLoggerInfoDesc = 'CRASHES_SUM_LOGGER_INFO_DESC',
  CrashesSumMessageAsc = 'CRASHES_SUM_MESSAGE_ASC',
  CrashesSumMessageDesc = 'CRASHES_SUM_MESSAGE_DESC',
  CrashesSumOsVersionAsc = 'CRASHES_SUM_OS_VERSION_ASC',
  CrashesSumOsVersionDesc = 'CRASHES_SUM_OS_VERSION_DESC',
  CrashesSumOutbankUserIdAsc = 'CRASHES_SUM_OUTBANK_USER_ID_ASC',
  CrashesSumOutbankUserIdDesc = 'CRASHES_SUM_OUTBANK_USER_ID_DESC',
  CrashesSumStateAsc = 'CRASHES_SUM_STATE_ASC',
  CrashesSumStateDesc = 'CRASHES_SUM_STATE_DESC',
  CrashesSumUpdatedAtAsc = 'CRASHES_SUM_UPDATED_AT_ASC',
  CrashesSumUpdatedAtDesc = 'CRASHES_SUM_UPDATED_AT_DESC',
  CrashesVariancePopulationAppVersionAsc = 'CRASHES_VARIANCE_POPULATION_APP_VERSION_ASC',
  CrashesVariancePopulationAppVersionDesc = 'CRASHES_VARIANCE_POPULATION_APP_VERSION_DESC',
  CrashesVariancePopulationCallMethodAsc = 'CRASHES_VARIANCE_POPULATION_CALL_METHOD_ASC',
  CrashesVariancePopulationCallMethodDesc = 'CRASHES_VARIANCE_POPULATION_CALL_METHOD_DESC',
  CrashesVariancePopulationCrashGroupIdAsc = 'CRASHES_VARIANCE_POPULATION_CRASH_GROUP_ID_ASC',
  CrashesVariancePopulationCrashGroupIdDesc = 'CRASHES_VARIANCE_POPULATION_CRASH_GROUP_ID_DESC',
  CrashesVariancePopulationCreatedAtAsc = 'CRASHES_VARIANCE_POPULATION_CREATED_AT_ASC',
  CrashesVariancePopulationCreatedAtDesc = 'CRASHES_VARIANCE_POPULATION_CREATED_AT_DESC',
  CrashesVariancePopulationFileAsc = 'CRASHES_VARIANCE_POPULATION_FILE_ASC',
  CrashesVariancePopulationFileDesc = 'CRASHES_VARIANCE_POPULATION_FILE_DESC',
  CrashesVariancePopulationFileLineNumberAsc = 'CRASHES_VARIANCE_POPULATION_FILE_LINE_NUMBER_ASC',
  CrashesVariancePopulationFileLineNumberDesc = 'CRASHES_VARIANCE_POPULATION_FILE_LINE_NUMBER_DESC',
  CrashesVariancePopulationFileNameAsc = 'CRASHES_VARIANCE_POPULATION_FILE_NAME_ASC',
  CrashesVariancePopulationFileNameDesc = 'CRASHES_VARIANCE_POPULATION_FILE_NAME_DESC',
  CrashesVariancePopulationFileVersionAsc = 'CRASHES_VARIANCE_POPULATION_FILE_VERSION_ASC',
  CrashesVariancePopulationFileVersionDesc = 'CRASHES_VARIANCE_POPULATION_FILE_VERSION_DESC',
  CrashesVariancePopulationIdAsc = 'CRASHES_VARIANCE_POPULATION_ID_ASC',
  CrashesVariancePopulationIdDesc = 'CRASHES_VARIANCE_POPULATION_ID_DESC',
  CrashesVariancePopulationLoggerInfoAsc = 'CRASHES_VARIANCE_POPULATION_LOGGER_INFO_ASC',
  CrashesVariancePopulationLoggerInfoDesc = 'CRASHES_VARIANCE_POPULATION_LOGGER_INFO_DESC',
  CrashesVariancePopulationMessageAsc = 'CRASHES_VARIANCE_POPULATION_MESSAGE_ASC',
  CrashesVariancePopulationMessageDesc = 'CRASHES_VARIANCE_POPULATION_MESSAGE_DESC',
  CrashesVariancePopulationOsVersionAsc = 'CRASHES_VARIANCE_POPULATION_OS_VERSION_ASC',
  CrashesVariancePopulationOsVersionDesc = 'CRASHES_VARIANCE_POPULATION_OS_VERSION_DESC',
  CrashesVariancePopulationOutbankUserIdAsc = 'CRASHES_VARIANCE_POPULATION_OUTBANK_USER_ID_ASC',
  CrashesVariancePopulationOutbankUserIdDesc = 'CRASHES_VARIANCE_POPULATION_OUTBANK_USER_ID_DESC',
  CrashesVariancePopulationStateAsc = 'CRASHES_VARIANCE_POPULATION_STATE_ASC',
  CrashesVariancePopulationStateDesc = 'CRASHES_VARIANCE_POPULATION_STATE_DESC',
  CrashesVariancePopulationUpdatedAtAsc = 'CRASHES_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CrashesVariancePopulationUpdatedAtDesc = 'CRASHES_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CrashesVarianceSampleAppVersionAsc = 'CRASHES_VARIANCE_SAMPLE_APP_VERSION_ASC',
  CrashesVarianceSampleAppVersionDesc = 'CRASHES_VARIANCE_SAMPLE_APP_VERSION_DESC',
  CrashesVarianceSampleCallMethodAsc = 'CRASHES_VARIANCE_SAMPLE_CALL_METHOD_ASC',
  CrashesVarianceSampleCallMethodDesc = 'CRASHES_VARIANCE_SAMPLE_CALL_METHOD_DESC',
  CrashesVarianceSampleCrashGroupIdAsc = 'CRASHES_VARIANCE_SAMPLE_CRASH_GROUP_ID_ASC',
  CrashesVarianceSampleCrashGroupIdDesc = 'CRASHES_VARIANCE_SAMPLE_CRASH_GROUP_ID_DESC',
  CrashesVarianceSampleCreatedAtAsc = 'CRASHES_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CrashesVarianceSampleCreatedAtDesc = 'CRASHES_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CrashesVarianceSampleFileAsc = 'CRASHES_VARIANCE_SAMPLE_FILE_ASC',
  CrashesVarianceSampleFileDesc = 'CRASHES_VARIANCE_SAMPLE_FILE_DESC',
  CrashesVarianceSampleFileLineNumberAsc = 'CRASHES_VARIANCE_SAMPLE_FILE_LINE_NUMBER_ASC',
  CrashesVarianceSampleFileLineNumberDesc = 'CRASHES_VARIANCE_SAMPLE_FILE_LINE_NUMBER_DESC',
  CrashesVarianceSampleFileNameAsc = 'CRASHES_VARIANCE_SAMPLE_FILE_NAME_ASC',
  CrashesVarianceSampleFileNameDesc = 'CRASHES_VARIANCE_SAMPLE_FILE_NAME_DESC',
  CrashesVarianceSampleFileVersionAsc = 'CRASHES_VARIANCE_SAMPLE_FILE_VERSION_ASC',
  CrashesVarianceSampleFileVersionDesc = 'CRASHES_VARIANCE_SAMPLE_FILE_VERSION_DESC',
  CrashesVarianceSampleIdAsc = 'CRASHES_VARIANCE_SAMPLE_ID_ASC',
  CrashesVarianceSampleIdDesc = 'CRASHES_VARIANCE_SAMPLE_ID_DESC',
  CrashesVarianceSampleLoggerInfoAsc = 'CRASHES_VARIANCE_SAMPLE_LOGGER_INFO_ASC',
  CrashesVarianceSampleLoggerInfoDesc = 'CRASHES_VARIANCE_SAMPLE_LOGGER_INFO_DESC',
  CrashesVarianceSampleMessageAsc = 'CRASHES_VARIANCE_SAMPLE_MESSAGE_ASC',
  CrashesVarianceSampleMessageDesc = 'CRASHES_VARIANCE_SAMPLE_MESSAGE_DESC',
  CrashesVarianceSampleOsVersionAsc = 'CRASHES_VARIANCE_SAMPLE_OS_VERSION_ASC',
  CrashesVarianceSampleOsVersionDesc = 'CRASHES_VARIANCE_SAMPLE_OS_VERSION_DESC',
  CrashesVarianceSampleOutbankUserIdAsc = 'CRASHES_VARIANCE_SAMPLE_OUTBANK_USER_ID_ASC',
  CrashesVarianceSampleOutbankUserIdDesc = 'CRASHES_VARIANCE_SAMPLE_OUTBANK_USER_ID_DESC',
  CrashesVarianceSampleStateAsc = 'CRASHES_VARIANCE_SAMPLE_STATE_ASC',
  CrashesVarianceSampleStateDesc = 'CRASHES_VARIANCE_SAMPLE_STATE_DESC',
  CrashesVarianceSampleUpdatedAtAsc = 'CRASHES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CrashesVarianceSampleUpdatedAtDesc = 'CRASHES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type CrashHavingAverageCrashCreatedAtToIntInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type CrashHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  createdAtToInt?: InputMaybe<CrashHavingAverageCrashCreatedAtToIntInput>;
  fileLineNumber?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type CrashHavingDistinctCountCrashCreatedAtToIntInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type CrashHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  createdAtToInt?: InputMaybe<CrashHavingDistinctCountCrashCreatedAtToIntInput>;
  fileLineNumber?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Crash` aggregates. */
export type CrashHavingInput = {
  AND?: InputMaybe<Array<CrashHavingInput>>;
  OR?: InputMaybe<Array<CrashHavingInput>>;
  average?: InputMaybe<CrashHavingAverageInput>;
  distinctCount?: InputMaybe<CrashHavingDistinctCountInput>;
  max?: InputMaybe<CrashHavingMaxInput>;
  min?: InputMaybe<CrashHavingMinInput>;
  stddevPopulation?: InputMaybe<CrashHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<CrashHavingStddevSampleInput>;
  sum?: InputMaybe<CrashHavingSumInput>;
  variancePopulation?: InputMaybe<CrashHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<CrashHavingVarianceSampleInput>;
};

export type CrashHavingMaxCrashCreatedAtToIntInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type CrashHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  createdAtToInt?: InputMaybe<CrashHavingMaxCrashCreatedAtToIntInput>;
  fileLineNumber?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type CrashHavingMinCrashCreatedAtToIntInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type CrashHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  createdAtToInt?: InputMaybe<CrashHavingMinCrashCreatedAtToIntInput>;
  fileLineNumber?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type CrashHavingStddevPopulationCrashCreatedAtToIntInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type CrashHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  createdAtToInt?: InputMaybe<CrashHavingStddevPopulationCrashCreatedAtToIntInput>;
  fileLineNumber?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type CrashHavingStddevSampleCrashCreatedAtToIntInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type CrashHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  createdAtToInt?: InputMaybe<CrashHavingStddevSampleCrashCreatedAtToIntInput>;
  fileLineNumber?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type CrashHavingSumCrashCreatedAtToIntInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type CrashHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  createdAtToInt?: InputMaybe<CrashHavingSumCrashCreatedAtToIntInput>;
  fileLineNumber?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type CrashHavingVariancePopulationCrashCreatedAtToIntInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type CrashHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  createdAtToInt?: InputMaybe<CrashHavingVariancePopulationCrashCreatedAtToIntInput>;
  fileLineNumber?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type CrashHavingVarianceSampleCrashCreatedAtToIntInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type CrashHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  createdAtToInt?: InputMaybe<CrashHavingVarianceSampleCrashCreatedAtToIntInput>;
  fileLineNumber?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Crash` */
export type CrashInput = {
  appVersion?: InputMaybe<Scalars['String']['input']>;
  callMethod?: InputMaybe<CrashCallMethod>;
  crashGroupId?: InputMaybe<Scalars['UUID']['input']>;
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  file: Scalars['String']['input'];
  fileLineNumber: Scalars['Int']['input'];
  fileName: Scalars['String']['input'];
  fileVersion: Scalars['String']['input'];
  id?: InputMaybe<Scalars['UUID']['input']>;
  loggerInfo: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  osVersion: Scalars['String']['input'];
  outbankUserId?: InputMaybe<Scalars['UUID']['input']>;
  state: StateType;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

export type CrashMaxAggregates = {
  __typename?: 'CrashMaxAggregates';
  /** Maximum of this field across the matching connection. */
  createdAtToInt?: Maybe<Scalars['Int']['output']>;
  /** Maximum of fileLineNumber across the matching connection */
  fileLineNumber?: Maybe<Scalars['Int']['output']>;
};

export type CrashMinAggregates = {
  __typename?: 'CrashMinAggregates';
  /** Minimum of this field across the matching connection. */
  createdAtToInt?: Maybe<Scalars['Int']['output']>;
  /** Minimum of fileLineNumber across the matching connection */
  fileLineNumber?: Maybe<Scalars['Int']['output']>;
};

/** Represents an update to a `Crash`. Fields that are set will be updated. */
export type CrashPatch = {
  appVersion?: InputMaybe<Scalars['String']['input']>;
  callMethod?: InputMaybe<CrashCallMethod>;
  crashGroupId?: InputMaybe<Scalars['UUID']['input']>;
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  file?: InputMaybe<Scalars['String']['input']>;
  fileLineNumber?: InputMaybe<Scalars['Int']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileVersion?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  loggerInfo?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  osVersion?: InputMaybe<Scalars['String']['input']>;
  outbankUserId?: InputMaybe<Scalars['UUID']['input']>;
  state?: InputMaybe<StateType>;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

export type CrashStddevPopulationAggregates = {
  __typename?: 'CrashStddevPopulationAggregates';
  /** Population standard deviation of this field across the matching connection. */
  createdAtToInt?: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of fileLineNumber across the matching connection */
  fileLineNumber?: Maybe<Scalars['BigFloat']['output']>;
};

export type CrashStddevSampleAggregates = {
  __typename?: 'CrashStddevSampleAggregates';
  /** Sample standard deviation of this field across the matching connection. */
  createdAtToInt?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of fileLineNumber across the matching connection */
  fileLineNumber?: Maybe<Scalars['BigFloat']['output']>;
};

export type CrashSumAggregates = {
  __typename?: 'CrashSumAggregates';
  /** Sum of this field across the matching connection. */
  createdAtToInt?: Maybe<Scalars['BigInt']['output']>;
  /** Sum of fileLineNumber across the matching connection */
  fileLineNumber: Scalars['BigInt']['output'];
};

export type CrashVariancePopulationAggregates = {
  __typename?: 'CrashVariancePopulationAggregates';
  /** Population variance of this field across the matching connection. */
  createdAtToInt?: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of fileLineNumber across the matching connection */
  fileLineNumber?: Maybe<Scalars['BigFloat']['output']>;
};

export type CrashVarianceSampleAggregates = {
  __typename?: 'CrashVarianceSampleAggregates';
  /** Sample variance of this field across the matching connection. */
  createdAtToInt?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of fileLineNumber across the matching connection */
  fileLineNumber?: Maybe<Scalars['BigFloat']['output']>;
};

/** A connection to a list of `Crash` values. */
export type CrashesConnection = {
  __typename?: 'CrashesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CrashAggregates>;
  /** A list of edges which contains the `Crash` and cursor to aid in pagination. */
  edges: Array<CrashesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CrashAggregates>>;
  /** A list of `Crash` objects. */
  nodes: Array<Crash>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Crash` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Crash` values. */
export type CrashesConnectionGroupedAggregatesArgs = {
  groupBy: Array<CrashGroupBy>;
  having?: InputMaybe<CrashHavingInput>;
};

/** A `Crash` edge in the connection. */
export type CrashesEdge = {
  __typename?: 'CrashesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Crash` at the end of the edge. */
  node: Crash;
};

/** Methods to use when ordering `Crash`. */
export enum CrashesOrderBy {
  CallMethodAsc = 'CALL_METHOD_ASC',
  CallMethodDesc = 'CALL_METHOD_DESC',
  CrashGroupIdAsc = 'CRASH_GROUP_ID_ASC',
  CrashGroupIdDesc = 'CRASH_GROUP_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  FilesAverageCrashIdAsc = 'FILES_AVERAGE_CRASH_ID_ASC',
  FilesAverageCrashIdDesc = 'FILES_AVERAGE_CRASH_ID_DESC',
  FilesAverageCreatedAtAsc = 'FILES_AVERAGE_CREATED_AT_ASC',
  FilesAverageCreatedAtDesc = 'FILES_AVERAGE_CREATED_AT_DESC',
  FilesAverageDataAsc = 'FILES_AVERAGE_DATA_ASC',
  FilesAverageDataDesc = 'FILES_AVERAGE_DATA_DESC',
  FilesAverageHarIdAsc = 'FILES_AVERAGE_HAR_ID_ASC',
  FilesAverageHarIdDesc = 'FILES_AVERAGE_HAR_ID_DESC',
  FilesAverageIdAsc = 'FILES_AVERAGE_ID_ASC',
  FilesAverageIdDesc = 'FILES_AVERAGE_ID_DESC',
  FilesAverageNameAsc = 'FILES_AVERAGE_NAME_ASC',
  FilesAverageNameDesc = 'FILES_AVERAGE_NAME_DESC',
  FilesAverageProtocolIdAsc = 'FILES_AVERAGE_PROTOCOL_ID_ASC',
  FilesAverageProtocolIdDesc = 'FILES_AVERAGE_PROTOCOL_ID_DESC',
  FilesAverageSizeAsc = 'FILES_AVERAGE_SIZE_ASC',
  FilesAverageSizeDesc = 'FILES_AVERAGE_SIZE_DESC',
  FilesAverageSuffixAsc = 'FILES_AVERAGE_SUFFIX_ASC',
  FilesAverageSuffixDesc = 'FILES_AVERAGE_SUFFIX_DESC',
  FilesAverageUpdatedAtAsc = 'FILES_AVERAGE_UPDATED_AT_ASC',
  FilesAverageUpdatedAtDesc = 'FILES_AVERAGE_UPDATED_AT_DESC',
  FilesCountAsc = 'FILES_COUNT_ASC',
  FilesCountDesc = 'FILES_COUNT_DESC',
  FilesDistinctCountCrashIdAsc = 'FILES_DISTINCT_COUNT_CRASH_ID_ASC',
  FilesDistinctCountCrashIdDesc = 'FILES_DISTINCT_COUNT_CRASH_ID_DESC',
  FilesDistinctCountCreatedAtAsc = 'FILES_DISTINCT_COUNT_CREATED_AT_ASC',
  FilesDistinctCountCreatedAtDesc = 'FILES_DISTINCT_COUNT_CREATED_AT_DESC',
  FilesDistinctCountDataAsc = 'FILES_DISTINCT_COUNT_DATA_ASC',
  FilesDistinctCountDataDesc = 'FILES_DISTINCT_COUNT_DATA_DESC',
  FilesDistinctCountHarIdAsc = 'FILES_DISTINCT_COUNT_HAR_ID_ASC',
  FilesDistinctCountHarIdDesc = 'FILES_DISTINCT_COUNT_HAR_ID_DESC',
  FilesDistinctCountIdAsc = 'FILES_DISTINCT_COUNT_ID_ASC',
  FilesDistinctCountIdDesc = 'FILES_DISTINCT_COUNT_ID_DESC',
  FilesDistinctCountNameAsc = 'FILES_DISTINCT_COUNT_NAME_ASC',
  FilesDistinctCountNameDesc = 'FILES_DISTINCT_COUNT_NAME_DESC',
  FilesDistinctCountProtocolIdAsc = 'FILES_DISTINCT_COUNT_PROTOCOL_ID_ASC',
  FilesDistinctCountProtocolIdDesc = 'FILES_DISTINCT_COUNT_PROTOCOL_ID_DESC',
  FilesDistinctCountSizeAsc = 'FILES_DISTINCT_COUNT_SIZE_ASC',
  FilesDistinctCountSizeDesc = 'FILES_DISTINCT_COUNT_SIZE_DESC',
  FilesDistinctCountSuffixAsc = 'FILES_DISTINCT_COUNT_SUFFIX_ASC',
  FilesDistinctCountSuffixDesc = 'FILES_DISTINCT_COUNT_SUFFIX_DESC',
  FilesDistinctCountUpdatedAtAsc = 'FILES_DISTINCT_COUNT_UPDATED_AT_ASC',
  FilesDistinctCountUpdatedAtDesc = 'FILES_DISTINCT_COUNT_UPDATED_AT_DESC',
  FilesMaxCrashIdAsc = 'FILES_MAX_CRASH_ID_ASC',
  FilesMaxCrashIdDesc = 'FILES_MAX_CRASH_ID_DESC',
  FilesMaxCreatedAtAsc = 'FILES_MAX_CREATED_AT_ASC',
  FilesMaxCreatedAtDesc = 'FILES_MAX_CREATED_AT_DESC',
  FilesMaxDataAsc = 'FILES_MAX_DATA_ASC',
  FilesMaxDataDesc = 'FILES_MAX_DATA_DESC',
  FilesMaxHarIdAsc = 'FILES_MAX_HAR_ID_ASC',
  FilesMaxHarIdDesc = 'FILES_MAX_HAR_ID_DESC',
  FilesMaxIdAsc = 'FILES_MAX_ID_ASC',
  FilesMaxIdDesc = 'FILES_MAX_ID_DESC',
  FilesMaxNameAsc = 'FILES_MAX_NAME_ASC',
  FilesMaxNameDesc = 'FILES_MAX_NAME_DESC',
  FilesMaxProtocolIdAsc = 'FILES_MAX_PROTOCOL_ID_ASC',
  FilesMaxProtocolIdDesc = 'FILES_MAX_PROTOCOL_ID_DESC',
  FilesMaxSizeAsc = 'FILES_MAX_SIZE_ASC',
  FilesMaxSizeDesc = 'FILES_MAX_SIZE_DESC',
  FilesMaxSuffixAsc = 'FILES_MAX_SUFFIX_ASC',
  FilesMaxSuffixDesc = 'FILES_MAX_SUFFIX_DESC',
  FilesMaxUpdatedAtAsc = 'FILES_MAX_UPDATED_AT_ASC',
  FilesMaxUpdatedAtDesc = 'FILES_MAX_UPDATED_AT_DESC',
  FilesMinCrashIdAsc = 'FILES_MIN_CRASH_ID_ASC',
  FilesMinCrashIdDesc = 'FILES_MIN_CRASH_ID_DESC',
  FilesMinCreatedAtAsc = 'FILES_MIN_CREATED_AT_ASC',
  FilesMinCreatedAtDesc = 'FILES_MIN_CREATED_AT_DESC',
  FilesMinDataAsc = 'FILES_MIN_DATA_ASC',
  FilesMinDataDesc = 'FILES_MIN_DATA_DESC',
  FilesMinHarIdAsc = 'FILES_MIN_HAR_ID_ASC',
  FilesMinHarIdDesc = 'FILES_MIN_HAR_ID_DESC',
  FilesMinIdAsc = 'FILES_MIN_ID_ASC',
  FilesMinIdDesc = 'FILES_MIN_ID_DESC',
  FilesMinNameAsc = 'FILES_MIN_NAME_ASC',
  FilesMinNameDesc = 'FILES_MIN_NAME_DESC',
  FilesMinProtocolIdAsc = 'FILES_MIN_PROTOCOL_ID_ASC',
  FilesMinProtocolIdDesc = 'FILES_MIN_PROTOCOL_ID_DESC',
  FilesMinSizeAsc = 'FILES_MIN_SIZE_ASC',
  FilesMinSizeDesc = 'FILES_MIN_SIZE_DESC',
  FilesMinSuffixAsc = 'FILES_MIN_SUFFIX_ASC',
  FilesMinSuffixDesc = 'FILES_MIN_SUFFIX_DESC',
  FilesMinUpdatedAtAsc = 'FILES_MIN_UPDATED_AT_ASC',
  FilesMinUpdatedAtDesc = 'FILES_MIN_UPDATED_AT_DESC',
  FilesStddevPopulationCrashIdAsc = 'FILES_STDDEV_POPULATION_CRASH_ID_ASC',
  FilesStddevPopulationCrashIdDesc = 'FILES_STDDEV_POPULATION_CRASH_ID_DESC',
  FilesStddevPopulationCreatedAtAsc = 'FILES_STDDEV_POPULATION_CREATED_AT_ASC',
  FilesStddevPopulationCreatedAtDesc = 'FILES_STDDEV_POPULATION_CREATED_AT_DESC',
  FilesStddevPopulationDataAsc = 'FILES_STDDEV_POPULATION_DATA_ASC',
  FilesStddevPopulationDataDesc = 'FILES_STDDEV_POPULATION_DATA_DESC',
  FilesStddevPopulationHarIdAsc = 'FILES_STDDEV_POPULATION_HAR_ID_ASC',
  FilesStddevPopulationHarIdDesc = 'FILES_STDDEV_POPULATION_HAR_ID_DESC',
  FilesStddevPopulationIdAsc = 'FILES_STDDEV_POPULATION_ID_ASC',
  FilesStddevPopulationIdDesc = 'FILES_STDDEV_POPULATION_ID_DESC',
  FilesStddevPopulationNameAsc = 'FILES_STDDEV_POPULATION_NAME_ASC',
  FilesStddevPopulationNameDesc = 'FILES_STDDEV_POPULATION_NAME_DESC',
  FilesStddevPopulationProtocolIdAsc = 'FILES_STDDEV_POPULATION_PROTOCOL_ID_ASC',
  FilesStddevPopulationProtocolIdDesc = 'FILES_STDDEV_POPULATION_PROTOCOL_ID_DESC',
  FilesStddevPopulationSizeAsc = 'FILES_STDDEV_POPULATION_SIZE_ASC',
  FilesStddevPopulationSizeDesc = 'FILES_STDDEV_POPULATION_SIZE_DESC',
  FilesStddevPopulationSuffixAsc = 'FILES_STDDEV_POPULATION_SUFFIX_ASC',
  FilesStddevPopulationSuffixDesc = 'FILES_STDDEV_POPULATION_SUFFIX_DESC',
  FilesStddevPopulationUpdatedAtAsc = 'FILES_STDDEV_POPULATION_UPDATED_AT_ASC',
  FilesStddevPopulationUpdatedAtDesc = 'FILES_STDDEV_POPULATION_UPDATED_AT_DESC',
  FilesStddevSampleCrashIdAsc = 'FILES_STDDEV_SAMPLE_CRASH_ID_ASC',
  FilesStddevSampleCrashIdDesc = 'FILES_STDDEV_SAMPLE_CRASH_ID_DESC',
  FilesStddevSampleCreatedAtAsc = 'FILES_STDDEV_SAMPLE_CREATED_AT_ASC',
  FilesStddevSampleCreatedAtDesc = 'FILES_STDDEV_SAMPLE_CREATED_AT_DESC',
  FilesStddevSampleDataAsc = 'FILES_STDDEV_SAMPLE_DATA_ASC',
  FilesStddevSampleDataDesc = 'FILES_STDDEV_SAMPLE_DATA_DESC',
  FilesStddevSampleHarIdAsc = 'FILES_STDDEV_SAMPLE_HAR_ID_ASC',
  FilesStddevSampleHarIdDesc = 'FILES_STDDEV_SAMPLE_HAR_ID_DESC',
  FilesStddevSampleIdAsc = 'FILES_STDDEV_SAMPLE_ID_ASC',
  FilesStddevSampleIdDesc = 'FILES_STDDEV_SAMPLE_ID_DESC',
  FilesStddevSampleNameAsc = 'FILES_STDDEV_SAMPLE_NAME_ASC',
  FilesStddevSampleNameDesc = 'FILES_STDDEV_SAMPLE_NAME_DESC',
  FilesStddevSampleProtocolIdAsc = 'FILES_STDDEV_SAMPLE_PROTOCOL_ID_ASC',
  FilesStddevSampleProtocolIdDesc = 'FILES_STDDEV_SAMPLE_PROTOCOL_ID_DESC',
  FilesStddevSampleSizeAsc = 'FILES_STDDEV_SAMPLE_SIZE_ASC',
  FilesStddevSampleSizeDesc = 'FILES_STDDEV_SAMPLE_SIZE_DESC',
  FilesStddevSampleSuffixAsc = 'FILES_STDDEV_SAMPLE_SUFFIX_ASC',
  FilesStddevSampleSuffixDesc = 'FILES_STDDEV_SAMPLE_SUFFIX_DESC',
  FilesStddevSampleUpdatedAtAsc = 'FILES_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FilesStddevSampleUpdatedAtDesc = 'FILES_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FilesSumCrashIdAsc = 'FILES_SUM_CRASH_ID_ASC',
  FilesSumCrashIdDesc = 'FILES_SUM_CRASH_ID_DESC',
  FilesSumCreatedAtAsc = 'FILES_SUM_CREATED_AT_ASC',
  FilesSumCreatedAtDesc = 'FILES_SUM_CREATED_AT_DESC',
  FilesSumDataAsc = 'FILES_SUM_DATA_ASC',
  FilesSumDataDesc = 'FILES_SUM_DATA_DESC',
  FilesSumHarIdAsc = 'FILES_SUM_HAR_ID_ASC',
  FilesSumHarIdDesc = 'FILES_SUM_HAR_ID_DESC',
  FilesSumIdAsc = 'FILES_SUM_ID_ASC',
  FilesSumIdDesc = 'FILES_SUM_ID_DESC',
  FilesSumNameAsc = 'FILES_SUM_NAME_ASC',
  FilesSumNameDesc = 'FILES_SUM_NAME_DESC',
  FilesSumProtocolIdAsc = 'FILES_SUM_PROTOCOL_ID_ASC',
  FilesSumProtocolIdDesc = 'FILES_SUM_PROTOCOL_ID_DESC',
  FilesSumSizeAsc = 'FILES_SUM_SIZE_ASC',
  FilesSumSizeDesc = 'FILES_SUM_SIZE_DESC',
  FilesSumSuffixAsc = 'FILES_SUM_SUFFIX_ASC',
  FilesSumSuffixDesc = 'FILES_SUM_SUFFIX_DESC',
  FilesSumUpdatedAtAsc = 'FILES_SUM_UPDATED_AT_ASC',
  FilesSumUpdatedAtDesc = 'FILES_SUM_UPDATED_AT_DESC',
  FilesVariancePopulationCrashIdAsc = 'FILES_VARIANCE_POPULATION_CRASH_ID_ASC',
  FilesVariancePopulationCrashIdDesc = 'FILES_VARIANCE_POPULATION_CRASH_ID_DESC',
  FilesVariancePopulationCreatedAtAsc = 'FILES_VARIANCE_POPULATION_CREATED_AT_ASC',
  FilesVariancePopulationCreatedAtDesc = 'FILES_VARIANCE_POPULATION_CREATED_AT_DESC',
  FilesVariancePopulationDataAsc = 'FILES_VARIANCE_POPULATION_DATA_ASC',
  FilesVariancePopulationDataDesc = 'FILES_VARIANCE_POPULATION_DATA_DESC',
  FilesVariancePopulationHarIdAsc = 'FILES_VARIANCE_POPULATION_HAR_ID_ASC',
  FilesVariancePopulationHarIdDesc = 'FILES_VARIANCE_POPULATION_HAR_ID_DESC',
  FilesVariancePopulationIdAsc = 'FILES_VARIANCE_POPULATION_ID_ASC',
  FilesVariancePopulationIdDesc = 'FILES_VARIANCE_POPULATION_ID_DESC',
  FilesVariancePopulationNameAsc = 'FILES_VARIANCE_POPULATION_NAME_ASC',
  FilesVariancePopulationNameDesc = 'FILES_VARIANCE_POPULATION_NAME_DESC',
  FilesVariancePopulationProtocolIdAsc = 'FILES_VARIANCE_POPULATION_PROTOCOL_ID_ASC',
  FilesVariancePopulationProtocolIdDesc = 'FILES_VARIANCE_POPULATION_PROTOCOL_ID_DESC',
  FilesVariancePopulationSizeAsc = 'FILES_VARIANCE_POPULATION_SIZE_ASC',
  FilesVariancePopulationSizeDesc = 'FILES_VARIANCE_POPULATION_SIZE_DESC',
  FilesVariancePopulationSuffixAsc = 'FILES_VARIANCE_POPULATION_SUFFIX_ASC',
  FilesVariancePopulationSuffixDesc = 'FILES_VARIANCE_POPULATION_SUFFIX_DESC',
  FilesVariancePopulationUpdatedAtAsc = 'FILES_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FilesVariancePopulationUpdatedAtDesc = 'FILES_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FilesVarianceSampleCrashIdAsc = 'FILES_VARIANCE_SAMPLE_CRASH_ID_ASC',
  FilesVarianceSampleCrashIdDesc = 'FILES_VARIANCE_SAMPLE_CRASH_ID_DESC',
  FilesVarianceSampleCreatedAtAsc = 'FILES_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FilesVarianceSampleCreatedAtDesc = 'FILES_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FilesVarianceSampleDataAsc = 'FILES_VARIANCE_SAMPLE_DATA_ASC',
  FilesVarianceSampleDataDesc = 'FILES_VARIANCE_SAMPLE_DATA_DESC',
  FilesVarianceSampleHarIdAsc = 'FILES_VARIANCE_SAMPLE_HAR_ID_ASC',
  FilesVarianceSampleHarIdDesc = 'FILES_VARIANCE_SAMPLE_HAR_ID_DESC',
  FilesVarianceSampleIdAsc = 'FILES_VARIANCE_SAMPLE_ID_ASC',
  FilesVarianceSampleIdDesc = 'FILES_VARIANCE_SAMPLE_ID_DESC',
  FilesVarianceSampleNameAsc = 'FILES_VARIANCE_SAMPLE_NAME_ASC',
  FilesVarianceSampleNameDesc = 'FILES_VARIANCE_SAMPLE_NAME_DESC',
  FilesVarianceSampleProtocolIdAsc = 'FILES_VARIANCE_SAMPLE_PROTOCOL_ID_ASC',
  FilesVarianceSampleProtocolIdDesc = 'FILES_VARIANCE_SAMPLE_PROTOCOL_ID_DESC',
  FilesVarianceSampleSizeAsc = 'FILES_VARIANCE_SAMPLE_SIZE_ASC',
  FilesVarianceSampleSizeDesc = 'FILES_VARIANCE_SAMPLE_SIZE_DESC',
  FilesVarianceSampleSuffixAsc = 'FILES_VARIANCE_SAMPLE_SUFFIX_ASC',
  FilesVarianceSampleSuffixDesc = 'FILES_VARIANCE_SAMPLE_SUFFIX_DESC',
  FilesVarianceSampleUpdatedAtAsc = 'FILES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FilesVarianceSampleUpdatedAtDesc = 'FILES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FileAsc = 'FILE_ASC',
  FileDesc = 'FILE_DESC',
  FileVersionAsc = 'FILE_VERSION_ASC',
  FileVersionDesc = 'FILE_VERSION_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MessageAsc = 'MESSAGE_ASC',
  MessageDesc = 'MESSAGE_DESC',
  Natural = 'NATURAL',
  OutbankUserIdAsc = 'OUTBANK_USER_ID_ASC',
  OutbankUserIdDesc = 'OUTBANK_USER_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC'
}

/** All input for the create `AppSource` mutation. */
export type CreateAppSourceInput = {
  /** The `AppSource` to be created by this mutation. */
  appSource: AppSourceInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our create `AppSource` mutation. */
export type CreateAppSourcePayload = {
  __typename?: 'CreateAppSourcePayload';
  /** The `AppSource` that was created by this mutation. */
  appSource?: Maybe<AppSource>;
  /** An edge for our `AppSource`. May be used by Relay 1. */
  appSourceEdge?: Maybe<AppSourcesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `AppSource` mutation. */
export type CreateAppSourcePayloadAppSourceEdgeArgs = {
  orderBy?: InputMaybe<Array<AppSourcesOrderBy>>;
};

/** All input for the create `CrashGroup` mutation. */
export type CreateCrashGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `CrashGroup` to be created by this mutation. */
  crashGroup: CrashGroupInput;
};

/** The output of our create `CrashGroup` mutation. */
export type CreateCrashGroupPayload = {
  __typename?: 'CreateCrashGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The `CrashGroup` that was created by this mutation. */
  crashGroup?: Maybe<CrashGroup>;
  /** An edge for our `CrashGroup`. May be used by Relay 1. */
  crashGroupEdge?: Maybe<CrashGroupsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `CrashGroup` mutation. */
export type CreateCrashGroupPayloadCrashGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<CrashGroupsOrderBy>>;
};

/** All input for the create `Crash` mutation. */
export type CreateCrashInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `Crash` to be created by this mutation. */
  crash: CrashInput;
};

/** The output of our create `Crash` mutation. */
export type CreateCrashPayload = {
  __typename?: 'CreateCrashPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The `Crash` that was created by this mutation. */
  crash?: Maybe<Crash>;
  /** An edge for our `Crash`. May be used by Relay 1. */
  crashEdge?: Maybe<CrashesEdge>;
  /** Reads a single `CrashGroup` that is related to this `Crash`. */
  crashGroup?: Maybe<CrashGroup>;
  /** Reads a single `OutbankUser` that is related to this `Crash`. */
  outbankUser?: Maybe<OutbankUser>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `Crash` mutation. */
export type CreateCrashPayloadCrashEdgeArgs = {
  orderBy?: InputMaybe<Array<CrashesOrderBy>>;
};

/** All input for the create `File` mutation. */
export type CreateFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `File` to be created by this mutation. */
  file: FileInput;
};

/** The output of our create `File` mutation. */
export type CreateFilePayload = {
  __typename?: 'CreateFilePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Crash` that is related to this `File`. */
  crash?: Maybe<Crash>;
  /** The `File` that was created by this mutation. */
  file?: Maybe<File>;
  /** An edge for our `File`. May be used by Relay 1. */
  fileEdge?: Maybe<FilesEdge>;
  /** Reads a single `Har` that is related to this `File`. */
  har?: Maybe<Har>;
  /** Reads a single `Protocol` that is related to this `File`. */
  protocol?: Maybe<Protocol>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `File` mutation. */
export type CreateFilePayloadFileEdgeArgs = {
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** All input for the create `Har` mutation. */
export type CreateHarInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `Har` to be created by this mutation. */
  har: HarInput;
};

/** The output of our create `Har` mutation. */
export type CreateHarPayload = {
  __typename?: 'CreateHarPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The `Har` that was created by this mutation. */
  har?: Maybe<Har>;
  /** An edge for our `Har`. May be used by Relay 1. */
  harEdge?: Maybe<HarsEdge>;
  /** Reads a single `OutbankUser` that is related to this `Har`. */
  outbankUser?: Maybe<OutbankUser>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `Har` mutation. */
export type CreateHarPayloadHarEdgeArgs = {
  orderBy?: InputMaybe<Array<HarsOrderBy>>;
};

/** All input for the create `LocalizationLanguage` mutation. */
export type CreateLocalizationLanguageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `LocalizationLanguage` to be created by this mutation. */
  localizationLanguage: LocalizationLanguageInput;
};

/** The output of our create `LocalizationLanguage` mutation. */
export type CreateLocalizationLanguagePayload = {
  __typename?: 'CreateLocalizationLanguagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The `LocalizationLanguage` that was created by this mutation. */
  localizationLanguage?: Maybe<LocalizationLanguage>;
  /** An edge for our `LocalizationLanguage`. May be used by Relay 1. */
  localizationLanguageEdge?: Maybe<LocalizationLanguagesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `LocalizationLanguage` mutation. */
export type CreateLocalizationLanguagePayloadLocalizationLanguageEdgeArgs = {
  orderBy?: InputMaybe<Array<LocalizationLanguagesOrderBy>>;
};

/** All input for the create `LocalizationProject` mutation. */
export type CreateLocalizationProjectInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `LocalizationProject` to be created by this mutation. */
  localizationProject: LocalizationProjectInput;
};

/** The output of our create `LocalizationProject` mutation. */
export type CreateLocalizationProjectPayload = {
  __typename?: 'CreateLocalizationProjectPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The `LocalizationProject` that was created by this mutation. */
  localizationProject?: Maybe<LocalizationProject>;
  /** An edge for our `LocalizationProject`. May be used by Relay 1. */
  localizationProjectEdge?: Maybe<LocalizationProjectsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `LocalizationProject` mutation. */
export type CreateLocalizationProjectPayloadLocalizationProjectEdgeArgs = {
  orderBy?: InputMaybe<Array<LocalizationProjectsOrderBy>>;
};

/** All input for the create `LocalizationProjectXLanguage` mutation. */
export type CreateLocalizationProjectXLanguageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `LocalizationProjectXLanguage` to be created by this mutation. */
  localizationProjectXLanguage: LocalizationProjectXLanguageInput;
};

/** The output of our create `LocalizationProjectXLanguage` mutation. */
export type CreateLocalizationProjectXLanguagePayload = {
  __typename?: 'CreateLocalizationProjectXLanguagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `LocalizationLanguage` that is related to this `LocalizationProjectXLanguage`. */
  localizationLanguage?: Maybe<LocalizationLanguage>;
  /** Reads a single `LocalizationProject` that is related to this `LocalizationProjectXLanguage`. */
  localizationProject?: Maybe<LocalizationProject>;
  /** The `LocalizationProjectXLanguage` that was created by this mutation. */
  localizationProjectXLanguage?: Maybe<LocalizationProjectXLanguage>;
  /** An edge for our `LocalizationProjectXLanguage`. May be used by Relay 1. */
  localizationProjectXLanguageEdge?: Maybe<LocalizationProjectXLanguagesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `LocalizationProjectXLanguage` mutation. */
export type CreateLocalizationProjectXLanguagePayloadLocalizationProjectXLanguageEdgeArgs = {
  orderBy?: InputMaybe<Array<LocalizationProjectXLanguagesOrderBy>>;
};

/** All input for the create `LocalizationTranslationKey` mutation. */
export type CreateLocalizationTranslationKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `LocalizationTranslationKey` to be created by this mutation. */
  localizationTranslationKey: LocalizationTranslationKeyInput;
};

/** The output of our create `LocalizationTranslationKey` mutation. */
export type CreateLocalizationTranslationKeyPayload = {
  __typename?: 'CreateLocalizationTranslationKeyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `LocalizationProject` that is related to this `LocalizationTranslationKey`. */
  localizationProject?: Maybe<LocalizationProject>;
  /** The `LocalizationTranslationKey` that was created by this mutation. */
  localizationTranslationKey?: Maybe<LocalizationTranslationKey>;
  /** An edge for our `LocalizationTranslationKey`. May be used by Relay 1. */
  localizationTranslationKeyEdge?: Maybe<LocalizationTranslationKeysEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `LocalizationTranslationKey` mutation. */
export type CreateLocalizationTranslationKeyPayloadLocalizationTranslationKeyEdgeArgs = {
  orderBy?: InputMaybe<Array<LocalizationTranslationKeysOrderBy>>;
};

/** All input for the create `LocalizationTranslationValue` mutation. */
export type CreateLocalizationTranslationValueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `LocalizationTranslationValue` to be created by this mutation. */
  localizationTranslationValue: LocalizationTranslationValueInput;
};

/** The output of our create `LocalizationTranslationValue` mutation. */
export type CreateLocalizationTranslationValuePayload = {
  __typename?: 'CreateLocalizationTranslationValuePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `LocalizationLanguage` that is related to this `LocalizationTranslationValue`. */
  localizationLanguage?: Maybe<LocalizationLanguage>;
  /** Reads a single `LocalizationTranslationKey` that is related to this `LocalizationTranslationValue`. */
  localizationTranslationKey?: Maybe<LocalizationTranslationKey>;
  /** The `LocalizationTranslationValue` that was created by this mutation. */
  localizationTranslationValue?: Maybe<LocalizationTranslationValue>;
  /** An edge for our `LocalizationTranslationValue`. May be used by Relay 1. */
  localizationTranslationValueEdge?: Maybe<LocalizationTranslationValuesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `LocalizationTranslationValue` mutation. */
export type CreateLocalizationTranslationValuePayloadLocalizationTranslationValueEdgeArgs = {
  orderBy?: InputMaybe<Array<LocalizationTranslationValuesOrderBy>>;
};

/** All input for the create `OutbankUser` mutation. */
export type CreateOutbankUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `OutbankUser` to be created by this mutation. */
  outbankUser: OutbankUserInput;
};

/** The output of our create `OutbankUser` mutation. */
export type CreateOutbankUserPayload = {
  __typename?: 'CreateOutbankUserPayload';
  /** Reads a single `AppSource` that is related to this `OutbankUser`. */
  appSource?: Maybe<AppSource>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The `OutbankUser` that was created by this mutation. */
  outbankUser?: Maybe<OutbankUser>;
  /** An edge for our `OutbankUser`. May be used by Relay 1. */
  outbankUserEdge?: Maybe<OutbankUsersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `OutbankUser` mutation. */
export type CreateOutbankUserPayloadOutbankUserEdgeArgs = {
  orderBy?: InputMaybe<Array<OutbankUsersOrderBy>>;
};

/** All input for the create `Protocol` mutation. */
export type CreateProtocolInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `Protocol` to be created by this mutation. */
  protocol: ProtocolInput;
};

/** The output of our create `Protocol` mutation. */
export type CreateProtocolPayload = {
  __typename?: 'CreateProtocolPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `OutbankUser` that is related to this `Protocol`. */
  outbankUser?: Maybe<OutbankUser>;
  /** The `Protocol` that was created by this mutation. */
  protocol?: Maybe<Protocol>;
  /** An edge for our `Protocol`. May be used by Relay 1. */
  protocolEdge?: Maybe<ProtocolsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `Protocol` mutation. */
export type CreateProtocolPayloadProtocolEdgeArgs = {
  orderBy?: InputMaybe<Array<ProtocolsOrderBy>>;
};

/** All input for the create `SequelizeMeta` mutation. */
export type CreateSequelizeMetaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `SequelizeMeta` to be created by this mutation. */
  sequelizeMeta: SequelizeMetaInput;
};

/** The output of our create `SequelizeMeta` mutation. */
export type CreateSequelizeMetaPayload = {
  __typename?: 'CreateSequelizeMetaPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SequelizeMeta` that was created by this mutation. */
  sequelizeMeta?: Maybe<SequelizeMeta>;
  /** An edge for our `SequelizeMeta`. May be used by Relay 1. */
  sequelizeMetaEdge?: Maybe<SequelizeMetasEdge>;
};


/** The output of our create `SequelizeMeta` mutation. */
export type CreateSequelizeMetaPayloadSequelizeMetaEdgeArgs = {
  orderBy?: InputMaybe<Array<SequelizeMetasOrderBy>>;
};

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Datetime']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Datetime']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Datetime']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Datetime']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Datetime']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Datetime']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Datetime']['input']>>;
};

/** All input for the `deleteAppSourceByNodeId` mutation. */
export type DeleteAppSourceByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `AppSource` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteAppSource` mutation. */
export type DeleteAppSourceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
};

/** The output of our delete `AppSource` mutation. */
export type DeleteAppSourcePayload = {
  __typename?: 'DeleteAppSourcePayload';
  /** The `AppSource` that was deleted by this mutation. */
  appSource?: Maybe<AppSource>;
  /** An edge for our `AppSource`. May be used by Relay 1. */
  appSourceEdge?: Maybe<AppSourcesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedAppSourceNodeId?: Maybe<Scalars['ID']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `AppSource` mutation. */
export type DeleteAppSourcePayloadAppSourceEdgeArgs = {
  orderBy?: InputMaybe<Array<AppSourcesOrderBy>>;
};

/** All input for the `deleteCrashByNodeId` mutation. */
export type DeleteCrashByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `Crash` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteCrashGroupByNodeId` mutation. */
export type DeleteCrashGroupByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `CrashGroup` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteCrashGroup` mutation. */
export type DeleteCrashGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
};

/** The output of our delete `CrashGroup` mutation. */
export type DeleteCrashGroupPayload = {
  __typename?: 'DeleteCrashGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The `CrashGroup` that was deleted by this mutation. */
  crashGroup?: Maybe<CrashGroup>;
  /** An edge for our `CrashGroup`. May be used by Relay 1. */
  crashGroupEdge?: Maybe<CrashGroupsEdge>;
  deletedCrashGroupNodeId?: Maybe<Scalars['ID']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `CrashGroup` mutation. */
export type DeleteCrashGroupPayloadCrashGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<CrashGroupsOrderBy>>;
};

/** All input for the `deleteCrash` mutation. */
export type DeleteCrashInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
};

/** The output of our delete `Crash` mutation. */
export type DeleteCrashPayload = {
  __typename?: 'DeleteCrashPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The `Crash` that was deleted by this mutation. */
  crash?: Maybe<Crash>;
  /** An edge for our `Crash`. May be used by Relay 1. */
  crashEdge?: Maybe<CrashesEdge>;
  /** Reads a single `CrashGroup` that is related to this `Crash`. */
  crashGroup?: Maybe<CrashGroup>;
  deletedCrashNodeId?: Maybe<Scalars['ID']['output']>;
  /** Reads a single `OutbankUser` that is related to this `Crash`. */
  outbankUser?: Maybe<OutbankUser>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `Crash` mutation. */
export type DeleteCrashPayloadCrashEdgeArgs = {
  orderBy?: InputMaybe<Array<CrashesOrderBy>>;
};

/** All input for the `deleteFileByNodeId` mutation. */
export type DeleteFileByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `File` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteFile` mutation. */
export type DeleteFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
};

/** The output of our delete `File` mutation. */
export type DeleteFilePayload = {
  __typename?: 'DeleteFilePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Crash` that is related to this `File`. */
  crash?: Maybe<Crash>;
  deletedFileNodeId?: Maybe<Scalars['ID']['output']>;
  /** The `File` that was deleted by this mutation. */
  file?: Maybe<File>;
  /** An edge for our `File`. May be used by Relay 1. */
  fileEdge?: Maybe<FilesEdge>;
  /** Reads a single `Har` that is related to this `File`. */
  har?: Maybe<Har>;
  /** Reads a single `Protocol` that is related to this `File`. */
  protocol?: Maybe<Protocol>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `File` mutation. */
export type DeleteFilePayloadFileEdgeArgs = {
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** All input for the `deleteHarByNodeId` mutation. */
export type DeleteHarByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `Har` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteHar` mutation. */
export type DeleteHarInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
};

/** The output of our delete `Har` mutation. */
export type DeleteHarPayload = {
  __typename?: 'DeleteHarPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedHarNodeId?: Maybe<Scalars['ID']['output']>;
  /** The `Har` that was deleted by this mutation. */
  har?: Maybe<Har>;
  /** An edge for our `Har`. May be used by Relay 1. */
  harEdge?: Maybe<HarsEdge>;
  /** Reads a single `OutbankUser` that is related to this `Har`. */
  outbankUser?: Maybe<OutbankUser>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `Har` mutation. */
export type DeleteHarPayloadHarEdgeArgs = {
  orderBy?: InputMaybe<Array<HarsOrderBy>>;
};

/** All input for the `deleteLocalizationLanguageByNodeId` mutation. */
export type DeleteLocalizationLanguageByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `LocalizationLanguage` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteLocalizationLanguage` mutation. */
export type DeleteLocalizationLanguageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
};

/** The output of our delete `LocalizationLanguage` mutation. */
export type DeleteLocalizationLanguagePayload = {
  __typename?: 'DeleteLocalizationLanguagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedLocalizationLanguageNodeId?: Maybe<Scalars['ID']['output']>;
  /** The `LocalizationLanguage` that was deleted by this mutation. */
  localizationLanguage?: Maybe<LocalizationLanguage>;
  /** An edge for our `LocalizationLanguage`. May be used by Relay 1. */
  localizationLanguageEdge?: Maybe<LocalizationLanguagesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `LocalizationLanguage` mutation. */
export type DeleteLocalizationLanguagePayloadLocalizationLanguageEdgeArgs = {
  orderBy?: InputMaybe<Array<LocalizationLanguagesOrderBy>>;
};

/** All input for the `deleteLocalizationProjectByNodeId` mutation. */
export type DeleteLocalizationProjectByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `LocalizationProject` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteLocalizationProject` mutation. */
export type DeleteLocalizationProjectInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
};

/** The output of our delete `LocalizationProject` mutation. */
export type DeleteLocalizationProjectPayload = {
  __typename?: 'DeleteLocalizationProjectPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedLocalizationProjectNodeId?: Maybe<Scalars['ID']['output']>;
  /** The `LocalizationProject` that was deleted by this mutation. */
  localizationProject?: Maybe<LocalizationProject>;
  /** An edge for our `LocalizationProject`. May be used by Relay 1. */
  localizationProjectEdge?: Maybe<LocalizationProjectsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `LocalizationProject` mutation. */
export type DeleteLocalizationProjectPayloadLocalizationProjectEdgeArgs = {
  orderBy?: InputMaybe<Array<LocalizationProjectsOrderBy>>;
};

/** All input for the `deleteLocalizationProjectXLanguageByNodeId` mutation. */
export type DeleteLocalizationProjectXLanguageByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `LocalizationProjectXLanguage` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteLocalizationProjectXLanguage` mutation. */
export type DeleteLocalizationProjectXLanguageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
};

/** The output of our delete `LocalizationProjectXLanguage` mutation. */
export type DeleteLocalizationProjectXLanguagePayload = {
  __typename?: 'DeleteLocalizationProjectXLanguagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedLocalizationProjectXLanguageNodeId?: Maybe<Scalars['ID']['output']>;
  /** Reads a single `LocalizationLanguage` that is related to this `LocalizationProjectXLanguage`. */
  localizationLanguage?: Maybe<LocalizationLanguage>;
  /** Reads a single `LocalizationProject` that is related to this `LocalizationProjectXLanguage`. */
  localizationProject?: Maybe<LocalizationProject>;
  /** The `LocalizationProjectXLanguage` that was deleted by this mutation. */
  localizationProjectXLanguage?: Maybe<LocalizationProjectXLanguage>;
  /** An edge for our `LocalizationProjectXLanguage`. May be used by Relay 1. */
  localizationProjectXLanguageEdge?: Maybe<LocalizationProjectXLanguagesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `LocalizationProjectXLanguage` mutation. */
export type DeleteLocalizationProjectXLanguagePayloadLocalizationProjectXLanguageEdgeArgs = {
  orderBy?: InputMaybe<Array<LocalizationProjectXLanguagesOrderBy>>;
};

/** All input for the `deleteLocalizationTranslationKeyByNodeId` mutation. */
export type DeleteLocalizationTranslationKeyByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `LocalizationTranslationKey` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteLocalizationTranslationKey` mutation. */
export type DeleteLocalizationTranslationKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
};

/** The output of our delete `LocalizationTranslationKey` mutation. */
export type DeleteLocalizationTranslationKeyPayload = {
  __typename?: 'DeleteLocalizationTranslationKeyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedLocalizationTranslationKeyNodeId?: Maybe<Scalars['ID']['output']>;
  /** Reads a single `LocalizationProject` that is related to this `LocalizationTranslationKey`. */
  localizationProject?: Maybe<LocalizationProject>;
  /** The `LocalizationTranslationKey` that was deleted by this mutation. */
  localizationTranslationKey?: Maybe<LocalizationTranslationKey>;
  /** An edge for our `LocalizationTranslationKey`. May be used by Relay 1. */
  localizationTranslationKeyEdge?: Maybe<LocalizationTranslationKeysEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `LocalizationTranslationKey` mutation. */
export type DeleteLocalizationTranslationKeyPayloadLocalizationTranslationKeyEdgeArgs = {
  orderBy?: InputMaybe<Array<LocalizationTranslationKeysOrderBy>>;
};

/** All input for the `deleteLocalizationTranslationValueByNodeId` mutation. */
export type DeleteLocalizationTranslationValueByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `LocalizationTranslationValue` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteLocalizationTranslationValue` mutation. */
export type DeleteLocalizationTranslationValueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
};

/** The output of our delete `LocalizationTranslationValue` mutation. */
export type DeleteLocalizationTranslationValuePayload = {
  __typename?: 'DeleteLocalizationTranslationValuePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedLocalizationTranslationValueNodeId?: Maybe<Scalars['ID']['output']>;
  /** Reads a single `LocalizationLanguage` that is related to this `LocalizationTranslationValue`. */
  localizationLanguage?: Maybe<LocalizationLanguage>;
  /** Reads a single `LocalizationTranslationKey` that is related to this `LocalizationTranslationValue`. */
  localizationTranslationKey?: Maybe<LocalizationTranslationKey>;
  /** The `LocalizationTranslationValue` that was deleted by this mutation. */
  localizationTranslationValue?: Maybe<LocalizationTranslationValue>;
  /** An edge for our `LocalizationTranslationValue`. May be used by Relay 1. */
  localizationTranslationValueEdge?: Maybe<LocalizationTranslationValuesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `LocalizationTranslationValue` mutation. */
export type DeleteLocalizationTranslationValuePayloadLocalizationTranslationValueEdgeArgs = {
  orderBy?: InputMaybe<Array<LocalizationTranslationValuesOrderBy>>;
};

/** All input for the `deleteOutbankUserByNodeId` mutation. */
export type DeleteOutbankUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `OutbankUser` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteOutbankUser` mutation. */
export type DeleteOutbankUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
};

/** The output of our delete `OutbankUser` mutation. */
export type DeleteOutbankUserPayload = {
  __typename?: 'DeleteOutbankUserPayload';
  /** Reads a single `AppSource` that is related to this `OutbankUser`. */
  appSource?: Maybe<AppSource>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedOutbankUserNodeId?: Maybe<Scalars['ID']['output']>;
  /** The `OutbankUser` that was deleted by this mutation. */
  outbankUser?: Maybe<OutbankUser>;
  /** An edge for our `OutbankUser`. May be used by Relay 1. */
  outbankUserEdge?: Maybe<OutbankUsersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `OutbankUser` mutation. */
export type DeleteOutbankUserPayloadOutbankUserEdgeArgs = {
  orderBy?: InputMaybe<Array<OutbankUsersOrderBy>>;
};

/** All input for the `deleteProtocolByNodeId` mutation. */
export type DeleteProtocolByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `Protocol` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteProtocol` mutation. */
export type DeleteProtocolInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
};

/** The output of our delete `Protocol` mutation. */
export type DeleteProtocolPayload = {
  __typename?: 'DeleteProtocolPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedProtocolNodeId?: Maybe<Scalars['ID']['output']>;
  /** Reads a single `OutbankUser` that is related to this `Protocol`. */
  outbankUser?: Maybe<OutbankUser>;
  /** The `Protocol` that was deleted by this mutation. */
  protocol?: Maybe<Protocol>;
  /** An edge for our `Protocol`. May be used by Relay 1. */
  protocolEdge?: Maybe<ProtocolsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `Protocol` mutation. */
export type DeleteProtocolPayloadProtocolEdgeArgs = {
  orderBy?: InputMaybe<Array<ProtocolsOrderBy>>;
};

/** All input for the `deleteSequelizeMetaByNodeId` mutation. */
export type DeleteSequelizeMetaByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `SequelizeMeta` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteSequelizeMeta` mutation. */
export type DeleteSequelizeMetaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** The output of our delete `SequelizeMeta` mutation. */
export type DeleteSequelizeMetaPayload = {
  __typename?: 'DeleteSequelizeMetaPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedSequelizeMetaNodeId?: Maybe<Scalars['ID']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SequelizeMeta` that was deleted by this mutation. */
  sequelizeMeta?: Maybe<SequelizeMeta>;
  /** An edge for our `SequelizeMeta`. May be used by Relay 1. */
  sequelizeMetaEdge?: Maybe<SequelizeMetasEdge>;
};


/** The output of our delete `SequelizeMeta` mutation. */
export type DeleteSequelizeMetaPayloadSequelizeMetaEdgeArgs = {
  orderBy?: InputMaybe<Array<SequelizeMetasOrderBy>>;
};

export type File = Node & {
  __typename?: 'File';
  /** Reads a single `Crash` that is related to this `File`. */
  crash?: Maybe<Crash>;
  crashId?: Maybe<Scalars['UUID']['output']>;
  createdAt: Scalars['Datetime']['output'];
  data: Scalars['String']['output'];
  /** Reads a single `Har` that is related to this `File`. */
  har?: Maybe<Har>;
  harId?: Maybe<Scalars['UUID']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `Protocol` that is related to this `File`. */
  protocol?: Maybe<Protocol>;
  protocolId?: Maybe<Scalars['UUID']['output']>;
  size: Scalars['Int']['output'];
  suffix: Scalars['String']['output'];
  updatedAt: Scalars['Datetime']['output'];
};

export type FileAggregates = {
  __typename?: 'FileAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<FileAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<FileDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<FileMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<FileMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<FileStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<FileStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<FileSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<FileVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<FileVarianceSampleAggregates>;
};

export type FileAverageAggregates = {
  __typename?: 'FileAverageAggregates';
  /** Mean average of size across the matching connection */
  size?: Maybe<Scalars['BigFloat']['output']>;
};

/** A condition to be used against `File` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type FileCondition = {
  /** Checks for equality with the object’s `crashId` field. */
  crashId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `harId` field. */
  harId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `protocolId` field. */
  protocolId?: InputMaybe<Scalars['UUID']['input']>;
};

export type FileDistinctCountAggregates = {
  __typename?: 'FileDistinctCountAggregates';
  /** Distinct count of crashId across the matching connection */
  crashId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of data across the matching connection */
  data?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of harId across the matching connection */
  harId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of protocolId across the matching connection */
  protocolId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of size across the matching connection */
  size?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of suffix across the matching connection */
  suffix?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `File` object types. All fields are combined with a logical ‘and.’ */
export type FileFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FileFilter>>;
  /** Filter by the object’s `crashId` field. */
  crashId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `harId` field. */
  harId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FileFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FileFilter>>;
  /** Filter by the object’s `protocolId` field. */
  protocolId?: InputMaybe<UuidFilter>;
};

/** Grouping methods for `File` for usage during aggregation. */
export enum FileGroupBy {
  CrashId = 'CRASH_ID',
  HarId = 'HAR_ID',
  ProtocolId = 'PROTOCOL_ID'
}

export type FileHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  size?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type FileHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  size?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `File` aggregates. */
export type FileHavingInput = {
  AND?: InputMaybe<Array<FileHavingInput>>;
  OR?: InputMaybe<Array<FileHavingInput>>;
  average?: InputMaybe<FileHavingAverageInput>;
  distinctCount?: InputMaybe<FileHavingDistinctCountInput>;
  max?: InputMaybe<FileHavingMaxInput>;
  min?: InputMaybe<FileHavingMinInput>;
  stddevPopulation?: InputMaybe<FileHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<FileHavingStddevSampleInput>;
  sum?: InputMaybe<FileHavingSumInput>;
  variancePopulation?: InputMaybe<FileHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<FileHavingVarianceSampleInput>;
};

export type FileHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  size?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type FileHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  size?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type FileHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  size?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type FileHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  size?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type FileHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  size?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type FileHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  size?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type FileHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  size?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `File` */
export type FileInput = {
  crashId?: InputMaybe<Scalars['UUID']['input']>;
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  data: Scalars['String']['input'];
  harId?: InputMaybe<Scalars['UUID']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
  protocolId?: InputMaybe<Scalars['UUID']['input']>;
  size: Scalars['Int']['input'];
  suffix: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

export type FileMaxAggregates = {
  __typename?: 'FileMaxAggregates';
  /** Maximum of size across the matching connection */
  size?: Maybe<Scalars['Int']['output']>;
};

export type FileMinAggregates = {
  __typename?: 'FileMinAggregates';
  /** Minimum of size across the matching connection */
  size?: Maybe<Scalars['Int']['output']>;
};

/** Represents an update to a `File`. Fields that are set will be updated. */
export type FilePatch = {
  crashId?: InputMaybe<Scalars['UUID']['input']>;
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  data?: InputMaybe<Scalars['String']['input']>;
  harId?: InputMaybe<Scalars['UUID']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  protocolId?: InputMaybe<Scalars['UUID']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  suffix?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

export type FileStddevPopulationAggregates = {
  __typename?: 'FileStddevPopulationAggregates';
  /** Population standard deviation of size across the matching connection */
  size?: Maybe<Scalars['BigFloat']['output']>;
};

export type FileStddevSampleAggregates = {
  __typename?: 'FileStddevSampleAggregates';
  /** Sample standard deviation of size across the matching connection */
  size?: Maybe<Scalars['BigFloat']['output']>;
};

export type FileSumAggregates = {
  __typename?: 'FileSumAggregates';
  /** Sum of size across the matching connection */
  size: Scalars['BigInt']['output'];
};

export type FileVariancePopulationAggregates = {
  __typename?: 'FileVariancePopulationAggregates';
  /** Population variance of size across the matching connection */
  size?: Maybe<Scalars['BigFloat']['output']>;
};

export type FileVarianceSampleAggregates = {
  __typename?: 'FileVarianceSampleAggregates';
  /** Sample variance of size across the matching connection */
  size?: Maybe<Scalars['BigFloat']['output']>;
};

/** A connection to a list of `File` values. */
export type FilesConnection = {
  __typename?: 'FilesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<FileAggregates>;
  /** A list of edges which contains the `File` and cursor to aid in pagination. */
  edges: Array<FilesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<FileAggregates>>;
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `File` values. */
export type FilesConnectionGroupedAggregatesArgs = {
  groupBy: Array<FileGroupBy>;
  having?: InputMaybe<FileHavingInput>;
};

/** A `File` edge in the connection. */
export type FilesEdge = {
  __typename?: 'FilesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `File` at the end of the edge. */
  node: File;
};

/** Methods to use when ordering `File`. */
export enum FilesOrderBy {
  CrashIdAsc = 'CRASH_ID_ASC',
  CrashIdDesc = 'CRASH_ID_DESC',
  HarIdAsc = 'HAR_ID_ASC',
  HarIdDesc = 'HAR_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProtocolIdAsc = 'PROTOCOL_ID_ASC',
  ProtocolIdDesc = 'PROTOCOL_ID_DESC'
}

export type Har = Node & {
  __typename?: 'Har';
  authorizecredentials: Scalars['Boolean']['output'];
  authorizetesting: Scalars['Boolean']['output'];
  bank: Scalars['String']['output'];
  createdAt: Scalars['Datetime']['output'];
  email: Scalars['String']['output'];
  /** Reads and enables pagination through a set of `File`. */
  files: FilesConnection;
  id: Scalars['UUID']['output'];
  issue?: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  /** Reads a single `OutbankUser` that is related to this `Har`. */
  outbankUser?: Maybe<OutbankUser>;
  outbankUserId?: Maybe<Scalars['UUID']['output']>;
  sessionwarning: Scalars['Boolean']['output'];
  since: Scalars['Datetime']['output'];
  state: StateType;
  updatedAt: Scalars['Datetime']['output'];
};


export type HarFilesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FileCondition>;
  filter?: InputMaybe<FileFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

export type HarAggregates = {
  __typename?: 'HarAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<HarDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/** A condition to be used against `Har` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type HarCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `outbankUserId` field. */
  outbankUserId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<StateType>;
};

export type HarDistinctCountAggregates = {
  __typename?: 'HarDistinctCountAggregates';
  /** Distinct count of authorizecredentials across the matching connection */
  authorizecredentials?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of authorizetesting across the matching connection */
  authorizetesting?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of bank across the matching connection */
  bank?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of email across the matching connection */
  email?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of issue across the matching connection */
  issue?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of note across the matching connection */
  note?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of outbankUserId across the matching connection */
  outbankUserId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of sessionwarning across the matching connection */
  sessionwarning?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of since across the matching connection */
  since?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of state across the matching connection */
  state?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `Har` object types. All fields are combined with a logical ‘and.’ */
export type HarFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<HarFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<HarFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<HarFilter>>;
  /** Filter by the object’s `outbankUserId` field. */
  outbankUserId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<StateTypeFilter>;
};

/** Grouping methods for `Har` for usage during aggregation. */
export enum HarGroupBy {
  OutbankUserId = 'OUTBANK_USER_ID',
  State = 'STATE'
}

export type HarHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  since?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type HarHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  since?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Har` aggregates. */
export type HarHavingInput = {
  AND?: InputMaybe<Array<HarHavingInput>>;
  OR?: InputMaybe<Array<HarHavingInput>>;
  average?: InputMaybe<HarHavingAverageInput>;
  distinctCount?: InputMaybe<HarHavingDistinctCountInput>;
  max?: InputMaybe<HarHavingMaxInput>;
  min?: InputMaybe<HarHavingMinInput>;
  stddevPopulation?: InputMaybe<HarHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<HarHavingStddevSampleInput>;
  sum?: InputMaybe<HarHavingSumInput>;
  variancePopulation?: InputMaybe<HarHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<HarHavingVarianceSampleInput>;
};

export type HarHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  since?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type HarHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  since?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type HarHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  since?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type HarHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  since?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type HarHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  since?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type HarHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  since?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type HarHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  since?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Har` */
export type HarInput = {
  authorizecredentials: Scalars['Boolean']['input'];
  authorizetesting: Scalars['Boolean']['input'];
  bank: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['UUID']['input']>;
  issue?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  outbankUserId?: InputMaybe<Scalars['UUID']['input']>;
  sessionwarning: Scalars['Boolean']['input'];
  since: Scalars['Datetime']['input'];
  state: StateType;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** Represents an update to a `Har`. Fields that are set will be updated. */
export type HarPatch = {
  authorizecredentials?: InputMaybe<Scalars['Boolean']['input']>;
  authorizetesting?: InputMaybe<Scalars['Boolean']['input']>;
  bank?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  issue?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  outbankUserId?: InputMaybe<Scalars['UUID']['input']>;
  sessionwarning?: InputMaybe<Scalars['Boolean']['input']>;
  since?: InputMaybe<Scalars['Datetime']['input']>;
  state?: InputMaybe<StateType>;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A connection to a list of `Har` values. */
export type HarsConnection = {
  __typename?: 'HarsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<HarAggregates>;
  /** A list of edges which contains the `Har` and cursor to aid in pagination. */
  edges: Array<HarsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<HarAggregates>>;
  /** A list of `Har` objects. */
  nodes: Array<Har>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Har` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Har` values. */
export type HarsConnectionGroupedAggregatesArgs = {
  groupBy: Array<HarGroupBy>;
  having?: InputMaybe<HarHavingInput>;
};

/** A `Har` edge in the connection. */
export type HarsEdge = {
  __typename?: 'HarsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Har` at the end of the edge. */
  node: Har;
};

/** Methods to use when ordering `Har`. */
export enum HarsOrderBy {
  FilesAverageCrashIdAsc = 'FILES_AVERAGE_CRASH_ID_ASC',
  FilesAverageCrashIdDesc = 'FILES_AVERAGE_CRASH_ID_DESC',
  FilesAverageCreatedAtAsc = 'FILES_AVERAGE_CREATED_AT_ASC',
  FilesAverageCreatedAtDesc = 'FILES_AVERAGE_CREATED_AT_DESC',
  FilesAverageDataAsc = 'FILES_AVERAGE_DATA_ASC',
  FilesAverageDataDesc = 'FILES_AVERAGE_DATA_DESC',
  FilesAverageHarIdAsc = 'FILES_AVERAGE_HAR_ID_ASC',
  FilesAverageHarIdDesc = 'FILES_AVERAGE_HAR_ID_DESC',
  FilesAverageIdAsc = 'FILES_AVERAGE_ID_ASC',
  FilesAverageIdDesc = 'FILES_AVERAGE_ID_DESC',
  FilesAverageNameAsc = 'FILES_AVERAGE_NAME_ASC',
  FilesAverageNameDesc = 'FILES_AVERAGE_NAME_DESC',
  FilesAverageProtocolIdAsc = 'FILES_AVERAGE_PROTOCOL_ID_ASC',
  FilesAverageProtocolIdDesc = 'FILES_AVERAGE_PROTOCOL_ID_DESC',
  FilesAverageSizeAsc = 'FILES_AVERAGE_SIZE_ASC',
  FilesAverageSizeDesc = 'FILES_AVERAGE_SIZE_DESC',
  FilesAverageSuffixAsc = 'FILES_AVERAGE_SUFFIX_ASC',
  FilesAverageSuffixDesc = 'FILES_AVERAGE_SUFFIX_DESC',
  FilesAverageUpdatedAtAsc = 'FILES_AVERAGE_UPDATED_AT_ASC',
  FilesAverageUpdatedAtDesc = 'FILES_AVERAGE_UPDATED_AT_DESC',
  FilesCountAsc = 'FILES_COUNT_ASC',
  FilesCountDesc = 'FILES_COUNT_DESC',
  FilesDistinctCountCrashIdAsc = 'FILES_DISTINCT_COUNT_CRASH_ID_ASC',
  FilesDistinctCountCrashIdDesc = 'FILES_DISTINCT_COUNT_CRASH_ID_DESC',
  FilesDistinctCountCreatedAtAsc = 'FILES_DISTINCT_COUNT_CREATED_AT_ASC',
  FilesDistinctCountCreatedAtDesc = 'FILES_DISTINCT_COUNT_CREATED_AT_DESC',
  FilesDistinctCountDataAsc = 'FILES_DISTINCT_COUNT_DATA_ASC',
  FilesDistinctCountDataDesc = 'FILES_DISTINCT_COUNT_DATA_DESC',
  FilesDistinctCountHarIdAsc = 'FILES_DISTINCT_COUNT_HAR_ID_ASC',
  FilesDistinctCountHarIdDesc = 'FILES_DISTINCT_COUNT_HAR_ID_DESC',
  FilesDistinctCountIdAsc = 'FILES_DISTINCT_COUNT_ID_ASC',
  FilesDistinctCountIdDesc = 'FILES_DISTINCT_COUNT_ID_DESC',
  FilesDistinctCountNameAsc = 'FILES_DISTINCT_COUNT_NAME_ASC',
  FilesDistinctCountNameDesc = 'FILES_DISTINCT_COUNT_NAME_DESC',
  FilesDistinctCountProtocolIdAsc = 'FILES_DISTINCT_COUNT_PROTOCOL_ID_ASC',
  FilesDistinctCountProtocolIdDesc = 'FILES_DISTINCT_COUNT_PROTOCOL_ID_DESC',
  FilesDistinctCountSizeAsc = 'FILES_DISTINCT_COUNT_SIZE_ASC',
  FilesDistinctCountSizeDesc = 'FILES_DISTINCT_COUNT_SIZE_DESC',
  FilesDistinctCountSuffixAsc = 'FILES_DISTINCT_COUNT_SUFFIX_ASC',
  FilesDistinctCountSuffixDesc = 'FILES_DISTINCT_COUNT_SUFFIX_DESC',
  FilesDistinctCountUpdatedAtAsc = 'FILES_DISTINCT_COUNT_UPDATED_AT_ASC',
  FilesDistinctCountUpdatedAtDesc = 'FILES_DISTINCT_COUNT_UPDATED_AT_DESC',
  FilesMaxCrashIdAsc = 'FILES_MAX_CRASH_ID_ASC',
  FilesMaxCrashIdDesc = 'FILES_MAX_CRASH_ID_DESC',
  FilesMaxCreatedAtAsc = 'FILES_MAX_CREATED_AT_ASC',
  FilesMaxCreatedAtDesc = 'FILES_MAX_CREATED_AT_DESC',
  FilesMaxDataAsc = 'FILES_MAX_DATA_ASC',
  FilesMaxDataDesc = 'FILES_MAX_DATA_DESC',
  FilesMaxHarIdAsc = 'FILES_MAX_HAR_ID_ASC',
  FilesMaxHarIdDesc = 'FILES_MAX_HAR_ID_DESC',
  FilesMaxIdAsc = 'FILES_MAX_ID_ASC',
  FilesMaxIdDesc = 'FILES_MAX_ID_DESC',
  FilesMaxNameAsc = 'FILES_MAX_NAME_ASC',
  FilesMaxNameDesc = 'FILES_MAX_NAME_DESC',
  FilesMaxProtocolIdAsc = 'FILES_MAX_PROTOCOL_ID_ASC',
  FilesMaxProtocolIdDesc = 'FILES_MAX_PROTOCOL_ID_DESC',
  FilesMaxSizeAsc = 'FILES_MAX_SIZE_ASC',
  FilesMaxSizeDesc = 'FILES_MAX_SIZE_DESC',
  FilesMaxSuffixAsc = 'FILES_MAX_SUFFIX_ASC',
  FilesMaxSuffixDesc = 'FILES_MAX_SUFFIX_DESC',
  FilesMaxUpdatedAtAsc = 'FILES_MAX_UPDATED_AT_ASC',
  FilesMaxUpdatedAtDesc = 'FILES_MAX_UPDATED_AT_DESC',
  FilesMinCrashIdAsc = 'FILES_MIN_CRASH_ID_ASC',
  FilesMinCrashIdDesc = 'FILES_MIN_CRASH_ID_DESC',
  FilesMinCreatedAtAsc = 'FILES_MIN_CREATED_AT_ASC',
  FilesMinCreatedAtDesc = 'FILES_MIN_CREATED_AT_DESC',
  FilesMinDataAsc = 'FILES_MIN_DATA_ASC',
  FilesMinDataDesc = 'FILES_MIN_DATA_DESC',
  FilesMinHarIdAsc = 'FILES_MIN_HAR_ID_ASC',
  FilesMinHarIdDesc = 'FILES_MIN_HAR_ID_DESC',
  FilesMinIdAsc = 'FILES_MIN_ID_ASC',
  FilesMinIdDesc = 'FILES_MIN_ID_DESC',
  FilesMinNameAsc = 'FILES_MIN_NAME_ASC',
  FilesMinNameDesc = 'FILES_MIN_NAME_DESC',
  FilesMinProtocolIdAsc = 'FILES_MIN_PROTOCOL_ID_ASC',
  FilesMinProtocolIdDesc = 'FILES_MIN_PROTOCOL_ID_DESC',
  FilesMinSizeAsc = 'FILES_MIN_SIZE_ASC',
  FilesMinSizeDesc = 'FILES_MIN_SIZE_DESC',
  FilesMinSuffixAsc = 'FILES_MIN_SUFFIX_ASC',
  FilesMinSuffixDesc = 'FILES_MIN_SUFFIX_DESC',
  FilesMinUpdatedAtAsc = 'FILES_MIN_UPDATED_AT_ASC',
  FilesMinUpdatedAtDesc = 'FILES_MIN_UPDATED_AT_DESC',
  FilesStddevPopulationCrashIdAsc = 'FILES_STDDEV_POPULATION_CRASH_ID_ASC',
  FilesStddevPopulationCrashIdDesc = 'FILES_STDDEV_POPULATION_CRASH_ID_DESC',
  FilesStddevPopulationCreatedAtAsc = 'FILES_STDDEV_POPULATION_CREATED_AT_ASC',
  FilesStddevPopulationCreatedAtDesc = 'FILES_STDDEV_POPULATION_CREATED_AT_DESC',
  FilesStddevPopulationDataAsc = 'FILES_STDDEV_POPULATION_DATA_ASC',
  FilesStddevPopulationDataDesc = 'FILES_STDDEV_POPULATION_DATA_DESC',
  FilesStddevPopulationHarIdAsc = 'FILES_STDDEV_POPULATION_HAR_ID_ASC',
  FilesStddevPopulationHarIdDesc = 'FILES_STDDEV_POPULATION_HAR_ID_DESC',
  FilesStddevPopulationIdAsc = 'FILES_STDDEV_POPULATION_ID_ASC',
  FilesStddevPopulationIdDesc = 'FILES_STDDEV_POPULATION_ID_DESC',
  FilesStddevPopulationNameAsc = 'FILES_STDDEV_POPULATION_NAME_ASC',
  FilesStddevPopulationNameDesc = 'FILES_STDDEV_POPULATION_NAME_DESC',
  FilesStddevPopulationProtocolIdAsc = 'FILES_STDDEV_POPULATION_PROTOCOL_ID_ASC',
  FilesStddevPopulationProtocolIdDesc = 'FILES_STDDEV_POPULATION_PROTOCOL_ID_DESC',
  FilesStddevPopulationSizeAsc = 'FILES_STDDEV_POPULATION_SIZE_ASC',
  FilesStddevPopulationSizeDesc = 'FILES_STDDEV_POPULATION_SIZE_DESC',
  FilesStddevPopulationSuffixAsc = 'FILES_STDDEV_POPULATION_SUFFIX_ASC',
  FilesStddevPopulationSuffixDesc = 'FILES_STDDEV_POPULATION_SUFFIX_DESC',
  FilesStddevPopulationUpdatedAtAsc = 'FILES_STDDEV_POPULATION_UPDATED_AT_ASC',
  FilesStddevPopulationUpdatedAtDesc = 'FILES_STDDEV_POPULATION_UPDATED_AT_DESC',
  FilesStddevSampleCrashIdAsc = 'FILES_STDDEV_SAMPLE_CRASH_ID_ASC',
  FilesStddevSampleCrashIdDesc = 'FILES_STDDEV_SAMPLE_CRASH_ID_DESC',
  FilesStddevSampleCreatedAtAsc = 'FILES_STDDEV_SAMPLE_CREATED_AT_ASC',
  FilesStddevSampleCreatedAtDesc = 'FILES_STDDEV_SAMPLE_CREATED_AT_DESC',
  FilesStddevSampleDataAsc = 'FILES_STDDEV_SAMPLE_DATA_ASC',
  FilesStddevSampleDataDesc = 'FILES_STDDEV_SAMPLE_DATA_DESC',
  FilesStddevSampleHarIdAsc = 'FILES_STDDEV_SAMPLE_HAR_ID_ASC',
  FilesStddevSampleHarIdDesc = 'FILES_STDDEV_SAMPLE_HAR_ID_DESC',
  FilesStddevSampleIdAsc = 'FILES_STDDEV_SAMPLE_ID_ASC',
  FilesStddevSampleIdDesc = 'FILES_STDDEV_SAMPLE_ID_DESC',
  FilesStddevSampleNameAsc = 'FILES_STDDEV_SAMPLE_NAME_ASC',
  FilesStddevSampleNameDesc = 'FILES_STDDEV_SAMPLE_NAME_DESC',
  FilesStddevSampleProtocolIdAsc = 'FILES_STDDEV_SAMPLE_PROTOCOL_ID_ASC',
  FilesStddevSampleProtocolIdDesc = 'FILES_STDDEV_SAMPLE_PROTOCOL_ID_DESC',
  FilesStddevSampleSizeAsc = 'FILES_STDDEV_SAMPLE_SIZE_ASC',
  FilesStddevSampleSizeDesc = 'FILES_STDDEV_SAMPLE_SIZE_DESC',
  FilesStddevSampleSuffixAsc = 'FILES_STDDEV_SAMPLE_SUFFIX_ASC',
  FilesStddevSampleSuffixDesc = 'FILES_STDDEV_SAMPLE_SUFFIX_DESC',
  FilesStddevSampleUpdatedAtAsc = 'FILES_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FilesStddevSampleUpdatedAtDesc = 'FILES_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FilesSumCrashIdAsc = 'FILES_SUM_CRASH_ID_ASC',
  FilesSumCrashIdDesc = 'FILES_SUM_CRASH_ID_DESC',
  FilesSumCreatedAtAsc = 'FILES_SUM_CREATED_AT_ASC',
  FilesSumCreatedAtDesc = 'FILES_SUM_CREATED_AT_DESC',
  FilesSumDataAsc = 'FILES_SUM_DATA_ASC',
  FilesSumDataDesc = 'FILES_SUM_DATA_DESC',
  FilesSumHarIdAsc = 'FILES_SUM_HAR_ID_ASC',
  FilesSumHarIdDesc = 'FILES_SUM_HAR_ID_DESC',
  FilesSumIdAsc = 'FILES_SUM_ID_ASC',
  FilesSumIdDesc = 'FILES_SUM_ID_DESC',
  FilesSumNameAsc = 'FILES_SUM_NAME_ASC',
  FilesSumNameDesc = 'FILES_SUM_NAME_DESC',
  FilesSumProtocolIdAsc = 'FILES_SUM_PROTOCOL_ID_ASC',
  FilesSumProtocolIdDesc = 'FILES_SUM_PROTOCOL_ID_DESC',
  FilesSumSizeAsc = 'FILES_SUM_SIZE_ASC',
  FilesSumSizeDesc = 'FILES_SUM_SIZE_DESC',
  FilesSumSuffixAsc = 'FILES_SUM_SUFFIX_ASC',
  FilesSumSuffixDesc = 'FILES_SUM_SUFFIX_DESC',
  FilesSumUpdatedAtAsc = 'FILES_SUM_UPDATED_AT_ASC',
  FilesSumUpdatedAtDesc = 'FILES_SUM_UPDATED_AT_DESC',
  FilesVariancePopulationCrashIdAsc = 'FILES_VARIANCE_POPULATION_CRASH_ID_ASC',
  FilesVariancePopulationCrashIdDesc = 'FILES_VARIANCE_POPULATION_CRASH_ID_DESC',
  FilesVariancePopulationCreatedAtAsc = 'FILES_VARIANCE_POPULATION_CREATED_AT_ASC',
  FilesVariancePopulationCreatedAtDesc = 'FILES_VARIANCE_POPULATION_CREATED_AT_DESC',
  FilesVariancePopulationDataAsc = 'FILES_VARIANCE_POPULATION_DATA_ASC',
  FilesVariancePopulationDataDesc = 'FILES_VARIANCE_POPULATION_DATA_DESC',
  FilesVariancePopulationHarIdAsc = 'FILES_VARIANCE_POPULATION_HAR_ID_ASC',
  FilesVariancePopulationHarIdDesc = 'FILES_VARIANCE_POPULATION_HAR_ID_DESC',
  FilesVariancePopulationIdAsc = 'FILES_VARIANCE_POPULATION_ID_ASC',
  FilesVariancePopulationIdDesc = 'FILES_VARIANCE_POPULATION_ID_DESC',
  FilesVariancePopulationNameAsc = 'FILES_VARIANCE_POPULATION_NAME_ASC',
  FilesVariancePopulationNameDesc = 'FILES_VARIANCE_POPULATION_NAME_DESC',
  FilesVariancePopulationProtocolIdAsc = 'FILES_VARIANCE_POPULATION_PROTOCOL_ID_ASC',
  FilesVariancePopulationProtocolIdDesc = 'FILES_VARIANCE_POPULATION_PROTOCOL_ID_DESC',
  FilesVariancePopulationSizeAsc = 'FILES_VARIANCE_POPULATION_SIZE_ASC',
  FilesVariancePopulationSizeDesc = 'FILES_VARIANCE_POPULATION_SIZE_DESC',
  FilesVariancePopulationSuffixAsc = 'FILES_VARIANCE_POPULATION_SUFFIX_ASC',
  FilesVariancePopulationSuffixDesc = 'FILES_VARIANCE_POPULATION_SUFFIX_DESC',
  FilesVariancePopulationUpdatedAtAsc = 'FILES_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FilesVariancePopulationUpdatedAtDesc = 'FILES_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FilesVarianceSampleCrashIdAsc = 'FILES_VARIANCE_SAMPLE_CRASH_ID_ASC',
  FilesVarianceSampleCrashIdDesc = 'FILES_VARIANCE_SAMPLE_CRASH_ID_DESC',
  FilesVarianceSampleCreatedAtAsc = 'FILES_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FilesVarianceSampleCreatedAtDesc = 'FILES_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FilesVarianceSampleDataAsc = 'FILES_VARIANCE_SAMPLE_DATA_ASC',
  FilesVarianceSampleDataDesc = 'FILES_VARIANCE_SAMPLE_DATA_DESC',
  FilesVarianceSampleHarIdAsc = 'FILES_VARIANCE_SAMPLE_HAR_ID_ASC',
  FilesVarianceSampleHarIdDesc = 'FILES_VARIANCE_SAMPLE_HAR_ID_DESC',
  FilesVarianceSampleIdAsc = 'FILES_VARIANCE_SAMPLE_ID_ASC',
  FilesVarianceSampleIdDesc = 'FILES_VARIANCE_SAMPLE_ID_DESC',
  FilesVarianceSampleNameAsc = 'FILES_VARIANCE_SAMPLE_NAME_ASC',
  FilesVarianceSampleNameDesc = 'FILES_VARIANCE_SAMPLE_NAME_DESC',
  FilesVarianceSampleProtocolIdAsc = 'FILES_VARIANCE_SAMPLE_PROTOCOL_ID_ASC',
  FilesVarianceSampleProtocolIdDesc = 'FILES_VARIANCE_SAMPLE_PROTOCOL_ID_DESC',
  FilesVarianceSampleSizeAsc = 'FILES_VARIANCE_SAMPLE_SIZE_ASC',
  FilesVarianceSampleSizeDesc = 'FILES_VARIANCE_SAMPLE_SIZE_DESC',
  FilesVarianceSampleSuffixAsc = 'FILES_VARIANCE_SAMPLE_SUFFIX_ASC',
  FilesVarianceSampleSuffixDesc = 'FILES_VARIANCE_SAMPLE_SUFFIX_DESC',
  FilesVarianceSampleUpdatedAtAsc = 'FILES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FilesVarianceSampleUpdatedAtDesc = 'FILES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  OutbankUserIdAsc = 'OUTBANK_USER_ID_ASC',
  OutbankUserIdDesc = 'OUTBANK_USER_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC'
}

export type HavingDatetimeFilter = {
  equalTo?: InputMaybe<Scalars['Datetime']['input']>;
  greaterThan?: InputMaybe<Scalars['Datetime']['input']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
  lessThan?: InputMaybe<Scalars['Datetime']['input']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
  notEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
};

export type HavingIntFilter = {
  equalTo?: InputMaybe<Scalars['Int']['input']>;
  greaterThan?: InputMaybe<Scalars['Int']['input']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['Int']['input']>;
  lessThan?: InputMaybe<Scalars['Int']['input']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['Int']['input']>;
  notEqualTo?: InputMaybe<Scalars['Int']['input']>;
};

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Int']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Int']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Int']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Int']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Int']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Int']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Int']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Int']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type LocalizationLanguage = Node & {
  __typename?: 'LocalizationLanguage';
  code: Scalars['String']['output'];
  createdAt: Scalars['Datetime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  /** Reads and enables pagination through a set of `LocalizationProjectXLanguage`. */
  localizationProjectXLanguages: LocalizationProjectXLanguagesConnection;
  /** Reads and enables pagination through a set of `LocalizationTranslationValue`. */
  localizationTranslationValues: LocalizationTranslationValuesConnection;
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  updatedAt: Scalars['Datetime']['output'];
};


export type LocalizationLanguageLocalizationProjectXLanguagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LocalizationProjectXLanguageCondition>;
  filter?: InputMaybe<LocalizationProjectXLanguageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LocalizationProjectXLanguagesOrderBy>>;
};


export type LocalizationLanguageLocalizationTranslationValuesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LocalizationTranslationValueCondition>;
  filter?: InputMaybe<LocalizationTranslationValueFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LocalizationTranslationValuesOrderBy>>;
};

export type LocalizationLanguageAggregates = {
  __typename?: 'LocalizationLanguageAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LocalizationLanguageDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * A condition to be used against `LocalizationLanguage` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type LocalizationLanguageCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
};

export type LocalizationLanguageDistinctCountAggregates = {
  __typename?: 'LocalizationLanguageDistinctCountAggregates';
  /** Distinct count of code across the matching connection */
  code?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `LocalizationLanguage` object types. All fields are combined with a logical ‘and.’ */
export type LocalizationLanguageFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LocalizationLanguageFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LocalizationLanguageFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LocalizationLanguageFilter>>;
};

/** An input for mutations affecting `LocalizationLanguage` */
export type LocalizationLanguageInput = {
  code: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** Represents an update to a `LocalizationLanguage`. Fields that are set will be updated. */
export type LocalizationLanguagePatch = {
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A connection to a list of `LocalizationLanguage` values. */
export type LocalizationLanguagesConnection = {
  __typename?: 'LocalizationLanguagesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LocalizationLanguageAggregates>;
  /** A list of edges which contains the `LocalizationLanguage` and cursor to aid in pagination. */
  edges: Array<LocalizationLanguagesEdge>;
  /** A list of `LocalizationLanguage` objects. */
  nodes: Array<LocalizationLanguage>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LocalizationLanguage` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `LocalizationLanguage` edge in the connection. */
export type LocalizationLanguagesEdge = {
  __typename?: 'LocalizationLanguagesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `LocalizationLanguage` at the end of the edge. */
  node: LocalizationLanguage;
};

/** Methods to use when ordering `LocalizationLanguage`. */
export enum LocalizationLanguagesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LocalizationProjectXlanguagesAverageCreatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_AVERAGE_CREATED_AT_ASC',
  LocalizationProjectXlanguagesAverageCreatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_AVERAGE_CREATED_AT_DESC',
  LocalizationProjectXlanguagesAverageIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_AVERAGE_ID_ASC',
  LocalizationProjectXlanguagesAverageIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_AVERAGE_ID_DESC',
  LocalizationProjectXlanguagesAverageLocalizationLanguageIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_AVERAGE_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationProjectXlanguagesAverageLocalizationLanguageIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_AVERAGE_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationProjectXlanguagesAverageLocalizationProjectIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_AVERAGE_LOCALIZATION_PROJECT_ID_ASC',
  LocalizationProjectXlanguagesAverageLocalizationProjectIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_AVERAGE_LOCALIZATION_PROJECT_ID_DESC',
  LocalizationProjectXlanguagesAverageUpdatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_AVERAGE_UPDATED_AT_ASC',
  LocalizationProjectXlanguagesAverageUpdatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_AVERAGE_UPDATED_AT_DESC',
  LocalizationProjectXlanguagesCountAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_COUNT_ASC',
  LocalizationProjectXlanguagesCountDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_COUNT_DESC',
  LocalizationProjectXlanguagesDistinctCountCreatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_DISTINCT_COUNT_CREATED_AT_ASC',
  LocalizationProjectXlanguagesDistinctCountCreatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_DISTINCT_COUNT_CREATED_AT_DESC',
  LocalizationProjectXlanguagesDistinctCountIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_DISTINCT_COUNT_ID_ASC',
  LocalizationProjectXlanguagesDistinctCountIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_DISTINCT_COUNT_ID_DESC',
  LocalizationProjectXlanguagesDistinctCountLocalizationLanguageIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_DISTINCT_COUNT_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationProjectXlanguagesDistinctCountLocalizationLanguageIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_DISTINCT_COUNT_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationProjectXlanguagesDistinctCountLocalizationProjectIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_DISTINCT_COUNT_LOCALIZATION_PROJECT_ID_ASC',
  LocalizationProjectXlanguagesDistinctCountLocalizationProjectIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_DISTINCT_COUNT_LOCALIZATION_PROJECT_ID_DESC',
  LocalizationProjectXlanguagesDistinctCountUpdatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_DISTINCT_COUNT_UPDATED_AT_ASC',
  LocalizationProjectXlanguagesDistinctCountUpdatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_DISTINCT_COUNT_UPDATED_AT_DESC',
  LocalizationProjectXlanguagesMaxCreatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_MAX_CREATED_AT_ASC',
  LocalizationProjectXlanguagesMaxCreatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_MAX_CREATED_AT_DESC',
  LocalizationProjectXlanguagesMaxIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_MAX_ID_ASC',
  LocalizationProjectXlanguagesMaxIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_MAX_ID_DESC',
  LocalizationProjectXlanguagesMaxLocalizationLanguageIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_MAX_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationProjectXlanguagesMaxLocalizationLanguageIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_MAX_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationProjectXlanguagesMaxLocalizationProjectIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_MAX_LOCALIZATION_PROJECT_ID_ASC',
  LocalizationProjectXlanguagesMaxLocalizationProjectIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_MAX_LOCALIZATION_PROJECT_ID_DESC',
  LocalizationProjectXlanguagesMaxUpdatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_MAX_UPDATED_AT_ASC',
  LocalizationProjectXlanguagesMaxUpdatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_MAX_UPDATED_AT_DESC',
  LocalizationProjectXlanguagesMinCreatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_MIN_CREATED_AT_ASC',
  LocalizationProjectXlanguagesMinCreatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_MIN_CREATED_AT_DESC',
  LocalizationProjectXlanguagesMinIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_MIN_ID_ASC',
  LocalizationProjectXlanguagesMinIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_MIN_ID_DESC',
  LocalizationProjectXlanguagesMinLocalizationLanguageIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_MIN_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationProjectXlanguagesMinLocalizationLanguageIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_MIN_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationProjectXlanguagesMinLocalizationProjectIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_MIN_LOCALIZATION_PROJECT_ID_ASC',
  LocalizationProjectXlanguagesMinLocalizationProjectIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_MIN_LOCALIZATION_PROJECT_ID_DESC',
  LocalizationProjectXlanguagesMinUpdatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_MIN_UPDATED_AT_ASC',
  LocalizationProjectXlanguagesMinUpdatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_MIN_UPDATED_AT_DESC',
  LocalizationProjectXlanguagesStddevPopulationCreatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_POPULATION_CREATED_AT_ASC',
  LocalizationProjectXlanguagesStddevPopulationCreatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_POPULATION_CREATED_AT_DESC',
  LocalizationProjectXlanguagesStddevPopulationIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_POPULATION_ID_ASC',
  LocalizationProjectXlanguagesStddevPopulationIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_POPULATION_ID_DESC',
  LocalizationProjectXlanguagesStddevPopulationLocalizationLanguageIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_POPULATION_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationProjectXlanguagesStddevPopulationLocalizationLanguageIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_POPULATION_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationProjectXlanguagesStddevPopulationLocalizationProjectIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_POPULATION_LOCALIZATION_PROJECT_ID_ASC',
  LocalizationProjectXlanguagesStddevPopulationLocalizationProjectIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_POPULATION_LOCALIZATION_PROJECT_ID_DESC',
  LocalizationProjectXlanguagesStddevPopulationUpdatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_POPULATION_UPDATED_AT_ASC',
  LocalizationProjectXlanguagesStddevPopulationUpdatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_POPULATION_UPDATED_AT_DESC',
  LocalizationProjectXlanguagesStddevSampleCreatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_SAMPLE_CREATED_AT_ASC',
  LocalizationProjectXlanguagesStddevSampleCreatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_SAMPLE_CREATED_AT_DESC',
  LocalizationProjectXlanguagesStddevSampleIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_SAMPLE_ID_ASC',
  LocalizationProjectXlanguagesStddevSampleIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_SAMPLE_ID_DESC',
  LocalizationProjectXlanguagesStddevSampleLocalizationLanguageIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_SAMPLE_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationProjectXlanguagesStddevSampleLocalizationLanguageIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_SAMPLE_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationProjectXlanguagesStddevSampleLocalizationProjectIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_SAMPLE_LOCALIZATION_PROJECT_ID_ASC',
  LocalizationProjectXlanguagesStddevSampleLocalizationProjectIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_SAMPLE_LOCALIZATION_PROJECT_ID_DESC',
  LocalizationProjectXlanguagesStddevSampleUpdatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LocalizationProjectXlanguagesStddevSampleUpdatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LocalizationProjectXlanguagesSumCreatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_SUM_CREATED_AT_ASC',
  LocalizationProjectXlanguagesSumCreatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_SUM_CREATED_AT_DESC',
  LocalizationProjectXlanguagesSumIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_SUM_ID_ASC',
  LocalizationProjectXlanguagesSumIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_SUM_ID_DESC',
  LocalizationProjectXlanguagesSumLocalizationLanguageIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_SUM_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationProjectXlanguagesSumLocalizationLanguageIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_SUM_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationProjectXlanguagesSumLocalizationProjectIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_SUM_LOCALIZATION_PROJECT_ID_ASC',
  LocalizationProjectXlanguagesSumLocalizationProjectIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_SUM_LOCALIZATION_PROJECT_ID_DESC',
  LocalizationProjectXlanguagesSumUpdatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_SUM_UPDATED_AT_ASC',
  LocalizationProjectXlanguagesSumUpdatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_SUM_UPDATED_AT_DESC',
  LocalizationProjectXlanguagesVariancePopulationCreatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_POPULATION_CREATED_AT_ASC',
  LocalizationProjectXlanguagesVariancePopulationCreatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_POPULATION_CREATED_AT_DESC',
  LocalizationProjectXlanguagesVariancePopulationIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_POPULATION_ID_ASC',
  LocalizationProjectXlanguagesVariancePopulationIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_POPULATION_ID_DESC',
  LocalizationProjectXlanguagesVariancePopulationLocalizationLanguageIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_POPULATION_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationProjectXlanguagesVariancePopulationLocalizationLanguageIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_POPULATION_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationProjectXlanguagesVariancePopulationLocalizationProjectIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_POPULATION_LOCALIZATION_PROJECT_ID_ASC',
  LocalizationProjectXlanguagesVariancePopulationLocalizationProjectIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_POPULATION_LOCALIZATION_PROJECT_ID_DESC',
  LocalizationProjectXlanguagesVariancePopulationUpdatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LocalizationProjectXlanguagesVariancePopulationUpdatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_POPULATION_UPDATED_AT_DESC',
  LocalizationProjectXlanguagesVarianceSampleCreatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LocalizationProjectXlanguagesVarianceSampleCreatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LocalizationProjectXlanguagesVarianceSampleIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_SAMPLE_ID_ASC',
  LocalizationProjectXlanguagesVarianceSampleIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_SAMPLE_ID_DESC',
  LocalizationProjectXlanguagesVarianceSampleLocalizationLanguageIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_SAMPLE_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationProjectXlanguagesVarianceSampleLocalizationLanguageIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_SAMPLE_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationProjectXlanguagesVarianceSampleLocalizationProjectIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_SAMPLE_LOCALIZATION_PROJECT_ID_ASC',
  LocalizationProjectXlanguagesVarianceSampleLocalizationProjectIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_SAMPLE_LOCALIZATION_PROJECT_ID_DESC',
  LocalizationProjectXlanguagesVarianceSampleUpdatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LocalizationProjectXlanguagesVarianceSampleUpdatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  LocalizationTranslationValuesAverageCreatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_AVERAGE_CREATED_AT_ASC',
  LocalizationTranslationValuesAverageCreatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_AVERAGE_CREATED_AT_DESC',
  LocalizationTranslationValuesAverageIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_AVERAGE_ID_ASC',
  LocalizationTranslationValuesAverageIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_AVERAGE_ID_DESC',
  LocalizationTranslationValuesAverageLocalizationLanguageIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_AVERAGE_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationTranslationValuesAverageLocalizationLanguageIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_AVERAGE_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationTranslationValuesAverageLocalizationTranslationKeyIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_AVERAGE_LOCALIZATION_TRANSLATION_KEY_ID_ASC',
  LocalizationTranslationValuesAverageLocalizationTranslationKeyIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_AVERAGE_LOCALIZATION_TRANSLATION_KEY_ID_DESC',
  LocalizationTranslationValuesAverageUpdatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_AVERAGE_UPDATED_AT_ASC',
  LocalizationTranslationValuesAverageUpdatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_AVERAGE_UPDATED_AT_DESC',
  LocalizationTranslationValuesAverageValueAsc = 'LOCALIZATION_TRANSLATION_VALUES_AVERAGE_VALUE_ASC',
  LocalizationTranslationValuesAverageValueDesc = 'LOCALIZATION_TRANSLATION_VALUES_AVERAGE_VALUE_DESC',
  LocalizationTranslationValuesCountAsc = 'LOCALIZATION_TRANSLATION_VALUES_COUNT_ASC',
  LocalizationTranslationValuesCountDesc = 'LOCALIZATION_TRANSLATION_VALUES_COUNT_DESC',
  LocalizationTranslationValuesDistinctCountCreatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_DISTINCT_COUNT_CREATED_AT_ASC',
  LocalizationTranslationValuesDistinctCountCreatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_DISTINCT_COUNT_CREATED_AT_DESC',
  LocalizationTranslationValuesDistinctCountIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_DISTINCT_COUNT_ID_ASC',
  LocalizationTranslationValuesDistinctCountIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_DISTINCT_COUNT_ID_DESC',
  LocalizationTranslationValuesDistinctCountLocalizationLanguageIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_DISTINCT_COUNT_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationTranslationValuesDistinctCountLocalizationLanguageIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_DISTINCT_COUNT_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationTranslationValuesDistinctCountLocalizationTranslationKeyIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_DISTINCT_COUNT_LOCALIZATION_TRANSLATION_KEY_ID_ASC',
  LocalizationTranslationValuesDistinctCountLocalizationTranslationKeyIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_DISTINCT_COUNT_LOCALIZATION_TRANSLATION_KEY_ID_DESC',
  LocalizationTranslationValuesDistinctCountUpdatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_DISTINCT_COUNT_UPDATED_AT_ASC',
  LocalizationTranslationValuesDistinctCountUpdatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_DISTINCT_COUNT_UPDATED_AT_DESC',
  LocalizationTranslationValuesDistinctCountValueAsc = 'LOCALIZATION_TRANSLATION_VALUES_DISTINCT_COUNT_VALUE_ASC',
  LocalizationTranslationValuesDistinctCountValueDesc = 'LOCALIZATION_TRANSLATION_VALUES_DISTINCT_COUNT_VALUE_DESC',
  LocalizationTranslationValuesMaxCreatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_MAX_CREATED_AT_ASC',
  LocalizationTranslationValuesMaxCreatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_MAX_CREATED_AT_DESC',
  LocalizationTranslationValuesMaxIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_MAX_ID_ASC',
  LocalizationTranslationValuesMaxIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_MAX_ID_DESC',
  LocalizationTranslationValuesMaxLocalizationLanguageIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_MAX_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationTranslationValuesMaxLocalizationLanguageIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_MAX_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationTranslationValuesMaxLocalizationTranslationKeyIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_MAX_LOCALIZATION_TRANSLATION_KEY_ID_ASC',
  LocalizationTranslationValuesMaxLocalizationTranslationKeyIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_MAX_LOCALIZATION_TRANSLATION_KEY_ID_DESC',
  LocalizationTranslationValuesMaxUpdatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_MAX_UPDATED_AT_ASC',
  LocalizationTranslationValuesMaxUpdatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_MAX_UPDATED_AT_DESC',
  LocalizationTranslationValuesMaxValueAsc = 'LOCALIZATION_TRANSLATION_VALUES_MAX_VALUE_ASC',
  LocalizationTranslationValuesMaxValueDesc = 'LOCALIZATION_TRANSLATION_VALUES_MAX_VALUE_DESC',
  LocalizationTranslationValuesMinCreatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_MIN_CREATED_AT_ASC',
  LocalizationTranslationValuesMinCreatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_MIN_CREATED_AT_DESC',
  LocalizationTranslationValuesMinIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_MIN_ID_ASC',
  LocalizationTranslationValuesMinIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_MIN_ID_DESC',
  LocalizationTranslationValuesMinLocalizationLanguageIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_MIN_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationTranslationValuesMinLocalizationLanguageIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_MIN_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationTranslationValuesMinLocalizationTranslationKeyIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_MIN_LOCALIZATION_TRANSLATION_KEY_ID_ASC',
  LocalizationTranslationValuesMinLocalizationTranslationKeyIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_MIN_LOCALIZATION_TRANSLATION_KEY_ID_DESC',
  LocalizationTranslationValuesMinUpdatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_MIN_UPDATED_AT_ASC',
  LocalizationTranslationValuesMinUpdatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_MIN_UPDATED_AT_DESC',
  LocalizationTranslationValuesMinValueAsc = 'LOCALIZATION_TRANSLATION_VALUES_MIN_VALUE_ASC',
  LocalizationTranslationValuesMinValueDesc = 'LOCALIZATION_TRANSLATION_VALUES_MIN_VALUE_DESC',
  LocalizationTranslationValuesStddevPopulationCreatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_POPULATION_CREATED_AT_ASC',
  LocalizationTranslationValuesStddevPopulationCreatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_POPULATION_CREATED_AT_DESC',
  LocalizationTranslationValuesStddevPopulationIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_POPULATION_ID_ASC',
  LocalizationTranslationValuesStddevPopulationIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_POPULATION_ID_DESC',
  LocalizationTranslationValuesStddevPopulationLocalizationLanguageIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_POPULATION_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationTranslationValuesStddevPopulationLocalizationLanguageIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_POPULATION_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationTranslationValuesStddevPopulationLocalizationTranslationKeyIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_POPULATION_LOCALIZATION_TRANSLATION_KEY_ID_ASC',
  LocalizationTranslationValuesStddevPopulationLocalizationTranslationKeyIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_POPULATION_LOCALIZATION_TRANSLATION_KEY_ID_DESC',
  LocalizationTranslationValuesStddevPopulationUpdatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_POPULATION_UPDATED_AT_ASC',
  LocalizationTranslationValuesStddevPopulationUpdatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_POPULATION_UPDATED_AT_DESC',
  LocalizationTranslationValuesStddevPopulationValueAsc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_POPULATION_VALUE_ASC',
  LocalizationTranslationValuesStddevPopulationValueDesc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_POPULATION_VALUE_DESC',
  LocalizationTranslationValuesStddevSampleCreatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_SAMPLE_CREATED_AT_ASC',
  LocalizationTranslationValuesStddevSampleCreatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_SAMPLE_CREATED_AT_DESC',
  LocalizationTranslationValuesStddevSampleIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_SAMPLE_ID_ASC',
  LocalizationTranslationValuesStddevSampleIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_SAMPLE_ID_DESC',
  LocalizationTranslationValuesStddevSampleLocalizationLanguageIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_SAMPLE_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationTranslationValuesStddevSampleLocalizationLanguageIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_SAMPLE_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationTranslationValuesStddevSampleLocalizationTranslationKeyIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_SAMPLE_LOCALIZATION_TRANSLATION_KEY_ID_ASC',
  LocalizationTranslationValuesStddevSampleLocalizationTranslationKeyIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_SAMPLE_LOCALIZATION_TRANSLATION_KEY_ID_DESC',
  LocalizationTranslationValuesStddevSampleUpdatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LocalizationTranslationValuesStddevSampleUpdatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LocalizationTranslationValuesStddevSampleValueAsc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_SAMPLE_VALUE_ASC',
  LocalizationTranslationValuesStddevSampleValueDesc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_SAMPLE_VALUE_DESC',
  LocalizationTranslationValuesSumCreatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_SUM_CREATED_AT_ASC',
  LocalizationTranslationValuesSumCreatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_SUM_CREATED_AT_DESC',
  LocalizationTranslationValuesSumIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_SUM_ID_ASC',
  LocalizationTranslationValuesSumIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_SUM_ID_DESC',
  LocalizationTranslationValuesSumLocalizationLanguageIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_SUM_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationTranslationValuesSumLocalizationLanguageIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_SUM_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationTranslationValuesSumLocalizationTranslationKeyIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_SUM_LOCALIZATION_TRANSLATION_KEY_ID_ASC',
  LocalizationTranslationValuesSumLocalizationTranslationKeyIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_SUM_LOCALIZATION_TRANSLATION_KEY_ID_DESC',
  LocalizationTranslationValuesSumUpdatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_SUM_UPDATED_AT_ASC',
  LocalizationTranslationValuesSumUpdatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_SUM_UPDATED_AT_DESC',
  LocalizationTranslationValuesSumValueAsc = 'LOCALIZATION_TRANSLATION_VALUES_SUM_VALUE_ASC',
  LocalizationTranslationValuesSumValueDesc = 'LOCALIZATION_TRANSLATION_VALUES_SUM_VALUE_DESC',
  LocalizationTranslationValuesVariancePopulationCreatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_POPULATION_CREATED_AT_ASC',
  LocalizationTranslationValuesVariancePopulationCreatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_POPULATION_CREATED_AT_DESC',
  LocalizationTranslationValuesVariancePopulationIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_POPULATION_ID_ASC',
  LocalizationTranslationValuesVariancePopulationIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_POPULATION_ID_DESC',
  LocalizationTranslationValuesVariancePopulationLocalizationLanguageIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_POPULATION_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationTranslationValuesVariancePopulationLocalizationLanguageIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_POPULATION_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationTranslationValuesVariancePopulationLocalizationTranslationKeyIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_POPULATION_LOCALIZATION_TRANSLATION_KEY_ID_ASC',
  LocalizationTranslationValuesVariancePopulationLocalizationTranslationKeyIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_POPULATION_LOCALIZATION_TRANSLATION_KEY_ID_DESC',
  LocalizationTranslationValuesVariancePopulationUpdatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LocalizationTranslationValuesVariancePopulationUpdatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_POPULATION_UPDATED_AT_DESC',
  LocalizationTranslationValuesVariancePopulationValueAsc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_POPULATION_VALUE_ASC',
  LocalizationTranslationValuesVariancePopulationValueDesc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_POPULATION_VALUE_DESC',
  LocalizationTranslationValuesVarianceSampleCreatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LocalizationTranslationValuesVarianceSampleCreatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LocalizationTranslationValuesVarianceSampleIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_SAMPLE_ID_ASC',
  LocalizationTranslationValuesVarianceSampleIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_SAMPLE_ID_DESC',
  LocalizationTranslationValuesVarianceSampleLocalizationLanguageIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_SAMPLE_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationTranslationValuesVarianceSampleLocalizationLanguageIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_SAMPLE_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationTranslationValuesVarianceSampleLocalizationTranslationKeyIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_SAMPLE_LOCALIZATION_TRANSLATION_KEY_ID_ASC',
  LocalizationTranslationValuesVarianceSampleLocalizationTranslationKeyIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_SAMPLE_LOCALIZATION_TRANSLATION_KEY_ID_DESC',
  LocalizationTranslationValuesVarianceSampleUpdatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LocalizationTranslationValuesVarianceSampleUpdatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  LocalizationTranslationValuesVarianceSampleValueAsc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_SAMPLE_VALUE_ASC',
  LocalizationTranslationValuesVarianceSampleValueDesc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_SAMPLE_VALUE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type LocalizationProject = Node & {
  __typename?: 'LocalizationProject';
  createdAt: Scalars['Datetime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  /** Reads and enables pagination through a set of `LocalizationProjectXLanguage`. */
  localizationProjectXLanguages: LocalizationProjectXLanguagesConnection;
  /** Reads and enables pagination through a set of `LocalizationTranslationKey`. */
  localizationTranslationKeys: LocalizationTranslationKeysConnection;
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  updatedAt: Scalars['Datetime']['output'];
};


export type LocalizationProjectLocalizationProjectXLanguagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LocalizationProjectXLanguageCondition>;
  filter?: InputMaybe<LocalizationProjectXLanguageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LocalizationProjectXLanguagesOrderBy>>;
};


export type LocalizationProjectLocalizationTranslationKeysArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LocalizationTranslationKeyCondition>;
  filter?: InputMaybe<LocalizationTranslationKeyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LocalizationTranslationKeysOrderBy>>;
};

export type LocalizationProjectAggregates = {
  __typename?: 'LocalizationProjectAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LocalizationProjectDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * A condition to be used against `LocalizationProject` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type LocalizationProjectCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
};

export type LocalizationProjectDistinctCountAggregates = {
  __typename?: 'LocalizationProjectDistinctCountAggregates';
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `LocalizationProject` object types. All fields are combined with a logical ‘and.’ */
export type LocalizationProjectFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LocalizationProjectFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LocalizationProjectFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LocalizationProjectFilter>>;
};

/** An input for mutations affecting `LocalizationProject` */
export type LocalizationProjectInput = {
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** Represents an update to a `LocalizationProject`. Fields that are set will be updated. */
export type LocalizationProjectPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

export type LocalizationProjectXLanguage = Node & {
  __typename?: 'LocalizationProjectXLanguage';
  createdAt: Scalars['Datetime']['output'];
  id: Scalars['UUID']['output'];
  /** Reads a single `LocalizationLanguage` that is related to this `LocalizationProjectXLanguage`. */
  localizationLanguage?: Maybe<LocalizationLanguage>;
  localizationLanguageId?: Maybe<Scalars['UUID']['output']>;
  /** Reads a single `LocalizationProject` that is related to this `LocalizationProjectXLanguage`. */
  localizationProject?: Maybe<LocalizationProject>;
  localizationProjectId?: Maybe<Scalars['UUID']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  updatedAt: Scalars['Datetime']['output'];
};

export type LocalizationProjectXLanguageAggregates = {
  __typename?: 'LocalizationProjectXLanguageAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LocalizationProjectXLanguageDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * A condition to be used against `LocalizationProjectXLanguage` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type LocalizationProjectXLanguageCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `localizationLanguageId` field. */
  localizationLanguageId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `localizationProjectId` field. */
  localizationProjectId?: InputMaybe<Scalars['UUID']['input']>;
};

export type LocalizationProjectXLanguageDistinctCountAggregates = {
  __typename?: 'LocalizationProjectXLanguageDistinctCountAggregates';
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of localizationLanguageId across the matching connection */
  localizationLanguageId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of localizationProjectId across the matching connection */
  localizationProjectId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `LocalizationProjectXLanguage` object types. All fields are combined with a logical ‘and.’ */
export type LocalizationProjectXLanguageFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LocalizationProjectXLanguageFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `localizationLanguageId` field. */
  localizationLanguageId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `localizationProjectId` field. */
  localizationProjectId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LocalizationProjectXLanguageFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LocalizationProjectXLanguageFilter>>;
};

/** Grouping methods for `LocalizationProjectXLanguage` for usage during aggregation. */
export enum LocalizationProjectXLanguageGroupBy {
  LocalizationLanguageId = 'LOCALIZATION_LANGUAGE_ID',
  LocalizationProjectId = 'LOCALIZATION_PROJECT_ID'
}

export type LocalizationProjectXLanguageHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LocalizationProjectXLanguageHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `LocalizationProjectXLanguage` aggregates. */
export type LocalizationProjectXLanguageHavingInput = {
  AND?: InputMaybe<Array<LocalizationProjectXLanguageHavingInput>>;
  OR?: InputMaybe<Array<LocalizationProjectXLanguageHavingInput>>;
  average?: InputMaybe<LocalizationProjectXLanguageHavingAverageInput>;
  distinctCount?: InputMaybe<LocalizationProjectXLanguageHavingDistinctCountInput>;
  max?: InputMaybe<LocalizationProjectXLanguageHavingMaxInput>;
  min?: InputMaybe<LocalizationProjectXLanguageHavingMinInput>;
  stddevPopulation?: InputMaybe<LocalizationProjectXLanguageHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LocalizationProjectXLanguageHavingStddevSampleInput>;
  sum?: InputMaybe<LocalizationProjectXLanguageHavingSumInput>;
  variancePopulation?: InputMaybe<LocalizationProjectXLanguageHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LocalizationProjectXLanguageHavingVarianceSampleInput>;
};

export type LocalizationProjectXLanguageHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LocalizationProjectXLanguageHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LocalizationProjectXLanguageHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LocalizationProjectXLanguageHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LocalizationProjectXLanguageHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LocalizationProjectXLanguageHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LocalizationProjectXLanguageHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `LocalizationProjectXLanguage` */
export type LocalizationProjectXLanguageInput = {
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  localizationLanguageId?: InputMaybe<Scalars['UUID']['input']>;
  localizationProjectId?: InputMaybe<Scalars['UUID']['input']>;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** Represents an update to a `LocalizationProjectXLanguage`. Fields that are set will be updated. */
export type LocalizationProjectXLanguagePatch = {
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  localizationLanguageId?: InputMaybe<Scalars['UUID']['input']>;
  localizationProjectId?: InputMaybe<Scalars['UUID']['input']>;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A connection to a list of `LocalizationProjectXLanguage` values. */
export type LocalizationProjectXLanguagesConnection = {
  __typename?: 'LocalizationProjectXLanguagesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LocalizationProjectXLanguageAggregates>;
  /** A list of edges which contains the `LocalizationProjectXLanguage` and cursor to aid in pagination. */
  edges: Array<LocalizationProjectXLanguagesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LocalizationProjectXLanguageAggregates>>;
  /** A list of `LocalizationProjectXLanguage` objects. */
  nodes: Array<LocalizationProjectXLanguage>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LocalizationProjectXLanguage` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `LocalizationProjectXLanguage` values. */
export type LocalizationProjectXLanguagesConnectionGroupedAggregatesArgs = {
  groupBy: Array<LocalizationProjectXLanguageGroupBy>;
  having?: InputMaybe<LocalizationProjectXLanguageHavingInput>;
};

/** A `LocalizationProjectXLanguage` edge in the connection. */
export type LocalizationProjectXLanguagesEdge = {
  __typename?: 'LocalizationProjectXLanguagesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `LocalizationProjectXLanguage` at the end of the edge. */
  node: LocalizationProjectXLanguage;
};

/** Methods to use when ordering `LocalizationProjectXLanguage`. */
export enum LocalizationProjectXLanguagesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LocalizationLanguageIdAsc = 'LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationLanguageIdDesc = 'LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationProjectIdAsc = 'LOCALIZATION_PROJECT_ID_ASC',
  LocalizationProjectIdDesc = 'LOCALIZATION_PROJECT_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A connection to a list of `LocalizationProject` values. */
export type LocalizationProjectsConnection = {
  __typename?: 'LocalizationProjectsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LocalizationProjectAggregates>;
  /** A list of edges which contains the `LocalizationProject` and cursor to aid in pagination. */
  edges: Array<LocalizationProjectsEdge>;
  /** A list of `LocalizationProject` objects. */
  nodes: Array<LocalizationProject>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LocalizationProject` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `LocalizationProject` edge in the connection. */
export type LocalizationProjectsEdge = {
  __typename?: 'LocalizationProjectsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `LocalizationProject` at the end of the edge. */
  node: LocalizationProject;
};

/** Methods to use when ordering `LocalizationProject`. */
export enum LocalizationProjectsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LocalizationProjectXlanguagesAverageCreatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_AVERAGE_CREATED_AT_ASC',
  LocalizationProjectXlanguagesAverageCreatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_AVERAGE_CREATED_AT_DESC',
  LocalizationProjectXlanguagesAverageIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_AVERAGE_ID_ASC',
  LocalizationProjectXlanguagesAverageIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_AVERAGE_ID_DESC',
  LocalizationProjectXlanguagesAverageLocalizationLanguageIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_AVERAGE_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationProjectXlanguagesAverageLocalizationLanguageIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_AVERAGE_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationProjectXlanguagesAverageLocalizationProjectIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_AVERAGE_LOCALIZATION_PROJECT_ID_ASC',
  LocalizationProjectXlanguagesAverageLocalizationProjectIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_AVERAGE_LOCALIZATION_PROJECT_ID_DESC',
  LocalizationProjectXlanguagesAverageUpdatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_AVERAGE_UPDATED_AT_ASC',
  LocalizationProjectXlanguagesAverageUpdatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_AVERAGE_UPDATED_AT_DESC',
  LocalizationProjectXlanguagesCountAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_COUNT_ASC',
  LocalizationProjectXlanguagesCountDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_COUNT_DESC',
  LocalizationProjectXlanguagesDistinctCountCreatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_DISTINCT_COUNT_CREATED_AT_ASC',
  LocalizationProjectXlanguagesDistinctCountCreatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_DISTINCT_COUNT_CREATED_AT_DESC',
  LocalizationProjectXlanguagesDistinctCountIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_DISTINCT_COUNT_ID_ASC',
  LocalizationProjectXlanguagesDistinctCountIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_DISTINCT_COUNT_ID_DESC',
  LocalizationProjectXlanguagesDistinctCountLocalizationLanguageIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_DISTINCT_COUNT_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationProjectXlanguagesDistinctCountLocalizationLanguageIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_DISTINCT_COUNT_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationProjectXlanguagesDistinctCountLocalizationProjectIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_DISTINCT_COUNT_LOCALIZATION_PROJECT_ID_ASC',
  LocalizationProjectXlanguagesDistinctCountLocalizationProjectIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_DISTINCT_COUNT_LOCALIZATION_PROJECT_ID_DESC',
  LocalizationProjectXlanguagesDistinctCountUpdatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_DISTINCT_COUNT_UPDATED_AT_ASC',
  LocalizationProjectXlanguagesDistinctCountUpdatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_DISTINCT_COUNT_UPDATED_AT_DESC',
  LocalizationProjectXlanguagesMaxCreatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_MAX_CREATED_AT_ASC',
  LocalizationProjectXlanguagesMaxCreatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_MAX_CREATED_AT_DESC',
  LocalizationProjectXlanguagesMaxIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_MAX_ID_ASC',
  LocalizationProjectXlanguagesMaxIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_MAX_ID_DESC',
  LocalizationProjectXlanguagesMaxLocalizationLanguageIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_MAX_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationProjectXlanguagesMaxLocalizationLanguageIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_MAX_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationProjectXlanguagesMaxLocalizationProjectIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_MAX_LOCALIZATION_PROJECT_ID_ASC',
  LocalizationProjectXlanguagesMaxLocalizationProjectIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_MAX_LOCALIZATION_PROJECT_ID_DESC',
  LocalizationProjectXlanguagesMaxUpdatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_MAX_UPDATED_AT_ASC',
  LocalizationProjectXlanguagesMaxUpdatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_MAX_UPDATED_AT_DESC',
  LocalizationProjectXlanguagesMinCreatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_MIN_CREATED_AT_ASC',
  LocalizationProjectXlanguagesMinCreatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_MIN_CREATED_AT_DESC',
  LocalizationProjectXlanguagesMinIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_MIN_ID_ASC',
  LocalizationProjectXlanguagesMinIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_MIN_ID_DESC',
  LocalizationProjectXlanguagesMinLocalizationLanguageIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_MIN_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationProjectXlanguagesMinLocalizationLanguageIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_MIN_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationProjectXlanguagesMinLocalizationProjectIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_MIN_LOCALIZATION_PROJECT_ID_ASC',
  LocalizationProjectXlanguagesMinLocalizationProjectIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_MIN_LOCALIZATION_PROJECT_ID_DESC',
  LocalizationProjectXlanguagesMinUpdatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_MIN_UPDATED_AT_ASC',
  LocalizationProjectXlanguagesMinUpdatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_MIN_UPDATED_AT_DESC',
  LocalizationProjectXlanguagesStddevPopulationCreatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_POPULATION_CREATED_AT_ASC',
  LocalizationProjectXlanguagesStddevPopulationCreatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_POPULATION_CREATED_AT_DESC',
  LocalizationProjectXlanguagesStddevPopulationIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_POPULATION_ID_ASC',
  LocalizationProjectXlanguagesStddevPopulationIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_POPULATION_ID_DESC',
  LocalizationProjectXlanguagesStddevPopulationLocalizationLanguageIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_POPULATION_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationProjectXlanguagesStddevPopulationLocalizationLanguageIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_POPULATION_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationProjectXlanguagesStddevPopulationLocalizationProjectIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_POPULATION_LOCALIZATION_PROJECT_ID_ASC',
  LocalizationProjectXlanguagesStddevPopulationLocalizationProjectIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_POPULATION_LOCALIZATION_PROJECT_ID_DESC',
  LocalizationProjectXlanguagesStddevPopulationUpdatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_POPULATION_UPDATED_AT_ASC',
  LocalizationProjectXlanguagesStddevPopulationUpdatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_POPULATION_UPDATED_AT_DESC',
  LocalizationProjectXlanguagesStddevSampleCreatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_SAMPLE_CREATED_AT_ASC',
  LocalizationProjectXlanguagesStddevSampleCreatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_SAMPLE_CREATED_AT_DESC',
  LocalizationProjectXlanguagesStddevSampleIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_SAMPLE_ID_ASC',
  LocalizationProjectXlanguagesStddevSampleIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_SAMPLE_ID_DESC',
  LocalizationProjectXlanguagesStddevSampleLocalizationLanguageIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_SAMPLE_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationProjectXlanguagesStddevSampleLocalizationLanguageIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_SAMPLE_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationProjectXlanguagesStddevSampleLocalizationProjectIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_SAMPLE_LOCALIZATION_PROJECT_ID_ASC',
  LocalizationProjectXlanguagesStddevSampleLocalizationProjectIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_SAMPLE_LOCALIZATION_PROJECT_ID_DESC',
  LocalizationProjectXlanguagesStddevSampleUpdatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LocalizationProjectXlanguagesStddevSampleUpdatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LocalizationProjectXlanguagesSumCreatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_SUM_CREATED_AT_ASC',
  LocalizationProjectXlanguagesSumCreatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_SUM_CREATED_AT_DESC',
  LocalizationProjectXlanguagesSumIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_SUM_ID_ASC',
  LocalizationProjectXlanguagesSumIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_SUM_ID_DESC',
  LocalizationProjectXlanguagesSumLocalizationLanguageIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_SUM_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationProjectXlanguagesSumLocalizationLanguageIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_SUM_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationProjectXlanguagesSumLocalizationProjectIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_SUM_LOCALIZATION_PROJECT_ID_ASC',
  LocalizationProjectXlanguagesSumLocalizationProjectIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_SUM_LOCALIZATION_PROJECT_ID_DESC',
  LocalizationProjectXlanguagesSumUpdatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_SUM_UPDATED_AT_ASC',
  LocalizationProjectXlanguagesSumUpdatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_SUM_UPDATED_AT_DESC',
  LocalizationProjectXlanguagesVariancePopulationCreatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_POPULATION_CREATED_AT_ASC',
  LocalizationProjectXlanguagesVariancePopulationCreatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_POPULATION_CREATED_AT_DESC',
  LocalizationProjectXlanguagesVariancePopulationIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_POPULATION_ID_ASC',
  LocalizationProjectXlanguagesVariancePopulationIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_POPULATION_ID_DESC',
  LocalizationProjectXlanguagesVariancePopulationLocalizationLanguageIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_POPULATION_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationProjectXlanguagesVariancePopulationLocalizationLanguageIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_POPULATION_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationProjectXlanguagesVariancePopulationLocalizationProjectIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_POPULATION_LOCALIZATION_PROJECT_ID_ASC',
  LocalizationProjectXlanguagesVariancePopulationLocalizationProjectIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_POPULATION_LOCALIZATION_PROJECT_ID_DESC',
  LocalizationProjectXlanguagesVariancePopulationUpdatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LocalizationProjectXlanguagesVariancePopulationUpdatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_POPULATION_UPDATED_AT_DESC',
  LocalizationProjectXlanguagesVarianceSampleCreatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LocalizationProjectXlanguagesVarianceSampleCreatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LocalizationProjectXlanguagesVarianceSampleIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_SAMPLE_ID_ASC',
  LocalizationProjectXlanguagesVarianceSampleIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_SAMPLE_ID_DESC',
  LocalizationProjectXlanguagesVarianceSampleLocalizationLanguageIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_SAMPLE_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationProjectXlanguagesVarianceSampleLocalizationLanguageIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_SAMPLE_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationProjectXlanguagesVarianceSampleLocalizationProjectIdAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_SAMPLE_LOCALIZATION_PROJECT_ID_ASC',
  LocalizationProjectXlanguagesVarianceSampleLocalizationProjectIdDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_SAMPLE_LOCALIZATION_PROJECT_ID_DESC',
  LocalizationProjectXlanguagesVarianceSampleUpdatedAtAsc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LocalizationProjectXlanguagesVarianceSampleUpdatedAtDesc = 'LOCALIZATION_PROJECT_XLANGUAGES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  LocalizationTranslationKeysAverageCommentAsc = 'LOCALIZATION_TRANSLATION_KEYS_AVERAGE_COMMENT_ASC',
  LocalizationTranslationKeysAverageCommentDesc = 'LOCALIZATION_TRANSLATION_KEYS_AVERAGE_COMMENT_DESC',
  LocalizationTranslationKeysAverageCreatedAtAsc = 'LOCALIZATION_TRANSLATION_KEYS_AVERAGE_CREATED_AT_ASC',
  LocalizationTranslationKeysAverageCreatedAtDesc = 'LOCALIZATION_TRANSLATION_KEYS_AVERAGE_CREATED_AT_DESC',
  LocalizationTranslationKeysAverageIdAsc = 'LOCALIZATION_TRANSLATION_KEYS_AVERAGE_ID_ASC',
  LocalizationTranslationKeysAverageIdDesc = 'LOCALIZATION_TRANSLATION_KEYS_AVERAGE_ID_DESC',
  LocalizationTranslationKeysAverageKeyAsc = 'LOCALIZATION_TRANSLATION_KEYS_AVERAGE_KEY_ASC',
  LocalizationTranslationKeysAverageKeyDesc = 'LOCALIZATION_TRANSLATION_KEYS_AVERAGE_KEY_DESC',
  LocalizationTranslationKeysAverageLocalizationProjectIdAsc = 'LOCALIZATION_TRANSLATION_KEYS_AVERAGE_LOCALIZATION_PROJECT_ID_ASC',
  LocalizationTranslationKeysAverageLocalizationProjectIdDesc = 'LOCALIZATION_TRANSLATION_KEYS_AVERAGE_LOCALIZATION_PROJECT_ID_DESC',
  LocalizationTranslationKeysAveragePoReferencesAsc = 'LOCALIZATION_TRANSLATION_KEYS_AVERAGE_PO_REFERENCES_ASC',
  LocalizationTranslationKeysAveragePoReferencesDesc = 'LOCALIZATION_TRANSLATION_KEYS_AVERAGE_PO_REFERENCES_DESC',
  LocalizationTranslationKeysAverageUpdatedAtAsc = 'LOCALIZATION_TRANSLATION_KEYS_AVERAGE_UPDATED_AT_ASC',
  LocalizationTranslationKeysAverageUpdatedAtDesc = 'LOCALIZATION_TRANSLATION_KEYS_AVERAGE_UPDATED_AT_DESC',
  LocalizationTranslationKeysCountAsc = 'LOCALIZATION_TRANSLATION_KEYS_COUNT_ASC',
  LocalizationTranslationKeysCountDesc = 'LOCALIZATION_TRANSLATION_KEYS_COUNT_DESC',
  LocalizationTranslationKeysDistinctCountCommentAsc = 'LOCALIZATION_TRANSLATION_KEYS_DISTINCT_COUNT_COMMENT_ASC',
  LocalizationTranslationKeysDistinctCountCommentDesc = 'LOCALIZATION_TRANSLATION_KEYS_DISTINCT_COUNT_COMMENT_DESC',
  LocalizationTranslationKeysDistinctCountCreatedAtAsc = 'LOCALIZATION_TRANSLATION_KEYS_DISTINCT_COUNT_CREATED_AT_ASC',
  LocalizationTranslationKeysDistinctCountCreatedAtDesc = 'LOCALIZATION_TRANSLATION_KEYS_DISTINCT_COUNT_CREATED_AT_DESC',
  LocalizationTranslationKeysDistinctCountIdAsc = 'LOCALIZATION_TRANSLATION_KEYS_DISTINCT_COUNT_ID_ASC',
  LocalizationTranslationKeysDistinctCountIdDesc = 'LOCALIZATION_TRANSLATION_KEYS_DISTINCT_COUNT_ID_DESC',
  LocalizationTranslationKeysDistinctCountKeyAsc = 'LOCALIZATION_TRANSLATION_KEYS_DISTINCT_COUNT_KEY_ASC',
  LocalizationTranslationKeysDistinctCountKeyDesc = 'LOCALIZATION_TRANSLATION_KEYS_DISTINCT_COUNT_KEY_DESC',
  LocalizationTranslationKeysDistinctCountLocalizationProjectIdAsc = 'LOCALIZATION_TRANSLATION_KEYS_DISTINCT_COUNT_LOCALIZATION_PROJECT_ID_ASC',
  LocalizationTranslationKeysDistinctCountLocalizationProjectIdDesc = 'LOCALIZATION_TRANSLATION_KEYS_DISTINCT_COUNT_LOCALIZATION_PROJECT_ID_DESC',
  LocalizationTranslationKeysDistinctCountPoReferencesAsc = 'LOCALIZATION_TRANSLATION_KEYS_DISTINCT_COUNT_PO_REFERENCES_ASC',
  LocalizationTranslationKeysDistinctCountPoReferencesDesc = 'LOCALIZATION_TRANSLATION_KEYS_DISTINCT_COUNT_PO_REFERENCES_DESC',
  LocalizationTranslationKeysDistinctCountUpdatedAtAsc = 'LOCALIZATION_TRANSLATION_KEYS_DISTINCT_COUNT_UPDATED_AT_ASC',
  LocalizationTranslationKeysDistinctCountUpdatedAtDesc = 'LOCALIZATION_TRANSLATION_KEYS_DISTINCT_COUNT_UPDATED_AT_DESC',
  LocalizationTranslationKeysMaxCommentAsc = 'LOCALIZATION_TRANSLATION_KEYS_MAX_COMMENT_ASC',
  LocalizationTranslationKeysMaxCommentDesc = 'LOCALIZATION_TRANSLATION_KEYS_MAX_COMMENT_DESC',
  LocalizationTranslationKeysMaxCreatedAtAsc = 'LOCALIZATION_TRANSLATION_KEYS_MAX_CREATED_AT_ASC',
  LocalizationTranslationKeysMaxCreatedAtDesc = 'LOCALIZATION_TRANSLATION_KEYS_MAX_CREATED_AT_DESC',
  LocalizationTranslationKeysMaxIdAsc = 'LOCALIZATION_TRANSLATION_KEYS_MAX_ID_ASC',
  LocalizationTranslationKeysMaxIdDesc = 'LOCALIZATION_TRANSLATION_KEYS_MAX_ID_DESC',
  LocalizationTranslationKeysMaxKeyAsc = 'LOCALIZATION_TRANSLATION_KEYS_MAX_KEY_ASC',
  LocalizationTranslationKeysMaxKeyDesc = 'LOCALIZATION_TRANSLATION_KEYS_MAX_KEY_DESC',
  LocalizationTranslationKeysMaxLocalizationProjectIdAsc = 'LOCALIZATION_TRANSLATION_KEYS_MAX_LOCALIZATION_PROJECT_ID_ASC',
  LocalizationTranslationKeysMaxLocalizationProjectIdDesc = 'LOCALIZATION_TRANSLATION_KEYS_MAX_LOCALIZATION_PROJECT_ID_DESC',
  LocalizationTranslationKeysMaxPoReferencesAsc = 'LOCALIZATION_TRANSLATION_KEYS_MAX_PO_REFERENCES_ASC',
  LocalizationTranslationKeysMaxPoReferencesDesc = 'LOCALIZATION_TRANSLATION_KEYS_MAX_PO_REFERENCES_DESC',
  LocalizationTranslationKeysMaxUpdatedAtAsc = 'LOCALIZATION_TRANSLATION_KEYS_MAX_UPDATED_AT_ASC',
  LocalizationTranslationKeysMaxUpdatedAtDesc = 'LOCALIZATION_TRANSLATION_KEYS_MAX_UPDATED_AT_DESC',
  LocalizationTranslationKeysMinCommentAsc = 'LOCALIZATION_TRANSLATION_KEYS_MIN_COMMENT_ASC',
  LocalizationTranslationKeysMinCommentDesc = 'LOCALIZATION_TRANSLATION_KEYS_MIN_COMMENT_DESC',
  LocalizationTranslationKeysMinCreatedAtAsc = 'LOCALIZATION_TRANSLATION_KEYS_MIN_CREATED_AT_ASC',
  LocalizationTranslationKeysMinCreatedAtDesc = 'LOCALIZATION_TRANSLATION_KEYS_MIN_CREATED_AT_DESC',
  LocalizationTranslationKeysMinIdAsc = 'LOCALIZATION_TRANSLATION_KEYS_MIN_ID_ASC',
  LocalizationTranslationKeysMinIdDesc = 'LOCALIZATION_TRANSLATION_KEYS_MIN_ID_DESC',
  LocalizationTranslationKeysMinKeyAsc = 'LOCALIZATION_TRANSLATION_KEYS_MIN_KEY_ASC',
  LocalizationTranslationKeysMinKeyDesc = 'LOCALIZATION_TRANSLATION_KEYS_MIN_KEY_DESC',
  LocalizationTranslationKeysMinLocalizationProjectIdAsc = 'LOCALIZATION_TRANSLATION_KEYS_MIN_LOCALIZATION_PROJECT_ID_ASC',
  LocalizationTranslationKeysMinLocalizationProjectIdDesc = 'LOCALIZATION_TRANSLATION_KEYS_MIN_LOCALIZATION_PROJECT_ID_DESC',
  LocalizationTranslationKeysMinPoReferencesAsc = 'LOCALIZATION_TRANSLATION_KEYS_MIN_PO_REFERENCES_ASC',
  LocalizationTranslationKeysMinPoReferencesDesc = 'LOCALIZATION_TRANSLATION_KEYS_MIN_PO_REFERENCES_DESC',
  LocalizationTranslationKeysMinUpdatedAtAsc = 'LOCALIZATION_TRANSLATION_KEYS_MIN_UPDATED_AT_ASC',
  LocalizationTranslationKeysMinUpdatedAtDesc = 'LOCALIZATION_TRANSLATION_KEYS_MIN_UPDATED_AT_DESC',
  LocalizationTranslationKeysStddevPopulationCommentAsc = 'LOCALIZATION_TRANSLATION_KEYS_STDDEV_POPULATION_COMMENT_ASC',
  LocalizationTranslationKeysStddevPopulationCommentDesc = 'LOCALIZATION_TRANSLATION_KEYS_STDDEV_POPULATION_COMMENT_DESC',
  LocalizationTranslationKeysStddevPopulationCreatedAtAsc = 'LOCALIZATION_TRANSLATION_KEYS_STDDEV_POPULATION_CREATED_AT_ASC',
  LocalizationTranslationKeysStddevPopulationCreatedAtDesc = 'LOCALIZATION_TRANSLATION_KEYS_STDDEV_POPULATION_CREATED_AT_DESC',
  LocalizationTranslationKeysStddevPopulationIdAsc = 'LOCALIZATION_TRANSLATION_KEYS_STDDEV_POPULATION_ID_ASC',
  LocalizationTranslationKeysStddevPopulationIdDesc = 'LOCALIZATION_TRANSLATION_KEYS_STDDEV_POPULATION_ID_DESC',
  LocalizationTranslationKeysStddevPopulationKeyAsc = 'LOCALIZATION_TRANSLATION_KEYS_STDDEV_POPULATION_KEY_ASC',
  LocalizationTranslationKeysStddevPopulationKeyDesc = 'LOCALIZATION_TRANSLATION_KEYS_STDDEV_POPULATION_KEY_DESC',
  LocalizationTranslationKeysStddevPopulationLocalizationProjectIdAsc = 'LOCALIZATION_TRANSLATION_KEYS_STDDEV_POPULATION_LOCALIZATION_PROJECT_ID_ASC',
  LocalizationTranslationKeysStddevPopulationLocalizationProjectIdDesc = 'LOCALIZATION_TRANSLATION_KEYS_STDDEV_POPULATION_LOCALIZATION_PROJECT_ID_DESC',
  LocalizationTranslationKeysStddevPopulationPoReferencesAsc = 'LOCALIZATION_TRANSLATION_KEYS_STDDEV_POPULATION_PO_REFERENCES_ASC',
  LocalizationTranslationKeysStddevPopulationPoReferencesDesc = 'LOCALIZATION_TRANSLATION_KEYS_STDDEV_POPULATION_PO_REFERENCES_DESC',
  LocalizationTranslationKeysStddevPopulationUpdatedAtAsc = 'LOCALIZATION_TRANSLATION_KEYS_STDDEV_POPULATION_UPDATED_AT_ASC',
  LocalizationTranslationKeysStddevPopulationUpdatedAtDesc = 'LOCALIZATION_TRANSLATION_KEYS_STDDEV_POPULATION_UPDATED_AT_DESC',
  LocalizationTranslationKeysStddevSampleCommentAsc = 'LOCALIZATION_TRANSLATION_KEYS_STDDEV_SAMPLE_COMMENT_ASC',
  LocalizationTranslationKeysStddevSampleCommentDesc = 'LOCALIZATION_TRANSLATION_KEYS_STDDEV_SAMPLE_COMMENT_DESC',
  LocalizationTranslationKeysStddevSampleCreatedAtAsc = 'LOCALIZATION_TRANSLATION_KEYS_STDDEV_SAMPLE_CREATED_AT_ASC',
  LocalizationTranslationKeysStddevSampleCreatedAtDesc = 'LOCALIZATION_TRANSLATION_KEYS_STDDEV_SAMPLE_CREATED_AT_DESC',
  LocalizationTranslationKeysStddevSampleIdAsc = 'LOCALIZATION_TRANSLATION_KEYS_STDDEV_SAMPLE_ID_ASC',
  LocalizationTranslationKeysStddevSampleIdDesc = 'LOCALIZATION_TRANSLATION_KEYS_STDDEV_SAMPLE_ID_DESC',
  LocalizationTranslationKeysStddevSampleKeyAsc = 'LOCALIZATION_TRANSLATION_KEYS_STDDEV_SAMPLE_KEY_ASC',
  LocalizationTranslationKeysStddevSampleKeyDesc = 'LOCALIZATION_TRANSLATION_KEYS_STDDEV_SAMPLE_KEY_DESC',
  LocalizationTranslationKeysStddevSampleLocalizationProjectIdAsc = 'LOCALIZATION_TRANSLATION_KEYS_STDDEV_SAMPLE_LOCALIZATION_PROJECT_ID_ASC',
  LocalizationTranslationKeysStddevSampleLocalizationProjectIdDesc = 'LOCALIZATION_TRANSLATION_KEYS_STDDEV_SAMPLE_LOCALIZATION_PROJECT_ID_DESC',
  LocalizationTranslationKeysStddevSamplePoReferencesAsc = 'LOCALIZATION_TRANSLATION_KEYS_STDDEV_SAMPLE_PO_REFERENCES_ASC',
  LocalizationTranslationKeysStddevSamplePoReferencesDesc = 'LOCALIZATION_TRANSLATION_KEYS_STDDEV_SAMPLE_PO_REFERENCES_DESC',
  LocalizationTranslationKeysStddevSampleUpdatedAtAsc = 'LOCALIZATION_TRANSLATION_KEYS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LocalizationTranslationKeysStddevSampleUpdatedAtDesc = 'LOCALIZATION_TRANSLATION_KEYS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LocalizationTranslationKeysSumCommentAsc = 'LOCALIZATION_TRANSLATION_KEYS_SUM_COMMENT_ASC',
  LocalizationTranslationKeysSumCommentDesc = 'LOCALIZATION_TRANSLATION_KEYS_SUM_COMMENT_DESC',
  LocalizationTranslationKeysSumCreatedAtAsc = 'LOCALIZATION_TRANSLATION_KEYS_SUM_CREATED_AT_ASC',
  LocalizationTranslationKeysSumCreatedAtDesc = 'LOCALIZATION_TRANSLATION_KEYS_SUM_CREATED_AT_DESC',
  LocalizationTranslationKeysSumIdAsc = 'LOCALIZATION_TRANSLATION_KEYS_SUM_ID_ASC',
  LocalizationTranslationKeysSumIdDesc = 'LOCALIZATION_TRANSLATION_KEYS_SUM_ID_DESC',
  LocalizationTranslationKeysSumKeyAsc = 'LOCALIZATION_TRANSLATION_KEYS_SUM_KEY_ASC',
  LocalizationTranslationKeysSumKeyDesc = 'LOCALIZATION_TRANSLATION_KEYS_SUM_KEY_DESC',
  LocalizationTranslationKeysSumLocalizationProjectIdAsc = 'LOCALIZATION_TRANSLATION_KEYS_SUM_LOCALIZATION_PROJECT_ID_ASC',
  LocalizationTranslationKeysSumLocalizationProjectIdDesc = 'LOCALIZATION_TRANSLATION_KEYS_SUM_LOCALIZATION_PROJECT_ID_DESC',
  LocalizationTranslationKeysSumPoReferencesAsc = 'LOCALIZATION_TRANSLATION_KEYS_SUM_PO_REFERENCES_ASC',
  LocalizationTranslationKeysSumPoReferencesDesc = 'LOCALIZATION_TRANSLATION_KEYS_SUM_PO_REFERENCES_DESC',
  LocalizationTranslationKeysSumUpdatedAtAsc = 'LOCALIZATION_TRANSLATION_KEYS_SUM_UPDATED_AT_ASC',
  LocalizationTranslationKeysSumUpdatedAtDesc = 'LOCALIZATION_TRANSLATION_KEYS_SUM_UPDATED_AT_DESC',
  LocalizationTranslationKeysVariancePopulationCommentAsc = 'LOCALIZATION_TRANSLATION_KEYS_VARIANCE_POPULATION_COMMENT_ASC',
  LocalizationTranslationKeysVariancePopulationCommentDesc = 'LOCALIZATION_TRANSLATION_KEYS_VARIANCE_POPULATION_COMMENT_DESC',
  LocalizationTranslationKeysVariancePopulationCreatedAtAsc = 'LOCALIZATION_TRANSLATION_KEYS_VARIANCE_POPULATION_CREATED_AT_ASC',
  LocalizationTranslationKeysVariancePopulationCreatedAtDesc = 'LOCALIZATION_TRANSLATION_KEYS_VARIANCE_POPULATION_CREATED_AT_DESC',
  LocalizationTranslationKeysVariancePopulationIdAsc = 'LOCALIZATION_TRANSLATION_KEYS_VARIANCE_POPULATION_ID_ASC',
  LocalizationTranslationKeysVariancePopulationIdDesc = 'LOCALIZATION_TRANSLATION_KEYS_VARIANCE_POPULATION_ID_DESC',
  LocalizationTranslationKeysVariancePopulationKeyAsc = 'LOCALIZATION_TRANSLATION_KEYS_VARIANCE_POPULATION_KEY_ASC',
  LocalizationTranslationKeysVariancePopulationKeyDesc = 'LOCALIZATION_TRANSLATION_KEYS_VARIANCE_POPULATION_KEY_DESC',
  LocalizationTranslationKeysVariancePopulationLocalizationProjectIdAsc = 'LOCALIZATION_TRANSLATION_KEYS_VARIANCE_POPULATION_LOCALIZATION_PROJECT_ID_ASC',
  LocalizationTranslationKeysVariancePopulationLocalizationProjectIdDesc = 'LOCALIZATION_TRANSLATION_KEYS_VARIANCE_POPULATION_LOCALIZATION_PROJECT_ID_DESC',
  LocalizationTranslationKeysVariancePopulationPoReferencesAsc = 'LOCALIZATION_TRANSLATION_KEYS_VARIANCE_POPULATION_PO_REFERENCES_ASC',
  LocalizationTranslationKeysVariancePopulationPoReferencesDesc = 'LOCALIZATION_TRANSLATION_KEYS_VARIANCE_POPULATION_PO_REFERENCES_DESC',
  LocalizationTranslationKeysVariancePopulationUpdatedAtAsc = 'LOCALIZATION_TRANSLATION_KEYS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LocalizationTranslationKeysVariancePopulationUpdatedAtDesc = 'LOCALIZATION_TRANSLATION_KEYS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  LocalizationTranslationKeysVarianceSampleCommentAsc = 'LOCALIZATION_TRANSLATION_KEYS_VARIANCE_SAMPLE_COMMENT_ASC',
  LocalizationTranslationKeysVarianceSampleCommentDesc = 'LOCALIZATION_TRANSLATION_KEYS_VARIANCE_SAMPLE_COMMENT_DESC',
  LocalizationTranslationKeysVarianceSampleCreatedAtAsc = 'LOCALIZATION_TRANSLATION_KEYS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LocalizationTranslationKeysVarianceSampleCreatedAtDesc = 'LOCALIZATION_TRANSLATION_KEYS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LocalizationTranslationKeysVarianceSampleIdAsc = 'LOCALIZATION_TRANSLATION_KEYS_VARIANCE_SAMPLE_ID_ASC',
  LocalizationTranslationKeysVarianceSampleIdDesc = 'LOCALIZATION_TRANSLATION_KEYS_VARIANCE_SAMPLE_ID_DESC',
  LocalizationTranslationKeysVarianceSampleKeyAsc = 'LOCALIZATION_TRANSLATION_KEYS_VARIANCE_SAMPLE_KEY_ASC',
  LocalizationTranslationKeysVarianceSampleKeyDesc = 'LOCALIZATION_TRANSLATION_KEYS_VARIANCE_SAMPLE_KEY_DESC',
  LocalizationTranslationKeysVarianceSampleLocalizationProjectIdAsc = 'LOCALIZATION_TRANSLATION_KEYS_VARIANCE_SAMPLE_LOCALIZATION_PROJECT_ID_ASC',
  LocalizationTranslationKeysVarianceSampleLocalizationProjectIdDesc = 'LOCALIZATION_TRANSLATION_KEYS_VARIANCE_SAMPLE_LOCALIZATION_PROJECT_ID_DESC',
  LocalizationTranslationKeysVarianceSamplePoReferencesAsc = 'LOCALIZATION_TRANSLATION_KEYS_VARIANCE_SAMPLE_PO_REFERENCES_ASC',
  LocalizationTranslationKeysVarianceSamplePoReferencesDesc = 'LOCALIZATION_TRANSLATION_KEYS_VARIANCE_SAMPLE_PO_REFERENCES_DESC',
  LocalizationTranslationKeysVarianceSampleUpdatedAtAsc = 'LOCALIZATION_TRANSLATION_KEYS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LocalizationTranslationKeysVarianceSampleUpdatedAtDesc = 'LOCALIZATION_TRANSLATION_KEYS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type LocalizationTranslationKey = Node & {
  __typename?: 'LocalizationTranslationKey';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Datetime']['output'];
  id: Scalars['UUID']['output'];
  key: Scalars['String']['output'];
  /** Reads a single `LocalizationProject` that is related to this `LocalizationTranslationKey`. */
  localizationProject?: Maybe<LocalizationProject>;
  localizationProjectId?: Maybe<Scalars['UUID']['output']>;
  /** Reads and enables pagination through a set of `LocalizationTranslationValue`. */
  localizationTranslationValues: LocalizationTranslationValuesConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  poReferences?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Datetime']['output'];
};


export type LocalizationTranslationKeyLocalizationTranslationValuesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LocalizationTranslationValueCondition>;
  filter?: InputMaybe<LocalizationTranslationValueFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LocalizationTranslationValuesOrderBy>>;
};

export type LocalizationTranslationKeyAggregates = {
  __typename?: 'LocalizationTranslationKeyAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LocalizationTranslationKeyDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * A condition to be used against `LocalizationTranslationKey` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type LocalizationTranslationKeyCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `localizationProjectId` field. */
  localizationProjectId?: InputMaybe<Scalars['UUID']['input']>;
};

export type LocalizationTranslationKeyDistinctCountAggregates = {
  __typename?: 'LocalizationTranslationKeyDistinctCountAggregates';
  /** Distinct count of comment across the matching connection */
  comment?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of key across the matching connection */
  key?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of localizationProjectId across the matching connection */
  localizationProjectId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of poReferences across the matching connection */
  poReferences?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `LocalizationTranslationKey` object types. All fields are combined with a logical ‘and.’ */
export type LocalizationTranslationKeyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LocalizationTranslationKeyFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `localizationProjectId` field. */
  localizationProjectId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LocalizationTranslationKeyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LocalizationTranslationKeyFilter>>;
};

/** Grouping methods for `LocalizationTranslationKey` for usage during aggregation. */
export enum LocalizationTranslationKeyGroupBy {
  LocalizationProjectId = 'LOCALIZATION_PROJECT_ID'
}

export type LocalizationTranslationKeyHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LocalizationTranslationKeyHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `LocalizationTranslationKey` aggregates. */
export type LocalizationTranslationKeyHavingInput = {
  AND?: InputMaybe<Array<LocalizationTranslationKeyHavingInput>>;
  OR?: InputMaybe<Array<LocalizationTranslationKeyHavingInput>>;
  average?: InputMaybe<LocalizationTranslationKeyHavingAverageInput>;
  distinctCount?: InputMaybe<LocalizationTranslationKeyHavingDistinctCountInput>;
  max?: InputMaybe<LocalizationTranslationKeyHavingMaxInput>;
  min?: InputMaybe<LocalizationTranslationKeyHavingMinInput>;
  stddevPopulation?: InputMaybe<LocalizationTranslationKeyHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LocalizationTranslationKeyHavingStddevSampleInput>;
  sum?: InputMaybe<LocalizationTranslationKeyHavingSumInput>;
  variancePopulation?: InputMaybe<LocalizationTranslationKeyHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LocalizationTranslationKeyHavingVarianceSampleInput>;
};

export type LocalizationTranslationKeyHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LocalizationTranslationKeyHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LocalizationTranslationKeyHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LocalizationTranslationKeyHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LocalizationTranslationKeyHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LocalizationTranslationKeyHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LocalizationTranslationKeyHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `LocalizationTranslationKey` */
export type LocalizationTranslationKeyInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  key: Scalars['String']['input'];
  localizationProjectId?: InputMaybe<Scalars['UUID']['input']>;
  poReferences?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** Represents an update to a `LocalizationTranslationKey`. Fields that are set will be updated. */
export type LocalizationTranslationKeyPatch = {
  comment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  localizationProjectId?: InputMaybe<Scalars['UUID']['input']>;
  poReferences?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A connection to a list of `LocalizationTranslationKey` values. */
export type LocalizationTranslationKeysConnection = {
  __typename?: 'LocalizationTranslationKeysConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LocalizationTranslationKeyAggregates>;
  /** A list of edges which contains the `LocalizationTranslationKey` and cursor to aid in pagination. */
  edges: Array<LocalizationTranslationKeysEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LocalizationTranslationKeyAggregates>>;
  /** A list of `LocalizationTranslationKey` objects. */
  nodes: Array<LocalizationTranslationKey>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LocalizationTranslationKey` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `LocalizationTranslationKey` values. */
export type LocalizationTranslationKeysConnectionGroupedAggregatesArgs = {
  groupBy: Array<LocalizationTranslationKeyGroupBy>;
  having?: InputMaybe<LocalizationTranslationKeyHavingInput>;
};

/** A `LocalizationTranslationKey` edge in the connection. */
export type LocalizationTranslationKeysEdge = {
  __typename?: 'LocalizationTranslationKeysEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `LocalizationTranslationKey` at the end of the edge. */
  node: LocalizationTranslationKey;
};

/** Methods to use when ordering `LocalizationTranslationKey`. */
export enum LocalizationTranslationKeysOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LocalizationProjectIdAsc = 'LOCALIZATION_PROJECT_ID_ASC',
  LocalizationProjectIdDesc = 'LOCALIZATION_PROJECT_ID_DESC',
  LocalizationTranslationValuesAverageCreatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_AVERAGE_CREATED_AT_ASC',
  LocalizationTranslationValuesAverageCreatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_AVERAGE_CREATED_AT_DESC',
  LocalizationTranslationValuesAverageIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_AVERAGE_ID_ASC',
  LocalizationTranslationValuesAverageIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_AVERAGE_ID_DESC',
  LocalizationTranslationValuesAverageLocalizationLanguageIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_AVERAGE_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationTranslationValuesAverageLocalizationLanguageIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_AVERAGE_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationTranslationValuesAverageLocalizationTranslationKeyIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_AVERAGE_LOCALIZATION_TRANSLATION_KEY_ID_ASC',
  LocalizationTranslationValuesAverageLocalizationTranslationKeyIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_AVERAGE_LOCALIZATION_TRANSLATION_KEY_ID_DESC',
  LocalizationTranslationValuesAverageUpdatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_AVERAGE_UPDATED_AT_ASC',
  LocalizationTranslationValuesAverageUpdatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_AVERAGE_UPDATED_AT_DESC',
  LocalizationTranslationValuesAverageValueAsc = 'LOCALIZATION_TRANSLATION_VALUES_AVERAGE_VALUE_ASC',
  LocalizationTranslationValuesAverageValueDesc = 'LOCALIZATION_TRANSLATION_VALUES_AVERAGE_VALUE_DESC',
  LocalizationTranslationValuesCountAsc = 'LOCALIZATION_TRANSLATION_VALUES_COUNT_ASC',
  LocalizationTranslationValuesCountDesc = 'LOCALIZATION_TRANSLATION_VALUES_COUNT_DESC',
  LocalizationTranslationValuesDistinctCountCreatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_DISTINCT_COUNT_CREATED_AT_ASC',
  LocalizationTranslationValuesDistinctCountCreatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_DISTINCT_COUNT_CREATED_AT_DESC',
  LocalizationTranslationValuesDistinctCountIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_DISTINCT_COUNT_ID_ASC',
  LocalizationTranslationValuesDistinctCountIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_DISTINCT_COUNT_ID_DESC',
  LocalizationTranslationValuesDistinctCountLocalizationLanguageIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_DISTINCT_COUNT_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationTranslationValuesDistinctCountLocalizationLanguageIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_DISTINCT_COUNT_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationTranslationValuesDistinctCountLocalizationTranslationKeyIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_DISTINCT_COUNT_LOCALIZATION_TRANSLATION_KEY_ID_ASC',
  LocalizationTranslationValuesDistinctCountLocalizationTranslationKeyIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_DISTINCT_COUNT_LOCALIZATION_TRANSLATION_KEY_ID_DESC',
  LocalizationTranslationValuesDistinctCountUpdatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_DISTINCT_COUNT_UPDATED_AT_ASC',
  LocalizationTranslationValuesDistinctCountUpdatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_DISTINCT_COUNT_UPDATED_AT_DESC',
  LocalizationTranslationValuesDistinctCountValueAsc = 'LOCALIZATION_TRANSLATION_VALUES_DISTINCT_COUNT_VALUE_ASC',
  LocalizationTranslationValuesDistinctCountValueDesc = 'LOCALIZATION_TRANSLATION_VALUES_DISTINCT_COUNT_VALUE_DESC',
  LocalizationTranslationValuesMaxCreatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_MAX_CREATED_AT_ASC',
  LocalizationTranslationValuesMaxCreatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_MAX_CREATED_AT_DESC',
  LocalizationTranslationValuesMaxIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_MAX_ID_ASC',
  LocalizationTranslationValuesMaxIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_MAX_ID_DESC',
  LocalizationTranslationValuesMaxLocalizationLanguageIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_MAX_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationTranslationValuesMaxLocalizationLanguageIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_MAX_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationTranslationValuesMaxLocalizationTranslationKeyIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_MAX_LOCALIZATION_TRANSLATION_KEY_ID_ASC',
  LocalizationTranslationValuesMaxLocalizationTranslationKeyIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_MAX_LOCALIZATION_TRANSLATION_KEY_ID_DESC',
  LocalizationTranslationValuesMaxUpdatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_MAX_UPDATED_AT_ASC',
  LocalizationTranslationValuesMaxUpdatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_MAX_UPDATED_AT_DESC',
  LocalizationTranslationValuesMaxValueAsc = 'LOCALIZATION_TRANSLATION_VALUES_MAX_VALUE_ASC',
  LocalizationTranslationValuesMaxValueDesc = 'LOCALIZATION_TRANSLATION_VALUES_MAX_VALUE_DESC',
  LocalizationTranslationValuesMinCreatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_MIN_CREATED_AT_ASC',
  LocalizationTranslationValuesMinCreatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_MIN_CREATED_AT_DESC',
  LocalizationTranslationValuesMinIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_MIN_ID_ASC',
  LocalizationTranslationValuesMinIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_MIN_ID_DESC',
  LocalizationTranslationValuesMinLocalizationLanguageIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_MIN_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationTranslationValuesMinLocalizationLanguageIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_MIN_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationTranslationValuesMinLocalizationTranslationKeyIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_MIN_LOCALIZATION_TRANSLATION_KEY_ID_ASC',
  LocalizationTranslationValuesMinLocalizationTranslationKeyIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_MIN_LOCALIZATION_TRANSLATION_KEY_ID_DESC',
  LocalizationTranslationValuesMinUpdatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_MIN_UPDATED_AT_ASC',
  LocalizationTranslationValuesMinUpdatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_MIN_UPDATED_AT_DESC',
  LocalizationTranslationValuesMinValueAsc = 'LOCALIZATION_TRANSLATION_VALUES_MIN_VALUE_ASC',
  LocalizationTranslationValuesMinValueDesc = 'LOCALIZATION_TRANSLATION_VALUES_MIN_VALUE_DESC',
  LocalizationTranslationValuesStddevPopulationCreatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_POPULATION_CREATED_AT_ASC',
  LocalizationTranslationValuesStddevPopulationCreatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_POPULATION_CREATED_AT_DESC',
  LocalizationTranslationValuesStddevPopulationIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_POPULATION_ID_ASC',
  LocalizationTranslationValuesStddevPopulationIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_POPULATION_ID_DESC',
  LocalizationTranslationValuesStddevPopulationLocalizationLanguageIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_POPULATION_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationTranslationValuesStddevPopulationLocalizationLanguageIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_POPULATION_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationTranslationValuesStddevPopulationLocalizationTranslationKeyIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_POPULATION_LOCALIZATION_TRANSLATION_KEY_ID_ASC',
  LocalizationTranslationValuesStddevPopulationLocalizationTranslationKeyIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_POPULATION_LOCALIZATION_TRANSLATION_KEY_ID_DESC',
  LocalizationTranslationValuesStddevPopulationUpdatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_POPULATION_UPDATED_AT_ASC',
  LocalizationTranslationValuesStddevPopulationUpdatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_POPULATION_UPDATED_AT_DESC',
  LocalizationTranslationValuesStddevPopulationValueAsc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_POPULATION_VALUE_ASC',
  LocalizationTranslationValuesStddevPopulationValueDesc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_POPULATION_VALUE_DESC',
  LocalizationTranslationValuesStddevSampleCreatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_SAMPLE_CREATED_AT_ASC',
  LocalizationTranslationValuesStddevSampleCreatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_SAMPLE_CREATED_AT_DESC',
  LocalizationTranslationValuesStddevSampleIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_SAMPLE_ID_ASC',
  LocalizationTranslationValuesStddevSampleIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_SAMPLE_ID_DESC',
  LocalizationTranslationValuesStddevSampleLocalizationLanguageIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_SAMPLE_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationTranslationValuesStddevSampleLocalizationLanguageIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_SAMPLE_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationTranslationValuesStddevSampleLocalizationTranslationKeyIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_SAMPLE_LOCALIZATION_TRANSLATION_KEY_ID_ASC',
  LocalizationTranslationValuesStddevSampleLocalizationTranslationKeyIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_SAMPLE_LOCALIZATION_TRANSLATION_KEY_ID_DESC',
  LocalizationTranslationValuesStddevSampleUpdatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LocalizationTranslationValuesStddevSampleUpdatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LocalizationTranslationValuesStddevSampleValueAsc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_SAMPLE_VALUE_ASC',
  LocalizationTranslationValuesStddevSampleValueDesc = 'LOCALIZATION_TRANSLATION_VALUES_STDDEV_SAMPLE_VALUE_DESC',
  LocalizationTranslationValuesSumCreatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_SUM_CREATED_AT_ASC',
  LocalizationTranslationValuesSumCreatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_SUM_CREATED_AT_DESC',
  LocalizationTranslationValuesSumIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_SUM_ID_ASC',
  LocalizationTranslationValuesSumIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_SUM_ID_DESC',
  LocalizationTranslationValuesSumLocalizationLanguageIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_SUM_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationTranslationValuesSumLocalizationLanguageIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_SUM_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationTranslationValuesSumLocalizationTranslationKeyIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_SUM_LOCALIZATION_TRANSLATION_KEY_ID_ASC',
  LocalizationTranslationValuesSumLocalizationTranslationKeyIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_SUM_LOCALIZATION_TRANSLATION_KEY_ID_DESC',
  LocalizationTranslationValuesSumUpdatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_SUM_UPDATED_AT_ASC',
  LocalizationTranslationValuesSumUpdatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_SUM_UPDATED_AT_DESC',
  LocalizationTranslationValuesSumValueAsc = 'LOCALIZATION_TRANSLATION_VALUES_SUM_VALUE_ASC',
  LocalizationTranslationValuesSumValueDesc = 'LOCALIZATION_TRANSLATION_VALUES_SUM_VALUE_DESC',
  LocalizationTranslationValuesVariancePopulationCreatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_POPULATION_CREATED_AT_ASC',
  LocalizationTranslationValuesVariancePopulationCreatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_POPULATION_CREATED_AT_DESC',
  LocalizationTranslationValuesVariancePopulationIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_POPULATION_ID_ASC',
  LocalizationTranslationValuesVariancePopulationIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_POPULATION_ID_DESC',
  LocalizationTranslationValuesVariancePopulationLocalizationLanguageIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_POPULATION_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationTranslationValuesVariancePopulationLocalizationLanguageIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_POPULATION_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationTranslationValuesVariancePopulationLocalizationTranslationKeyIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_POPULATION_LOCALIZATION_TRANSLATION_KEY_ID_ASC',
  LocalizationTranslationValuesVariancePopulationLocalizationTranslationKeyIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_POPULATION_LOCALIZATION_TRANSLATION_KEY_ID_DESC',
  LocalizationTranslationValuesVariancePopulationUpdatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LocalizationTranslationValuesVariancePopulationUpdatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_POPULATION_UPDATED_AT_DESC',
  LocalizationTranslationValuesVariancePopulationValueAsc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_POPULATION_VALUE_ASC',
  LocalizationTranslationValuesVariancePopulationValueDesc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_POPULATION_VALUE_DESC',
  LocalizationTranslationValuesVarianceSampleCreatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LocalizationTranslationValuesVarianceSampleCreatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LocalizationTranslationValuesVarianceSampleIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_SAMPLE_ID_ASC',
  LocalizationTranslationValuesVarianceSampleIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_SAMPLE_ID_DESC',
  LocalizationTranslationValuesVarianceSampleLocalizationLanguageIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_SAMPLE_LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationTranslationValuesVarianceSampleLocalizationLanguageIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_SAMPLE_LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationTranslationValuesVarianceSampleLocalizationTranslationKeyIdAsc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_SAMPLE_LOCALIZATION_TRANSLATION_KEY_ID_ASC',
  LocalizationTranslationValuesVarianceSampleLocalizationTranslationKeyIdDesc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_SAMPLE_LOCALIZATION_TRANSLATION_KEY_ID_DESC',
  LocalizationTranslationValuesVarianceSampleUpdatedAtAsc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LocalizationTranslationValuesVarianceSampleUpdatedAtDesc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  LocalizationTranslationValuesVarianceSampleValueAsc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_SAMPLE_VALUE_ASC',
  LocalizationTranslationValuesVarianceSampleValueDesc = 'LOCALIZATION_TRANSLATION_VALUES_VARIANCE_SAMPLE_VALUE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type LocalizationTranslationValue = Node & {
  __typename?: 'LocalizationTranslationValue';
  createdAt: Scalars['Datetime']['output'];
  id: Scalars['UUID']['output'];
  /** Reads a single `LocalizationLanguage` that is related to this `LocalizationTranslationValue`. */
  localizationLanguage?: Maybe<LocalizationLanguage>;
  localizationLanguageId?: Maybe<Scalars['UUID']['output']>;
  /** Reads a single `LocalizationTranslationKey` that is related to this `LocalizationTranslationValue`. */
  localizationTranslationKey?: Maybe<LocalizationTranslationKey>;
  localizationTranslationKeyId?: Maybe<Scalars['UUID']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  updatedAt: Scalars['Datetime']['output'];
  value: Scalars['String']['output'];
};

export type LocalizationTranslationValueAggregates = {
  __typename?: 'LocalizationTranslationValueAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LocalizationTranslationValueDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * A condition to be used against `LocalizationTranslationValue` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type LocalizationTranslationValueCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `localizationLanguageId` field. */
  localizationLanguageId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `localizationTranslationKeyId` field. */
  localizationTranslationKeyId?: InputMaybe<Scalars['UUID']['input']>;
};

export type LocalizationTranslationValueDistinctCountAggregates = {
  __typename?: 'LocalizationTranslationValueDistinctCountAggregates';
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of localizationLanguageId across the matching connection */
  localizationLanguageId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of localizationTranslationKeyId across the matching connection */
  localizationTranslationKeyId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of value across the matching connection */
  value?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `LocalizationTranslationValue` object types. All fields are combined with a logical ‘and.’ */
export type LocalizationTranslationValueFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LocalizationTranslationValueFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `localizationLanguageId` field. */
  localizationLanguageId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `localizationTranslationKeyId` field. */
  localizationTranslationKeyId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LocalizationTranslationValueFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LocalizationTranslationValueFilter>>;
};

/** Grouping methods for `LocalizationTranslationValue` for usage during aggregation. */
export enum LocalizationTranslationValueGroupBy {
  LocalizationLanguageId = 'LOCALIZATION_LANGUAGE_ID',
  LocalizationTranslationKeyId = 'LOCALIZATION_TRANSLATION_KEY_ID'
}

export type LocalizationTranslationValueHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LocalizationTranslationValueHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `LocalizationTranslationValue` aggregates. */
export type LocalizationTranslationValueHavingInput = {
  AND?: InputMaybe<Array<LocalizationTranslationValueHavingInput>>;
  OR?: InputMaybe<Array<LocalizationTranslationValueHavingInput>>;
  average?: InputMaybe<LocalizationTranslationValueHavingAverageInput>;
  distinctCount?: InputMaybe<LocalizationTranslationValueHavingDistinctCountInput>;
  max?: InputMaybe<LocalizationTranslationValueHavingMaxInput>;
  min?: InputMaybe<LocalizationTranslationValueHavingMinInput>;
  stddevPopulation?: InputMaybe<LocalizationTranslationValueHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LocalizationTranslationValueHavingStddevSampleInput>;
  sum?: InputMaybe<LocalizationTranslationValueHavingSumInput>;
  variancePopulation?: InputMaybe<LocalizationTranslationValueHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LocalizationTranslationValueHavingVarianceSampleInput>;
};

export type LocalizationTranslationValueHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LocalizationTranslationValueHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LocalizationTranslationValueHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LocalizationTranslationValueHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LocalizationTranslationValueHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LocalizationTranslationValueHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LocalizationTranslationValueHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `LocalizationTranslationValue` */
export type LocalizationTranslationValueInput = {
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  localizationLanguageId?: InputMaybe<Scalars['UUID']['input']>;
  localizationTranslationKeyId?: InputMaybe<Scalars['UUID']['input']>;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  value: Scalars['String']['input'];
};

/** Represents an update to a `LocalizationTranslationValue`. Fields that are set will be updated. */
export type LocalizationTranslationValuePatch = {
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  localizationLanguageId?: InputMaybe<Scalars['UUID']['input']>;
  localizationTranslationKeyId?: InputMaybe<Scalars['UUID']['input']>;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of `LocalizationTranslationValue` values. */
export type LocalizationTranslationValuesConnection = {
  __typename?: 'LocalizationTranslationValuesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LocalizationTranslationValueAggregates>;
  /** A list of edges which contains the `LocalizationTranslationValue` and cursor to aid in pagination. */
  edges: Array<LocalizationTranslationValuesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LocalizationTranslationValueAggregates>>;
  /** A list of `LocalizationTranslationValue` objects. */
  nodes: Array<LocalizationTranslationValue>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LocalizationTranslationValue` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `LocalizationTranslationValue` values. */
export type LocalizationTranslationValuesConnectionGroupedAggregatesArgs = {
  groupBy: Array<LocalizationTranslationValueGroupBy>;
  having?: InputMaybe<LocalizationTranslationValueHavingInput>;
};

/** A `LocalizationTranslationValue` edge in the connection. */
export type LocalizationTranslationValuesEdge = {
  __typename?: 'LocalizationTranslationValuesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `LocalizationTranslationValue` at the end of the edge. */
  node: LocalizationTranslationValue;
};

/** Methods to use when ordering `LocalizationTranslationValue`. */
export enum LocalizationTranslationValuesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LocalizationLanguageIdAsc = 'LOCALIZATION_LANGUAGE_ID_ASC',
  LocalizationLanguageIdDesc = 'LOCALIZATION_LANGUAGE_ID_DESC',
  LocalizationTranslationKeyIdAsc = 'LOCALIZATION_TRANSLATION_KEY_ID_ASC',
  LocalizationTranslationKeyIdDesc = 'LOCALIZATION_TRANSLATION_KEY_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  /** Creates a single `AppSource`. */
  createAppSource?: Maybe<CreateAppSourcePayload>;
  /** Creates a single `Crash`. */
  createCrash?: Maybe<CreateCrashPayload>;
  /** Creates a single `CrashGroup`. */
  createCrashGroup?: Maybe<CreateCrashGroupPayload>;
  /** Creates a single `File`. */
  createFile?: Maybe<CreateFilePayload>;
  /** Creates a single `Har`. */
  createHar?: Maybe<CreateHarPayload>;
  /** Creates a single `LocalizationLanguage`. */
  createLocalizationLanguage?: Maybe<CreateLocalizationLanguagePayload>;
  /** Creates a single `LocalizationProject`. */
  createLocalizationProject?: Maybe<CreateLocalizationProjectPayload>;
  /** Creates a single `LocalizationProjectXLanguage`. */
  createLocalizationProjectXLanguage?: Maybe<CreateLocalizationProjectXLanguagePayload>;
  /** Creates a single `LocalizationTranslationKey`. */
  createLocalizationTranslationKey?: Maybe<CreateLocalizationTranslationKeyPayload>;
  /** Creates a single `LocalizationTranslationValue`. */
  createLocalizationTranslationValue?: Maybe<CreateLocalizationTranslationValuePayload>;
  /** Creates a single `OutbankUser`. */
  createOutbankUser?: Maybe<CreateOutbankUserPayload>;
  /** Creates a single `Protocol`. */
  createProtocol?: Maybe<CreateProtocolPayload>;
  /** Creates a single `SequelizeMeta`. */
  createSequelizeMeta?: Maybe<CreateSequelizeMetaPayload>;
  /** Deletes a single `AppSource` using a unique key. */
  deleteAppSource?: Maybe<DeleteAppSourcePayload>;
  /** Deletes a single `AppSource` using its globally unique id. */
  deleteAppSourceByNodeId?: Maybe<DeleteAppSourcePayload>;
  /** Deletes a single `Crash` using a unique key. */
  deleteCrash?: Maybe<DeleteCrashPayload>;
  /** Deletes a single `Crash` using its globally unique id. */
  deleteCrashByNodeId?: Maybe<DeleteCrashPayload>;
  /** Deletes a single `CrashGroup` using a unique key. */
  deleteCrashGroup?: Maybe<DeleteCrashGroupPayload>;
  /** Deletes a single `CrashGroup` using its globally unique id. */
  deleteCrashGroupByNodeId?: Maybe<DeleteCrashGroupPayload>;
  /** Deletes a single `File` using a unique key. */
  deleteFile?: Maybe<DeleteFilePayload>;
  /** Deletes a single `File` using its globally unique id. */
  deleteFileByNodeId?: Maybe<DeleteFilePayload>;
  /** Deletes a single `Har` using a unique key. */
  deleteHar?: Maybe<DeleteHarPayload>;
  /** Deletes a single `Har` using its globally unique id. */
  deleteHarByNodeId?: Maybe<DeleteHarPayload>;
  /** Deletes a single `LocalizationLanguage` using a unique key. */
  deleteLocalizationLanguage?: Maybe<DeleteLocalizationLanguagePayload>;
  /** Deletes a single `LocalizationLanguage` using its globally unique id. */
  deleteLocalizationLanguageByNodeId?: Maybe<DeleteLocalizationLanguagePayload>;
  /** Deletes a single `LocalizationProject` using a unique key. */
  deleteLocalizationProject?: Maybe<DeleteLocalizationProjectPayload>;
  /** Deletes a single `LocalizationProject` using its globally unique id. */
  deleteLocalizationProjectByNodeId?: Maybe<DeleteLocalizationProjectPayload>;
  /** Deletes a single `LocalizationProjectXLanguage` using a unique key. */
  deleteLocalizationProjectXLanguage?: Maybe<DeleteLocalizationProjectXLanguagePayload>;
  /** Deletes a single `LocalizationProjectXLanguage` using its globally unique id. */
  deleteLocalizationProjectXLanguageByNodeId?: Maybe<DeleteLocalizationProjectXLanguagePayload>;
  /** Deletes a single `LocalizationTranslationKey` using a unique key. */
  deleteLocalizationTranslationKey?: Maybe<DeleteLocalizationTranslationKeyPayload>;
  /** Deletes a single `LocalizationTranslationKey` using its globally unique id. */
  deleteLocalizationTranslationKeyByNodeId?: Maybe<DeleteLocalizationTranslationKeyPayload>;
  /** Deletes a single `LocalizationTranslationValue` using a unique key. */
  deleteLocalizationTranslationValue?: Maybe<DeleteLocalizationTranslationValuePayload>;
  /** Deletes a single `LocalizationTranslationValue` using its globally unique id. */
  deleteLocalizationTranslationValueByNodeId?: Maybe<DeleteLocalizationTranslationValuePayload>;
  /** Deletes a single `OutbankUser` using a unique key. */
  deleteOutbankUser?: Maybe<DeleteOutbankUserPayload>;
  /** Deletes a single `OutbankUser` using its globally unique id. */
  deleteOutbankUserByNodeId?: Maybe<DeleteOutbankUserPayload>;
  /** Deletes a single `Protocol` using a unique key. */
  deleteProtocol?: Maybe<DeleteProtocolPayload>;
  /** Deletes a single `Protocol` using its globally unique id. */
  deleteProtocolByNodeId?: Maybe<DeleteProtocolPayload>;
  /** Deletes a single `SequelizeMeta` using a unique key. */
  deleteSequelizeMeta?: Maybe<DeleteSequelizeMetaPayload>;
  /** Deletes a single `SequelizeMeta` using its globally unique id. */
  deleteSequelizeMetaByNodeId?: Maybe<DeleteSequelizeMetaPayload>;
  /** Updates a single `AppSource` using a unique key and a patch. */
  updateAppSource?: Maybe<UpdateAppSourcePayload>;
  /** Updates a single `AppSource` using its globally unique id and a patch. */
  updateAppSourceByNodeId?: Maybe<UpdateAppSourcePayload>;
  /** Updates a single `Crash` using a unique key and a patch. */
  updateCrash?: Maybe<UpdateCrashPayload>;
  /** Updates a single `Crash` using its globally unique id and a patch. */
  updateCrashByNodeId?: Maybe<UpdateCrashPayload>;
  /** Updates a single `CrashGroup` using a unique key and a patch. */
  updateCrashGroup?: Maybe<UpdateCrashGroupPayload>;
  /** Updates a single `CrashGroup` using its globally unique id and a patch. */
  updateCrashGroupByNodeId?: Maybe<UpdateCrashGroupPayload>;
  updateCrashState?: Maybe<UpdateCrashStatePayload>;
  /** Updates a single `File` using a unique key and a patch. */
  updateFile?: Maybe<UpdateFilePayload>;
  /** Updates a single `File` using its globally unique id and a patch. */
  updateFileByNodeId?: Maybe<UpdateFilePayload>;
  /** Updates a single `Har` using a unique key and a patch. */
  updateHar?: Maybe<UpdateHarPayload>;
  /** Updates a single `Har` using its globally unique id and a patch. */
  updateHarByNodeId?: Maybe<UpdateHarPayload>;
  /** Updates a single `LocalizationLanguage` using a unique key and a patch. */
  updateLocalizationLanguage?: Maybe<UpdateLocalizationLanguagePayload>;
  /** Updates a single `LocalizationLanguage` using its globally unique id and a patch. */
  updateLocalizationLanguageByNodeId?: Maybe<UpdateLocalizationLanguagePayload>;
  /** Updates a single `LocalizationProject` using a unique key and a patch. */
  updateLocalizationProject?: Maybe<UpdateLocalizationProjectPayload>;
  /** Updates a single `LocalizationProject` using its globally unique id and a patch. */
  updateLocalizationProjectByNodeId?: Maybe<UpdateLocalizationProjectPayload>;
  /** Updates a single `LocalizationProjectXLanguage` using a unique key and a patch. */
  updateLocalizationProjectXLanguage?: Maybe<UpdateLocalizationProjectXLanguagePayload>;
  /** Updates a single `LocalizationProjectXLanguage` using its globally unique id and a patch. */
  updateLocalizationProjectXLanguageByNodeId?: Maybe<UpdateLocalizationProjectXLanguagePayload>;
  /** Updates a single `LocalizationTranslationKey` using a unique key and a patch. */
  updateLocalizationTranslationKey?: Maybe<UpdateLocalizationTranslationKeyPayload>;
  /** Updates a single `LocalizationTranslationKey` using its globally unique id and a patch. */
  updateLocalizationTranslationKeyByNodeId?: Maybe<UpdateLocalizationTranslationKeyPayload>;
  /** Updates a single `LocalizationTranslationValue` using a unique key and a patch. */
  updateLocalizationTranslationValue?: Maybe<UpdateLocalizationTranslationValuePayload>;
  /** Updates a single `LocalizationTranslationValue` using its globally unique id and a patch. */
  updateLocalizationTranslationValueByNodeId?: Maybe<UpdateLocalizationTranslationValuePayload>;
  /** Updates a single `OutbankUser` using a unique key and a patch. */
  updateOutbankUser?: Maybe<UpdateOutbankUserPayload>;
  /** Updates a single `OutbankUser` using its globally unique id and a patch. */
  updateOutbankUserByNodeId?: Maybe<UpdateOutbankUserPayload>;
  /** Updates a single `Protocol` using a unique key and a patch. */
  updateProtocol?: Maybe<UpdateProtocolPayload>;
  /** Updates a single `Protocol` using its globally unique id and a patch. */
  updateProtocolByNodeId?: Maybe<UpdateProtocolPayload>;
  /** Updates a single `SequelizeMeta` using a unique key and a patch. */
  updateSequelizeMeta?: Maybe<UpdateSequelizeMetaPayload>;
  /** Updates a single `SequelizeMeta` using its globally unique id and a patch. */
  updateSequelizeMetaByNodeId?: Maybe<UpdateSequelizeMetaPayload>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAppSourceArgs = {
  input: CreateAppSourceInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCrashArgs = {
  input: CreateCrashInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCrashGroupArgs = {
  input: CreateCrashGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFileArgs = {
  input: CreateFileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateHarArgs = {
  input: CreateHarInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLocalizationLanguageArgs = {
  input: CreateLocalizationLanguageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLocalizationProjectArgs = {
  input: CreateLocalizationProjectInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLocalizationProjectXLanguageArgs = {
  input: CreateLocalizationProjectXLanguageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLocalizationTranslationKeyArgs = {
  input: CreateLocalizationTranslationKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLocalizationTranslationValueArgs = {
  input: CreateLocalizationTranslationValueInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOutbankUserArgs = {
  input: CreateOutbankUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProtocolArgs = {
  input: CreateProtocolInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSequelizeMetaArgs = {
  input: CreateSequelizeMetaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAppSourceArgs = {
  input: DeleteAppSourceInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAppSourceByNodeIdArgs = {
  input: DeleteAppSourceByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCrashArgs = {
  input: DeleteCrashInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCrashByNodeIdArgs = {
  input: DeleteCrashByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCrashGroupArgs = {
  input: DeleteCrashGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCrashGroupByNodeIdArgs = {
  input: DeleteCrashGroupByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFileArgs = {
  input: DeleteFileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFileByNodeIdArgs = {
  input: DeleteFileByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteHarArgs = {
  input: DeleteHarInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteHarByNodeIdArgs = {
  input: DeleteHarByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLocalizationLanguageArgs = {
  input: DeleteLocalizationLanguageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLocalizationLanguageByNodeIdArgs = {
  input: DeleteLocalizationLanguageByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLocalizationProjectArgs = {
  input: DeleteLocalizationProjectInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLocalizationProjectByNodeIdArgs = {
  input: DeleteLocalizationProjectByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLocalizationProjectXLanguageArgs = {
  input: DeleteLocalizationProjectXLanguageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLocalizationProjectXLanguageByNodeIdArgs = {
  input: DeleteLocalizationProjectXLanguageByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLocalizationTranslationKeyArgs = {
  input: DeleteLocalizationTranslationKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLocalizationTranslationKeyByNodeIdArgs = {
  input: DeleteLocalizationTranslationKeyByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLocalizationTranslationValueArgs = {
  input: DeleteLocalizationTranslationValueInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLocalizationTranslationValueByNodeIdArgs = {
  input: DeleteLocalizationTranslationValueByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOutbankUserArgs = {
  input: DeleteOutbankUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOutbankUserByNodeIdArgs = {
  input: DeleteOutbankUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProtocolArgs = {
  input: DeleteProtocolInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProtocolByNodeIdArgs = {
  input: DeleteProtocolByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSequelizeMetaArgs = {
  input: DeleteSequelizeMetaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSequelizeMetaByNodeIdArgs = {
  input: DeleteSequelizeMetaByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAppSourceArgs = {
  input: UpdateAppSourceInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAppSourceByNodeIdArgs = {
  input: UpdateAppSourceByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCrashArgs = {
  input: UpdateCrashInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCrashByNodeIdArgs = {
  input: UpdateCrashByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCrashGroupArgs = {
  input: UpdateCrashGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCrashGroupByNodeIdArgs = {
  input: UpdateCrashGroupByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCrashStateArgs = {
  input: UpdateCrashStateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFileArgs = {
  input: UpdateFileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFileByNodeIdArgs = {
  input: UpdateFileByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateHarArgs = {
  input: UpdateHarInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateHarByNodeIdArgs = {
  input: UpdateHarByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLocalizationLanguageArgs = {
  input: UpdateLocalizationLanguageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLocalizationLanguageByNodeIdArgs = {
  input: UpdateLocalizationLanguageByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLocalizationProjectArgs = {
  input: UpdateLocalizationProjectInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLocalizationProjectByNodeIdArgs = {
  input: UpdateLocalizationProjectByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLocalizationProjectXLanguageArgs = {
  input: UpdateLocalizationProjectXLanguageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLocalizationProjectXLanguageByNodeIdArgs = {
  input: UpdateLocalizationProjectXLanguageByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLocalizationTranslationKeyArgs = {
  input: UpdateLocalizationTranslationKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLocalizationTranslationKeyByNodeIdArgs = {
  input: UpdateLocalizationTranslationKeyByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLocalizationTranslationValueArgs = {
  input: UpdateLocalizationTranslationValueInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLocalizationTranslationValueByNodeIdArgs = {
  input: UpdateLocalizationTranslationValueByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOutbankUserArgs = {
  input: UpdateOutbankUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOutbankUserByNodeIdArgs = {
  input: UpdateOutbankUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProtocolArgs = {
  input: UpdateProtocolInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProtocolByNodeIdArgs = {
  input: UpdateProtocolByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSequelizeMetaArgs = {
  input: UpdateSequelizeMetaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSequelizeMetaByNodeIdArgs = {
  input: UpdateSequelizeMetaByNodeIdInput;
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
};

export type OutbankUser = Node & {
  __typename?: 'OutbankUser';
  /** Reads a single `AppSource` that is related to this `OutbankUser`. */
  appSource?: Maybe<AppSource>;
  appSourceId: Scalars['UUID']['output'];
  /** Reads and enables pagination through a set of `Crash`. */
  crashes: CrashesConnection;
  createdAt: Scalars['Datetime']['output'];
  email: Scalars['String']['output'];
  /** Reads and enables pagination through a set of `Har`. */
  hars: HarsConnection;
  id: Scalars['UUID']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads and enables pagination through a set of `Protocol`. */
  protocols: ProtocolsConnection;
  updatedAt: Scalars['Datetime']['output'];
};


export type OutbankUserCrashesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CrashCondition>;
  filter?: InputMaybe<CrashFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrashesOrderBy>>;
};


export type OutbankUserHarsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<HarCondition>;
  filter?: InputMaybe<HarFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<HarsOrderBy>>;
};


export type OutbankUserProtocolsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProtocolCondition>;
  filter?: InputMaybe<ProtocolFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProtocolsOrderBy>>;
};

export type OutbankUserAggregates = {
  __typename?: 'OutbankUserAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<OutbankUserDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * A condition to be used against `OutbankUser` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type OutbankUserCondition = {
  /** Checks for equality with the object’s `appSourceId` field. */
  appSourceId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
};

export type OutbankUserDistinctCountAggregates = {
  __typename?: 'OutbankUserDistinctCountAggregates';
  /** Distinct count of appSourceId across the matching connection */
  appSourceId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of email across the matching connection */
  email?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `OutbankUser` object types. All fields are combined with a logical ‘and.’ */
export type OutbankUserFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OutbankUserFilter>>;
  /** Filter by the object’s `appSourceId` field. */
  appSourceId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OutbankUserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OutbankUserFilter>>;
};

/** Grouping methods for `OutbankUser` for usage during aggregation. */
export enum OutbankUserGroupBy {
  AppSourceId = 'APP_SOURCE_ID'
}

export type OutbankUserHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OutbankUserHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `OutbankUser` aggregates. */
export type OutbankUserHavingInput = {
  AND?: InputMaybe<Array<OutbankUserHavingInput>>;
  OR?: InputMaybe<Array<OutbankUserHavingInput>>;
  average?: InputMaybe<OutbankUserHavingAverageInput>;
  distinctCount?: InputMaybe<OutbankUserHavingDistinctCountInput>;
  max?: InputMaybe<OutbankUserHavingMaxInput>;
  min?: InputMaybe<OutbankUserHavingMinInput>;
  stddevPopulation?: InputMaybe<OutbankUserHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<OutbankUserHavingStddevSampleInput>;
  sum?: InputMaybe<OutbankUserHavingSumInput>;
  variancePopulation?: InputMaybe<OutbankUserHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<OutbankUserHavingVarianceSampleInput>;
};

export type OutbankUserHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OutbankUserHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OutbankUserHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OutbankUserHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OutbankUserHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OutbankUserHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OutbankUserHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `OutbankUser` */
export type OutbankUserInput = {
  appSourceId: Scalars['UUID']['input'];
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['UUID']['input']>;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** Represents an update to a `OutbankUser`. Fields that are set will be updated. */
export type OutbankUserPatch = {
  appSourceId?: InputMaybe<Scalars['UUID']['input']>;
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A connection to a list of `OutbankUser` values. */
export type OutbankUsersConnection = {
  __typename?: 'OutbankUsersConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OutbankUserAggregates>;
  /** A list of edges which contains the `OutbankUser` and cursor to aid in pagination. */
  edges: Array<OutbankUsersEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OutbankUserAggregates>>;
  /** A list of `OutbankUser` objects. */
  nodes: Array<OutbankUser>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OutbankUser` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `OutbankUser` values. */
export type OutbankUsersConnectionGroupedAggregatesArgs = {
  groupBy: Array<OutbankUserGroupBy>;
  having?: InputMaybe<OutbankUserHavingInput>;
};

/** A `OutbankUser` edge in the connection. */
export type OutbankUsersEdge = {
  __typename?: 'OutbankUsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `OutbankUser` at the end of the edge. */
  node: OutbankUser;
};

/** Methods to use when ordering `OutbankUser`. */
export enum OutbankUsersOrderBy {
  AppSourceIdAsc = 'APP_SOURCE_ID_ASC',
  AppSourceIdDesc = 'APP_SOURCE_ID_DESC',
  CrashesAverageAppVersionAsc = 'CRASHES_AVERAGE_APP_VERSION_ASC',
  CrashesAverageAppVersionDesc = 'CRASHES_AVERAGE_APP_VERSION_DESC',
  CrashesAverageCallMethodAsc = 'CRASHES_AVERAGE_CALL_METHOD_ASC',
  CrashesAverageCallMethodDesc = 'CRASHES_AVERAGE_CALL_METHOD_DESC',
  CrashesAverageCrashGroupIdAsc = 'CRASHES_AVERAGE_CRASH_GROUP_ID_ASC',
  CrashesAverageCrashGroupIdDesc = 'CRASHES_AVERAGE_CRASH_GROUP_ID_DESC',
  CrashesAverageCreatedAtAsc = 'CRASHES_AVERAGE_CREATED_AT_ASC',
  CrashesAverageCreatedAtDesc = 'CRASHES_AVERAGE_CREATED_AT_DESC',
  CrashesAverageFileAsc = 'CRASHES_AVERAGE_FILE_ASC',
  CrashesAverageFileDesc = 'CRASHES_AVERAGE_FILE_DESC',
  CrashesAverageFileLineNumberAsc = 'CRASHES_AVERAGE_FILE_LINE_NUMBER_ASC',
  CrashesAverageFileLineNumberDesc = 'CRASHES_AVERAGE_FILE_LINE_NUMBER_DESC',
  CrashesAverageFileNameAsc = 'CRASHES_AVERAGE_FILE_NAME_ASC',
  CrashesAverageFileNameDesc = 'CRASHES_AVERAGE_FILE_NAME_DESC',
  CrashesAverageFileVersionAsc = 'CRASHES_AVERAGE_FILE_VERSION_ASC',
  CrashesAverageFileVersionDesc = 'CRASHES_AVERAGE_FILE_VERSION_DESC',
  CrashesAverageIdAsc = 'CRASHES_AVERAGE_ID_ASC',
  CrashesAverageIdDesc = 'CRASHES_AVERAGE_ID_DESC',
  CrashesAverageLoggerInfoAsc = 'CRASHES_AVERAGE_LOGGER_INFO_ASC',
  CrashesAverageLoggerInfoDesc = 'CRASHES_AVERAGE_LOGGER_INFO_DESC',
  CrashesAverageMessageAsc = 'CRASHES_AVERAGE_MESSAGE_ASC',
  CrashesAverageMessageDesc = 'CRASHES_AVERAGE_MESSAGE_DESC',
  CrashesAverageOsVersionAsc = 'CRASHES_AVERAGE_OS_VERSION_ASC',
  CrashesAverageOsVersionDesc = 'CRASHES_AVERAGE_OS_VERSION_DESC',
  CrashesAverageOutbankUserIdAsc = 'CRASHES_AVERAGE_OUTBANK_USER_ID_ASC',
  CrashesAverageOutbankUserIdDesc = 'CRASHES_AVERAGE_OUTBANK_USER_ID_DESC',
  CrashesAverageStateAsc = 'CRASHES_AVERAGE_STATE_ASC',
  CrashesAverageStateDesc = 'CRASHES_AVERAGE_STATE_DESC',
  CrashesAverageUpdatedAtAsc = 'CRASHES_AVERAGE_UPDATED_AT_ASC',
  CrashesAverageUpdatedAtDesc = 'CRASHES_AVERAGE_UPDATED_AT_DESC',
  CrashesCountAsc = 'CRASHES_COUNT_ASC',
  CrashesCountDesc = 'CRASHES_COUNT_DESC',
  CrashesDistinctCountAppVersionAsc = 'CRASHES_DISTINCT_COUNT_APP_VERSION_ASC',
  CrashesDistinctCountAppVersionDesc = 'CRASHES_DISTINCT_COUNT_APP_VERSION_DESC',
  CrashesDistinctCountCallMethodAsc = 'CRASHES_DISTINCT_COUNT_CALL_METHOD_ASC',
  CrashesDistinctCountCallMethodDesc = 'CRASHES_DISTINCT_COUNT_CALL_METHOD_DESC',
  CrashesDistinctCountCrashGroupIdAsc = 'CRASHES_DISTINCT_COUNT_CRASH_GROUP_ID_ASC',
  CrashesDistinctCountCrashGroupIdDesc = 'CRASHES_DISTINCT_COUNT_CRASH_GROUP_ID_DESC',
  CrashesDistinctCountCreatedAtAsc = 'CRASHES_DISTINCT_COUNT_CREATED_AT_ASC',
  CrashesDistinctCountCreatedAtDesc = 'CRASHES_DISTINCT_COUNT_CREATED_AT_DESC',
  CrashesDistinctCountFileAsc = 'CRASHES_DISTINCT_COUNT_FILE_ASC',
  CrashesDistinctCountFileDesc = 'CRASHES_DISTINCT_COUNT_FILE_DESC',
  CrashesDistinctCountFileLineNumberAsc = 'CRASHES_DISTINCT_COUNT_FILE_LINE_NUMBER_ASC',
  CrashesDistinctCountFileLineNumberDesc = 'CRASHES_DISTINCT_COUNT_FILE_LINE_NUMBER_DESC',
  CrashesDistinctCountFileNameAsc = 'CRASHES_DISTINCT_COUNT_FILE_NAME_ASC',
  CrashesDistinctCountFileNameDesc = 'CRASHES_DISTINCT_COUNT_FILE_NAME_DESC',
  CrashesDistinctCountFileVersionAsc = 'CRASHES_DISTINCT_COUNT_FILE_VERSION_ASC',
  CrashesDistinctCountFileVersionDesc = 'CRASHES_DISTINCT_COUNT_FILE_VERSION_DESC',
  CrashesDistinctCountIdAsc = 'CRASHES_DISTINCT_COUNT_ID_ASC',
  CrashesDistinctCountIdDesc = 'CRASHES_DISTINCT_COUNT_ID_DESC',
  CrashesDistinctCountLoggerInfoAsc = 'CRASHES_DISTINCT_COUNT_LOGGER_INFO_ASC',
  CrashesDistinctCountLoggerInfoDesc = 'CRASHES_DISTINCT_COUNT_LOGGER_INFO_DESC',
  CrashesDistinctCountMessageAsc = 'CRASHES_DISTINCT_COUNT_MESSAGE_ASC',
  CrashesDistinctCountMessageDesc = 'CRASHES_DISTINCT_COUNT_MESSAGE_DESC',
  CrashesDistinctCountOsVersionAsc = 'CRASHES_DISTINCT_COUNT_OS_VERSION_ASC',
  CrashesDistinctCountOsVersionDesc = 'CRASHES_DISTINCT_COUNT_OS_VERSION_DESC',
  CrashesDistinctCountOutbankUserIdAsc = 'CRASHES_DISTINCT_COUNT_OUTBANK_USER_ID_ASC',
  CrashesDistinctCountOutbankUserIdDesc = 'CRASHES_DISTINCT_COUNT_OUTBANK_USER_ID_DESC',
  CrashesDistinctCountStateAsc = 'CRASHES_DISTINCT_COUNT_STATE_ASC',
  CrashesDistinctCountStateDesc = 'CRASHES_DISTINCT_COUNT_STATE_DESC',
  CrashesDistinctCountUpdatedAtAsc = 'CRASHES_DISTINCT_COUNT_UPDATED_AT_ASC',
  CrashesDistinctCountUpdatedAtDesc = 'CRASHES_DISTINCT_COUNT_UPDATED_AT_DESC',
  CrashesMaxAppVersionAsc = 'CRASHES_MAX_APP_VERSION_ASC',
  CrashesMaxAppVersionDesc = 'CRASHES_MAX_APP_VERSION_DESC',
  CrashesMaxCallMethodAsc = 'CRASHES_MAX_CALL_METHOD_ASC',
  CrashesMaxCallMethodDesc = 'CRASHES_MAX_CALL_METHOD_DESC',
  CrashesMaxCrashGroupIdAsc = 'CRASHES_MAX_CRASH_GROUP_ID_ASC',
  CrashesMaxCrashGroupIdDesc = 'CRASHES_MAX_CRASH_GROUP_ID_DESC',
  CrashesMaxCreatedAtAsc = 'CRASHES_MAX_CREATED_AT_ASC',
  CrashesMaxCreatedAtDesc = 'CRASHES_MAX_CREATED_AT_DESC',
  CrashesMaxFileAsc = 'CRASHES_MAX_FILE_ASC',
  CrashesMaxFileDesc = 'CRASHES_MAX_FILE_DESC',
  CrashesMaxFileLineNumberAsc = 'CRASHES_MAX_FILE_LINE_NUMBER_ASC',
  CrashesMaxFileLineNumberDesc = 'CRASHES_MAX_FILE_LINE_NUMBER_DESC',
  CrashesMaxFileNameAsc = 'CRASHES_MAX_FILE_NAME_ASC',
  CrashesMaxFileNameDesc = 'CRASHES_MAX_FILE_NAME_DESC',
  CrashesMaxFileVersionAsc = 'CRASHES_MAX_FILE_VERSION_ASC',
  CrashesMaxFileVersionDesc = 'CRASHES_MAX_FILE_VERSION_DESC',
  CrashesMaxIdAsc = 'CRASHES_MAX_ID_ASC',
  CrashesMaxIdDesc = 'CRASHES_MAX_ID_DESC',
  CrashesMaxLoggerInfoAsc = 'CRASHES_MAX_LOGGER_INFO_ASC',
  CrashesMaxLoggerInfoDesc = 'CRASHES_MAX_LOGGER_INFO_DESC',
  CrashesMaxMessageAsc = 'CRASHES_MAX_MESSAGE_ASC',
  CrashesMaxMessageDesc = 'CRASHES_MAX_MESSAGE_DESC',
  CrashesMaxOsVersionAsc = 'CRASHES_MAX_OS_VERSION_ASC',
  CrashesMaxOsVersionDesc = 'CRASHES_MAX_OS_VERSION_DESC',
  CrashesMaxOutbankUserIdAsc = 'CRASHES_MAX_OUTBANK_USER_ID_ASC',
  CrashesMaxOutbankUserIdDesc = 'CRASHES_MAX_OUTBANK_USER_ID_DESC',
  CrashesMaxStateAsc = 'CRASHES_MAX_STATE_ASC',
  CrashesMaxStateDesc = 'CRASHES_MAX_STATE_DESC',
  CrashesMaxUpdatedAtAsc = 'CRASHES_MAX_UPDATED_AT_ASC',
  CrashesMaxUpdatedAtDesc = 'CRASHES_MAX_UPDATED_AT_DESC',
  CrashesMinAppVersionAsc = 'CRASHES_MIN_APP_VERSION_ASC',
  CrashesMinAppVersionDesc = 'CRASHES_MIN_APP_VERSION_DESC',
  CrashesMinCallMethodAsc = 'CRASHES_MIN_CALL_METHOD_ASC',
  CrashesMinCallMethodDesc = 'CRASHES_MIN_CALL_METHOD_DESC',
  CrashesMinCrashGroupIdAsc = 'CRASHES_MIN_CRASH_GROUP_ID_ASC',
  CrashesMinCrashGroupIdDesc = 'CRASHES_MIN_CRASH_GROUP_ID_DESC',
  CrashesMinCreatedAtAsc = 'CRASHES_MIN_CREATED_AT_ASC',
  CrashesMinCreatedAtDesc = 'CRASHES_MIN_CREATED_AT_DESC',
  CrashesMinFileAsc = 'CRASHES_MIN_FILE_ASC',
  CrashesMinFileDesc = 'CRASHES_MIN_FILE_DESC',
  CrashesMinFileLineNumberAsc = 'CRASHES_MIN_FILE_LINE_NUMBER_ASC',
  CrashesMinFileLineNumberDesc = 'CRASHES_MIN_FILE_LINE_NUMBER_DESC',
  CrashesMinFileNameAsc = 'CRASHES_MIN_FILE_NAME_ASC',
  CrashesMinFileNameDesc = 'CRASHES_MIN_FILE_NAME_DESC',
  CrashesMinFileVersionAsc = 'CRASHES_MIN_FILE_VERSION_ASC',
  CrashesMinFileVersionDesc = 'CRASHES_MIN_FILE_VERSION_DESC',
  CrashesMinIdAsc = 'CRASHES_MIN_ID_ASC',
  CrashesMinIdDesc = 'CRASHES_MIN_ID_DESC',
  CrashesMinLoggerInfoAsc = 'CRASHES_MIN_LOGGER_INFO_ASC',
  CrashesMinLoggerInfoDesc = 'CRASHES_MIN_LOGGER_INFO_DESC',
  CrashesMinMessageAsc = 'CRASHES_MIN_MESSAGE_ASC',
  CrashesMinMessageDesc = 'CRASHES_MIN_MESSAGE_DESC',
  CrashesMinOsVersionAsc = 'CRASHES_MIN_OS_VERSION_ASC',
  CrashesMinOsVersionDesc = 'CRASHES_MIN_OS_VERSION_DESC',
  CrashesMinOutbankUserIdAsc = 'CRASHES_MIN_OUTBANK_USER_ID_ASC',
  CrashesMinOutbankUserIdDesc = 'CRASHES_MIN_OUTBANK_USER_ID_DESC',
  CrashesMinStateAsc = 'CRASHES_MIN_STATE_ASC',
  CrashesMinStateDesc = 'CRASHES_MIN_STATE_DESC',
  CrashesMinUpdatedAtAsc = 'CRASHES_MIN_UPDATED_AT_ASC',
  CrashesMinUpdatedAtDesc = 'CRASHES_MIN_UPDATED_AT_DESC',
  CrashesStddevPopulationAppVersionAsc = 'CRASHES_STDDEV_POPULATION_APP_VERSION_ASC',
  CrashesStddevPopulationAppVersionDesc = 'CRASHES_STDDEV_POPULATION_APP_VERSION_DESC',
  CrashesStddevPopulationCallMethodAsc = 'CRASHES_STDDEV_POPULATION_CALL_METHOD_ASC',
  CrashesStddevPopulationCallMethodDesc = 'CRASHES_STDDEV_POPULATION_CALL_METHOD_DESC',
  CrashesStddevPopulationCrashGroupIdAsc = 'CRASHES_STDDEV_POPULATION_CRASH_GROUP_ID_ASC',
  CrashesStddevPopulationCrashGroupIdDesc = 'CRASHES_STDDEV_POPULATION_CRASH_GROUP_ID_DESC',
  CrashesStddevPopulationCreatedAtAsc = 'CRASHES_STDDEV_POPULATION_CREATED_AT_ASC',
  CrashesStddevPopulationCreatedAtDesc = 'CRASHES_STDDEV_POPULATION_CREATED_AT_DESC',
  CrashesStddevPopulationFileAsc = 'CRASHES_STDDEV_POPULATION_FILE_ASC',
  CrashesStddevPopulationFileDesc = 'CRASHES_STDDEV_POPULATION_FILE_DESC',
  CrashesStddevPopulationFileLineNumberAsc = 'CRASHES_STDDEV_POPULATION_FILE_LINE_NUMBER_ASC',
  CrashesStddevPopulationFileLineNumberDesc = 'CRASHES_STDDEV_POPULATION_FILE_LINE_NUMBER_DESC',
  CrashesStddevPopulationFileNameAsc = 'CRASHES_STDDEV_POPULATION_FILE_NAME_ASC',
  CrashesStddevPopulationFileNameDesc = 'CRASHES_STDDEV_POPULATION_FILE_NAME_DESC',
  CrashesStddevPopulationFileVersionAsc = 'CRASHES_STDDEV_POPULATION_FILE_VERSION_ASC',
  CrashesStddevPopulationFileVersionDesc = 'CRASHES_STDDEV_POPULATION_FILE_VERSION_DESC',
  CrashesStddevPopulationIdAsc = 'CRASHES_STDDEV_POPULATION_ID_ASC',
  CrashesStddevPopulationIdDesc = 'CRASHES_STDDEV_POPULATION_ID_DESC',
  CrashesStddevPopulationLoggerInfoAsc = 'CRASHES_STDDEV_POPULATION_LOGGER_INFO_ASC',
  CrashesStddevPopulationLoggerInfoDesc = 'CRASHES_STDDEV_POPULATION_LOGGER_INFO_DESC',
  CrashesStddevPopulationMessageAsc = 'CRASHES_STDDEV_POPULATION_MESSAGE_ASC',
  CrashesStddevPopulationMessageDesc = 'CRASHES_STDDEV_POPULATION_MESSAGE_DESC',
  CrashesStddevPopulationOsVersionAsc = 'CRASHES_STDDEV_POPULATION_OS_VERSION_ASC',
  CrashesStddevPopulationOsVersionDesc = 'CRASHES_STDDEV_POPULATION_OS_VERSION_DESC',
  CrashesStddevPopulationOutbankUserIdAsc = 'CRASHES_STDDEV_POPULATION_OUTBANK_USER_ID_ASC',
  CrashesStddevPopulationOutbankUserIdDesc = 'CRASHES_STDDEV_POPULATION_OUTBANK_USER_ID_DESC',
  CrashesStddevPopulationStateAsc = 'CRASHES_STDDEV_POPULATION_STATE_ASC',
  CrashesStddevPopulationStateDesc = 'CRASHES_STDDEV_POPULATION_STATE_DESC',
  CrashesStddevPopulationUpdatedAtAsc = 'CRASHES_STDDEV_POPULATION_UPDATED_AT_ASC',
  CrashesStddevPopulationUpdatedAtDesc = 'CRASHES_STDDEV_POPULATION_UPDATED_AT_DESC',
  CrashesStddevSampleAppVersionAsc = 'CRASHES_STDDEV_SAMPLE_APP_VERSION_ASC',
  CrashesStddevSampleAppVersionDesc = 'CRASHES_STDDEV_SAMPLE_APP_VERSION_DESC',
  CrashesStddevSampleCallMethodAsc = 'CRASHES_STDDEV_SAMPLE_CALL_METHOD_ASC',
  CrashesStddevSampleCallMethodDesc = 'CRASHES_STDDEV_SAMPLE_CALL_METHOD_DESC',
  CrashesStddevSampleCrashGroupIdAsc = 'CRASHES_STDDEV_SAMPLE_CRASH_GROUP_ID_ASC',
  CrashesStddevSampleCrashGroupIdDesc = 'CRASHES_STDDEV_SAMPLE_CRASH_GROUP_ID_DESC',
  CrashesStddevSampleCreatedAtAsc = 'CRASHES_STDDEV_SAMPLE_CREATED_AT_ASC',
  CrashesStddevSampleCreatedAtDesc = 'CRASHES_STDDEV_SAMPLE_CREATED_AT_DESC',
  CrashesStddevSampleFileAsc = 'CRASHES_STDDEV_SAMPLE_FILE_ASC',
  CrashesStddevSampleFileDesc = 'CRASHES_STDDEV_SAMPLE_FILE_DESC',
  CrashesStddevSampleFileLineNumberAsc = 'CRASHES_STDDEV_SAMPLE_FILE_LINE_NUMBER_ASC',
  CrashesStddevSampleFileLineNumberDesc = 'CRASHES_STDDEV_SAMPLE_FILE_LINE_NUMBER_DESC',
  CrashesStddevSampleFileNameAsc = 'CRASHES_STDDEV_SAMPLE_FILE_NAME_ASC',
  CrashesStddevSampleFileNameDesc = 'CRASHES_STDDEV_SAMPLE_FILE_NAME_DESC',
  CrashesStddevSampleFileVersionAsc = 'CRASHES_STDDEV_SAMPLE_FILE_VERSION_ASC',
  CrashesStddevSampleFileVersionDesc = 'CRASHES_STDDEV_SAMPLE_FILE_VERSION_DESC',
  CrashesStddevSampleIdAsc = 'CRASHES_STDDEV_SAMPLE_ID_ASC',
  CrashesStddevSampleIdDesc = 'CRASHES_STDDEV_SAMPLE_ID_DESC',
  CrashesStddevSampleLoggerInfoAsc = 'CRASHES_STDDEV_SAMPLE_LOGGER_INFO_ASC',
  CrashesStddevSampleLoggerInfoDesc = 'CRASHES_STDDEV_SAMPLE_LOGGER_INFO_DESC',
  CrashesStddevSampleMessageAsc = 'CRASHES_STDDEV_SAMPLE_MESSAGE_ASC',
  CrashesStddevSampleMessageDesc = 'CRASHES_STDDEV_SAMPLE_MESSAGE_DESC',
  CrashesStddevSampleOsVersionAsc = 'CRASHES_STDDEV_SAMPLE_OS_VERSION_ASC',
  CrashesStddevSampleOsVersionDesc = 'CRASHES_STDDEV_SAMPLE_OS_VERSION_DESC',
  CrashesStddevSampleOutbankUserIdAsc = 'CRASHES_STDDEV_SAMPLE_OUTBANK_USER_ID_ASC',
  CrashesStddevSampleOutbankUserIdDesc = 'CRASHES_STDDEV_SAMPLE_OUTBANK_USER_ID_DESC',
  CrashesStddevSampleStateAsc = 'CRASHES_STDDEV_SAMPLE_STATE_ASC',
  CrashesStddevSampleStateDesc = 'CRASHES_STDDEV_SAMPLE_STATE_DESC',
  CrashesStddevSampleUpdatedAtAsc = 'CRASHES_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CrashesStddevSampleUpdatedAtDesc = 'CRASHES_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CrashesSumAppVersionAsc = 'CRASHES_SUM_APP_VERSION_ASC',
  CrashesSumAppVersionDesc = 'CRASHES_SUM_APP_VERSION_DESC',
  CrashesSumCallMethodAsc = 'CRASHES_SUM_CALL_METHOD_ASC',
  CrashesSumCallMethodDesc = 'CRASHES_SUM_CALL_METHOD_DESC',
  CrashesSumCrashGroupIdAsc = 'CRASHES_SUM_CRASH_GROUP_ID_ASC',
  CrashesSumCrashGroupIdDesc = 'CRASHES_SUM_CRASH_GROUP_ID_DESC',
  CrashesSumCreatedAtAsc = 'CRASHES_SUM_CREATED_AT_ASC',
  CrashesSumCreatedAtDesc = 'CRASHES_SUM_CREATED_AT_DESC',
  CrashesSumFileAsc = 'CRASHES_SUM_FILE_ASC',
  CrashesSumFileDesc = 'CRASHES_SUM_FILE_DESC',
  CrashesSumFileLineNumberAsc = 'CRASHES_SUM_FILE_LINE_NUMBER_ASC',
  CrashesSumFileLineNumberDesc = 'CRASHES_SUM_FILE_LINE_NUMBER_DESC',
  CrashesSumFileNameAsc = 'CRASHES_SUM_FILE_NAME_ASC',
  CrashesSumFileNameDesc = 'CRASHES_SUM_FILE_NAME_DESC',
  CrashesSumFileVersionAsc = 'CRASHES_SUM_FILE_VERSION_ASC',
  CrashesSumFileVersionDesc = 'CRASHES_SUM_FILE_VERSION_DESC',
  CrashesSumIdAsc = 'CRASHES_SUM_ID_ASC',
  CrashesSumIdDesc = 'CRASHES_SUM_ID_DESC',
  CrashesSumLoggerInfoAsc = 'CRASHES_SUM_LOGGER_INFO_ASC',
  CrashesSumLoggerInfoDesc = 'CRASHES_SUM_LOGGER_INFO_DESC',
  CrashesSumMessageAsc = 'CRASHES_SUM_MESSAGE_ASC',
  CrashesSumMessageDesc = 'CRASHES_SUM_MESSAGE_DESC',
  CrashesSumOsVersionAsc = 'CRASHES_SUM_OS_VERSION_ASC',
  CrashesSumOsVersionDesc = 'CRASHES_SUM_OS_VERSION_DESC',
  CrashesSumOutbankUserIdAsc = 'CRASHES_SUM_OUTBANK_USER_ID_ASC',
  CrashesSumOutbankUserIdDesc = 'CRASHES_SUM_OUTBANK_USER_ID_DESC',
  CrashesSumStateAsc = 'CRASHES_SUM_STATE_ASC',
  CrashesSumStateDesc = 'CRASHES_SUM_STATE_DESC',
  CrashesSumUpdatedAtAsc = 'CRASHES_SUM_UPDATED_AT_ASC',
  CrashesSumUpdatedAtDesc = 'CRASHES_SUM_UPDATED_AT_DESC',
  CrashesVariancePopulationAppVersionAsc = 'CRASHES_VARIANCE_POPULATION_APP_VERSION_ASC',
  CrashesVariancePopulationAppVersionDesc = 'CRASHES_VARIANCE_POPULATION_APP_VERSION_DESC',
  CrashesVariancePopulationCallMethodAsc = 'CRASHES_VARIANCE_POPULATION_CALL_METHOD_ASC',
  CrashesVariancePopulationCallMethodDesc = 'CRASHES_VARIANCE_POPULATION_CALL_METHOD_DESC',
  CrashesVariancePopulationCrashGroupIdAsc = 'CRASHES_VARIANCE_POPULATION_CRASH_GROUP_ID_ASC',
  CrashesVariancePopulationCrashGroupIdDesc = 'CRASHES_VARIANCE_POPULATION_CRASH_GROUP_ID_DESC',
  CrashesVariancePopulationCreatedAtAsc = 'CRASHES_VARIANCE_POPULATION_CREATED_AT_ASC',
  CrashesVariancePopulationCreatedAtDesc = 'CRASHES_VARIANCE_POPULATION_CREATED_AT_DESC',
  CrashesVariancePopulationFileAsc = 'CRASHES_VARIANCE_POPULATION_FILE_ASC',
  CrashesVariancePopulationFileDesc = 'CRASHES_VARIANCE_POPULATION_FILE_DESC',
  CrashesVariancePopulationFileLineNumberAsc = 'CRASHES_VARIANCE_POPULATION_FILE_LINE_NUMBER_ASC',
  CrashesVariancePopulationFileLineNumberDesc = 'CRASHES_VARIANCE_POPULATION_FILE_LINE_NUMBER_DESC',
  CrashesVariancePopulationFileNameAsc = 'CRASHES_VARIANCE_POPULATION_FILE_NAME_ASC',
  CrashesVariancePopulationFileNameDesc = 'CRASHES_VARIANCE_POPULATION_FILE_NAME_DESC',
  CrashesVariancePopulationFileVersionAsc = 'CRASHES_VARIANCE_POPULATION_FILE_VERSION_ASC',
  CrashesVariancePopulationFileVersionDesc = 'CRASHES_VARIANCE_POPULATION_FILE_VERSION_DESC',
  CrashesVariancePopulationIdAsc = 'CRASHES_VARIANCE_POPULATION_ID_ASC',
  CrashesVariancePopulationIdDesc = 'CRASHES_VARIANCE_POPULATION_ID_DESC',
  CrashesVariancePopulationLoggerInfoAsc = 'CRASHES_VARIANCE_POPULATION_LOGGER_INFO_ASC',
  CrashesVariancePopulationLoggerInfoDesc = 'CRASHES_VARIANCE_POPULATION_LOGGER_INFO_DESC',
  CrashesVariancePopulationMessageAsc = 'CRASHES_VARIANCE_POPULATION_MESSAGE_ASC',
  CrashesVariancePopulationMessageDesc = 'CRASHES_VARIANCE_POPULATION_MESSAGE_DESC',
  CrashesVariancePopulationOsVersionAsc = 'CRASHES_VARIANCE_POPULATION_OS_VERSION_ASC',
  CrashesVariancePopulationOsVersionDesc = 'CRASHES_VARIANCE_POPULATION_OS_VERSION_DESC',
  CrashesVariancePopulationOutbankUserIdAsc = 'CRASHES_VARIANCE_POPULATION_OUTBANK_USER_ID_ASC',
  CrashesVariancePopulationOutbankUserIdDesc = 'CRASHES_VARIANCE_POPULATION_OUTBANK_USER_ID_DESC',
  CrashesVariancePopulationStateAsc = 'CRASHES_VARIANCE_POPULATION_STATE_ASC',
  CrashesVariancePopulationStateDesc = 'CRASHES_VARIANCE_POPULATION_STATE_DESC',
  CrashesVariancePopulationUpdatedAtAsc = 'CRASHES_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CrashesVariancePopulationUpdatedAtDesc = 'CRASHES_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CrashesVarianceSampleAppVersionAsc = 'CRASHES_VARIANCE_SAMPLE_APP_VERSION_ASC',
  CrashesVarianceSampleAppVersionDesc = 'CRASHES_VARIANCE_SAMPLE_APP_VERSION_DESC',
  CrashesVarianceSampleCallMethodAsc = 'CRASHES_VARIANCE_SAMPLE_CALL_METHOD_ASC',
  CrashesVarianceSampleCallMethodDesc = 'CRASHES_VARIANCE_SAMPLE_CALL_METHOD_DESC',
  CrashesVarianceSampleCrashGroupIdAsc = 'CRASHES_VARIANCE_SAMPLE_CRASH_GROUP_ID_ASC',
  CrashesVarianceSampleCrashGroupIdDesc = 'CRASHES_VARIANCE_SAMPLE_CRASH_GROUP_ID_DESC',
  CrashesVarianceSampleCreatedAtAsc = 'CRASHES_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CrashesVarianceSampleCreatedAtDesc = 'CRASHES_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CrashesVarianceSampleFileAsc = 'CRASHES_VARIANCE_SAMPLE_FILE_ASC',
  CrashesVarianceSampleFileDesc = 'CRASHES_VARIANCE_SAMPLE_FILE_DESC',
  CrashesVarianceSampleFileLineNumberAsc = 'CRASHES_VARIANCE_SAMPLE_FILE_LINE_NUMBER_ASC',
  CrashesVarianceSampleFileLineNumberDesc = 'CRASHES_VARIANCE_SAMPLE_FILE_LINE_NUMBER_DESC',
  CrashesVarianceSampleFileNameAsc = 'CRASHES_VARIANCE_SAMPLE_FILE_NAME_ASC',
  CrashesVarianceSampleFileNameDesc = 'CRASHES_VARIANCE_SAMPLE_FILE_NAME_DESC',
  CrashesVarianceSampleFileVersionAsc = 'CRASHES_VARIANCE_SAMPLE_FILE_VERSION_ASC',
  CrashesVarianceSampleFileVersionDesc = 'CRASHES_VARIANCE_SAMPLE_FILE_VERSION_DESC',
  CrashesVarianceSampleIdAsc = 'CRASHES_VARIANCE_SAMPLE_ID_ASC',
  CrashesVarianceSampleIdDesc = 'CRASHES_VARIANCE_SAMPLE_ID_DESC',
  CrashesVarianceSampleLoggerInfoAsc = 'CRASHES_VARIANCE_SAMPLE_LOGGER_INFO_ASC',
  CrashesVarianceSampleLoggerInfoDesc = 'CRASHES_VARIANCE_SAMPLE_LOGGER_INFO_DESC',
  CrashesVarianceSampleMessageAsc = 'CRASHES_VARIANCE_SAMPLE_MESSAGE_ASC',
  CrashesVarianceSampleMessageDesc = 'CRASHES_VARIANCE_SAMPLE_MESSAGE_DESC',
  CrashesVarianceSampleOsVersionAsc = 'CRASHES_VARIANCE_SAMPLE_OS_VERSION_ASC',
  CrashesVarianceSampleOsVersionDesc = 'CRASHES_VARIANCE_SAMPLE_OS_VERSION_DESC',
  CrashesVarianceSampleOutbankUserIdAsc = 'CRASHES_VARIANCE_SAMPLE_OUTBANK_USER_ID_ASC',
  CrashesVarianceSampleOutbankUserIdDesc = 'CRASHES_VARIANCE_SAMPLE_OUTBANK_USER_ID_DESC',
  CrashesVarianceSampleStateAsc = 'CRASHES_VARIANCE_SAMPLE_STATE_ASC',
  CrashesVarianceSampleStateDesc = 'CRASHES_VARIANCE_SAMPLE_STATE_DESC',
  CrashesVarianceSampleUpdatedAtAsc = 'CRASHES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CrashesVarianceSampleUpdatedAtDesc = 'CRASHES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  HarsAverageAuthorizecredentialsAsc = 'HARS_AVERAGE_AUTHORIZECREDENTIALS_ASC',
  HarsAverageAuthorizecredentialsDesc = 'HARS_AVERAGE_AUTHORIZECREDENTIALS_DESC',
  HarsAverageAuthorizetestingAsc = 'HARS_AVERAGE_AUTHORIZETESTING_ASC',
  HarsAverageAuthorizetestingDesc = 'HARS_AVERAGE_AUTHORIZETESTING_DESC',
  HarsAverageBankAsc = 'HARS_AVERAGE_BANK_ASC',
  HarsAverageBankDesc = 'HARS_AVERAGE_BANK_DESC',
  HarsAverageCreatedAtAsc = 'HARS_AVERAGE_CREATED_AT_ASC',
  HarsAverageCreatedAtDesc = 'HARS_AVERAGE_CREATED_AT_DESC',
  HarsAverageEmailAsc = 'HARS_AVERAGE_EMAIL_ASC',
  HarsAverageEmailDesc = 'HARS_AVERAGE_EMAIL_DESC',
  HarsAverageIdAsc = 'HARS_AVERAGE_ID_ASC',
  HarsAverageIdDesc = 'HARS_AVERAGE_ID_DESC',
  HarsAverageIssueAsc = 'HARS_AVERAGE_ISSUE_ASC',
  HarsAverageIssueDesc = 'HARS_AVERAGE_ISSUE_DESC',
  HarsAverageNoteAsc = 'HARS_AVERAGE_NOTE_ASC',
  HarsAverageNoteDesc = 'HARS_AVERAGE_NOTE_DESC',
  HarsAverageOutbankUserIdAsc = 'HARS_AVERAGE_OUTBANK_USER_ID_ASC',
  HarsAverageOutbankUserIdDesc = 'HARS_AVERAGE_OUTBANK_USER_ID_DESC',
  HarsAverageSessionwarningAsc = 'HARS_AVERAGE_SESSIONWARNING_ASC',
  HarsAverageSessionwarningDesc = 'HARS_AVERAGE_SESSIONWARNING_DESC',
  HarsAverageSinceAsc = 'HARS_AVERAGE_SINCE_ASC',
  HarsAverageSinceDesc = 'HARS_AVERAGE_SINCE_DESC',
  HarsAverageStateAsc = 'HARS_AVERAGE_STATE_ASC',
  HarsAverageStateDesc = 'HARS_AVERAGE_STATE_DESC',
  HarsAverageUpdatedAtAsc = 'HARS_AVERAGE_UPDATED_AT_ASC',
  HarsAverageUpdatedAtDesc = 'HARS_AVERAGE_UPDATED_AT_DESC',
  HarsCountAsc = 'HARS_COUNT_ASC',
  HarsCountDesc = 'HARS_COUNT_DESC',
  HarsDistinctCountAuthorizecredentialsAsc = 'HARS_DISTINCT_COUNT_AUTHORIZECREDENTIALS_ASC',
  HarsDistinctCountAuthorizecredentialsDesc = 'HARS_DISTINCT_COUNT_AUTHORIZECREDENTIALS_DESC',
  HarsDistinctCountAuthorizetestingAsc = 'HARS_DISTINCT_COUNT_AUTHORIZETESTING_ASC',
  HarsDistinctCountAuthorizetestingDesc = 'HARS_DISTINCT_COUNT_AUTHORIZETESTING_DESC',
  HarsDistinctCountBankAsc = 'HARS_DISTINCT_COUNT_BANK_ASC',
  HarsDistinctCountBankDesc = 'HARS_DISTINCT_COUNT_BANK_DESC',
  HarsDistinctCountCreatedAtAsc = 'HARS_DISTINCT_COUNT_CREATED_AT_ASC',
  HarsDistinctCountCreatedAtDesc = 'HARS_DISTINCT_COUNT_CREATED_AT_DESC',
  HarsDistinctCountEmailAsc = 'HARS_DISTINCT_COUNT_EMAIL_ASC',
  HarsDistinctCountEmailDesc = 'HARS_DISTINCT_COUNT_EMAIL_DESC',
  HarsDistinctCountIdAsc = 'HARS_DISTINCT_COUNT_ID_ASC',
  HarsDistinctCountIdDesc = 'HARS_DISTINCT_COUNT_ID_DESC',
  HarsDistinctCountIssueAsc = 'HARS_DISTINCT_COUNT_ISSUE_ASC',
  HarsDistinctCountIssueDesc = 'HARS_DISTINCT_COUNT_ISSUE_DESC',
  HarsDistinctCountNoteAsc = 'HARS_DISTINCT_COUNT_NOTE_ASC',
  HarsDistinctCountNoteDesc = 'HARS_DISTINCT_COUNT_NOTE_DESC',
  HarsDistinctCountOutbankUserIdAsc = 'HARS_DISTINCT_COUNT_OUTBANK_USER_ID_ASC',
  HarsDistinctCountOutbankUserIdDesc = 'HARS_DISTINCT_COUNT_OUTBANK_USER_ID_DESC',
  HarsDistinctCountSessionwarningAsc = 'HARS_DISTINCT_COUNT_SESSIONWARNING_ASC',
  HarsDistinctCountSessionwarningDesc = 'HARS_DISTINCT_COUNT_SESSIONWARNING_DESC',
  HarsDistinctCountSinceAsc = 'HARS_DISTINCT_COUNT_SINCE_ASC',
  HarsDistinctCountSinceDesc = 'HARS_DISTINCT_COUNT_SINCE_DESC',
  HarsDistinctCountStateAsc = 'HARS_DISTINCT_COUNT_STATE_ASC',
  HarsDistinctCountStateDesc = 'HARS_DISTINCT_COUNT_STATE_DESC',
  HarsDistinctCountUpdatedAtAsc = 'HARS_DISTINCT_COUNT_UPDATED_AT_ASC',
  HarsDistinctCountUpdatedAtDesc = 'HARS_DISTINCT_COUNT_UPDATED_AT_DESC',
  HarsMaxAuthorizecredentialsAsc = 'HARS_MAX_AUTHORIZECREDENTIALS_ASC',
  HarsMaxAuthorizecredentialsDesc = 'HARS_MAX_AUTHORIZECREDENTIALS_DESC',
  HarsMaxAuthorizetestingAsc = 'HARS_MAX_AUTHORIZETESTING_ASC',
  HarsMaxAuthorizetestingDesc = 'HARS_MAX_AUTHORIZETESTING_DESC',
  HarsMaxBankAsc = 'HARS_MAX_BANK_ASC',
  HarsMaxBankDesc = 'HARS_MAX_BANK_DESC',
  HarsMaxCreatedAtAsc = 'HARS_MAX_CREATED_AT_ASC',
  HarsMaxCreatedAtDesc = 'HARS_MAX_CREATED_AT_DESC',
  HarsMaxEmailAsc = 'HARS_MAX_EMAIL_ASC',
  HarsMaxEmailDesc = 'HARS_MAX_EMAIL_DESC',
  HarsMaxIdAsc = 'HARS_MAX_ID_ASC',
  HarsMaxIdDesc = 'HARS_MAX_ID_DESC',
  HarsMaxIssueAsc = 'HARS_MAX_ISSUE_ASC',
  HarsMaxIssueDesc = 'HARS_MAX_ISSUE_DESC',
  HarsMaxNoteAsc = 'HARS_MAX_NOTE_ASC',
  HarsMaxNoteDesc = 'HARS_MAX_NOTE_DESC',
  HarsMaxOutbankUserIdAsc = 'HARS_MAX_OUTBANK_USER_ID_ASC',
  HarsMaxOutbankUserIdDesc = 'HARS_MAX_OUTBANK_USER_ID_DESC',
  HarsMaxSessionwarningAsc = 'HARS_MAX_SESSIONWARNING_ASC',
  HarsMaxSessionwarningDesc = 'HARS_MAX_SESSIONWARNING_DESC',
  HarsMaxSinceAsc = 'HARS_MAX_SINCE_ASC',
  HarsMaxSinceDesc = 'HARS_MAX_SINCE_DESC',
  HarsMaxStateAsc = 'HARS_MAX_STATE_ASC',
  HarsMaxStateDesc = 'HARS_MAX_STATE_DESC',
  HarsMaxUpdatedAtAsc = 'HARS_MAX_UPDATED_AT_ASC',
  HarsMaxUpdatedAtDesc = 'HARS_MAX_UPDATED_AT_DESC',
  HarsMinAuthorizecredentialsAsc = 'HARS_MIN_AUTHORIZECREDENTIALS_ASC',
  HarsMinAuthorizecredentialsDesc = 'HARS_MIN_AUTHORIZECREDENTIALS_DESC',
  HarsMinAuthorizetestingAsc = 'HARS_MIN_AUTHORIZETESTING_ASC',
  HarsMinAuthorizetestingDesc = 'HARS_MIN_AUTHORIZETESTING_DESC',
  HarsMinBankAsc = 'HARS_MIN_BANK_ASC',
  HarsMinBankDesc = 'HARS_MIN_BANK_DESC',
  HarsMinCreatedAtAsc = 'HARS_MIN_CREATED_AT_ASC',
  HarsMinCreatedAtDesc = 'HARS_MIN_CREATED_AT_DESC',
  HarsMinEmailAsc = 'HARS_MIN_EMAIL_ASC',
  HarsMinEmailDesc = 'HARS_MIN_EMAIL_DESC',
  HarsMinIdAsc = 'HARS_MIN_ID_ASC',
  HarsMinIdDesc = 'HARS_MIN_ID_DESC',
  HarsMinIssueAsc = 'HARS_MIN_ISSUE_ASC',
  HarsMinIssueDesc = 'HARS_MIN_ISSUE_DESC',
  HarsMinNoteAsc = 'HARS_MIN_NOTE_ASC',
  HarsMinNoteDesc = 'HARS_MIN_NOTE_DESC',
  HarsMinOutbankUserIdAsc = 'HARS_MIN_OUTBANK_USER_ID_ASC',
  HarsMinOutbankUserIdDesc = 'HARS_MIN_OUTBANK_USER_ID_DESC',
  HarsMinSessionwarningAsc = 'HARS_MIN_SESSIONWARNING_ASC',
  HarsMinSessionwarningDesc = 'HARS_MIN_SESSIONWARNING_DESC',
  HarsMinSinceAsc = 'HARS_MIN_SINCE_ASC',
  HarsMinSinceDesc = 'HARS_MIN_SINCE_DESC',
  HarsMinStateAsc = 'HARS_MIN_STATE_ASC',
  HarsMinStateDesc = 'HARS_MIN_STATE_DESC',
  HarsMinUpdatedAtAsc = 'HARS_MIN_UPDATED_AT_ASC',
  HarsMinUpdatedAtDesc = 'HARS_MIN_UPDATED_AT_DESC',
  HarsStddevPopulationAuthorizecredentialsAsc = 'HARS_STDDEV_POPULATION_AUTHORIZECREDENTIALS_ASC',
  HarsStddevPopulationAuthorizecredentialsDesc = 'HARS_STDDEV_POPULATION_AUTHORIZECREDENTIALS_DESC',
  HarsStddevPopulationAuthorizetestingAsc = 'HARS_STDDEV_POPULATION_AUTHORIZETESTING_ASC',
  HarsStddevPopulationAuthorizetestingDesc = 'HARS_STDDEV_POPULATION_AUTHORIZETESTING_DESC',
  HarsStddevPopulationBankAsc = 'HARS_STDDEV_POPULATION_BANK_ASC',
  HarsStddevPopulationBankDesc = 'HARS_STDDEV_POPULATION_BANK_DESC',
  HarsStddevPopulationCreatedAtAsc = 'HARS_STDDEV_POPULATION_CREATED_AT_ASC',
  HarsStddevPopulationCreatedAtDesc = 'HARS_STDDEV_POPULATION_CREATED_AT_DESC',
  HarsStddevPopulationEmailAsc = 'HARS_STDDEV_POPULATION_EMAIL_ASC',
  HarsStddevPopulationEmailDesc = 'HARS_STDDEV_POPULATION_EMAIL_DESC',
  HarsStddevPopulationIdAsc = 'HARS_STDDEV_POPULATION_ID_ASC',
  HarsStddevPopulationIdDesc = 'HARS_STDDEV_POPULATION_ID_DESC',
  HarsStddevPopulationIssueAsc = 'HARS_STDDEV_POPULATION_ISSUE_ASC',
  HarsStddevPopulationIssueDesc = 'HARS_STDDEV_POPULATION_ISSUE_DESC',
  HarsStddevPopulationNoteAsc = 'HARS_STDDEV_POPULATION_NOTE_ASC',
  HarsStddevPopulationNoteDesc = 'HARS_STDDEV_POPULATION_NOTE_DESC',
  HarsStddevPopulationOutbankUserIdAsc = 'HARS_STDDEV_POPULATION_OUTBANK_USER_ID_ASC',
  HarsStddevPopulationOutbankUserIdDesc = 'HARS_STDDEV_POPULATION_OUTBANK_USER_ID_DESC',
  HarsStddevPopulationSessionwarningAsc = 'HARS_STDDEV_POPULATION_SESSIONWARNING_ASC',
  HarsStddevPopulationSessionwarningDesc = 'HARS_STDDEV_POPULATION_SESSIONWARNING_DESC',
  HarsStddevPopulationSinceAsc = 'HARS_STDDEV_POPULATION_SINCE_ASC',
  HarsStddevPopulationSinceDesc = 'HARS_STDDEV_POPULATION_SINCE_DESC',
  HarsStddevPopulationStateAsc = 'HARS_STDDEV_POPULATION_STATE_ASC',
  HarsStddevPopulationStateDesc = 'HARS_STDDEV_POPULATION_STATE_DESC',
  HarsStddevPopulationUpdatedAtAsc = 'HARS_STDDEV_POPULATION_UPDATED_AT_ASC',
  HarsStddevPopulationUpdatedAtDesc = 'HARS_STDDEV_POPULATION_UPDATED_AT_DESC',
  HarsStddevSampleAuthorizecredentialsAsc = 'HARS_STDDEV_SAMPLE_AUTHORIZECREDENTIALS_ASC',
  HarsStddevSampleAuthorizecredentialsDesc = 'HARS_STDDEV_SAMPLE_AUTHORIZECREDENTIALS_DESC',
  HarsStddevSampleAuthorizetestingAsc = 'HARS_STDDEV_SAMPLE_AUTHORIZETESTING_ASC',
  HarsStddevSampleAuthorizetestingDesc = 'HARS_STDDEV_SAMPLE_AUTHORIZETESTING_DESC',
  HarsStddevSampleBankAsc = 'HARS_STDDEV_SAMPLE_BANK_ASC',
  HarsStddevSampleBankDesc = 'HARS_STDDEV_SAMPLE_BANK_DESC',
  HarsStddevSampleCreatedAtAsc = 'HARS_STDDEV_SAMPLE_CREATED_AT_ASC',
  HarsStddevSampleCreatedAtDesc = 'HARS_STDDEV_SAMPLE_CREATED_AT_DESC',
  HarsStddevSampleEmailAsc = 'HARS_STDDEV_SAMPLE_EMAIL_ASC',
  HarsStddevSampleEmailDesc = 'HARS_STDDEV_SAMPLE_EMAIL_DESC',
  HarsStddevSampleIdAsc = 'HARS_STDDEV_SAMPLE_ID_ASC',
  HarsStddevSampleIdDesc = 'HARS_STDDEV_SAMPLE_ID_DESC',
  HarsStddevSampleIssueAsc = 'HARS_STDDEV_SAMPLE_ISSUE_ASC',
  HarsStddevSampleIssueDesc = 'HARS_STDDEV_SAMPLE_ISSUE_DESC',
  HarsStddevSampleNoteAsc = 'HARS_STDDEV_SAMPLE_NOTE_ASC',
  HarsStddevSampleNoteDesc = 'HARS_STDDEV_SAMPLE_NOTE_DESC',
  HarsStddevSampleOutbankUserIdAsc = 'HARS_STDDEV_SAMPLE_OUTBANK_USER_ID_ASC',
  HarsStddevSampleOutbankUserIdDesc = 'HARS_STDDEV_SAMPLE_OUTBANK_USER_ID_DESC',
  HarsStddevSampleSessionwarningAsc = 'HARS_STDDEV_SAMPLE_SESSIONWARNING_ASC',
  HarsStddevSampleSessionwarningDesc = 'HARS_STDDEV_SAMPLE_SESSIONWARNING_DESC',
  HarsStddevSampleSinceAsc = 'HARS_STDDEV_SAMPLE_SINCE_ASC',
  HarsStddevSampleSinceDesc = 'HARS_STDDEV_SAMPLE_SINCE_DESC',
  HarsStddevSampleStateAsc = 'HARS_STDDEV_SAMPLE_STATE_ASC',
  HarsStddevSampleStateDesc = 'HARS_STDDEV_SAMPLE_STATE_DESC',
  HarsStddevSampleUpdatedAtAsc = 'HARS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  HarsStddevSampleUpdatedAtDesc = 'HARS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  HarsSumAuthorizecredentialsAsc = 'HARS_SUM_AUTHORIZECREDENTIALS_ASC',
  HarsSumAuthorizecredentialsDesc = 'HARS_SUM_AUTHORIZECREDENTIALS_DESC',
  HarsSumAuthorizetestingAsc = 'HARS_SUM_AUTHORIZETESTING_ASC',
  HarsSumAuthorizetestingDesc = 'HARS_SUM_AUTHORIZETESTING_DESC',
  HarsSumBankAsc = 'HARS_SUM_BANK_ASC',
  HarsSumBankDesc = 'HARS_SUM_BANK_DESC',
  HarsSumCreatedAtAsc = 'HARS_SUM_CREATED_AT_ASC',
  HarsSumCreatedAtDesc = 'HARS_SUM_CREATED_AT_DESC',
  HarsSumEmailAsc = 'HARS_SUM_EMAIL_ASC',
  HarsSumEmailDesc = 'HARS_SUM_EMAIL_DESC',
  HarsSumIdAsc = 'HARS_SUM_ID_ASC',
  HarsSumIdDesc = 'HARS_SUM_ID_DESC',
  HarsSumIssueAsc = 'HARS_SUM_ISSUE_ASC',
  HarsSumIssueDesc = 'HARS_SUM_ISSUE_DESC',
  HarsSumNoteAsc = 'HARS_SUM_NOTE_ASC',
  HarsSumNoteDesc = 'HARS_SUM_NOTE_DESC',
  HarsSumOutbankUserIdAsc = 'HARS_SUM_OUTBANK_USER_ID_ASC',
  HarsSumOutbankUserIdDesc = 'HARS_SUM_OUTBANK_USER_ID_DESC',
  HarsSumSessionwarningAsc = 'HARS_SUM_SESSIONWARNING_ASC',
  HarsSumSessionwarningDesc = 'HARS_SUM_SESSIONWARNING_DESC',
  HarsSumSinceAsc = 'HARS_SUM_SINCE_ASC',
  HarsSumSinceDesc = 'HARS_SUM_SINCE_DESC',
  HarsSumStateAsc = 'HARS_SUM_STATE_ASC',
  HarsSumStateDesc = 'HARS_SUM_STATE_DESC',
  HarsSumUpdatedAtAsc = 'HARS_SUM_UPDATED_AT_ASC',
  HarsSumUpdatedAtDesc = 'HARS_SUM_UPDATED_AT_DESC',
  HarsVariancePopulationAuthorizecredentialsAsc = 'HARS_VARIANCE_POPULATION_AUTHORIZECREDENTIALS_ASC',
  HarsVariancePopulationAuthorizecredentialsDesc = 'HARS_VARIANCE_POPULATION_AUTHORIZECREDENTIALS_DESC',
  HarsVariancePopulationAuthorizetestingAsc = 'HARS_VARIANCE_POPULATION_AUTHORIZETESTING_ASC',
  HarsVariancePopulationAuthorizetestingDesc = 'HARS_VARIANCE_POPULATION_AUTHORIZETESTING_DESC',
  HarsVariancePopulationBankAsc = 'HARS_VARIANCE_POPULATION_BANK_ASC',
  HarsVariancePopulationBankDesc = 'HARS_VARIANCE_POPULATION_BANK_DESC',
  HarsVariancePopulationCreatedAtAsc = 'HARS_VARIANCE_POPULATION_CREATED_AT_ASC',
  HarsVariancePopulationCreatedAtDesc = 'HARS_VARIANCE_POPULATION_CREATED_AT_DESC',
  HarsVariancePopulationEmailAsc = 'HARS_VARIANCE_POPULATION_EMAIL_ASC',
  HarsVariancePopulationEmailDesc = 'HARS_VARIANCE_POPULATION_EMAIL_DESC',
  HarsVariancePopulationIdAsc = 'HARS_VARIANCE_POPULATION_ID_ASC',
  HarsVariancePopulationIdDesc = 'HARS_VARIANCE_POPULATION_ID_DESC',
  HarsVariancePopulationIssueAsc = 'HARS_VARIANCE_POPULATION_ISSUE_ASC',
  HarsVariancePopulationIssueDesc = 'HARS_VARIANCE_POPULATION_ISSUE_DESC',
  HarsVariancePopulationNoteAsc = 'HARS_VARIANCE_POPULATION_NOTE_ASC',
  HarsVariancePopulationNoteDesc = 'HARS_VARIANCE_POPULATION_NOTE_DESC',
  HarsVariancePopulationOutbankUserIdAsc = 'HARS_VARIANCE_POPULATION_OUTBANK_USER_ID_ASC',
  HarsVariancePopulationOutbankUserIdDesc = 'HARS_VARIANCE_POPULATION_OUTBANK_USER_ID_DESC',
  HarsVariancePopulationSessionwarningAsc = 'HARS_VARIANCE_POPULATION_SESSIONWARNING_ASC',
  HarsVariancePopulationSessionwarningDesc = 'HARS_VARIANCE_POPULATION_SESSIONWARNING_DESC',
  HarsVariancePopulationSinceAsc = 'HARS_VARIANCE_POPULATION_SINCE_ASC',
  HarsVariancePopulationSinceDesc = 'HARS_VARIANCE_POPULATION_SINCE_DESC',
  HarsVariancePopulationStateAsc = 'HARS_VARIANCE_POPULATION_STATE_ASC',
  HarsVariancePopulationStateDesc = 'HARS_VARIANCE_POPULATION_STATE_DESC',
  HarsVariancePopulationUpdatedAtAsc = 'HARS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  HarsVariancePopulationUpdatedAtDesc = 'HARS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  HarsVarianceSampleAuthorizecredentialsAsc = 'HARS_VARIANCE_SAMPLE_AUTHORIZECREDENTIALS_ASC',
  HarsVarianceSampleAuthorizecredentialsDesc = 'HARS_VARIANCE_SAMPLE_AUTHORIZECREDENTIALS_DESC',
  HarsVarianceSampleAuthorizetestingAsc = 'HARS_VARIANCE_SAMPLE_AUTHORIZETESTING_ASC',
  HarsVarianceSampleAuthorizetestingDesc = 'HARS_VARIANCE_SAMPLE_AUTHORIZETESTING_DESC',
  HarsVarianceSampleBankAsc = 'HARS_VARIANCE_SAMPLE_BANK_ASC',
  HarsVarianceSampleBankDesc = 'HARS_VARIANCE_SAMPLE_BANK_DESC',
  HarsVarianceSampleCreatedAtAsc = 'HARS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  HarsVarianceSampleCreatedAtDesc = 'HARS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  HarsVarianceSampleEmailAsc = 'HARS_VARIANCE_SAMPLE_EMAIL_ASC',
  HarsVarianceSampleEmailDesc = 'HARS_VARIANCE_SAMPLE_EMAIL_DESC',
  HarsVarianceSampleIdAsc = 'HARS_VARIANCE_SAMPLE_ID_ASC',
  HarsVarianceSampleIdDesc = 'HARS_VARIANCE_SAMPLE_ID_DESC',
  HarsVarianceSampleIssueAsc = 'HARS_VARIANCE_SAMPLE_ISSUE_ASC',
  HarsVarianceSampleIssueDesc = 'HARS_VARIANCE_SAMPLE_ISSUE_DESC',
  HarsVarianceSampleNoteAsc = 'HARS_VARIANCE_SAMPLE_NOTE_ASC',
  HarsVarianceSampleNoteDesc = 'HARS_VARIANCE_SAMPLE_NOTE_DESC',
  HarsVarianceSampleOutbankUserIdAsc = 'HARS_VARIANCE_SAMPLE_OUTBANK_USER_ID_ASC',
  HarsVarianceSampleOutbankUserIdDesc = 'HARS_VARIANCE_SAMPLE_OUTBANK_USER_ID_DESC',
  HarsVarianceSampleSessionwarningAsc = 'HARS_VARIANCE_SAMPLE_SESSIONWARNING_ASC',
  HarsVarianceSampleSessionwarningDesc = 'HARS_VARIANCE_SAMPLE_SESSIONWARNING_DESC',
  HarsVarianceSampleSinceAsc = 'HARS_VARIANCE_SAMPLE_SINCE_ASC',
  HarsVarianceSampleSinceDesc = 'HARS_VARIANCE_SAMPLE_SINCE_DESC',
  HarsVarianceSampleStateAsc = 'HARS_VARIANCE_SAMPLE_STATE_ASC',
  HarsVarianceSampleStateDesc = 'HARS_VARIANCE_SAMPLE_STATE_DESC',
  HarsVarianceSampleUpdatedAtAsc = 'HARS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  HarsVarianceSampleUpdatedAtDesc = 'HARS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProtocolsAverageAppVersionAsc = 'PROTOCOLS_AVERAGE_APP_VERSION_ASC',
  ProtocolsAverageAppVersionDesc = 'PROTOCOLS_AVERAGE_APP_VERSION_DESC',
  ProtocolsAverageCreatedAtAsc = 'PROTOCOLS_AVERAGE_CREATED_AT_ASC',
  ProtocolsAverageCreatedAtDesc = 'PROTOCOLS_AVERAGE_CREATED_AT_DESC',
  ProtocolsAverageIdAsc = 'PROTOCOLS_AVERAGE_ID_ASC',
  ProtocolsAverageIdDesc = 'PROTOCOLS_AVERAGE_ID_DESC',
  ProtocolsAverageModelAsc = 'PROTOCOLS_AVERAGE_MODEL_ASC',
  ProtocolsAverageModelDesc = 'PROTOCOLS_AVERAGE_MODEL_DESC',
  ProtocolsAverageOsVersionAsc = 'PROTOCOLS_AVERAGE_OS_VERSION_ASC',
  ProtocolsAverageOsVersionDesc = 'PROTOCOLS_AVERAGE_OS_VERSION_DESC',
  ProtocolsAverageOutbankUserIdAsc = 'PROTOCOLS_AVERAGE_OUTBANK_USER_ID_ASC',
  ProtocolsAverageOutbankUserIdDesc = 'PROTOCOLS_AVERAGE_OUTBANK_USER_ID_DESC',
  ProtocolsAverageStateAsc = 'PROTOCOLS_AVERAGE_STATE_ASC',
  ProtocolsAverageStateDesc = 'PROTOCOLS_AVERAGE_STATE_DESC',
  ProtocolsAverageUpdatedAtAsc = 'PROTOCOLS_AVERAGE_UPDATED_AT_ASC',
  ProtocolsAverageUpdatedAtDesc = 'PROTOCOLS_AVERAGE_UPDATED_AT_DESC',
  ProtocolsCountAsc = 'PROTOCOLS_COUNT_ASC',
  ProtocolsCountDesc = 'PROTOCOLS_COUNT_DESC',
  ProtocolsDistinctCountAppVersionAsc = 'PROTOCOLS_DISTINCT_COUNT_APP_VERSION_ASC',
  ProtocolsDistinctCountAppVersionDesc = 'PROTOCOLS_DISTINCT_COUNT_APP_VERSION_DESC',
  ProtocolsDistinctCountCreatedAtAsc = 'PROTOCOLS_DISTINCT_COUNT_CREATED_AT_ASC',
  ProtocolsDistinctCountCreatedAtDesc = 'PROTOCOLS_DISTINCT_COUNT_CREATED_AT_DESC',
  ProtocolsDistinctCountIdAsc = 'PROTOCOLS_DISTINCT_COUNT_ID_ASC',
  ProtocolsDistinctCountIdDesc = 'PROTOCOLS_DISTINCT_COUNT_ID_DESC',
  ProtocolsDistinctCountModelAsc = 'PROTOCOLS_DISTINCT_COUNT_MODEL_ASC',
  ProtocolsDistinctCountModelDesc = 'PROTOCOLS_DISTINCT_COUNT_MODEL_DESC',
  ProtocolsDistinctCountOsVersionAsc = 'PROTOCOLS_DISTINCT_COUNT_OS_VERSION_ASC',
  ProtocolsDistinctCountOsVersionDesc = 'PROTOCOLS_DISTINCT_COUNT_OS_VERSION_DESC',
  ProtocolsDistinctCountOutbankUserIdAsc = 'PROTOCOLS_DISTINCT_COUNT_OUTBANK_USER_ID_ASC',
  ProtocolsDistinctCountOutbankUserIdDesc = 'PROTOCOLS_DISTINCT_COUNT_OUTBANK_USER_ID_DESC',
  ProtocolsDistinctCountStateAsc = 'PROTOCOLS_DISTINCT_COUNT_STATE_ASC',
  ProtocolsDistinctCountStateDesc = 'PROTOCOLS_DISTINCT_COUNT_STATE_DESC',
  ProtocolsDistinctCountUpdatedAtAsc = 'PROTOCOLS_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProtocolsDistinctCountUpdatedAtDesc = 'PROTOCOLS_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProtocolsMaxAppVersionAsc = 'PROTOCOLS_MAX_APP_VERSION_ASC',
  ProtocolsMaxAppVersionDesc = 'PROTOCOLS_MAX_APP_VERSION_DESC',
  ProtocolsMaxCreatedAtAsc = 'PROTOCOLS_MAX_CREATED_AT_ASC',
  ProtocolsMaxCreatedAtDesc = 'PROTOCOLS_MAX_CREATED_AT_DESC',
  ProtocolsMaxIdAsc = 'PROTOCOLS_MAX_ID_ASC',
  ProtocolsMaxIdDesc = 'PROTOCOLS_MAX_ID_DESC',
  ProtocolsMaxModelAsc = 'PROTOCOLS_MAX_MODEL_ASC',
  ProtocolsMaxModelDesc = 'PROTOCOLS_MAX_MODEL_DESC',
  ProtocolsMaxOsVersionAsc = 'PROTOCOLS_MAX_OS_VERSION_ASC',
  ProtocolsMaxOsVersionDesc = 'PROTOCOLS_MAX_OS_VERSION_DESC',
  ProtocolsMaxOutbankUserIdAsc = 'PROTOCOLS_MAX_OUTBANK_USER_ID_ASC',
  ProtocolsMaxOutbankUserIdDesc = 'PROTOCOLS_MAX_OUTBANK_USER_ID_DESC',
  ProtocolsMaxStateAsc = 'PROTOCOLS_MAX_STATE_ASC',
  ProtocolsMaxStateDesc = 'PROTOCOLS_MAX_STATE_DESC',
  ProtocolsMaxUpdatedAtAsc = 'PROTOCOLS_MAX_UPDATED_AT_ASC',
  ProtocolsMaxUpdatedAtDesc = 'PROTOCOLS_MAX_UPDATED_AT_DESC',
  ProtocolsMinAppVersionAsc = 'PROTOCOLS_MIN_APP_VERSION_ASC',
  ProtocolsMinAppVersionDesc = 'PROTOCOLS_MIN_APP_VERSION_DESC',
  ProtocolsMinCreatedAtAsc = 'PROTOCOLS_MIN_CREATED_AT_ASC',
  ProtocolsMinCreatedAtDesc = 'PROTOCOLS_MIN_CREATED_AT_DESC',
  ProtocolsMinIdAsc = 'PROTOCOLS_MIN_ID_ASC',
  ProtocolsMinIdDesc = 'PROTOCOLS_MIN_ID_DESC',
  ProtocolsMinModelAsc = 'PROTOCOLS_MIN_MODEL_ASC',
  ProtocolsMinModelDesc = 'PROTOCOLS_MIN_MODEL_DESC',
  ProtocolsMinOsVersionAsc = 'PROTOCOLS_MIN_OS_VERSION_ASC',
  ProtocolsMinOsVersionDesc = 'PROTOCOLS_MIN_OS_VERSION_DESC',
  ProtocolsMinOutbankUserIdAsc = 'PROTOCOLS_MIN_OUTBANK_USER_ID_ASC',
  ProtocolsMinOutbankUserIdDesc = 'PROTOCOLS_MIN_OUTBANK_USER_ID_DESC',
  ProtocolsMinStateAsc = 'PROTOCOLS_MIN_STATE_ASC',
  ProtocolsMinStateDesc = 'PROTOCOLS_MIN_STATE_DESC',
  ProtocolsMinUpdatedAtAsc = 'PROTOCOLS_MIN_UPDATED_AT_ASC',
  ProtocolsMinUpdatedAtDesc = 'PROTOCOLS_MIN_UPDATED_AT_DESC',
  ProtocolsStddevPopulationAppVersionAsc = 'PROTOCOLS_STDDEV_POPULATION_APP_VERSION_ASC',
  ProtocolsStddevPopulationAppVersionDesc = 'PROTOCOLS_STDDEV_POPULATION_APP_VERSION_DESC',
  ProtocolsStddevPopulationCreatedAtAsc = 'PROTOCOLS_STDDEV_POPULATION_CREATED_AT_ASC',
  ProtocolsStddevPopulationCreatedAtDesc = 'PROTOCOLS_STDDEV_POPULATION_CREATED_AT_DESC',
  ProtocolsStddevPopulationIdAsc = 'PROTOCOLS_STDDEV_POPULATION_ID_ASC',
  ProtocolsStddevPopulationIdDesc = 'PROTOCOLS_STDDEV_POPULATION_ID_DESC',
  ProtocolsStddevPopulationModelAsc = 'PROTOCOLS_STDDEV_POPULATION_MODEL_ASC',
  ProtocolsStddevPopulationModelDesc = 'PROTOCOLS_STDDEV_POPULATION_MODEL_DESC',
  ProtocolsStddevPopulationOsVersionAsc = 'PROTOCOLS_STDDEV_POPULATION_OS_VERSION_ASC',
  ProtocolsStddevPopulationOsVersionDesc = 'PROTOCOLS_STDDEV_POPULATION_OS_VERSION_DESC',
  ProtocolsStddevPopulationOutbankUserIdAsc = 'PROTOCOLS_STDDEV_POPULATION_OUTBANK_USER_ID_ASC',
  ProtocolsStddevPopulationOutbankUserIdDesc = 'PROTOCOLS_STDDEV_POPULATION_OUTBANK_USER_ID_DESC',
  ProtocolsStddevPopulationStateAsc = 'PROTOCOLS_STDDEV_POPULATION_STATE_ASC',
  ProtocolsStddevPopulationStateDesc = 'PROTOCOLS_STDDEV_POPULATION_STATE_DESC',
  ProtocolsStddevPopulationUpdatedAtAsc = 'PROTOCOLS_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProtocolsStddevPopulationUpdatedAtDesc = 'PROTOCOLS_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProtocolsStddevSampleAppVersionAsc = 'PROTOCOLS_STDDEV_SAMPLE_APP_VERSION_ASC',
  ProtocolsStddevSampleAppVersionDesc = 'PROTOCOLS_STDDEV_SAMPLE_APP_VERSION_DESC',
  ProtocolsStddevSampleCreatedAtAsc = 'PROTOCOLS_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProtocolsStddevSampleCreatedAtDesc = 'PROTOCOLS_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProtocolsStddevSampleIdAsc = 'PROTOCOLS_STDDEV_SAMPLE_ID_ASC',
  ProtocolsStddevSampleIdDesc = 'PROTOCOLS_STDDEV_SAMPLE_ID_DESC',
  ProtocolsStddevSampleModelAsc = 'PROTOCOLS_STDDEV_SAMPLE_MODEL_ASC',
  ProtocolsStddevSampleModelDesc = 'PROTOCOLS_STDDEV_SAMPLE_MODEL_DESC',
  ProtocolsStddevSampleOsVersionAsc = 'PROTOCOLS_STDDEV_SAMPLE_OS_VERSION_ASC',
  ProtocolsStddevSampleOsVersionDesc = 'PROTOCOLS_STDDEV_SAMPLE_OS_VERSION_DESC',
  ProtocolsStddevSampleOutbankUserIdAsc = 'PROTOCOLS_STDDEV_SAMPLE_OUTBANK_USER_ID_ASC',
  ProtocolsStddevSampleOutbankUserIdDesc = 'PROTOCOLS_STDDEV_SAMPLE_OUTBANK_USER_ID_DESC',
  ProtocolsStddevSampleStateAsc = 'PROTOCOLS_STDDEV_SAMPLE_STATE_ASC',
  ProtocolsStddevSampleStateDesc = 'PROTOCOLS_STDDEV_SAMPLE_STATE_DESC',
  ProtocolsStddevSampleUpdatedAtAsc = 'PROTOCOLS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProtocolsStddevSampleUpdatedAtDesc = 'PROTOCOLS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProtocolsSumAppVersionAsc = 'PROTOCOLS_SUM_APP_VERSION_ASC',
  ProtocolsSumAppVersionDesc = 'PROTOCOLS_SUM_APP_VERSION_DESC',
  ProtocolsSumCreatedAtAsc = 'PROTOCOLS_SUM_CREATED_AT_ASC',
  ProtocolsSumCreatedAtDesc = 'PROTOCOLS_SUM_CREATED_AT_DESC',
  ProtocolsSumIdAsc = 'PROTOCOLS_SUM_ID_ASC',
  ProtocolsSumIdDesc = 'PROTOCOLS_SUM_ID_DESC',
  ProtocolsSumModelAsc = 'PROTOCOLS_SUM_MODEL_ASC',
  ProtocolsSumModelDesc = 'PROTOCOLS_SUM_MODEL_DESC',
  ProtocolsSumOsVersionAsc = 'PROTOCOLS_SUM_OS_VERSION_ASC',
  ProtocolsSumOsVersionDesc = 'PROTOCOLS_SUM_OS_VERSION_DESC',
  ProtocolsSumOutbankUserIdAsc = 'PROTOCOLS_SUM_OUTBANK_USER_ID_ASC',
  ProtocolsSumOutbankUserIdDesc = 'PROTOCOLS_SUM_OUTBANK_USER_ID_DESC',
  ProtocolsSumStateAsc = 'PROTOCOLS_SUM_STATE_ASC',
  ProtocolsSumStateDesc = 'PROTOCOLS_SUM_STATE_DESC',
  ProtocolsSumUpdatedAtAsc = 'PROTOCOLS_SUM_UPDATED_AT_ASC',
  ProtocolsSumUpdatedAtDesc = 'PROTOCOLS_SUM_UPDATED_AT_DESC',
  ProtocolsVariancePopulationAppVersionAsc = 'PROTOCOLS_VARIANCE_POPULATION_APP_VERSION_ASC',
  ProtocolsVariancePopulationAppVersionDesc = 'PROTOCOLS_VARIANCE_POPULATION_APP_VERSION_DESC',
  ProtocolsVariancePopulationCreatedAtAsc = 'PROTOCOLS_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProtocolsVariancePopulationCreatedAtDesc = 'PROTOCOLS_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProtocolsVariancePopulationIdAsc = 'PROTOCOLS_VARIANCE_POPULATION_ID_ASC',
  ProtocolsVariancePopulationIdDesc = 'PROTOCOLS_VARIANCE_POPULATION_ID_DESC',
  ProtocolsVariancePopulationModelAsc = 'PROTOCOLS_VARIANCE_POPULATION_MODEL_ASC',
  ProtocolsVariancePopulationModelDesc = 'PROTOCOLS_VARIANCE_POPULATION_MODEL_DESC',
  ProtocolsVariancePopulationOsVersionAsc = 'PROTOCOLS_VARIANCE_POPULATION_OS_VERSION_ASC',
  ProtocolsVariancePopulationOsVersionDesc = 'PROTOCOLS_VARIANCE_POPULATION_OS_VERSION_DESC',
  ProtocolsVariancePopulationOutbankUserIdAsc = 'PROTOCOLS_VARIANCE_POPULATION_OUTBANK_USER_ID_ASC',
  ProtocolsVariancePopulationOutbankUserIdDesc = 'PROTOCOLS_VARIANCE_POPULATION_OUTBANK_USER_ID_DESC',
  ProtocolsVariancePopulationStateAsc = 'PROTOCOLS_VARIANCE_POPULATION_STATE_ASC',
  ProtocolsVariancePopulationStateDesc = 'PROTOCOLS_VARIANCE_POPULATION_STATE_DESC',
  ProtocolsVariancePopulationUpdatedAtAsc = 'PROTOCOLS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProtocolsVariancePopulationUpdatedAtDesc = 'PROTOCOLS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ProtocolsVarianceSampleAppVersionAsc = 'PROTOCOLS_VARIANCE_SAMPLE_APP_VERSION_ASC',
  ProtocolsVarianceSampleAppVersionDesc = 'PROTOCOLS_VARIANCE_SAMPLE_APP_VERSION_DESC',
  ProtocolsVarianceSampleCreatedAtAsc = 'PROTOCOLS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProtocolsVarianceSampleCreatedAtDesc = 'PROTOCOLS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProtocolsVarianceSampleIdAsc = 'PROTOCOLS_VARIANCE_SAMPLE_ID_ASC',
  ProtocolsVarianceSampleIdDesc = 'PROTOCOLS_VARIANCE_SAMPLE_ID_DESC',
  ProtocolsVarianceSampleModelAsc = 'PROTOCOLS_VARIANCE_SAMPLE_MODEL_ASC',
  ProtocolsVarianceSampleModelDesc = 'PROTOCOLS_VARIANCE_SAMPLE_MODEL_DESC',
  ProtocolsVarianceSampleOsVersionAsc = 'PROTOCOLS_VARIANCE_SAMPLE_OS_VERSION_ASC',
  ProtocolsVarianceSampleOsVersionDesc = 'PROTOCOLS_VARIANCE_SAMPLE_OS_VERSION_DESC',
  ProtocolsVarianceSampleOutbankUserIdAsc = 'PROTOCOLS_VARIANCE_SAMPLE_OUTBANK_USER_ID_ASC',
  ProtocolsVarianceSampleOutbankUserIdDesc = 'PROTOCOLS_VARIANCE_SAMPLE_OUTBANK_USER_ID_DESC',
  ProtocolsVarianceSampleStateAsc = 'PROTOCOLS_VARIANCE_SAMPLE_STATE_ASC',
  ProtocolsVarianceSampleStateDesc = 'PROTOCOLS_VARIANCE_SAMPLE_STATE_DESC',
  ProtocolsVarianceSampleUpdatedAtAsc = 'PROTOCOLS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProtocolsVarianceSampleUpdatedAtDesc = 'PROTOCOLS_VARIANCE_SAMPLE_UPDATED_AT_DESC'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']['output']>;
};

export type Protocol = Node & {
  __typename?: 'Protocol';
  appVersion: Scalars['String']['output'];
  createdAt: Scalars['Datetime']['output'];
  /** Reads and enables pagination through a set of `File`. */
  files: FilesConnection;
  id: Scalars['UUID']['output'];
  model?: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  osVersion: Scalars['String']['output'];
  /** Reads a single `OutbankUser` that is related to this `Protocol`. */
  outbankUser?: Maybe<OutbankUser>;
  outbankUserId?: Maybe<Scalars['UUID']['output']>;
  state: StateType;
  updatedAt: Scalars['Datetime']['output'];
};


export type ProtocolFilesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FileCondition>;
  filter?: InputMaybe<FileFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

export type ProtocolAggregates = {
  __typename?: 'ProtocolAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ProtocolDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * A condition to be used against `Protocol` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ProtocolCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `outbankUserId` field. */
  outbankUserId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<StateType>;
};

export type ProtocolDistinctCountAggregates = {
  __typename?: 'ProtocolDistinctCountAggregates';
  /** Distinct count of appVersion across the matching connection */
  appVersion?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of model across the matching connection */
  model?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of osVersion across the matching connection */
  osVersion?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of outbankUserId across the matching connection */
  outbankUserId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of state across the matching connection */
  state?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `Protocol` object types. All fields are combined with a logical ‘and.’ */
export type ProtocolFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProtocolFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProtocolFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProtocolFilter>>;
  /** Filter by the object’s `outbankUserId` field. */
  outbankUserId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<StateTypeFilter>;
};

/** Grouping methods for `Protocol` for usage during aggregation. */
export enum ProtocolGroupBy {
  OutbankUserId = 'OUTBANK_USER_ID',
  State = 'STATE'
}

export type ProtocolHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProtocolHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Protocol` aggregates. */
export type ProtocolHavingInput = {
  AND?: InputMaybe<Array<ProtocolHavingInput>>;
  OR?: InputMaybe<Array<ProtocolHavingInput>>;
  average?: InputMaybe<ProtocolHavingAverageInput>;
  distinctCount?: InputMaybe<ProtocolHavingDistinctCountInput>;
  max?: InputMaybe<ProtocolHavingMaxInput>;
  min?: InputMaybe<ProtocolHavingMinInput>;
  stddevPopulation?: InputMaybe<ProtocolHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ProtocolHavingStddevSampleInput>;
  sum?: InputMaybe<ProtocolHavingSumInput>;
  variancePopulation?: InputMaybe<ProtocolHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ProtocolHavingVarianceSampleInput>;
};

export type ProtocolHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProtocolHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProtocolHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProtocolHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProtocolHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProtocolHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProtocolHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Protocol` */
export type ProtocolInput = {
  appVersion?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  osVersion: Scalars['String']['input'];
  outbankUserId?: InputMaybe<Scalars['UUID']['input']>;
  state: StateType;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** Represents an update to a `Protocol`. Fields that are set will be updated. */
export type ProtocolPatch = {
  appVersion?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  osVersion?: InputMaybe<Scalars['String']['input']>;
  outbankUserId?: InputMaybe<Scalars['UUID']['input']>;
  state?: InputMaybe<StateType>;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A connection to a list of `Protocol` values. */
export type ProtocolsConnection = {
  __typename?: 'ProtocolsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ProtocolAggregates>;
  /** A list of edges which contains the `Protocol` and cursor to aid in pagination. */
  edges: Array<ProtocolsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ProtocolAggregates>>;
  /** A list of `Protocol` objects. */
  nodes: Array<Protocol>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Protocol` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Protocol` values. */
export type ProtocolsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProtocolGroupBy>;
  having?: InputMaybe<ProtocolHavingInput>;
};

/** A `Protocol` edge in the connection. */
export type ProtocolsEdge = {
  __typename?: 'ProtocolsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Protocol` at the end of the edge. */
  node: Protocol;
};

/** Methods to use when ordering `Protocol`. */
export enum ProtocolsOrderBy {
  FilesAverageCrashIdAsc = 'FILES_AVERAGE_CRASH_ID_ASC',
  FilesAverageCrashIdDesc = 'FILES_AVERAGE_CRASH_ID_DESC',
  FilesAverageCreatedAtAsc = 'FILES_AVERAGE_CREATED_AT_ASC',
  FilesAverageCreatedAtDesc = 'FILES_AVERAGE_CREATED_AT_DESC',
  FilesAverageDataAsc = 'FILES_AVERAGE_DATA_ASC',
  FilesAverageDataDesc = 'FILES_AVERAGE_DATA_DESC',
  FilesAverageHarIdAsc = 'FILES_AVERAGE_HAR_ID_ASC',
  FilesAverageHarIdDesc = 'FILES_AVERAGE_HAR_ID_DESC',
  FilesAverageIdAsc = 'FILES_AVERAGE_ID_ASC',
  FilesAverageIdDesc = 'FILES_AVERAGE_ID_DESC',
  FilesAverageNameAsc = 'FILES_AVERAGE_NAME_ASC',
  FilesAverageNameDesc = 'FILES_AVERAGE_NAME_DESC',
  FilesAverageProtocolIdAsc = 'FILES_AVERAGE_PROTOCOL_ID_ASC',
  FilesAverageProtocolIdDesc = 'FILES_AVERAGE_PROTOCOL_ID_DESC',
  FilesAverageSizeAsc = 'FILES_AVERAGE_SIZE_ASC',
  FilesAverageSizeDesc = 'FILES_AVERAGE_SIZE_DESC',
  FilesAverageSuffixAsc = 'FILES_AVERAGE_SUFFIX_ASC',
  FilesAverageSuffixDesc = 'FILES_AVERAGE_SUFFIX_DESC',
  FilesAverageUpdatedAtAsc = 'FILES_AVERAGE_UPDATED_AT_ASC',
  FilesAverageUpdatedAtDesc = 'FILES_AVERAGE_UPDATED_AT_DESC',
  FilesCountAsc = 'FILES_COUNT_ASC',
  FilesCountDesc = 'FILES_COUNT_DESC',
  FilesDistinctCountCrashIdAsc = 'FILES_DISTINCT_COUNT_CRASH_ID_ASC',
  FilesDistinctCountCrashIdDesc = 'FILES_DISTINCT_COUNT_CRASH_ID_DESC',
  FilesDistinctCountCreatedAtAsc = 'FILES_DISTINCT_COUNT_CREATED_AT_ASC',
  FilesDistinctCountCreatedAtDesc = 'FILES_DISTINCT_COUNT_CREATED_AT_DESC',
  FilesDistinctCountDataAsc = 'FILES_DISTINCT_COUNT_DATA_ASC',
  FilesDistinctCountDataDesc = 'FILES_DISTINCT_COUNT_DATA_DESC',
  FilesDistinctCountHarIdAsc = 'FILES_DISTINCT_COUNT_HAR_ID_ASC',
  FilesDistinctCountHarIdDesc = 'FILES_DISTINCT_COUNT_HAR_ID_DESC',
  FilesDistinctCountIdAsc = 'FILES_DISTINCT_COUNT_ID_ASC',
  FilesDistinctCountIdDesc = 'FILES_DISTINCT_COUNT_ID_DESC',
  FilesDistinctCountNameAsc = 'FILES_DISTINCT_COUNT_NAME_ASC',
  FilesDistinctCountNameDesc = 'FILES_DISTINCT_COUNT_NAME_DESC',
  FilesDistinctCountProtocolIdAsc = 'FILES_DISTINCT_COUNT_PROTOCOL_ID_ASC',
  FilesDistinctCountProtocolIdDesc = 'FILES_DISTINCT_COUNT_PROTOCOL_ID_DESC',
  FilesDistinctCountSizeAsc = 'FILES_DISTINCT_COUNT_SIZE_ASC',
  FilesDistinctCountSizeDesc = 'FILES_DISTINCT_COUNT_SIZE_DESC',
  FilesDistinctCountSuffixAsc = 'FILES_DISTINCT_COUNT_SUFFIX_ASC',
  FilesDistinctCountSuffixDesc = 'FILES_DISTINCT_COUNT_SUFFIX_DESC',
  FilesDistinctCountUpdatedAtAsc = 'FILES_DISTINCT_COUNT_UPDATED_AT_ASC',
  FilesDistinctCountUpdatedAtDesc = 'FILES_DISTINCT_COUNT_UPDATED_AT_DESC',
  FilesMaxCrashIdAsc = 'FILES_MAX_CRASH_ID_ASC',
  FilesMaxCrashIdDesc = 'FILES_MAX_CRASH_ID_DESC',
  FilesMaxCreatedAtAsc = 'FILES_MAX_CREATED_AT_ASC',
  FilesMaxCreatedAtDesc = 'FILES_MAX_CREATED_AT_DESC',
  FilesMaxDataAsc = 'FILES_MAX_DATA_ASC',
  FilesMaxDataDesc = 'FILES_MAX_DATA_DESC',
  FilesMaxHarIdAsc = 'FILES_MAX_HAR_ID_ASC',
  FilesMaxHarIdDesc = 'FILES_MAX_HAR_ID_DESC',
  FilesMaxIdAsc = 'FILES_MAX_ID_ASC',
  FilesMaxIdDesc = 'FILES_MAX_ID_DESC',
  FilesMaxNameAsc = 'FILES_MAX_NAME_ASC',
  FilesMaxNameDesc = 'FILES_MAX_NAME_DESC',
  FilesMaxProtocolIdAsc = 'FILES_MAX_PROTOCOL_ID_ASC',
  FilesMaxProtocolIdDesc = 'FILES_MAX_PROTOCOL_ID_DESC',
  FilesMaxSizeAsc = 'FILES_MAX_SIZE_ASC',
  FilesMaxSizeDesc = 'FILES_MAX_SIZE_DESC',
  FilesMaxSuffixAsc = 'FILES_MAX_SUFFIX_ASC',
  FilesMaxSuffixDesc = 'FILES_MAX_SUFFIX_DESC',
  FilesMaxUpdatedAtAsc = 'FILES_MAX_UPDATED_AT_ASC',
  FilesMaxUpdatedAtDesc = 'FILES_MAX_UPDATED_AT_DESC',
  FilesMinCrashIdAsc = 'FILES_MIN_CRASH_ID_ASC',
  FilesMinCrashIdDesc = 'FILES_MIN_CRASH_ID_DESC',
  FilesMinCreatedAtAsc = 'FILES_MIN_CREATED_AT_ASC',
  FilesMinCreatedAtDesc = 'FILES_MIN_CREATED_AT_DESC',
  FilesMinDataAsc = 'FILES_MIN_DATA_ASC',
  FilesMinDataDesc = 'FILES_MIN_DATA_DESC',
  FilesMinHarIdAsc = 'FILES_MIN_HAR_ID_ASC',
  FilesMinHarIdDesc = 'FILES_MIN_HAR_ID_DESC',
  FilesMinIdAsc = 'FILES_MIN_ID_ASC',
  FilesMinIdDesc = 'FILES_MIN_ID_DESC',
  FilesMinNameAsc = 'FILES_MIN_NAME_ASC',
  FilesMinNameDesc = 'FILES_MIN_NAME_DESC',
  FilesMinProtocolIdAsc = 'FILES_MIN_PROTOCOL_ID_ASC',
  FilesMinProtocolIdDesc = 'FILES_MIN_PROTOCOL_ID_DESC',
  FilesMinSizeAsc = 'FILES_MIN_SIZE_ASC',
  FilesMinSizeDesc = 'FILES_MIN_SIZE_DESC',
  FilesMinSuffixAsc = 'FILES_MIN_SUFFIX_ASC',
  FilesMinSuffixDesc = 'FILES_MIN_SUFFIX_DESC',
  FilesMinUpdatedAtAsc = 'FILES_MIN_UPDATED_AT_ASC',
  FilesMinUpdatedAtDesc = 'FILES_MIN_UPDATED_AT_DESC',
  FilesStddevPopulationCrashIdAsc = 'FILES_STDDEV_POPULATION_CRASH_ID_ASC',
  FilesStddevPopulationCrashIdDesc = 'FILES_STDDEV_POPULATION_CRASH_ID_DESC',
  FilesStddevPopulationCreatedAtAsc = 'FILES_STDDEV_POPULATION_CREATED_AT_ASC',
  FilesStddevPopulationCreatedAtDesc = 'FILES_STDDEV_POPULATION_CREATED_AT_DESC',
  FilesStddevPopulationDataAsc = 'FILES_STDDEV_POPULATION_DATA_ASC',
  FilesStddevPopulationDataDesc = 'FILES_STDDEV_POPULATION_DATA_DESC',
  FilesStddevPopulationHarIdAsc = 'FILES_STDDEV_POPULATION_HAR_ID_ASC',
  FilesStddevPopulationHarIdDesc = 'FILES_STDDEV_POPULATION_HAR_ID_DESC',
  FilesStddevPopulationIdAsc = 'FILES_STDDEV_POPULATION_ID_ASC',
  FilesStddevPopulationIdDesc = 'FILES_STDDEV_POPULATION_ID_DESC',
  FilesStddevPopulationNameAsc = 'FILES_STDDEV_POPULATION_NAME_ASC',
  FilesStddevPopulationNameDesc = 'FILES_STDDEV_POPULATION_NAME_DESC',
  FilesStddevPopulationProtocolIdAsc = 'FILES_STDDEV_POPULATION_PROTOCOL_ID_ASC',
  FilesStddevPopulationProtocolIdDesc = 'FILES_STDDEV_POPULATION_PROTOCOL_ID_DESC',
  FilesStddevPopulationSizeAsc = 'FILES_STDDEV_POPULATION_SIZE_ASC',
  FilesStddevPopulationSizeDesc = 'FILES_STDDEV_POPULATION_SIZE_DESC',
  FilesStddevPopulationSuffixAsc = 'FILES_STDDEV_POPULATION_SUFFIX_ASC',
  FilesStddevPopulationSuffixDesc = 'FILES_STDDEV_POPULATION_SUFFIX_DESC',
  FilesStddevPopulationUpdatedAtAsc = 'FILES_STDDEV_POPULATION_UPDATED_AT_ASC',
  FilesStddevPopulationUpdatedAtDesc = 'FILES_STDDEV_POPULATION_UPDATED_AT_DESC',
  FilesStddevSampleCrashIdAsc = 'FILES_STDDEV_SAMPLE_CRASH_ID_ASC',
  FilesStddevSampleCrashIdDesc = 'FILES_STDDEV_SAMPLE_CRASH_ID_DESC',
  FilesStddevSampleCreatedAtAsc = 'FILES_STDDEV_SAMPLE_CREATED_AT_ASC',
  FilesStddevSampleCreatedAtDesc = 'FILES_STDDEV_SAMPLE_CREATED_AT_DESC',
  FilesStddevSampleDataAsc = 'FILES_STDDEV_SAMPLE_DATA_ASC',
  FilesStddevSampleDataDesc = 'FILES_STDDEV_SAMPLE_DATA_DESC',
  FilesStddevSampleHarIdAsc = 'FILES_STDDEV_SAMPLE_HAR_ID_ASC',
  FilesStddevSampleHarIdDesc = 'FILES_STDDEV_SAMPLE_HAR_ID_DESC',
  FilesStddevSampleIdAsc = 'FILES_STDDEV_SAMPLE_ID_ASC',
  FilesStddevSampleIdDesc = 'FILES_STDDEV_SAMPLE_ID_DESC',
  FilesStddevSampleNameAsc = 'FILES_STDDEV_SAMPLE_NAME_ASC',
  FilesStddevSampleNameDesc = 'FILES_STDDEV_SAMPLE_NAME_DESC',
  FilesStddevSampleProtocolIdAsc = 'FILES_STDDEV_SAMPLE_PROTOCOL_ID_ASC',
  FilesStddevSampleProtocolIdDesc = 'FILES_STDDEV_SAMPLE_PROTOCOL_ID_DESC',
  FilesStddevSampleSizeAsc = 'FILES_STDDEV_SAMPLE_SIZE_ASC',
  FilesStddevSampleSizeDesc = 'FILES_STDDEV_SAMPLE_SIZE_DESC',
  FilesStddevSampleSuffixAsc = 'FILES_STDDEV_SAMPLE_SUFFIX_ASC',
  FilesStddevSampleSuffixDesc = 'FILES_STDDEV_SAMPLE_SUFFIX_DESC',
  FilesStddevSampleUpdatedAtAsc = 'FILES_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FilesStddevSampleUpdatedAtDesc = 'FILES_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FilesSumCrashIdAsc = 'FILES_SUM_CRASH_ID_ASC',
  FilesSumCrashIdDesc = 'FILES_SUM_CRASH_ID_DESC',
  FilesSumCreatedAtAsc = 'FILES_SUM_CREATED_AT_ASC',
  FilesSumCreatedAtDesc = 'FILES_SUM_CREATED_AT_DESC',
  FilesSumDataAsc = 'FILES_SUM_DATA_ASC',
  FilesSumDataDesc = 'FILES_SUM_DATA_DESC',
  FilesSumHarIdAsc = 'FILES_SUM_HAR_ID_ASC',
  FilesSumHarIdDesc = 'FILES_SUM_HAR_ID_DESC',
  FilesSumIdAsc = 'FILES_SUM_ID_ASC',
  FilesSumIdDesc = 'FILES_SUM_ID_DESC',
  FilesSumNameAsc = 'FILES_SUM_NAME_ASC',
  FilesSumNameDesc = 'FILES_SUM_NAME_DESC',
  FilesSumProtocolIdAsc = 'FILES_SUM_PROTOCOL_ID_ASC',
  FilesSumProtocolIdDesc = 'FILES_SUM_PROTOCOL_ID_DESC',
  FilesSumSizeAsc = 'FILES_SUM_SIZE_ASC',
  FilesSumSizeDesc = 'FILES_SUM_SIZE_DESC',
  FilesSumSuffixAsc = 'FILES_SUM_SUFFIX_ASC',
  FilesSumSuffixDesc = 'FILES_SUM_SUFFIX_DESC',
  FilesSumUpdatedAtAsc = 'FILES_SUM_UPDATED_AT_ASC',
  FilesSumUpdatedAtDesc = 'FILES_SUM_UPDATED_AT_DESC',
  FilesVariancePopulationCrashIdAsc = 'FILES_VARIANCE_POPULATION_CRASH_ID_ASC',
  FilesVariancePopulationCrashIdDesc = 'FILES_VARIANCE_POPULATION_CRASH_ID_DESC',
  FilesVariancePopulationCreatedAtAsc = 'FILES_VARIANCE_POPULATION_CREATED_AT_ASC',
  FilesVariancePopulationCreatedAtDesc = 'FILES_VARIANCE_POPULATION_CREATED_AT_DESC',
  FilesVariancePopulationDataAsc = 'FILES_VARIANCE_POPULATION_DATA_ASC',
  FilesVariancePopulationDataDesc = 'FILES_VARIANCE_POPULATION_DATA_DESC',
  FilesVariancePopulationHarIdAsc = 'FILES_VARIANCE_POPULATION_HAR_ID_ASC',
  FilesVariancePopulationHarIdDesc = 'FILES_VARIANCE_POPULATION_HAR_ID_DESC',
  FilesVariancePopulationIdAsc = 'FILES_VARIANCE_POPULATION_ID_ASC',
  FilesVariancePopulationIdDesc = 'FILES_VARIANCE_POPULATION_ID_DESC',
  FilesVariancePopulationNameAsc = 'FILES_VARIANCE_POPULATION_NAME_ASC',
  FilesVariancePopulationNameDesc = 'FILES_VARIANCE_POPULATION_NAME_DESC',
  FilesVariancePopulationProtocolIdAsc = 'FILES_VARIANCE_POPULATION_PROTOCOL_ID_ASC',
  FilesVariancePopulationProtocolIdDesc = 'FILES_VARIANCE_POPULATION_PROTOCOL_ID_DESC',
  FilesVariancePopulationSizeAsc = 'FILES_VARIANCE_POPULATION_SIZE_ASC',
  FilesVariancePopulationSizeDesc = 'FILES_VARIANCE_POPULATION_SIZE_DESC',
  FilesVariancePopulationSuffixAsc = 'FILES_VARIANCE_POPULATION_SUFFIX_ASC',
  FilesVariancePopulationSuffixDesc = 'FILES_VARIANCE_POPULATION_SUFFIX_DESC',
  FilesVariancePopulationUpdatedAtAsc = 'FILES_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FilesVariancePopulationUpdatedAtDesc = 'FILES_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FilesVarianceSampleCrashIdAsc = 'FILES_VARIANCE_SAMPLE_CRASH_ID_ASC',
  FilesVarianceSampleCrashIdDesc = 'FILES_VARIANCE_SAMPLE_CRASH_ID_DESC',
  FilesVarianceSampleCreatedAtAsc = 'FILES_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FilesVarianceSampleCreatedAtDesc = 'FILES_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FilesVarianceSampleDataAsc = 'FILES_VARIANCE_SAMPLE_DATA_ASC',
  FilesVarianceSampleDataDesc = 'FILES_VARIANCE_SAMPLE_DATA_DESC',
  FilesVarianceSampleHarIdAsc = 'FILES_VARIANCE_SAMPLE_HAR_ID_ASC',
  FilesVarianceSampleHarIdDesc = 'FILES_VARIANCE_SAMPLE_HAR_ID_DESC',
  FilesVarianceSampleIdAsc = 'FILES_VARIANCE_SAMPLE_ID_ASC',
  FilesVarianceSampleIdDesc = 'FILES_VARIANCE_SAMPLE_ID_DESC',
  FilesVarianceSampleNameAsc = 'FILES_VARIANCE_SAMPLE_NAME_ASC',
  FilesVarianceSampleNameDesc = 'FILES_VARIANCE_SAMPLE_NAME_DESC',
  FilesVarianceSampleProtocolIdAsc = 'FILES_VARIANCE_SAMPLE_PROTOCOL_ID_ASC',
  FilesVarianceSampleProtocolIdDesc = 'FILES_VARIANCE_SAMPLE_PROTOCOL_ID_DESC',
  FilesVarianceSampleSizeAsc = 'FILES_VARIANCE_SAMPLE_SIZE_ASC',
  FilesVarianceSampleSizeDesc = 'FILES_VARIANCE_SAMPLE_SIZE_DESC',
  FilesVarianceSampleSuffixAsc = 'FILES_VARIANCE_SAMPLE_SUFFIX_ASC',
  FilesVarianceSampleSuffixDesc = 'FILES_VARIANCE_SAMPLE_SUFFIX_DESC',
  FilesVarianceSampleUpdatedAtAsc = 'FILES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FilesVarianceSampleUpdatedAtDesc = 'FILES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  OutbankUserIdAsc = 'OUTBANK_USER_ID_ASC',
  OutbankUserIdDesc = 'OUTBANK_USER_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC'
}

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query';
  appSource?: Maybe<AppSource>;
  /** Reads a single `AppSource` using its globally unique `ID`. */
  appSourceByNodeId?: Maybe<AppSource>;
  /** Reads and enables pagination through a set of `AppSource`. */
  appSources?: Maybe<AppSourcesConnection>;
  crash?: Maybe<Crash>;
  /** Reads a single `Crash` using its globally unique `ID`. */
  crashByNodeId?: Maybe<Crash>;
  crashGroup?: Maybe<CrashGroup>;
  /** Reads a single `CrashGroup` using its globally unique `ID`. */
  crashGroupByNodeId?: Maybe<CrashGroup>;
  /** Reads and enables pagination through a set of `CrashGroup`. */
  crashGroups?: Maybe<CrashGroupsConnection>;
  /** Reads and enables pagination through a set of `Crash`. */
  crashes?: Maybe<CrashesConnection>;
  file?: Maybe<File>;
  /** Reads a single `File` using its globally unique `ID`. */
  fileByNodeId?: Maybe<File>;
  /** Reads and enables pagination through a set of `File`. */
  files?: Maybe<FilesConnection>;
  har?: Maybe<Har>;
  /** Reads a single `Har` using its globally unique `ID`. */
  harByNodeId?: Maybe<Har>;
  /** Reads and enables pagination through a set of `Har`. */
  hars?: Maybe<HarsConnection>;
  localizationLanguage?: Maybe<LocalizationLanguage>;
  /** Reads a single `LocalizationLanguage` using its globally unique `ID`. */
  localizationLanguageByNodeId?: Maybe<LocalizationLanguage>;
  /** Reads and enables pagination through a set of `LocalizationLanguage`. */
  localizationLanguages?: Maybe<LocalizationLanguagesConnection>;
  localizationProject?: Maybe<LocalizationProject>;
  /** Reads a single `LocalizationProject` using its globally unique `ID`. */
  localizationProjectByNodeId?: Maybe<LocalizationProject>;
  localizationProjectXLanguage?: Maybe<LocalizationProjectXLanguage>;
  /** Reads a single `LocalizationProjectXLanguage` using its globally unique `ID`. */
  localizationProjectXLanguageByNodeId?: Maybe<LocalizationProjectXLanguage>;
  /** Reads and enables pagination through a set of `LocalizationProjectXLanguage`. */
  localizationProjectXLanguages?: Maybe<LocalizationProjectXLanguagesConnection>;
  /** Reads and enables pagination through a set of `LocalizationProject`. */
  localizationProjects?: Maybe<LocalizationProjectsConnection>;
  localizationTranslationKey?: Maybe<LocalizationTranslationKey>;
  /** Reads a single `LocalizationTranslationKey` using its globally unique `ID`. */
  localizationTranslationKeyByNodeId?: Maybe<LocalizationTranslationKey>;
  /** Reads and enables pagination through a set of `LocalizationTranslationKey`. */
  localizationTranslationKeys?: Maybe<LocalizationTranslationKeysConnection>;
  localizationTranslationValue?: Maybe<LocalizationTranslationValue>;
  /** Reads a single `LocalizationTranslationValue` using its globally unique `ID`. */
  localizationTranslationValueByNodeId?: Maybe<LocalizationTranslationValue>;
  /** Reads and enables pagination through a set of `LocalizationTranslationValue`. */
  localizationTranslationValues?: Maybe<LocalizationTranslationValuesConnection>;
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID']['output'];
  outbankUser?: Maybe<OutbankUser>;
  /** Reads a single `OutbankUser` using its globally unique `ID`. */
  outbankUserByNodeId?: Maybe<OutbankUser>;
  /** Reads and enables pagination through a set of `OutbankUser`. */
  outbankUsers?: Maybe<OutbankUsersConnection>;
  protocol?: Maybe<Protocol>;
  /** Reads a single `Protocol` using its globally unique `ID`. */
  protocolByNodeId?: Maybe<Protocol>;
  /** Reads and enables pagination through a set of `Protocol`. */
  protocols?: Maybe<ProtocolsConnection>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  sequelizeMeta?: Maybe<SequelizeMeta>;
  /** Reads a single `SequelizeMeta` using its globally unique `ID`. */
  sequelizeMetaByNodeId?: Maybe<SequelizeMeta>;
  /** Reads and enables pagination through a set of `SequelizeMeta`. */
  sequelizeMetas?: Maybe<SequelizeMetasConnection>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAppSourceArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAppSourceByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAppSourcesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<AppSourceCondition>;
  filter?: InputMaybe<AppSourceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AppSourcesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCrashArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCrashByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCrashGroupArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCrashGroupByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCrashGroupsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CrashGroupCondition>;
  filter?: InputMaybe<CrashGroupFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrashGroupsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCrashesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CrashCondition>;
  filter?: InputMaybe<CrashFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrashesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFileArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFileByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFilesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FileCondition>;
  filter?: InputMaybe<FileFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryHarArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryHarByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryHarsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<HarCondition>;
  filter?: InputMaybe<HarFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<HarsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLocalizationLanguageArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLocalizationLanguageByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLocalizationLanguagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LocalizationLanguageCondition>;
  filter?: InputMaybe<LocalizationLanguageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LocalizationLanguagesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLocalizationProjectArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLocalizationProjectByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLocalizationProjectXLanguageArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLocalizationProjectXLanguageByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLocalizationProjectXLanguagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LocalizationProjectXLanguageCondition>;
  filter?: InputMaybe<LocalizationProjectXLanguageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LocalizationProjectXLanguagesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLocalizationProjectsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LocalizationProjectCondition>;
  filter?: InputMaybe<LocalizationProjectFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LocalizationProjectsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLocalizationTranslationKeyArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLocalizationTranslationKeyByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLocalizationTranslationKeysArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LocalizationTranslationKeyCondition>;
  filter?: InputMaybe<LocalizationTranslationKeyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LocalizationTranslationKeysOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLocalizationTranslationValueArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLocalizationTranslationValueByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLocalizationTranslationValuesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LocalizationTranslationValueCondition>;
  filter?: InputMaybe<LocalizationTranslationValueFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LocalizationTranslationValuesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOutbankUserArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOutbankUserByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOutbankUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<OutbankUserCondition>;
  filter?: InputMaybe<OutbankUserFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OutbankUsersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProtocolArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProtocolByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProtocolsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProtocolCondition>;
  filter?: InputMaybe<ProtocolFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProtocolsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySequelizeMetaArgs = {
  name: Scalars['String']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySequelizeMetaByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySequelizeMetasArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<SequelizeMetaCondition>;
  filter?: InputMaybe<SequelizeMetaFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SequelizeMetasOrderBy>>;
};

export type SequelizeMeta = Node & {
  __typename?: 'SequelizeMeta';
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
};

export type SequelizeMetaAggregates = {
  __typename?: 'SequelizeMetaAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<SequelizeMetaDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * A condition to be used against `SequelizeMeta` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SequelizeMetaCondition = {
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SequelizeMetaDistinctCountAggregates = {
  __typename?: 'SequelizeMetaDistinctCountAggregates';
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `SequelizeMeta` object types. All fields are combined with a logical ‘and.’ */
export type SequelizeMetaFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SequelizeMetaFilter>>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SequelizeMetaFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SequelizeMetaFilter>>;
};

/** An input for mutations affecting `SequelizeMeta` */
export type SequelizeMetaInput = {
  name: Scalars['String']['input'];
};

/** Represents an update to a `SequelizeMeta`. Fields that are set will be updated. */
export type SequelizeMetaPatch = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of `SequelizeMeta` values. */
export type SequelizeMetasConnection = {
  __typename?: 'SequelizeMetasConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<SequelizeMetaAggregates>;
  /** A list of edges which contains the `SequelizeMeta` and cursor to aid in pagination. */
  edges: Array<SequelizeMetasEdge>;
  /** A list of `SequelizeMeta` objects. */
  nodes: Array<SequelizeMeta>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SequelizeMeta` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `SequelizeMeta` edge in the connection. */
export type SequelizeMetasEdge = {
  __typename?: 'SequelizeMetasEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `SequelizeMeta` at the end of the edge. */
  node: SequelizeMeta;
};

/** Methods to use when ordering `SequelizeMeta`. */
export enum SequelizeMetasOrderBy {
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export enum StateType {
  /** closed */
  Closed = 'CLOSED',
  /** in progress */
  Inprogress = 'INPROGRESS',
  /** open */
  Open = 'OPEN',
  /** unread */
  Unread = 'UNREAD'
}

/** A filter to be used against StateType fields. All fields are combined with a logical ‘and.’ */
export type StateTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<StateType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<StateType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<StateType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<StateType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<StateType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<StateType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<StateType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<StateType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<StateType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<StateType>>;
};

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['String']['input']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: InputMaybe<Scalars['String']['input']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['String']['input']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['String']['input']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['String']['input']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Contains the specified string (case-sensitive). */
  includes?: InputMaybe<Scalars['String']['input']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['String']['input']>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['String']['input']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: InputMaybe<Scalars['String']['input']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['String']['input']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: InputMaybe<Scalars['String']['input']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['String']['input']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: InputMaybe<Scalars['String']['input']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: InputMaybe<Scalars['String']['input']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: InputMaybe<Scalars['String']['input']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: InputMaybe<Scalars['String']['input']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['UUID']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['UUID']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['UUID']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['UUID']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['UUID']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['UUID']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['UUID']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['UUID']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

/** All input for the `updateAppSourceByNodeId` mutation. */
export type UpdateAppSourceByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `AppSource` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `AppSource` being updated. */
  patch: AppSourcePatch;
};

/** All input for the `updateAppSource` mutation. */
export type UpdateAppSourceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  /** An object where the defined keys will be set on the `AppSource` being updated. */
  patch: AppSourcePatch;
};

/** The output of our update `AppSource` mutation. */
export type UpdateAppSourcePayload = {
  __typename?: 'UpdateAppSourcePayload';
  /** The `AppSource` that was updated by this mutation. */
  appSource?: Maybe<AppSource>;
  /** An edge for our `AppSource`. May be used by Relay 1. */
  appSourceEdge?: Maybe<AppSourcesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `AppSource` mutation. */
export type UpdateAppSourcePayloadAppSourceEdgeArgs = {
  orderBy?: InputMaybe<Array<AppSourcesOrderBy>>;
};

/** All input for the `updateCrashByNodeId` mutation. */
export type UpdateCrashByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `Crash` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `Crash` being updated. */
  patch: CrashPatch;
};

/** All input for the `updateCrashGroupByNodeId` mutation. */
export type UpdateCrashGroupByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `CrashGroup` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `CrashGroup` being updated. */
  patch: CrashGroupPatch;
};

/** All input for the `updateCrashGroup` mutation. */
export type UpdateCrashGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  /** An object where the defined keys will be set on the `CrashGroup` being updated. */
  patch: CrashGroupPatch;
};

/** The output of our update `CrashGroup` mutation. */
export type UpdateCrashGroupPayload = {
  __typename?: 'UpdateCrashGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The `CrashGroup` that was updated by this mutation. */
  crashGroup?: Maybe<CrashGroup>;
  /** An edge for our `CrashGroup`. May be used by Relay 1. */
  crashGroupEdge?: Maybe<CrashGroupsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `CrashGroup` mutation. */
export type UpdateCrashGroupPayloadCrashGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<CrashGroupsOrderBy>>;
};

/** All input for the `updateCrash` mutation. */
export type UpdateCrashInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  /** An object where the defined keys will be set on the `Crash` being updated. */
  patch: CrashPatch;
};

/** The output of our update `Crash` mutation. */
export type UpdateCrashPayload = {
  __typename?: 'UpdateCrashPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The `Crash` that was updated by this mutation. */
  crash?: Maybe<Crash>;
  /** An edge for our `Crash`. May be used by Relay 1. */
  crashEdge?: Maybe<CrashesEdge>;
  /** Reads a single `CrashGroup` that is related to this `Crash`. */
  crashGroup?: Maybe<CrashGroup>;
  /** Reads a single `OutbankUser` that is related to this `Crash`. */
  outbankUser?: Maybe<OutbankUser>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `Crash` mutation. */
export type UpdateCrashPayloadCrashEdgeArgs = {
  orderBy?: InputMaybe<Array<CrashesOrderBy>>;
};

/** All input for the `updateCrashState` mutation. */
export type UpdateCrashStateInput = {
  _state: Scalars['String']['input'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  groupid: Scalars['UUID']['input'];
};

/** The output of our `updateCrashState` mutation. */
export type UpdateCrashStatePayload = {
  __typename?: 'UpdateCrashStatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  crash?: Maybe<Crash>;
  /** An edge for our `Crash`. May be used by Relay 1. */
  crashEdge?: Maybe<CrashesEdge>;
  /** Reads a single `CrashGroup` that is related to this `Crash`. */
  crashGroup?: Maybe<CrashGroup>;
  /** Reads a single `OutbankUser` that is related to this `Crash`. */
  outbankUser?: Maybe<OutbankUser>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `updateCrashState` mutation. */
export type UpdateCrashStatePayloadCrashEdgeArgs = {
  orderBy?: InputMaybe<Array<CrashesOrderBy>>;
};

/** All input for the `updateFileByNodeId` mutation. */
export type UpdateFileByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `File` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `File` being updated. */
  patch: FilePatch;
};

/** All input for the `updateFile` mutation. */
export type UpdateFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  /** An object where the defined keys will be set on the `File` being updated. */
  patch: FilePatch;
};

/** The output of our update `File` mutation. */
export type UpdateFilePayload = {
  __typename?: 'UpdateFilePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Crash` that is related to this `File`. */
  crash?: Maybe<Crash>;
  /** The `File` that was updated by this mutation. */
  file?: Maybe<File>;
  /** An edge for our `File`. May be used by Relay 1. */
  fileEdge?: Maybe<FilesEdge>;
  /** Reads a single `Har` that is related to this `File`. */
  har?: Maybe<Har>;
  /** Reads a single `Protocol` that is related to this `File`. */
  protocol?: Maybe<Protocol>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `File` mutation. */
export type UpdateFilePayloadFileEdgeArgs = {
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** All input for the `updateHarByNodeId` mutation. */
export type UpdateHarByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `Har` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `Har` being updated. */
  patch: HarPatch;
};

/** All input for the `updateHar` mutation. */
export type UpdateHarInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  /** An object where the defined keys will be set on the `Har` being updated. */
  patch: HarPatch;
};

/** The output of our update `Har` mutation. */
export type UpdateHarPayload = {
  __typename?: 'UpdateHarPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The `Har` that was updated by this mutation. */
  har?: Maybe<Har>;
  /** An edge for our `Har`. May be used by Relay 1. */
  harEdge?: Maybe<HarsEdge>;
  /** Reads a single `OutbankUser` that is related to this `Har`. */
  outbankUser?: Maybe<OutbankUser>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `Har` mutation. */
export type UpdateHarPayloadHarEdgeArgs = {
  orderBy?: InputMaybe<Array<HarsOrderBy>>;
};

/** All input for the `updateLocalizationLanguageByNodeId` mutation. */
export type UpdateLocalizationLanguageByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `LocalizationLanguage` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `LocalizationLanguage` being updated. */
  patch: LocalizationLanguagePatch;
};

/** All input for the `updateLocalizationLanguage` mutation. */
export type UpdateLocalizationLanguageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  /** An object where the defined keys will be set on the `LocalizationLanguage` being updated. */
  patch: LocalizationLanguagePatch;
};

/** The output of our update `LocalizationLanguage` mutation. */
export type UpdateLocalizationLanguagePayload = {
  __typename?: 'UpdateLocalizationLanguagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The `LocalizationLanguage` that was updated by this mutation. */
  localizationLanguage?: Maybe<LocalizationLanguage>;
  /** An edge for our `LocalizationLanguage`. May be used by Relay 1. */
  localizationLanguageEdge?: Maybe<LocalizationLanguagesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `LocalizationLanguage` mutation. */
export type UpdateLocalizationLanguagePayloadLocalizationLanguageEdgeArgs = {
  orderBy?: InputMaybe<Array<LocalizationLanguagesOrderBy>>;
};

/** All input for the `updateLocalizationProjectByNodeId` mutation. */
export type UpdateLocalizationProjectByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `LocalizationProject` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `LocalizationProject` being updated. */
  patch: LocalizationProjectPatch;
};

/** All input for the `updateLocalizationProject` mutation. */
export type UpdateLocalizationProjectInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  /** An object where the defined keys will be set on the `LocalizationProject` being updated. */
  patch: LocalizationProjectPatch;
};

/** The output of our update `LocalizationProject` mutation. */
export type UpdateLocalizationProjectPayload = {
  __typename?: 'UpdateLocalizationProjectPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The `LocalizationProject` that was updated by this mutation. */
  localizationProject?: Maybe<LocalizationProject>;
  /** An edge for our `LocalizationProject`. May be used by Relay 1. */
  localizationProjectEdge?: Maybe<LocalizationProjectsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `LocalizationProject` mutation. */
export type UpdateLocalizationProjectPayloadLocalizationProjectEdgeArgs = {
  orderBy?: InputMaybe<Array<LocalizationProjectsOrderBy>>;
};

/** All input for the `updateLocalizationProjectXLanguageByNodeId` mutation. */
export type UpdateLocalizationProjectXLanguageByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `LocalizationProjectXLanguage` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `LocalizationProjectXLanguage` being updated. */
  patch: LocalizationProjectXLanguagePatch;
};

/** All input for the `updateLocalizationProjectXLanguage` mutation. */
export type UpdateLocalizationProjectXLanguageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  /** An object where the defined keys will be set on the `LocalizationProjectXLanguage` being updated. */
  patch: LocalizationProjectXLanguagePatch;
};

/** The output of our update `LocalizationProjectXLanguage` mutation. */
export type UpdateLocalizationProjectXLanguagePayload = {
  __typename?: 'UpdateLocalizationProjectXLanguagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `LocalizationLanguage` that is related to this `LocalizationProjectXLanguage`. */
  localizationLanguage?: Maybe<LocalizationLanguage>;
  /** Reads a single `LocalizationProject` that is related to this `LocalizationProjectXLanguage`. */
  localizationProject?: Maybe<LocalizationProject>;
  /** The `LocalizationProjectXLanguage` that was updated by this mutation. */
  localizationProjectXLanguage?: Maybe<LocalizationProjectXLanguage>;
  /** An edge for our `LocalizationProjectXLanguage`. May be used by Relay 1. */
  localizationProjectXLanguageEdge?: Maybe<LocalizationProjectXLanguagesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `LocalizationProjectXLanguage` mutation. */
export type UpdateLocalizationProjectXLanguagePayloadLocalizationProjectXLanguageEdgeArgs = {
  orderBy?: InputMaybe<Array<LocalizationProjectXLanguagesOrderBy>>;
};

/** All input for the `updateLocalizationTranslationKeyByNodeId` mutation. */
export type UpdateLocalizationTranslationKeyByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `LocalizationTranslationKey` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `LocalizationTranslationKey` being updated. */
  patch: LocalizationTranslationKeyPatch;
};

/** All input for the `updateLocalizationTranslationKey` mutation. */
export type UpdateLocalizationTranslationKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  /** An object where the defined keys will be set on the `LocalizationTranslationKey` being updated. */
  patch: LocalizationTranslationKeyPatch;
};

/** The output of our update `LocalizationTranslationKey` mutation. */
export type UpdateLocalizationTranslationKeyPayload = {
  __typename?: 'UpdateLocalizationTranslationKeyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `LocalizationProject` that is related to this `LocalizationTranslationKey`. */
  localizationProject?: Maybe<LocalizationProject>;
  /** The `LocalizationTranslationKey` that was updated by this mutation. */
  localizationTranslationKey?: Maybe<LocalizationTranslationKey>;
  /** An edge for our `LocalizationTranslationKey`. May be used by Relay 1. */
  localizationTranslationKeyEdge?: Maybe<LocalizationTranslationKeysEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `LocalizationTranslationKey` mutation. */
export type UpdateLocalizationTranslationKeyPayloadLocalizationTranslationKeyEdgeArgs = {
  orderBy?: InputMaybe<Array<LocalizationTranslationKeysOrderBy>>;
};

/** All input for the `updateLocalizationTranslationValueByNodeId` mutation. */
export type UpdateLocalizationTranslationValueByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `LocalizationTranslationValue` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `LocalizationTranslationValue` being updated. */
  patch: LocalizationTranslationValuePatch;
};

/** All input for the `updateLocalizationTranslationValue` mutation. */
export type UpdateLocalizationTranslationValueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  /** An object where the defined keys will be set on the `LocalizationTranslationValue` being updated. */
  patch: LocalizationTranslationValuePatch;
};

/** The output of our update `LocalizationTranslationValue` mutation. */
export type UpdateLocalizationTranslationValuePayload = {
  __typename?: 'UpdateLocalizationTranslationValuePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `LocalizationLanguage` that is related to this `LocalizationTranslationValue`. */
  localizationLanguage?: Maybe<LocalizationLanguage>;
  /** Reads a single `LocalizationTranslationKey` that is related to this `LocalizationTranslationValue`. */
  localizationTranslationKey?: Maybe<LocalizationTranslationKey>;
  /** The `LocalizationTranslationValue` that was updated by this mutation. */
  localizationTranslationValue?: Maybe<LocalizationTranslationValue>;
  /** An edge for our `LocalizationTranslationValue`. May be used by Relay 1. */
  localizationTranslationValueEdge?: Maybe<LocalizationTranslationValuesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `LocalizationTranslationValue` mutation. */
export type UpdateLocalizationTranslationValuePayloadLocalizationTranslationValueEdgeArgs = {
  orderBy?: InputMaybe<Array<LocalizationTranslationValuesOrderBy>>;
};

/** All input for the `updateOutbankUserByNodeId` mutation. */
export type UpdateOutbankUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `OutbankUser` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `OutbankUser` being updated. */
  patch: OutbankUserPatch;
};

/** All input for the `updateOutbankUser` mutation. */
export type UpdateOutbankUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  /** An object where the defined keys will be set on the `OutbankUser` being updated. */
  patch: OutbankUserPatch;
};

/** The output of our update `OutbankUser` mutation. */
export type UpdateOutbankUserPayload = {
  __typename?: 'UpdateOutbankUserPayload';
  /** Reads a single `AppSource` that is related to this `OutbankUser`. */
  appSource?: Maybe<AppSource>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The `OutbankUser` that was updated by this mutation. */
  outbankUser?: Maybe<OutbankUser>;
  /** An edge for our `OutbankUser`. May be used by Relay 1. */
  outbankUserEdge?: Maybe<OutbankUsersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `OutbankUser` mutation. */
export type UpdateOutbankUserPayloadOutbankUserEdgeArgs = {
  orderBy?: InputMaybe<Array<OutbankUsersOrderBy>>;
};

/** All input for the `updateProtocolByNodeId` mutation. */
export type UpdateProtocolByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `Protocol` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `Protocol` being updated. */
  patch: ProtocolPatch;
};

/** All input for the `updateProtocol` mutation. */
export type UpdateProtocolInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  /** An object where the defined keys will be set on the `Protocol` being updated. */
  patch: ProtocolPatch;
};

/** The output of our update `Protocol` mutation. */
export type UpdateProtocolPayload = {
  __typename?: 'UpdateProtocolPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `OutbankUser` that is related to this `Protocol`. */
  outbankUser?: Maybe<OutbankUser>;
  /** The `Protocol` that was updated by this mutation. */
  protocol?: Maybe<Protocol>;
  /** An edge for our `Protocol`. May be used by Relay 1. */
  protocolEdge?: Maybe<ProtocolsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `Protocol` mutation. */
export type UpdateProtocolPayloadProtocolEdgeArgs = {
  orderBy?: InputMaybe<Array<ProtocolsOrderBy>>;
};

/** All input for the `updateSequelizeMetaByNodeId` mutation. */
export type UpdateSequelizeMetaByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `SequelizeMeta` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `SequelizeMeta` being updated. */
  patch: SequelizeMetaPatch;
};

/** All input for the `updateSequelizeMeta` mutation. */
export type UpdateSequelizeMetaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  /** An object where the defined keys will be set on the `SequelizeMeta` being updated. */
  patch: SequelizeMetaPatch;
};

/** The output of our update `SequelizeMeta` mutation. */
export type UpdateSequelizeMetaPayload = {
  __typename?: 'UpdateSequelizeMetaPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SequelizeMeta` that was updated by this mutation. */
  sequelizeMeta?: Maybe<SequelizeMeta>;
  /** An edge for our `SequelizeMeta`. May be used by Relay 1. */
  sequelizeMetaEdge?: Maybe<SequelizeMetasEdge>;
};


/** The output of our update `SequelizeMeta` mutation. */
export type UpdateSequelizeMetaPayloadSequelizeMetaEdgeArgs = {
  orderBy?: InputMaybe<Array<SequelizeMetasOrderBy>>;
};

export type UpdateCrashStateMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  state: Scalars['String']['input'];
}>;


export type UpdateCrashStateMutation = { __typename?: 'Mutation', updateCrashState?: { __typename?: 'UpdateCrashStatePayload', crash?: { __typename?: 'Crash', state: StateType } | null } | null };

export type FilteredCrashesGroupedByVersionQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type FilteredCrashesGroupedByVersionQuery = { __typename?: 'Query', crashes?: { __typename?: 'CrashesConnection', groupedAggregates?: Array<{ __typename?: 'CrashAggregates', keys?: Array<string> | null, distinctCount?: { __typename?: 'CrashDistinctCountAggregates', id?: any | null, fileLineNumber?: any | null } | null, average?: { __typename?: 'CrashAverageAggregates', fileLineNumber?: any | null } | null, max?: { __typename?: 'CrashMaxAggregates', createdAtToInt?: number | null } | null, min?: { __typename?: 'CrashMinAggregates', createdAtToInt?: number | null } | null }> | null } | null };

export type CrashListElementFragment = { __typename?: 'Crash', state: StateType, file: string, fileLineNumber: number, fileName: string, fileVersion: string, id: any, message?: string | null, osVersion: string, appVersion: string, createdAt: any, crashGroupId?: any | null, outbankUser?: { __typename?: 'OutbankUser', id: any, email: string, appSource?: { __typename?: 'AppSource', name: string } | null } | null };

export type GetCrashesByCrashGroupQueryVariables = Exact<{
  crashGroupId: Scalars['UUID']['input'];
  fileVersion: Scalars['String']['input'];
}>;


export type GetCrashesByCrashGroupQuery = { __typename?: 'Query', crashes?: { __typename?: 'CrashesConnection', nodes: Array<{ __typename?: 'Crash', state: StateType, file: string, fileLineNumber: number, fileName: string, fileVersion: string, id: any, message?: string | null, osVersion: string, appVersion: string, createdAt: any, crashGroupId?: any | null, outbankUser?: { __typename?: 'OutbankUser', id: any, email: string, appSource?: { __typename?: 'AppSource', name: string } | null } | null }> } | null };

export type GetCrashesByUserQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type GetCrashesByUserQuery = { __typename?: 'Query', crashes?: { __typename?: 'CrashesConnection', nodes: Array<{ __typename?: 'Crash', state: StateType, file: string, fileLineNumber: number, fileName: string, fileVersion: string, id: any, message?: string | null, osVersion: string, appVersion: string, createdAt: any, crashGroupId?: any | null, outbankUser?: { __typename?: 'OutbankUser', id: any, email: string, appSource?: { __typename?: 'AppSource', name: string } | null } | null }> } | null };

export type GetCrashesByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type GetCrashesByIdQuery = { __typename?: 'Query', crashes?: { __typename?: 'CrashesConnection', nodes: Array<{ __typename?: 'Crash', state: StateType, file: string, fileLineNumber: number, fileName: string, fileVersion: string, id: any, message?: string | null, osVersion: string, appVersion: string, createdAt: any, crashGroupId?: any | null, outbankUser?: { __typename?: 'OutbankUser', id: any, email: string, appSource?: { __typename?: 'AppSource', name: string } | null } | null }> } | null };

export type GetLastCrashesQueryVariables = Exact<{
  count: Scalars['Int']['input'];
}>;


export type GetLastCrashesQuery = { __typename?: 'Query', crashes?: { __typename?: 'CrashesConnection', nodes: Array<{ __typename?: 'Crash', state: StateType, file: string, fileLineNumber: number, fileName: string, fileVersion: string, id: any, message?: string | null, osVersion: string, appVersion: string, createdAt: any, crashGroupId?: any | null, outbankUser?: { __typename?: 'OutbankUser', id: any, email: string, appSource?: { __typename?: 'AppSource', name: string } | null } | null }> } | null };

export type GetFeatureRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFeatureRequestsQuery = { __typename?: 'Query', crashes?: { __typename?: 'CrashesConnection', nodes: Array<{ __typename?: 'Crash', state: StateType, file: string, fileLineNumber: number, fileName: string, fileVersion: string, id: any, message?: string | null, osVersion: string, appVersion: string, createdAt: any, crashGroupId?: any | null, outbankUser?: { __typename?: 'OutbankUser', id: any, email: string, appSource?: { __typename?: 'AppSource', name: string } | null } | null }> } | null };

export type GetImporterErrorsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetImporterErrorsQuery = { __typename?: 'Query', crashes?: { __typename?: 'CrashesConnection', nodes: Array<{ __typename?: 'Crash', state: StateType, file: string, fileLineNumber: number, fileName: string, fileVersion: string, id: any, message?: string | null, osVersion: string, appVersion: string, createdAt: any, crashGroupId?: any | null, outbankUser?: { __typename?: 'OutbankUser', id: any, email: string, appSource?: { __typename?: 'AppSource', name: string } | null } | null }> } | null };

export type CrashGroupsCrashesFragment = { __typename?: 'Crash', file: string, fileLineNumber: number, fileName: string, fileVersion: string, id: any, message?: string | null, osVersion: string, callMethod: CrashCallMethod };

export type CrashGroupFragment = { __typename?: 'CrashGroup', codeLine: string, message?: string | null, file: string, createdAt: any, id: any, issue?: string | null, stateLog?: string | null, countOpen?: number | null, countClosed?: number | null, countInprogress?: number | null, crashes: { __typename?: 'CrashesConnection', nodes: Array<{ __typename?: 'Crash', file: string, fileLineNumber: number, fileName: string, fileVersion: string, id: any, message?: string | null, osVersion: string, callMethod: CrashCallMethod }> } };

export type GetAllCrashGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCrashGroupsQuery = { __typename?: 'Query', crashGroups?: { __typename?: 'CrashGroupsConnection', nodes: Array<{ __typename?: 'CrashGroup', codeLine: string, message?: string | null, file: string, createdAt: any, id: any, issue?: string | null, stateLog?: string | null, countOpen?: number | null, countClosed?: number | null, countInprogress?: number | null, crashes: { __typename?: 'CrashesConnection', nodes: Array<{ __typename?: 'Crash', file: string, fileLineNumber: number, fileName: string, fileVersion: string, id: any, message?: string | null, osVersion: string, callMethod: CrashCallMethod }> } }> } | null };

export type GetAllUnClosedCrashGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllUnClosedCrashGroupsQuery = { __typename?: 'Query', crashGroups?: { __typename?: 'CrashGroupsConnection', nodes: Array<{ __typename?: 'CrashGroup', codeLine: string, message?: string | null, file: string, createdAt: any, id: any, issue?: string | null, stateLog?: string | null, countOpen?: number | null, countClosed?: number | null, countInprogress?: number | null, crashes: { __typename?: 'CrashesConnection', nodes: Array<{ __typename?: 'Crash', file: string, fileLineNumber: number, fileName: string, fileVersion: string, id: any, message?: string | null, osVersion: string, callMethod: CrashCallMethod }> } }> } | null };

export type GetCrashGroupQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type GetCrashGroupQuery = { __typename?: 'Query', crashGroups?: { __typename?: 'CrashGroupsConnection', nodes: Array<{ __typename?: 'CrashGroup', codeLine: string, message?: string | null, file: string, createdAt: any, id: any, issue?: string | null, stateLog?: string | null, countOpen?: number | null, countClosed?: number | null, countInprogress?: number | null, crashes: { __typename?: 'CrashesConnection', nodes: Array<{ __typename?: 'Crash', file: string, fileLineNumber: number, fileName: string, fileVersion: string, id: any, message?: string | null, osVersion: string, callMethod: CrashCallMethod }> } }> } | null };

export type UpdateCrashGroupMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  patch: CrashGroupPatch;
}>;


export type UpdateCrashGroupMutation = { __typename?: 'Mutation', updateCrashGroup?: { __typename?: 'UpdateCrashGroupPayload', crashGroup?: { __typename?: 'CrashGroup', id: any } | null } | null };

export type GetDashboardCountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDashboardCountsQuery = { __typename?: 'Query', crashes?: { __typename?: 'CrashesConnection', totalCount: number } | null, hars?: { __typename?: 'HarsConnection', totalCount: number } | null, protocols?: { __typename?: 'ProtocolsConnection', totalCount: number } | null, fr?: { __typename?: 'CrashesConnection', totalCount: number } | null, importer?: { __typename?: 'CrashesConnection', totalCount: number } | null };

export type HarFilesFragment = { __typename?: 'File', name: string, size: number, suffix: string, id: any };

export type HarFragment = { __typename?: 'Har', authorizetesting: boolean, bank: string, createdAt: any, email: string, issue?: string | null, sessionwarning: boolean, since: any, state: StateType, id: any, updatedAt: any, authorizecredentials: boolean, note?: string | null, files: { __typename?: 'FilesConnection', nodes: Array<{ __typename?: 'File', name: string, size: number, suffix: string, id: any }> }, outbankUser?: { __typename?: 'OutbankUser', email: string, id: any } | null };

export type GetAllHarsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllHarsQuery = { __typename?: 'Query', hars?: { __typename?: 'HarsConnection', nodes: Array<{ __typename?: 'Har', authorizetesting: boolean, bank: string, createdAt: any, email: string, issue?: string | null, sessionwarning: boolean, since: any, state: StateType, id: any, updatedAt: any, authorizecredentials: boolean, note?: string | null, files: { __typename?: 'FilesConnection', nodes: Array<{ __typename?: 'File', name: string, size: number, suffix: string, id: any }> }, outbankUser?: { __typename?: 'OutbankUser', email: string, id: any } | null }> } | null };

export type UpdateHarMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  patch: HarPatch;
}>;


export type UpdateHarMutation = { __typename?: 'Mutation', updateHar?: { __typename?: 'UpdateHarPayload', har?: { __typename?: 'Har', id: any } | null } | null };

export type ProtocolElementFragment = { __typename?: 'Protocol', id: any, model?: string | null, osVersion: string, appVersion: string, state: StateType, createdAt: any, outbankUser?: { __typename?: 'OutbankUser', email: string, id: any, appSource?: { __typename?: 'AppSource', name: string } | null } | null };

export type GetAllProtocolsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllProtocolsQuery = { __typename?: 'Query', protocols?: { __typename?: 'ProtocolsConnection', nodes: Array<{ __typename?: 'Protocol', id: any, model?: string | null, osVersion: string, appVersion: string, state: StateType, createdAt: any, outbankUser?: { __typename?: 'OutbankUser', email: string, id: any, appSource?: { __typename?: 'AppSource', name: string } | null } | null }> } | null };

export type UpdateProtocolMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  patch: ProtocolPatch;
}>;


export type UpdateProtocolMutation = { __typename?: 'Mutation', updateProtocol?: { __typename?: 'UpdateProtocolPayload', protocol?: { __typename?: 'Protocol', id: any } | null } | null };

export type UserFragment = { __typename?: 'OutbankUser', id: any, email: string, crashes: { __typename?: 'CrashesConnection', totalCount: number }, hars: { __typename?: 'HarsConnection', totalCount: number }, protocols: { __typename?: 'ProtocolsConnection', totalCount: number }, appSource?: { __typename?: 'AppSource', name: string } | null };

export type GetAllUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllUsersQuery = { __typename?: 'Query', outbankUsers?: { __typename?: 'OutbankUsersConnection', nodes: Array<{ __typename?: 'OutbankUser', id: any, email: string, crashes: { __typename?: 'CrashesConnection', totalCount: number }, hars: { __typename?: 'HarsConnection', totalCount: number }, protocols: { __typename?: 'ProtocolsConnection', totalCount: number }, appSource?: { __typename?: 'AppSource', name: string } | null }> } | null };

export const CrashListElementFragmentDoc = gql`
    fragment CrashListElement on Crash {
  state
  file
  fileLineNumber
  fileName
  fileVersion
  id
  message
  osVersion
  appVersion
  createdAt
  crashGroupId
  outbankUser {
    id
    email
    appSource {
      name
    }
  }
}
    `;
export const CrashGroupsCrashesFragmentDoc = gql`
    fragment CrashGroupsCrashes on Crash {
  file
  fileLineNumber
  fileName
  fileVersion
  id
  message
  osVersion
  callMethod
}
    `;
export const CrashGroupFragmentDoc = gql`
    fragment CrashGroup on CrashGroup {
  codeLine
  message
  file
  createdAt
  id
  issue
  stateLog
  countOpen
  countClosed
  countInprogress
  crashes {
    nodes {
      ...CrashGroupsCrashes
    }
  }
}
    ${CrashGroupsCrashesFragmentDoc}`;
export const HarFilesFragmentDoc = gql`
    fragment HarFiles on File {
  name
  size
  suffix
  id
}
    `;
export const HarFragmentDoc = gql`
    fragment Har on Har {
  authorizetesting
  bank
  createdAt
  email
  issue
  sessionwarning
  since
  state
  id
  updatedAt
  authorizecredentials
  files {
    nodes {
      ...HarFiles
    }
  }
  outbankUser {
    email
    id
  }
  note
}
    ${HarFilesFragmentDoc}`;
export const ProtocolElementFragmentDoc = gql`
    fragment ProtocolElement on Protocol {
  id
  model
  osVersion
  appVersion
  state
  createdAt
  outbankUser {
    email
    id
    appSource {
      name
    }
  }
}
    `;
export const UserFragmentDoc = gql`
    fragment User on OutbankUser {
  id
  email
  crashes {
    totalCount
  }
  hars {
    totalCount
  }
  protocols {
    totalCount
  }
  appSource {
    name
  }
}
    `;
export const UpdateCrashStateDocument = gql`
    mutation UpdateCrashState($id: UUID!, $state: String!) {
  updateCrashState(input: {groupid: $id, _state: $state}) {
    crash {
      state
    }
  }
}
    `;

export function useUpdateCrashStateMutation() {
  return Urql.useMutation<UpdateCrashStateMutation, UpdateCrashStateMutationVariables>(UpdateCrashStateDocument);
};
export const FilteredCrashesGroupedByVersionDocument = gql`
    query FilteredCrashesGroupedByVersion($id: UUID!) {
  crashes(filter: {crashGroupId: {equalTo: $id}}) {
    groupedAggregates(groupBy: FILE_VERSION) {
      keys
      distinctCount {
        id
        fileLineNumber
      }
      average {
        fileLineNumber
      }
      max {
        createdAtToInt
      }
      min {
        createdAtToInt
      }
    }
  }
}
    `;

export function useFilteredCrashesGroupedByVersionQuery(options: Omit<Urql.UseQueryArgs<FilteredCrashesGroupedByVersionQueryVariables>, 'query'>) {
  return Urql.useQuery<FilteredCrashesGroupedByVersionQuery, FilteredCrashesGroupedByVersionQueryVariables>({ query: FilteredCrashesGroupedByVersionDocument, ...options });
};
export const GetCrashesByCrashGroupDocument = gql`
    query GetCrashesByCrashGroup($crashGroupId: UUID!, $fileVersion: String!) {
  crashes(
    filter: {crashGroupId: {equalTo: $crashGroupId}, fileVersion: {equalTo: $fileVersion}}
  ) {
    nodes {
      ...CrashListElement
    }
  }
}
    ${CrashListElementFragmentDoc}`;

export function useGetCrashesByCrashGroupQuery(options: Omit<Urql.UseQueryArgs<GetCrashesByCrashGroupQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCrashesByCrashGroupQuery, GetCrashesByCrashGroupQueryVariables>({ query: GetCrashesByCrashGroupDocument, ...options });
};
export const GetCrashesByUserDocument = gql`
    query GetCrashesByUser($userId: UUID) {
  crashes(filter: {outbankUserId: {equalTo: $userId}}) {
    nodes {
      ...CrashListElement
    }
  }
}
    ${CrashListElementFragmentDoc}`;

export function useGetCrashesByUserQuery(options?: Omit<Urql.UseQueryArgs<GetCrashesByUserQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCrashesByUserQuery, GetCrashesByUserQueryVariables>({ query: GetCrashesByUserDocument, ...options });
};
export const GetCrashesByIdDocument = gql`
    query GetCrashesById($id: UUID) {
  crashes(filter: {id: {equalTo: $id}}) {
    nodes {
      ...CrashListElement
    }
  }
}
    ${CrashListElementFragmentDoc}`;

export function useGetCrashesByIdQuery(options?: Omit<Urql.UseQueryArgs<GetCrashesByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCrashesByIdQuery, GetCrashesByIdQueryVariables>({ query: GetCrashesByIdDocument, ...options });
};
export const GetLastCrashesDocument = gql`
    query GetLastCrashes($count: Int!) {
  crashes(
    first: $count
    orderBy: CREATED_AT_DESC
    filter: {state: {notEqualTo: CLOSED}}
  ) {
    nodes {
      ...CrashListElement
    }
  }
}
    ${CrashListElementFragmentDoc}`;

export function useGetLastCrashesQuery(options: Omit<Urql.UseQueryArgs<GetLastCrashesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetLastCrashesQuery, GetLastCrashesQueryVariables>({ query: GetLastCrashesDocument, ...options });
};
export const GetFeatureRequestsDocument = gql`
    query GetFeatureRequests {
  crashes(
    orderBy: CREATED_AT_DESC
    filter: {message: {includes: "NotImplementedError: Feature Request for unsupported Product"}, state: {notEqualTo: CLOSED}}
  ) {
    nodes {
      ...CrashListElement
    }
  }
}
    ${CrashListElementFragmentDoc}`;

export function useGetFeatureRequestsQuery(options?: Omit<Urql.UseQueryArgs<GetFeatureRequestsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetFeatureRequestsQuery, GetFeatureRequestsQueryVariables>({ query: GetFeatureRequestsDocument, ...options });
};
export const GetImporterErrorsDocument = gql`
    query GetImporterErrors {
  crashes(
    orderBy: CREATED_AT_DESC
    filter: {state: {notEqualTo: CLOSED}, file: {startsWith: "YY+"}}
  ) {
    nodes {
      ...CrashListElement
    }
  }
}
    ${CrashListElementFragmentDoc}`;

export function useGetImporterErrorsQuery(options?: Omit<Urql.UseQueryArgs<GetImporterErrorsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetImporterErrorsQuery, GetImporterErrorsQueryVariables>({ query: GetImporterErrorsDocument, ...options });
};
export const GetAllCrashGroupsDocument = gql`
    query GetAllCrashGroups {
  crashGroups {
    nodes {
      ...CrashGroup
    }
  }
}
    ${CrashGroupFragmentDoc}`;

export function useGetAllCrashGroupsQuery(options?: Omit<Urql.UseQueryArgs<GetAllCrashGroupsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllCrashGroupsQuery, GetAllCrashGroupsQueryVariables>({ query: GetAllCrashGroupsDocument, ...options });
};
export const GetAllUnClosedCrashGroupsDocument = gql`
    query GetAllUnClosedCrashGroups {
  crashGroups(
    filter: {or: [{countInprogress: {greaterThan: 0}}, {countOpen: {greaterThan: 0}}]}
  ) {
    nodes {
      ...CrashGroup
    }
  }
}
    ${CrashGroupFragmentDoc}`;

export function useGetAllUnClosedCrashGroupsQuery(options?: Omit<Urql.UseQueryArgs<GetAllUnClosedCrashGroupsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllUnClosedCrashGroupsQuery, GetAllUnClosedCrashGroupsQueryVariables>({ query: GetAllUnClosedCrashGroupsDocument, ...options });
};
export const GetCrashGroupDocument = gql`
    query GetCrashGroup($id: UUID!) {
  crashGroups(filter: {id: {equalTo: $id}}) {
    nodes {
      ...CrashGroup
    }
  }
}
    ${CrashGroupFragmentDoc}`;

export function useGetCrashGroupQuery(options: Omit<Urql.UseQueryArgs<GetCrashGroupQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCrashGroupQuery, GetCrashGroupQueryVariables>({ query: GetCrashGroupDocument, ...options });
};
export const UpdateCrashGroupDocument = gql`
    mutation UpdateCrashGroup($id: UUID!, $patch: CrashGroupPatch!) {
  updateCrashGroup(input: {id: $id, patch: $patch}) {
    crashGroup {
      id
    }
  }
}
    `;

export function useUpdateCrashGroupMutation() {
  return Urql.useMutation<UpdateCrashGroupMutation, UpdateCrashGroupMutationVariables>(UpdateCrashGroupDocument);
};
export const GetDashboardCountsDocument = gql`
    query GetDashboardCounts {
  crashes(filter: {state: {notEqualTo: CLOSED}}) {
    totalCount
  }
  hars(filter: {state: {notEqualTo: CLOSED}}) {
    totalCount
  }
  protocols(filter: {state: {notEqualTo: CLOSED}}) {
    totalCount
  }
  fr: crashes(
    filter: {message: {includes: "NotImplementedError: Feature Request for unsupported Product"}, state: {notEqualTo: CLOSED}}
  ) {
    totalCount
  }
  importer: crashes(
    filter: {state: {notEqualTo: CLOSED}, file: {startsWith: "YY+"}}
  ) {
    totalCount
  }
}
    `;

export function useGetDashboardCountsQuery(options?: Omit<Urql.UseQueryArgs<GetDashboardCountsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetDashboardCountsQuery, GetDashboardCountsQueryVariables>({ query: GetDashboardCountsDocument, ...options });
};
export const GetAllHarsDocument = gql`
    query GetAllHars {
  hars {
    nodes {
      ...Har
    }
  }
}
    ${HarFragmentDoc}`;

export function useGetAllHarsQuery(options?: Omit<Urql.UseQueryArgs<GetAllHarsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllHarsQuery, GetAllHarsQueryVariables>({ query: GetAllHarsDocument, ...options });
};
export const UpdateHarDocument = gql`
    mutation UpdateHar($id: UUID!, $patch: HarPatch!) {
  updateHar(input: {id: $id, patch: $patch}) {
    har {
      id
    }
  }
}
    `;

export function useUpdateHarMutation() {
  return Urql.useMutation<UpdateHarMutation, UpdateHarMutationVariables>(UpdateHarDocument);
};
export const GetAllProtocolsDocument = gql`
    query GetAllProtocols {
  protocols {
    nodes {
      ...ProtocolElement
    }
  }
}
    ${ProtocolElementFragmentDoc}`;

export function useGetAllProtocolsQuery(options?: Omit<Urql.UseQueryArgs<GetAllProtocolsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllProtocolsQuery, GetAllProtocolsQueryVariables>({ query: GetAllProtocolsDocument, ...options });
};
export const UpdateProtocolDocument = gql`
    mutation UpdateProtocol($id: UUID!, $patch: ProtocolPatch!) {
  updateProtocol(input: {id: $id, patch: $patch}) {
    protocol {
      id
    }
  }
}
    `;

export function useUpdateProtocolMutation() {
  return Urql.useMutation<UpdateProtocolMutation, UpdateProtocolMutationVariables>(UpdateProtocolDocument);
};
export const GetAllUsersDocument = gql`
    query GetAllUsers {
  outbankUsers {
    nodes {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;

export function useGetAllUsersQuery(options?: Omit<Urql.UseQueryArgs<GetAllUsersQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>({ query: GetAllUsersDocument, ...options });
};

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "Node": [
      "AppSource",
      "Crash",
      "CrashGroup",
      "File",
      "Har",
      "LocalizationLanguage",
      "LocalizationProject",
      "LocalizationProjectXLanguage",
      "LocalizationTranslationKey",
      "LocalizationTranslationValue",
      "OutbankUser",
      "Protocol",
      "Query",
      "SequelizeMeta"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Node": [
      "AppSource",
      "Crash",
      "CrashGroup",
      "File",
      "Har",
      "LocalizationLanguage",
      "LocalizationProject",
      "LocalizationProjectXLanguage",
      "LocalizationTranslationKey",
      "LocalizationTranslationValue",
      "OutbankUser",
      "Protocol",
      "Query",
      "SequelizeMeta"
    ]
  }
};
      export default result;
    