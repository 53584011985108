import React, { type FunctionComponent } from 'react';
import { CircleIcon } from '../components/CircleIcon';
import { type StateType } from '../generated/types';
import { colorForState } from '../helper';

interface StateIconProps {
    state: StateType;
    size?: number;
}

export const StateIcon: FunctionComponent<StateIconProps> = (props: StateIconProps) => {
    const { state, size = 3 } = props;
    return <CircleIcon color={colorForState(state)} boxSize={size} />;
};
