import React, { type FunctionComponent, type ReactNode } from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useBoolean,
} from '@chakra-ui/react';

interface Props {
    title: string;
    subtitle?: string;
    isOpen: boolean;
    onClose: () => void;
    onReload?: () => Promise<void>;
    saveTitle?: string;
    isSaveDisabled: boolean | undefined;
    onSave: () => Promise<void>;
    children: ReactNode;
}

export const InputForm: FunctionComponent<Props> = (props) => {
    const { title, subtitle, isOpen, onClose, onReload, saveTitle, isSaveDisabled, onSave } = props;

    const [isSaving, setSaving] = useBoolean();
    const [isReloading, setReloading] = useBoolean();

    const onClickReload = async (): Promise<void> => {
        setReloading.on();
        onReload && (await onReload());
        setReloading.off();
    };

    const onClickSave = async (): Promise<void> => {
        setSaving.on();
        await onSave();
        setSaving.off();
    };

    return (
        <Modal size="4xl" isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Text>{title}</Text>
                    {subtitle && <Text color="gray.400">{subtitle}</Text>}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>{props.children}</ModalBody>
                <ModalFooter>
                    {onReload && (
                        <Button mr={3} isLoading={isReloading} onClick={onClickReload} isDisabled={isSaving}>
                            Reload
                        </Button>
                    )}
                    <Button
                        isLoading={isSaving}
                        colorScheme="blue"
                        onClick={onClickSave}
                        isDisabled={isReloading || isSaveDisabled}
                    >
                        {saveTitle ?? 'Save'}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
