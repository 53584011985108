import { StateType } from '../generated/types';

export const allStates = [StateType.Unread, StateType.Open, StateType.Inprogress, StateType.Closed];

export const colorForState = (state: StateType): string => {
    switch (state) {
        case StateType.Unread:
            return 'blue.500';
        case StateType.Open:
            return 'green.500';
        case StateType.Inprogress:
            return 'yellow.500';
        case StateType.Closed:
            return 'gray.300';
    }
};

export const labelForState = (state: StateType): string => {
    switch (state) {
        case StateType.Unread:
            return 'Unread';
        case StateType.Open:
            return 'Open';
        case StateType.Inprogress:
            return 'In Progress';
        case StateType.Closed:
            return 'Closed';
    }
};
