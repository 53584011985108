/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { type FunctionComponent } from 'react';
import { type Request } from '../../interface/Crash';
import { RequestTableView } from './requestTableView';

interface Props {
    request: Request;
}

export const ResponseHeaderView: FunctionComponent<Props> = (props: Props) => {
    const { request } = props;

    if (!request.response?.headers) return null;

    const _responseHeaders = Object.keys(request.response.headers).map((key) => [key, request.response?.headers[key]]);

    return <RequestTableView data={_responseHeaders as string[][]} headers={['name', 'value']} />;
};
