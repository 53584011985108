import React, { useEffect, useState } from 'react';
import type { FunctionComponent } from 'react';
import type { IProject, Translation } from '../../interface/Localization';
import { InputGroup, InputLeftAddon, Input, InputRightAddon, Box, useToast, Button, Flex } from '@chakra-ui/react';
import { Request as apiRequest } from '../../helper';

interface Props {
    translationData: Translation[];
    projectId: string;
}

export const FormComponent: FunctionComponent<Props> = ({ translationData, projectId }) => {
    const [translationValue, setTranslationValue] = useState<Translation[]>(translationData);
    const toast = useToast();

    useEffect(() => {}, [translationValue]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { id, value } = event.target;
        setTranslationValue((prevData) =>
            prevData.map((trans) => (trans.id + '/' + trans.language === id ? { ...trans, value } : trans)),
        );
    };

    const saveInput = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        const saveNewValue = async (translation: Translation): Promise<void> => {
            await apiRequest.post<IProject>({
                path: '/localization/project/' + (projectId ?? translation.project_id),
                data: translation,
            });
        };
        for (const translation of translationValue) {
            if (translation.value !== translation.placeholder) {
                await saveNewValue(translation);
                setTranslationValue((prevData) =>
                    prevData.map((trans) =>
                        trans.id === translation.id && trans.language === translation.language
                            ? { ...trans, placeholder: translation.value }
                            : trans,
                    ),
                );
            }
        }
    };

    const saveToClipboard = (key: string): void => {
        navigator.clipboard
            .writeText(key)
            .then(() => {
                toast({
                    title: 'Copied to clipboard.',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
            })
            .catch(() => {
                toast({
                    title: 'Error',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            });
    };

    return (
        <form
            style={{ marginBottom: '2rem' }}
            onSubmit={async (event) => {
                await saveInput(event);
            }}
            id={translationValue[0].id}
        >
            <Flex direction="column" id="form-input">
                {translationValue[0]?.project && (
                    <Box marginBottom={5} fontWeight={500}>
                        {translationValue[0].project}
                    </Box>
                )}
                <InputGroup marginBottom={8}>
                    <InputLeftAddon width={55}>Key</InputLeftAddon>
                    <Input
                        value={translationValue[0]?.key}
                        isReadOnly
                        pr="4.5rem"
                        isDisabled
                        id={translationValue[0].id}
                    />
                    <InputRightAddon
                        width="4.5rem"
                        onClick={() => {
                            saveToClipboard(translationValue[0]?.key);
                        }}
                    >
                        <Box style={{ cursor: 'pointer' }}>Copy</Box>
                    </InputRightAddon>
                </InputGroup>
                {translationValue.map((trans: Translation) => (
                    <InputGroup key={trans.value_id || `${trans.id}/${trans.language}`} marginBottom={2} height={10}>
                        <InputLeftAddon>{trans.language_code.toUpperCase()}</InputLeftAddon>
                        <Input
                            id={`${trans.id}/${trans.language}`}
                            value={trans.value ?? ''}
                            placeholder={trans.placeholder}
                            onChange={handleChange}
                        />
                    </InputGroup>
                ))}
                <Flex justify="flex-end" mt={4} id="save-button">
                    <Button
                        colorScheme="blue"
                        type="submit"
                        isDisabled={translationValue.every(
                            (translation: Translation) => translation.value === translation.placeholder,
                        )}
                    >
                        Save
                    </Button>
                </Flex>
            </Flex>
        </form>
    );
};
