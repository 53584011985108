import React, { type FunctionComponent, useState, useMemo, useEffect } from 'react';
import { MenuItem, SimpleGrid, useToast } from '@chakra-ui/react';
import { type Column } from 'react-table';
import { IoClipboardOutline, IoDownloadOutline } from 'react-icons/io5';
import { ContentContainer } from '../../components/ContentContainer';
import { ActionMenu } from '../../components/ActionMenu';
import { TableContainer } from '../../components/table/TableContainer';
import { useLocation, useParams } from 'react-router-dom';
import { Request as apiRequest } from '../../helper';

interface Nightly {
    name: string;
    path?: string;
}

export const List: FunctionComponent = () => {
    const { id, version, build, file } = useParams<Record<string, string | undefined>>();
    const location = useLocation();

    const toast = useToast({ position: 'top' });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [versionBuildData, setVersionBuildData] = useState<any | undefined>();
    const [versionData, setVersionData] = useState<Nightly[] | undefined>();
    const [selectedVersion, setSelectedVersion] = useState<string | undefined>();
    const [buildData, setBuildData] = useState<Nightly[] | undefined>();
    const [selectedBuild, setSelectedBuild] = useState<string | undefined>();
    const [fileData, setFileData] = useState<Nightly[] | undefined>();
    const [selectedFile, setSelectedFile] = useState<string | undefined>(file);

    useEffect(() => {
        setBuildData(undefined);
        setFileData(undefined);
        setSelectedVersion(version ?? undefined);
        setSelectedBuild(build ?? undefined);
        setSelectedFile(file ?? undefined);
        async function getBuildData(): Promise<void> {
            const response = await apiRequest.get<any>({ path: `/apps/outbank-nightly/andoid/${id}` });
            setVersionBuildData(response.data.result);
        }

        async function automaticDownload(): Promise<void> {
            if (version && build && file) {
                await download(`${version}/${build}/${file}`, file);
            }
        }
        automaticDownload();
        getBuildData();
    }, [id]);

    const copyLinkClipboard = async (path: string): Promise<void> => {
        const _location = location.pathname;
        const url = window.location.protocol + '//' + window.location.host;
        navigator.clipboard.writeText(`${url}/${_location}/${path}`);
    };

    useEffect(() => {
        if (versionBuildData) {
            const _data: any[] = Object.keys(versionBuildData).map((item) => {
                return { name: item };
            });
            setVersionData(_data);
        }
    }, [versionBuildData]);

    useEffect(() => {
        if (versionBuildData && selectedVersion) {
            const _data = Object.keys(versionBuildData[`${selectedVersion}`]).map((item) => {
                return { name: item };
            });
            setBuildData(_data);
        }
    }, [selectedVersion, versionBuildData]);

    useEffect(() => {
        if (selectedVersion && selectedBuild && versionBuildData) {
            const _data = versionBuildData[`${selectedVersion}`][`${selectedBuild}`]
                .map((item: string): any => {
                    if (item.endsWith('.apk') ?? item.endsWith('.aab'))
                        return { name: item, path: `${selectedVersion}/${selectedBuild}/${item}` };
                    return undefined;
                })
                .filter((item: any) => item !== undefined);
            setFileData(_data);
        }
    }, [selectedBuild, versionBuildData]);

    const tableData1 = useMemo(() => versionData, [versionData]);
    const tableData2 = useMemo(() => buildData, [buildData]);
    const tableData3 = useMemo(() => fileData, [fileData]);

    const download = async (path: string, name: string): Promise<void> => {
        await apiRequest.download(
            { path: `/apps/outbank-nightly/download?path=${encodeURIComponent(`${id}/${path}`)}` },
            selectedFile ?? name,
            toast,
        );
    };

    const openVersionDetails = (_selectedItem: Nightly): void => {
        setSelectedVersion(_selectedItem.name);
    };

    const openBuildDetails = (_selectedItem: Nightly): void => {
        setSelectedBuild(_selectedItem.name);
    };

    const openFileDetails = (_selectedItem: Nightly): void => {
        setSelectedFile(_selectedItem.path);
    };

    const actionMenu = (_selectedItem: Nightly): JSX.Element => (
        <ActionMenu>
            <MenuItem
                icon={<IoClipboardOutline />}
                onClick={async () => {
                    await copyLinkClipboard(_selectedItem.path ?? '');
                }}
            >
                Copy link to clipboard
            </MenuItem>
            <MenuItem
                icon={<IoDownloadOutline />}
                onClick={async () => {
                    await download(_selectedItem.path ?? '', _selectedItem.name);
                }}
            >
                Download
            </MenuItem>
        </ActionMenu>
    );

    const columns1: Array<Column<Nightly>> = useMemo(
        () => [
            {
                Header: 'Version',
                accessor: 'name',
                maxWidth: 100,
            },
        ],
        [versionData],
    );

    const columns2: Array<Column<Nightly>> = useMemo(
        () => [
            {
                Header: 'Build',
                accessor: 'name',
                maxWidth: 100,
            },
        ],
        [buildData],
    );

    const columns3: Array<Column<Nightly>> = useMemo(
        () => [
            {
                Header: 'Filename',
                accessor: 'name',
                maxWidth: 100,
            },
            {
                Header: '',
                accessor: 'path',
                disableSortBy: true,
                // eslint-disable-next-line react/display-name
                Cell: (cell) => actionMenu(cell.row.original),
                textAlign: 'right',
                hasCellClick: false,
                minWidth: 10,
                maxWidth: 10,
            },
        ],
        [fileData],
    );

    return (
        <ContentContainer
            headline={`List all "${id?.toUpperCase()}" nightly Versions`}
            description="Nightly Version for Android can be downloaded here"
            // showSpinner={fetching}
            // error={error}
        >
            <SimpleGrid columns={3} spacing={10}>
                {tableData1 && (
                    <TableContainer
                        columns={columns1}
                        data={tableData1}
                        rowClick={openVersionDetails}
                        initialState={{
                            sortBy: [{ id: 'Name', asc: true }],
                        }}
                        showNoRecordsFound={false}
                        selectedRowItem={{
                            key: 'name',
                            value: selectedVersion,
                        }}
                        defaultPageSize={50}
                    />
                )}
                {tableData2 && (
                    <TableContainer
                        columns={columns2}
                        data={tableData2}
                        rowClick={openBuildDetails}
                        initialState={{
                            sortBy: [{ id: 'name', asc: true }],
                        }}
                        showNoRecordsFound={false}
                        selectedRowItem={{
                            key: 'name',
                            value: selectedBuild,
                        }}
                        defaultPageSize={50}
                    />
                )}
                {tableData3 && (
                    <TableContainer
                        columns={columns3}
                        data={tableData3}
                        rowClick={openFileDetails}
                        initialState={{
                            sortBy: [{ id: 'Name', desc: true }],
                        }}
                        showNoRecordsFound={false}
                        selectedRowItem={{
                            key: 'name',
                            value: selectedFile,
                        }}
                        defaultPageSize={50}
                    />
                )}
            </SimpleGrid>
        </ContentContainer>
    );
};
