import React, { type FunctionComponent, useState, useMemo } from 'react';
import { type ITransactionsData } from '../../interface/SampleData';
import {
    Card,
    CardHeader,
    Heading,
    CardBody,
    Editable,
    EditablePreview,
    EditableTextarea,
    FormControl,
    Switch,
    Divider,
    Button,
} from '@chakra-ui/react';
import { type Column } from 'react-table';
import { StateOptions } from '../../components/table/StateOptions';
import { colorForState } from '../../helper';
import { TableContainer } from '../../components/table/TableContainer';
import { cloneDeep } from 'lodash';

interface SampleTransactionDataProps {
    selectedTransaction: ITransactionsData;
    saveTransaction: (transaction: ITransactionsData) => void;
}

export const SampleTransactionData: FunctionComponent<SampleTransactionDataProps> = ({
    selectedTransaction,
    saveTransaction,
}: SampleTransactionDataProps) => {
    const [currentlySelectedTransaction, setCurrentlySelectedTransaction] =
        useState<ITransactionsData>(selectedTransaction);

    const handleChange = (
        key: keyof ITransactionsData,
        arrayIndex: number = -1,
        arrayName: keyof ITransactionsData | undefined = undefined,
        value: string | undefined = undefined,
    ): boolean => {
        const tempTransaction: ITransactionsData = cloneDeep(currentlySelectedTransaction);
        if (arrayIndex >= 0 && arrayName && Array.isArray(tempTransaction[arrayName])) {
            const array = tempTransaction[arrayName] as any[];
            if (arrayIndex < array.length) {
                const arrayItem = array[arrayIndex];
                if (typeof arrayItem === 'object' && arrayItem !== null) {
                    arrayItem[key] = value;
                }
            }
        } else {
            if (key === 'expected') {
                tempTransaction.expected = !tempTransaction.expected;
            } else if (key === 'cleared') {
                tempTransaction.cleared = !tempTransaction.cleared;
            }
        }
        setCurrentlySelectedTransaction(tempTransaction);
        return true;
    };

    const saveChanges = (): boolean => {
        saveTransaction(currentlySelectedTransaction);
        return true;
    };

    const amountsTableData = useMemo(() => currentlySelectedTransaction.amounts, [currentlySelectedTransaction]);
    const amountsColumns: Array<Column<any>> = useMemo(
        () => [
            {
                Header: 'key',
                accessor: 'key',
                Cell: (cell) => {
                    return (
                        <>
                            <Editable
                                defaultValue={cell.row.original.key}
                                onChange={(value) =>
                                    handleChange(
                                        'key' as keyof ITransactionsData,
                                        cell.row.index,
                                        'amounts' as keyof ITransactionsData,
                                        value,
                                    )
                                }
                            >
                                <EditablePreview />
                                <EditableTextarea />
                            </Editable>
                        </>
                    );
                },
            },
            {
                Header: 'value',
                accessor: 'value',
                Cell: (cell) => {
                    return (
                        <>
                            <Editable
                                defaultValue={cell.row.original.value.toString()}
                                onChange={(value) =>
                                    handleChange(
                                        'value' as keyof ITransactionsData,
                                        cell.row.index,
                                        'amounts' as keyof ITransactionsData,
                                        value,
                                    )
                                }
                            >
                                <EditablePreview />
                                <EditableTextarea />
                            </Editable>
                        </>
                    );
                },
            },
            {
                Header: 'currency',
                accessor: 'currency',
                Cell: (cell) => {
                    return (
                        <>
                            <Editable
                                defaultValue={cell.row.original.currency}
                                onChange={(value) =>
                                    handleChange(
                                        'currency' as keyof ITransactionsData,
                                        cell.row.index,
                                        'amounts' as keyof ITransactionsData,
                                        value,
                                    )
                                }
                            >
                                <EditablePreview />
                                <EditableTextarea />
                            </Editable>
                        </>
                    );
                },
            },
        ],
        [],
    );

    const datesTableData = useMemo(() => currentlySelectedTransaction.dates, [currentlySelectedTransaction]);
    const datesColumns: Array<Column<any>> = useMemo(
        () => [
            {
                Header: 'key',
                accessor: 'key',
                Cell: (cell) => {
                    return (
                        <>
                            <Editable
                                defaultValue={cell.row.original.key}
                                onChange={(value) =>
                                    handleChange(
                                        'key' as keyof ITransactionsData,
                                        cell.row.index,
                                        'dates' as keyof ITransactionsData,
                                        value,
                                    )
                                }
                            >
                                <EditablePreview />
                                <EditableTextarea />
                            </Editable>
                        </>
                    );
                },
            },
            {
                Header: 'value',
                accessor: 'value',
                Cell: (cell) => {
                    return (
                        <>
                            <Editable
                                defaultValue={cell.row.original.value.toString()}
                                onChange={(value) =>
                                    handleChange(
                                        'value' as keyof ITransactionsData,
                                        cell.row.index,
                                        'dates' as keyof ITransactionsData,
                                        value,
                                    )
                                }
                            >
                                <EditablePreview />
                                <EditableTextarea />
                            </Editable>
                        </>
                    );
                },
            },
        ],
        [],
    );

    const fieldsTableData = useMemo(() => currentlySelectedTransaction.fields, [currentlySelectedTransaction]);
    const fieldsColumns: Array<Column<any>> = useMemo(
        () => [
            {
                Header: 'key',
                accessor: 'key',
                Cell: (cell) => {
                    return (
                        <>
                            <Editable
                                defaultValue={cell.row.original.key}
                                onChange={(value) =>
                                    handleChange(
                                        'key' as keyof ITransactionsData,
                                        cell.row.index,
                                        'fields' as keyof ITransactionsData,
                                        value,
                                    )
                                }
                            >
                                <EditablePreview />
                                <EditableTextarea />
                            </Editable>
                        </>
                    );
                },
            },
            {
                Header: 'value',
                accessor: 'value',
                Cell: (cell) => {
                    return (
                        <>
                            <Editable
                                defaultValue={cell.row.original.value.toString()}
                                onChange={(value) =>
                                    handleChange(
                                        'value' as keyof ITransactionsData,
                                        cell.row.index,
                                        'fields' as keyof ITransactionsData,
                                        value,
                                    )
                                }
                            >
                                <EditablePreview />
                                <EditableTextarea />
                            </Editable>
                        </>
                    );
                },
            },
        ],
        [],
    );

    return (
        <>
            <Card>
                <CardHeader>
                    <Heading size="md">Transaction Details</Heading>
                </CardHeader>

                <CardBody>
                    <Heading size="xs" textTransform="uppercase">
                        Expected
                    </Heading>
                    <FormControl pt="2">
                        <Switch
                            id="isChecked"
                            isChecked={currentlySelectedTransaction.expected}
                            onChange={() => handleChange('expected' as keyof ITransactionsData)}
                        />
                    </FormControl>
                    <Heading size="xs" textTransform="uppercase">
                        Cleared
                    </Heading>
                    <FormControl pt="2">
                        <Switch
                            id="isChecked"
                            isChecked={currentlySelectedTransaction.cleared}
                            onChange={() => handleChange('cleared' as keyof ITransactionsData)}
                        />
                    </FormControl>
                </CardBody>
            </Card>
            <Divider mt="8" />
            <Heading as="h3" size="lg">
                Amounts
            </Heading>
            <TableContainer
                columns={amountsColumns}
                data={amountsTableData}
                search={{
                    placeholder: 'Search for Amounts',
                    selectConfig: {
                        columAccessor: 'state',
                        selectOptions: <StateOptions />,
                    },
                }}
                hiddenColumns={[]}
                showColoredState={true}
                coloredStateMethod={colorForState}
                initialState={{
                    sortBy: [{ id: 'order', desc: true }],
                }}
                defaultPageSize={20}
            />
            <Divider mt="8" />
            <TableContainer
                columns={datesColumns}
                data={datesTableData}
                search={{
                    placeholder: 'Search for Dates',
                    selectConfig: {
                        columAccessor: 'state',
                        selectOptions: <StateOptions />,
                    },
                }}
                hiddenColumns={[]}
                showColoredState={true}
                coloredStateMethod={colorForState}
                initialState={{
                    sortBy: [{ id: 'order', desc: true }],
                }}
                defaultPageSize={20}
            />
            <Divider mt="8" />
            <TableContainer
                columns={fieldsColumns}
                data={fieldsTableData}
                search={{
                    placeholder: 'Search for Fields',
                    selectConfig: {
                        columAccessor: 'state',
                        selectOptions: <StateOptions />,
                    },
                }}
                hiddenColumns={[]}
                showColoredState={true}
                coloredStateMethod={colorForState}
                initialState={{
                    sortBy: [{ id: 'order', desc: true }],
                }}
                defaultPageSize={20}
            />
            <Button onClick={saveChanges} colorScheme="blue" mr="10">
                Save Changes
            </Button>
        </>
    );
};
