import {
    IoAlertCircle,
    IoCheckmarkCircle,
    IoHelp,
    IoHelpCircle,
    IoLogoAmazon,
    IoLogoApple,
    IoLogoGoogle,
    IoTime,
} from 'react-icons/io5';
import { SiHuawei } from 'react-icons/si';
import { type IconType } from 'react-icons';
import { type Platform, type AppStoreVersionStatus } from '../../interface/AppData';

export const labelForPlatform = (platform: Platform): string => {
    switch (platform) {
        case 'IOS':
            return 'iOS';
        case 'MAC_OS':
            return 'macOS';
        case 'GOOGLE':
            return 'Google';
        case 'WEAR_OS':
            return 'Google (Wear OS)';
        case 'HUAWEI':
            return 'Huawei';
        case 'AMAZON':
            return 'Amazon';
    }
};

export const iconForPlatform = (platform: Platform): { as: IconType } => {
    switch (platform) {
        case 'IOS':
        case 'MAC_OS':
            return { as: IoLogoApple };
        case 'GOOGLE':
        case 'WEAR_OS':
            return { as: IoLogoGoogle };
        case 'HUAWEI':
            return { as: SiHuawei };
        case 'AMAZON':
            return { as: IoLogoAmazon };
        default:
            return { as: IoHelp };
    }
};

export const storeNameForPlatform = (platform: Platform): string => {
    switch (platform) {
        case 'IOS':
        case 'MAC_OS':
            return 'App Store';
        case 'GOOGLE':
        case 'WEAR_OS':
            return 'Play Store';
        case 'HUAWEI':
            return 'App Gallery';
        case 'AMAZON':
            return 'Appstore';
        default:
            return 'App Store';
    }
};

export const iconForState = (status: AppStoreVersionStatus): { as: IconType; color: string } => {
    switch (status.type) {
        case 'released':
            return { as: IoCheckmarkCircle, color: 'green.500' };
        case 'pending':
            return { as: IoTime, color: 'yellow.400' };
        case 'issue':
            return { as: IoAlertCircle, color: 'red.600' };
        case 'unknown':
            return { as: IoHelpCircle, color: 'gray.400' };
    }
};
