import { Button, Divider, FormControl, Heading, Switch } from '@chakra-ui/react';
import { TableContainer } from '../../../components/table/TableContainer';
import React, { type FunctionComponent, useMemo } from 'react';
import { type Column } from 'react-table';
import { type ITransactionsData } from '../../../interface/SampleData';
import { StateOptions } from '../../../components/table/StateOptions';
import { colorForState } from '../../../helper';

interface TransactionsTableProps {
    transactionsData: ITransactionsData[];
    openEditTransactions: (transaction: ITransactionsData) => void;
    addTransaction: (transaction: ITransactionsData) => void;
}

export const TransactionsTable: FunctionComponent<TransactionsTableProps> = ({
    transactionsData,
    openEditTransactions,
    addTransaction,
}: TransactionsTableProps) => {
    const createTransaction = (): void => {
        const newTransaction: ITransactionsData = {
            expected: false,
            cleared: false,
            amounts: [
                {
                    key: 'newKey',
                    value: 0,
                    currency: 'newCurrency',
                },
            ],
            dates: [
                {
                    key: 'bookingDate',
                    value: 0,
                },
            ],
            fields: [
                {
                    key: 'newField',
                    value: 'newValue',
                },
            ],
        };
        addTransaction(newTransaction);
    };

    const transactionsTableData = useMemo(() => transactionsData, [transactionsData]);
    const transactionsColumns: Array<Column<ITransactionsData>> = useMemo(
        () => [
            {
                Header: 'expected',
                accessor: 'expected',
                Cell: (cell) => {
                    return (
                        <FormControl pt="2">
                            <Switch id="isChecked" isChecked={cell.row.original.expected} />
                        </FormControl>
                    );
                },
            },
            {
                Header: 'cleared',
                accessor: 'cleared',
                Cell: (cell) => {
                    return (
                        <FormControl pt="2">
                            <Switch id="isChecked" isChecked={cell.row.original.cleared} />
                        </FormControl>
                    );
                },
            },
            {
                Header: 'amounts',
                accessor: 'amounts',
                Cell: (cell) => {
                    return (
                        <>
                            {cell.row.original.amounts.map((amount: any, index: any) => (
                                <p key={index}>{amount.value + ' ' + amount.currency}</p>
                            ))}
                        </>
                    );
                },
            },
            {
                Header: 'dates',
                accessor: 'dates',
                Cell: (cell) => {
                    return (
                        <>
                            {cell.row.original.dates.map((date: any, index: any) => (
                                <p key={index}>{date.key + ' ' + date.value}</p>
                            ))}
                        </>
                    );
                },
            },
            {
                Header: 'fields',
                accessor: 'fields',
                Cell: (cell) => {
                    return (
                        <>
                            {cell.row.original.fields.map((field: any, index: any) => (
                                <p key={index}>{field.key + ' ' + field.value}</p>
                            ))}
                        </>
                    );
                },
            },
        ],
        [],
    );

    return (
        <>
            <Divider mt="8" />
            <Heading as="h3" size="lg" mt="8">
                Transactions
            </Heading>
            <TableContainer
                columns={transactionsColumns}
                data={transactionsTableData}
                search={{
                    placeholder: 'Search for transactions',
                    selectConfig: {
                        columAccessor: 'state',
                        selectOptions: <StateOptions />,
                    },
                }}
                hiddenColumns={[]}
                showColoredState={true}
                coloredStateMethod={colorForState}
                initialState={{
                    sortBy: [{ id: 'order', desc: true }],
                }}
                rowClick={openEditTransactions}
                defaultPageSize={20}
            />
            <Button onClick={createTransaction} colorScheme="blue" mr="10">
                Create new transaction
            </Button>
            <Divider mt="8" />
        </>
    );
};
