import React, { type FunctionComponent } from 'react';
import { MenuItem } from '@chakra-ui/react';
import { StateIcon } from './StateIcon';
import { labelForState } from '../helper';
import { type StateType } from '../generated/types';

interface Props {
    state: StateType;
    currentState: StateType;
    recordId: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    click: Function;
    additionalClickArgs?: unknown[];
    showAll?: boolean;
}

export const StateMenuItem: FunctionComponent<Props> = (props: Props) => {
    const {
        state,
        currentState,
        recordId,
        additionalClickArgs: additionalFunctionArgs = [],
        click,
        showAll = false,
    } = props;
    if (!showAll && (currentState === state || !currentState)) return <></>;
    return (
        <MenuItem
            icon={<StateIcon state={state} />}
            onClick={async () => click(recordId, state, ...additionalFunctionArgs)}
        >
            Mark as {labelForState(props.state)}
        </MenuItem>
    );
};
